import { CheckCircleIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  useActionData,
  useLocation,
  useParams,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import warnignIcon from "../../../assets/images/Ellipse2.svg";
import signInImage from "../../../assets/images/newcropped.png";
import {
  AppContext,
  PosAlert,
  PosMainProgress,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";

const RsetPassword = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [originalKey, setOriginalKey] = useState("");
  const location = useLocation();
  const { key } = useParams();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const actionResponse = useActionData();

  useEffect(() => {
    if (!originalKey && key) {
      setOriginalKey(key);
    }
  }, [key, originalKey]);

  const formik = useFormik({
    initialValues: {
      confirmPassword: "",
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, Constants.PASSWORD_MUST_BE_8_CHARACHTER)
        .required(Constants.PASSWORD_FIELD_REQUIRED)
        .oneOf(
          [Yup.ref(Constants.PASSWORD)],
          Constants.YOU_ENTERED_THE_WRONG_PASSWORD
        ),
      confirmPassword: Yup.string()
        .required(Constants.CONFIRM_PASSWORD_FIELD_REQUIRED)
        .oneOf([Yup.ref("password")], Constants.SAME_PASSWORD),
    }),
    onSubmit: (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      // const token = key.split("=")[1];
      // values.token = token;
      values.token = originalKey.split("=")[1];
      submit(values, {
        method: Constants.POST,
        path: Constants.RESET_PASSWORD_PATH,
      });
    },
  });

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      myContext.handleLoading(false);
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
      } else {
        setShowSuccessMessage(true);
        props.navigate(Constants.SIGNIN_PATH);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: "Reset Password",
          alertDescription: actionResponse.data.message,
        });
      }
    }
  }, [actionResponse]);
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.confirmPassword.length <= 0 ||
        formik.errors.confirmPassword ||
        formik.values.password.length <= 0 ||
        formik.errors.password ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  return (
    <Flex
      bg={Colors.loginAuthBackground}
      minHeight={"100vh"}
      flexDirection={"column"}
    >
      <Box
        w={"100%"}
        sx={{
          position: "-webkit-sticky",
          /* Safari */ position: "sticky",
          top: "0",
          zIndex: 999,
        }}
      >
        <Box
          // w={"100%"}
          sx={{
            position: "-webkit-sticky",
            /* Safari */ position: "sticky",
            top: "0",
            zIndex: 99999999,
          }}
        >
          {myContext.loading ? <PosMainProgress /> : null}
        </Box>
        <Flex
          h={"100vh"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          {" "}
          <Box
            // mt={{ base: 16, md: 20, sm: 16, lg: 28 }}/
            // mb={{ base: 16, md: 20, sm: 16, lg: 28 }}
            bg={Colors.loginAuthBackground}
            style={{
              width: "54.13rem",
              borderRadius: "0.63rem",
              border: "0.13rem solid #FFF",
              boxShadow: "0rem 1.25rem 3.13rem 0rem #59595926",
              paddingBottom: "2.81rem",
              boxSizing: "border-box",
            }}
          >
            {/* <Box
          bg={"red"}
          h={8}
          w={72}
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={20}
        >
          <Alert status="success" variant="subtle">
            <CheckIcon />
            Password Successfully changed!
          </Alert>
        </Box> */}
            {showSuccessMessage && (
              <Box
                gap={2}
                bg={"#14FF3A24"}
                style={{
                  borderRadius: "0.63rem",
                }}
                color="#09B825"
                h={8}
                w={72}
                ml={72}
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={20}
              >
                <CheckCircleIcon color="#047E17" />
                Password Successfully changed!
              </Box>
            )}
            <Box display="flex" justifyContent="center" alignItems="center">
              <Image
                style={{
                  width: "15.31rem",
                  marginTop: "4.69rem",
                }}
                src={signInImage}
                alt="Octopos"
              />
            </Box>
            <Box
              color={Colors.posTextInfo}
              style={{
                marginTop: "2.63rem",
                fontSize: "1.5rem",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text>Create New Password</Text>
            </Box>
            <Box
              color={Colors.posTextInfo}
              style={{
                marginTop: "0.31rem",
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            ></Box>
            <Box mx={{ base: 4, md: 156 }} textAlign="left">
              <Stack spacing={2}>
                <Text
                  mt={{ base: 20, md: 20, sm: 16, lg: 28 }}
                  color={Colors.posTextInfo}
                  style={{
                    fontSize: "0.88rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                >
                  Please enter a new password
                </Text>
                <InputGroup
                  bg={Colors.loginAuthInput}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    flexShrink: 0,
                    borderRadius: "0.63rem",
                  }}
                >
                  <InputLeftElement pointerEvents="none">
                    <LockIcon color={Colors.primeTaxInfo} />
                  </InputLeftElement>
                  <Input
                    placeholder="Password"
                    name="password"
                    type="password"
                    onKeyDown={handleKeyDown}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                </InputGroup>
                {formik.touched.password && formik.errors.password ? (
                  <PosAlert
                    alertStatus={"error"}
                    alertTitle={""}
                    width={{ base: "100%" }}
                    image={warnignIcon}
                    bg={Colors.errorsign}
                    color={Colors.orangeButton}
                    alertDescription={formik.errors.password}
                  />
                ) : null}

                <InputGroup
                  bg={Colors.loginAuthInput}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    flexShrink: 0,
                    borderRadius: "0.63rem",
                  }}
                >
                  <InputLeftElement pointerEvents="none">
                    <LockIcon color={Colors.primeTaxInfo} />
                  </InputLeftElement>
                  <Input
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    onKeyDown={handleKeyDown}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                  />
                </InputGroup>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <PosAlert
                    alertStatus={"error"}
                    alertTitle={""}
                    width={{ base: "100%" }}
                    image={warnignIcon}
                    bg={Colors.errorsign}
                    color={Colors.orangeButton}
                    alertDescription={formik.errors.confirmPassword}
                  />
                ) : null}

                <Button
                  mt={10}
                  color={Colors.loginButtonText}
                  bg={Colors.loginButtonBackground}
                  onClick={formik.handleSubmit}
                  w={"100%"}
                  isDisabled={
                    formik.values.password.length <= 0 ||
                    formik.errors.confirmPassword ||
                    formik.errors.password ||
                    buttonDisable
                      ? true
                      : false
                  }
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "0.63rem",
                    flexShrink: 0,
                    gap: "0.63rem",
                    borderRadius: "0.63rem",
                    border: "1px solid #DEE6F9",
                    boxShadow: "0px 1.63rem 2.5rem 0px #e3e3e340",
                    opacity: 1,
                  }}
                >
                  Create New Password
                </Button>
              </Stack>
            </Box>
            <Box
              color={Colors.loginFooter}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10rem",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
              }}
            >
              <CopyrightIcon
                style={{
                  width: "1.13rem",
                  height: "1.13rem",
                  flexShrink: 0,
                  marginRight: "0.25rem",
                }}
              />
              <Text>
                2024,
                <Link target="_blank" href="https://www.octopos.com/">
                  Octopos.
                </Link>
                All rights reserved
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default RouteWithLayout(RsetPassword);
