import { ChakraProvider } from "@chakra-ui/react";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Mtheme, theme } from "./theme/index";
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={Mtheme}>
      <PrimeReactProvider>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </PrimeReactProvider>
    </ThemeProvider>
);
