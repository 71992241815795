import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  useMediaQuery,
} from "@chakra-ui/react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit, useLocation } from "react-router-dom";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosFormButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  PosIconButton,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import { twofix,commonDateFormate } from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";

const OutstandingGiftCardLiability = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [totalDailySales, seltTotalDailySales] = useState([]);
  const stateData = useLocation();
  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });

  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "created_at",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );
  const giftCardPage = (id) => {
    var id = id;
    if (
      null != id &&
      "" != id &&
      undefined != id
    ) {
      myContext.handleLoading(true);
      let payload = {
        screen: "Outstanding",
        limit: paginationModel.pageSize,
        page: paginationModel.page,
        order:
          sortModel.length > 0
            ? sortModel[0].field
            : sortRef.current[0].field,
        order_type:
          sortModel.length > 0
            ? sortModel[0].sort
            : sortRef.current[0].sort,
      };
      props.navigate(Constants.GIFT_CARD_VIEW + id, {
        state: payload,
      });
    }
  };
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 150 } : { flex: 0.8 };
  const actionColumnFlexDouble = isMobile ? { width: 200 } : { flex: 1.5 };
  const columnNames = [
    { field: "sr_no", headerName: "Sr. No.", sortable: false, width: 90 },
    { field: "code", headerName: "Code", sortable: false, ...actionColumnFlexDouble },
    {
      field: "balance",
      headerName: "Balance($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "created_by",
      headerName: "Created By",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "created_at",
      headerName: "Created At(UTC)",
      sortable: true,
      ...actionColumnFlexDouble
    },
    {
      field: "updated_at",
      headerName: "Last Used(UTC)",
      sortable: true,
      ...actionColumnFlexDouble
    },
    {
      field: "view",
      headerName: "Action",
      sortable: false,
      disableExport: true,
      resizable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          myContext.handleLoading(true);
          giftCardPage(rowData.id);
          myContext.handleLoading(false);
        };
        return (
          <PosIconButton
            name={Constants.VIEW_BUTTON}
            onClick={handleView}
            viewIcon={true}
            width={"4.625rem"}
          />
        );
      },
    },
  ];

  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);

  useEffect(() => {
    if (effectRun.current === true) {
      getOutStandingGiftCard();
        myContext.handleLoading(false);
      effectRun.current = false;
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Outstanding Gift Card Liability Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "OutstandingGiftCardLiability.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else if (
          actionResponse.data?.message === "Pdf generated successfully."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, Constants.PDF_FILE_NAME);
          document.body.appendChild(link);
          window.stop();
          // link.click();
          window.open(url, "_blank");
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.outstandingGiftCard);
          setTotalCount(actionResponse.data.data.totalItems);
          let data = [
            "Total Balance($)",
            twofix(actionResponse.data.data.totalGiftCardBalance),
          ];
          seltTotalDailySales(data);
          setLoading(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
      }
    }
  }, [actionResponse]);

  const handleExportReport = () => {
    myContext.handleLoading(true);
    let data = {
      exportReport: true,
      query: JSON.stringify({
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      }),
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.OUTSTATING_GIFT_CARD_LIABILITY,
    });
  };
  const handlePdfReport = () => {
    myContext.handleLoading(true);
    let data = {
      exportReport: false,
      query: JSON.stringify({
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      }),
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.OUTSTATING_GIFT_CARD_LIABILITY,
    });
  };
  const getOutStandingGiftCard = () => {
    setLoading(true);
    myContext.handleLoading(true);
    let data = {
      getreport: true,
      query: JSON.stringify({
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      }),
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.OUTSTATING_GIFT_CARD_LIABILITY,
    });
  };

  const modifiedData = tableData.map((data) => ({
    sr_no: data.sr_no,
    id: data.id,
    code: data.code,
    balance: data.balance,
    type: data.type,
    created_by: data.user.name,
    created_at: commonDateFormate(data.created_at),
    updated_at: commonDateFormate(data.updated_at),
  }));
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Outstanding Gift Card Liability"]}
          breadCrumTitle={"Outstanding Gift Card Liability"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              Export
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleExportReport}>Export Excel</MenuItem>
              <MenuItem onClick={handlePdfReport}>Export PDF</MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Box p={4}>
          {loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <Table>
                <TableHead sx={{ backgroundColor: Colors.modifierTable }}>
                  <TableRow>
                    {totalDailySales.map((column, index) => {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            lineHeight: "1.18rem",
                            letterSpacing: "-1.5%",
                            color: Colors.posTextInfo,
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {column}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              </Table>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPaginationModel) => {
                  effectRun.current = true;
                  setPaginationModel(newPaginationModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  effectRun.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.OUTSTATING_GIFT_CARD_REPORT_CSV}
              />
            </Flex>
          ) : (
            <PosNoDataFound
              title={Constants.OUTSTATING_GIFT_CARD_NO_DATA}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(OutstandingGiftCardLiability);
