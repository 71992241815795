import React, { useEffect, useRef, useState,forwardRef,
  useImperativeHandle } from "react";

import {
  Box,
  Link,
  Stack,
  Text,
  VisuallyHiddenInput,
  useMediaQuery,
} from "@chakra-ui/react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Colors from "../../assets/colors/Colors.js";
import { PosTostMessage } from "../../components/index";
import * as Constants from "../../constants/Constants.js";
import { canvasPreview } from "./canvasPreview.ts";
import { useDebounceEffect } from "./useDebounceEffect.ts";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
  cropperWidth: number,
  cropperHeight: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px",
        width: cropperWidth,
        height: cropperHeight,
      },
      aspect,
      cropperWidth,
      cropperHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const PosImageCropper = forwardRef((props: Record<string, any>, ref) => {

  useImperativeHandle(ref, () => {
      return {
        chooseImgIconClick,
      };
  });
  const { addToast } = PosTostMessage();

  const [isScreenSize1280] = useMediaQuery("(max-width: 1280px)");
  const [isScreenSize992] = useMediaQuery("(max-width: 992px)");
  const [isScreenSize480] = useMediaQuery("(max-width: 480px)");

  const [imgSrc, setImgSrc] = useState(props?.image?.ImageData || "");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>(props?.image?.CropData);
  const [aspect, setAspect] = useState<number | undefined>(
    props.cropperWidth / props.cropperHeight
  );
  const [imgDetails, setImgDetails] = useState({});
  const [newImgUploadFlag, setNewImgUploadFlag] = useState(0);

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const logoInputElement = document.getElementById("logo-input");

  useEffect(() => {
    if (props.hasOwnProperty("oldImageBase64")) {
      if(props.hasOwnProperty("handleImage")){
        props.handleImage({ ImageData:props.oldImageBase64,CropData:completedCrop})
      }
      setImgSrc(props.oldImageBase64);
    }
  }, [props.oldImageBase64]);

  // let hiddenFileInput = null;
  const handleBrowse = function (e) {
    e.preventDefault();
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
    // hiddenFileInput.click();
  };

  const chooseImgIconClick = () => {
    if (null != imgSrc && "" != imgSrc) {
      if(props.hasOwnProperty("handleImage")){
        props.handleImage({ ImageData:"",CropData:undefined})
      }
      setImgSrc("");
      setCrop(undefined);
      setCompletedCrop(undefined);
      setImgDetails({});
      props.parentCallback(null, null, null);
      if (logoInputElement) {
        (logoInputElement as HTMLInputElement).value = "";
      }
    }
  };

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      let files = e.target.files;
      if (files.length != 0) {
        if (
          files[0].type.toLowerCase() !== Constants.IMAGE_TYPE_PNG &&
          files[0].type.toLowerCase() !== Constants.IMAGE_TYPE_JPG &&
          files[0].type.toLowerCase() !== Constants.IMAGE_TYPE_WEBP
        ) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: "Warning",
            alertDescription: Constants.IMAGE_TYPE_VALIDATION,
          });
          resetCropper();
          // console.error(Constants.IMAGE_TYPE_VALIDATION);
          return;
        }
        if (files[0].size >= Number(Constants.UPLOAD_IMAGE_SIZE) && undefined == props.slideShow) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: "Warning",
            alertDescription: Constants.IMAGE_SIZE_VALIDATION,
          });
          resetCropper();
          // console.error(Constants.IMAGE_SIZE_VALIDATION);
          return;
        }
        else if(files[0].size >= Number(Constants.UPLOAD_IMAGE_SIZE_SLIDE_SHOW) && 1 == props.slideShow){
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: "Warning",
            alertDescription: Constants.SLIDE_SHOW_IMAGE_SIZE_VALIDATION,
          });
          resetCropper();
          return;
        }
      }
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>{
        if(props.hasOwnProperty("handleImage")){
          props.handleImage({ ImageData:reader.result?.toString() || "",CropData:completedCrop})
        }
        setImgSrc(reader.result?.toString() || "")
      }
      );
      reader.readAsDataURL(e.target.files[0]);
      setImgDetails(files[0]);
      setNewImgUploadFlag(1);
    }
  }

  const resetCropper = () => {
    if(props.hasOwnProperty("handleImage")){
      props.handleImage({ ImageData:"",CropData:completedCrop})
    }
    setImgSrc('');
    setCrop(undefined);
    setImgDetails({});
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      // set width of cropper according to image size. if image width is greater than cropper width then use cropper's actual width else use image width as cropper width.
      if (width > props.cropperWidth) {
        var cropperWidth = props.cropperWidth;
      } else {
        cropperWidth = width;
      }
      // set height of cropper according to image size. if image height is greater than cropper height then use cropper's actual height else use image height as cropper height.
      if (height > props.cropperHeight) {
        var cropperHeight = props.cropperHeight;
      } else {
        cropperHeight = height;
      }
      setCrop(
        centerAspectCrop(width, height, aspect, cropperWidth, cropperHeight)
      );
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
          //   scale,
          //   rotate,
        );
        props.parentCallback(previewCanvasRef.current, imgDetails, {
          newImgUploadFlag: newImgUploadFlag,
        });
      }
    },
    100,
    [completedCrop]
  );    
  return (
    <>
      <Box>
        <Text
          color={Colors.inputHeadingFont}
          fontSize={"0.94rem"}
          fontStyle={"normal"}
          fontWeight={"300"}
          lineHeight={"normal"}
        >
          {props.imgUploadLabel}
        </Text>
        <Stack direction={"column"}>
          <Stack
            flexDirection={
              isScreenSize1280 && isScreenSize992 && isScreenSize480
                ? "column"
                : isScreenSize1280 && isScreenSize992 && !isScreenSize480
                ? "row"
                : isScreenSize1280 && !isScreenSize992 && !isScreenSize480
                ? "column"
                : "row"
            }
            spacing={"1.50rem"}
            mt={{ base: "0.88rem", md: "0.88rem" }}
            // gap={"0rem"}
          >     
            <Box>
              {!!imgSrc && (
               <ReactCrop // Cropper issue solved don't change any code
               crop={crop}
               onChange={(_, percentCrop) => setCrop(percentCrop)}
               onComplete={(c) => {
                if(props.hasOwnProperty("handleImage")){
                props.handleImage({ ImageData:imgSrc,CropData:c})
                }
                setCompletedCrop(c)
              }}
               aspect={aspect}
               minWidth={props.cropperMinWidth}  // Minimum width of the crop area
               maxWidth={props.cropperMaxWidth}  // Maximum width of the crop area
               minHeight={props.cropperMinHeight}  // Minimum height of the crop area
               maxHeight={props.cropperMaxHeight}  // Maximum height of the crop area
               style={{
                 maxHeight: props.canvasHeight,
                 maxWidth: props.canvasWidth,
                 objectFit: "contain",
               }}
               keepSelection
             >
               <img
                 ref={imgRef}
                 alt="Crop me"
                 src={imgSrc}
                 onLoad={onImageLoad}
                 style={{
                   minHeight: props.cropperMinHeight,  // Set minHeight based on aspect ratio
                   minWidth: props.cropperMinWidth,  // Fixed minWidth
                   maxHeight: props.cropperMaxHeight,  // Set maxHeight based on aspect ratio
                   maxWidth: props.cropperMaxWidth,  // Fixed maxWidth
                   objectFit: "contain",
                 }}
               />
             </ReactCrop>
              )}
            </Box>
            <Box>
              {!!completedCrop && (
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    // objectFit: "contain",
                    // width: completedCrop.width,
                    // height: completedCrop.height,
                    width: undefined == props.slideShow ? 236 : completedCrop.width,
                    height: undefined == props.slideShow ? 193 : completedCrop.height,
                    // width: "210px",
                    // height: "150px",
                  }}
                />
              )}
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={"0.25rem"}>
            {!imgSrc ? (
              <CameraAltIcon
                onClick={() => chooseImgIconClick()}
                style={{ height: "1.25rem", width: "1.25rem" }}
              />
            ) : (
              <DeleteIcon
                onClick={() => chooseImgIconClick()}
                style={{ height: "1.25rem", width: "1.25rem", color: "red" }}
              />
            )}
            <VisuallyHiddenInput
              id="logo-input"
              ref={hiddenFileInput}
              type="file"
              onChange={onSelectFile}
              accept="image/*"
            />
            <Link
              mt={"0.1rem"}
              fontSize={"0.75rem"}
              fontStyle={"normal"}
              fontWeight={"500"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              color={Colors.posNavbarLink}
              onClick={handleBrowse}
            >
              Choose Image
            </Link>
          </Stack>
        </Stack>
      </Box>
    </>
  );
});

export default PosImageCropper