import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,Text,useMediaQuery
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData,useLocation, useSubmit } from "react-router-dom";
import {
  AppContext,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTab,
  PosTable,
  PosDataGridTable,
  PosSpliteButton,
  PosTostMessage,
} from "../../../components/index";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Colors from "../../../assets/colors/Colors";
import { GridToolbar } from "@mui/x-data-grid";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getAllDeactiveTareContainer,
  getAllTareContainer,
} from "./TareContainerService";
const TareContainerList = (props) => {
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const stateData = useLocation();
  const effectRun = useRef(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 200 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [tareContainerData, setTareContainerData] = useState([]);
  const [tareConatinerDeactiveData, setTareContainerDeactiveData] = useState(
    []
  );
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page-1
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const sortRef = useRef(sortModel);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page: stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:  undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"id",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"desc",
    },
  ]);
  const sortRefDeactive = useRef(sortModelDeactive);
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true,width: 90  },
    { field: "name", headerName: "Name", sortable: true, ...actionColumnFlexDouble, },
    { field: "weight", headerName: "Weight(lb)", sortable: true, ...actionColumn, },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
            order_type:
              sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
            filter: searchQuery,
          };
          myContext.handleLoading(true);
          props.navigate(`${Constants.TARE_CONTAINER_EDIT + item.id}`,{
            state: data,
          });
        };
       
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if(tareContainerData.length == 1 && paginationModel.page != 0){
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.PRODUCT_TARE_PATH,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "Deactive",
              disabledButton:buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];
  const deactiveColumnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90  },
    { field: "name", headerName: "Name", sortable: true, ...actionColumnFlexDouble,},
    { field: "weight", headerName: "Weight(lb)", sortable: true,...actionColumn, },

    {
      field: "reactive",
      headerName: "Reactive",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;

        const handleReactive = () => {
          setButtonDisable(true);
          if(tareConatinerDeactiveData.length == 1 && paginationModelDeactive.page != 0){
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.PRODUCT_TARE_PATH,
          });
        };
        return (
          <Text
            // onClick={handleReactive} 
            // cursor="pointer"
            color={Colors.posNavbarLink}
            // fontSize="1rem"
            // letterSpacing="-1.5%"
            // textDecoration="underline"
            // fontWeight={600}
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            // color={buttonDisable ? "gray" : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }}
          >
            Activate
          </Text>
        );
      },
    },
  ];
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllTareContainer(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTareContainerData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setTareContainerData([]);
            setTotalCount(0);
            setLoading(false);
            myContext.handleLoading(false);

            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setTareContainerData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setTareContainerData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchDeActiveTareContainerData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].field : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].sort : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllDeactiveTareContainer(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTareContainerDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setTareContainerDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!deactiveSearchHideRef.current && err?.response?.data?.data === 0) {
            deactiveSearchHideRef.current = true;
          }
          setTareContainerDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setTareContainerDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveTareContainerData();
      }
      effectRun.current = false;
    }
  } ,[paginationModel,sortModel,loading,searchQuery,paginationModelDeactive,sortModelDeactive]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.TARE_CONTAINER_MODULE,
            alertDescription: Constants.TARE_CONTAINER_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          fetchDeActiveTareContainerData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.TARE_CONTAINER_MODULE,
            alertDescription: Constants.TARE_CONTAINER_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
    }
  }, [actionResponse]);
  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0){
      deactiveSearchHideRef.current = false;
    } else {
      searchInputHideRef.current = false;
    }
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setPaginationModelDeactive({
      page: 0,
      pageSize:25,
    });
    setSortModelDeactive([
      {
        field:"id",
        sort: "desc",
      },
    ]);
    setSearchQuery("");
    effectRun.current = true;
    setLoading(true);
  };
  const handleSearchList = () => {
    paginationModel.page = 0;
    props.navigate(Constants.PRODUCT_TARE_PATH);
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveTareContainerData();
    }
  };
  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.PRODUCT_TARE_PATH);
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Tare Containers"}
          discription={"Manage tare containers for your products"}
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => { 
              myContext.handleLoading(true); 
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page + 1,
                order:
                  sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
                filter:curruntTab == 1 ? "":searchQuery ,
              };
              props.navigate(Constants.TARE_CONTAINER_CREATE , {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              // onChange={(e) => setSearchQuery(e.target.value)}
              onChange={(e) => {
                let value =
                  searchQuery.length > 0
                    ? e.target.value
                    : e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
            <Spacer />
          </Flex>
           ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
            ) : null}
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0&& curruntTab === 0 ? (
                <PosDataGridTable
                columns={columnNames}
                rows={tareContainerData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={(newPaginationModel) => {
                  effectRun.current = true;
                  setPaginationModel(newPaginationModel);
                }}
                sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  sortRef.current = sortModel;
                  effectRun.current = true;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.ACTIVE_TARE_EXPORT_FILENAME}
              />
              ) : (
                <PosNoDataFound title={Constants.TARE_CONTAINER_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                <PosDataGridTable
                  columns={deactiveColumnNames}
                  rows={tareConatinerDeactiveData}
                  totalcount={deactiveTotalCount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  onPaginationModelChange={(newPaginationModelDeactive) => {
                    effectRun.current = true;
                    setPaginationModelDeactive(newPaginationModelDeactive);
                  }}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.DEACTIVE_TARE_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound
                  title={Constants.DEACTIVE_TARE_CONTAINER_NOT_FOUND}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default WithRouter(TareContainerList);
