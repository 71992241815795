import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  ButtonGroup,
  useMediaQuery,
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTab,
  PosTable,
  PosTostMessage,
  PosIconButton,
  PosDataGridTable,
  PosSpliteButton,
} from "../../../components/index";
import { GridToolbar } from "@mui/x-data-grid";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllDeactiveEmployees, getAllEmployees } from "./EmployeesService";
import VisibilityIcon from "@mui/icons-material/Visibility";
const EmployeesList = (props) => {
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const actionResponse = useActionData();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [employeesDeactiveData, setEmployeesDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const effectRun = useRef(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page - 1
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const sortRef = useRef(sortModel);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const sortRefDeactive = useRef(sortModelDeactive);
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Employee Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "roles",
      headerName: "Employee Roles",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "email",
      headerName: "Email Address",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          myContext.handleLoading(true);
          props.navigate(`${Constants.EMPLOYEES_EDIT + item.id}`, {
            state: data,
          });
        };
        const handleViewPage = (item) => {
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          myContext.handleLoading(true);
          props.navigate(`${Constants.EMPLOYEES_VIEW + item.id}`, {
            state: data,
          });
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          const employee = employeesData.find(
            (employee) => employee.id === item.id
          );
          const { email, name } = employee;
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
            email: email,
            name: name,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.EMPOYEES_LIST_ROUTE,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            // {
            //   name: "View",
            //   icon: (
            //     <RemoveRedEyeOutlinedIcon
            //       style={{ color: Colors.posViewTextColor }}
            //     />
            //   ),
            //   onClick: (item) => handleViewPage(item),
            // },
            {
              name: "Deactive",
              disabledButton: buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];

  const deactiveColumnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Employee Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "roles",
      headerName: "Employee Roles",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "email",
      headerName: "Email Address",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          setButtonDisable(true);
          if (
            deactiveEmployeesData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          const employee = employeesDeactiveData.find(
            (employee) => employee.id === rowData.id
          );
          const { email, name } = employee;
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
            email: email,
            name: name,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.EMPOYEES_LIST_ROUTE,
          });
        };
        return (
          <Text
            // onClick={handleReactive}
            // cursor="pointer"
            color={Colors.posNavbarLink}
            // fontSize="1rem"
            // letterSpacing="-1.5%"
            // textDecoration="underline"
            // fontWeight={600}
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            // color={buttonDisable ? "gray" : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }}
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const deactiveAllEmployeesData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllDeactiveEmployees(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setEmployeesDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setEmployeesDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            myContext.handleLoading(false);

            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setEmployeesDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          setLoading(false);
          myContext.handleLoading(false);

          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      setEmployeesDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllEmployees(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setEmployeesData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setEmployeesData([]);
            setTotalCount(0);
            setLoading(false);
            myContext.handleLoading(false);

            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setEmployeesData([]);
          setTotalCount(0);
          setLoading(false);
          myContext.handleLoading(false);

          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      setEmployeesData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const modifiedData = employeesData.map((employees) => ({
    id: employees.id,
    name: employees.name,
    email: employees.email,
    roles: employees.roles.map((m) => " "+ m.display_name),
  }));
  const deactiveEmployeesData = employeesDeactiveData.map(
    (deactiveEmployees) => ({
      id: deactiveEmployees.id,
      name: deactiveEmployees.name,
      email: deactiveEmployees.email,
      roles: deactiveEmployees.roles.map((m) => " "+m.display_name),
    })
  );
  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        deactiveAllEmployeesData();
      }
      effectRun.current = false;
    }
  }, [
    paginationModel,
    sortModel,
    loading,
    searchQuery,
    paginationModelDeactive,
    sortModelDeactive,
  ]);

  const handleTabChange = (index) => {
    setCurruntTab(index);
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setPaginationModelDeactive({
      page: 0,
      pageSize: 25,
    });
    setSortModelDeactive([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setSearchQuery("");
    effectRun.current = true;
    setLoading(true);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
    } else {
      searchInputHideRef.current = false;
    }
  };
  const handleSearchList = () => {
    paginationModel.page = 0;
    props.navigate(Constants.EMPOYEES_LIST_ROUTE);
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      deactiveAllEmployeesData();
    }
  };
  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.EMPOYEES_LIST_ROUTE);
  };
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.EMPLOYEES_MODULE,
            alertDescription: Constants.EMPLOYEES_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          deactiveAllEmployeesData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.EMPLOYEES_MODULE,
            alertDescription: Constants.EMPLOYEES_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
    }
  }, [actionResponse]);
  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <Box p="2">
          <Heading
            as="h2"
            size="lg"
            fontWeight="600"
            fontSize="1.5rem"
            lineHeight="1.875rem"
            letterSpacing="-0.0225rem"
            color="#010048"
          >
            Employees
          </Heading>
          <Text color="rgba(1, 0, 72, 0.6)" fontSize="xs">
            Manage employee roles and identification
          </Text>
        </Box>
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true); 
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page + 1,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                filter: curruntTab == 1 ? "" : searchQuery,
              };
              props.navigate(Constants.EMPLOYEES_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                // onChange={(e) => setSearchQuery(e.target.value)}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <PosDataGridTable
                  columns={columnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    effectRun.current = true;
                    setSortModel(newSortModel);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.ACTIVE_EMP_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound title={Constants.EMPLOYEES_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                <PosDataGridTable
                  columns={deactiveColumnNames}
                  rows={deactiveEmployeesData}
                  totalcount={deactiveTotalCount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  onPaginationModelChange={(newPaginationModelDeactive) => {
                    effectRun.current = true;
                    setPaginationModelDeactive(newPaginationModelDeactive);
                  }}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.DEACTIVE_EMP_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound
                  title={Constants.DEACTIVE_EMPLOYEES_NOT_FOUND}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default WithRouter(EmployeesList);
