import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function dailyInventoryReportAndExport({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).getreport === "true") {
      const payload = JSON.stringify({
        data: JSON.parse(Object.fromEntries(formData).data),
        query: JSON.parse(Object.fromEntries(formData).query),
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else {
      const payload = JSON.stringify({
        data: JSON.parse(Object.fromEntries(formData).data),
        type: Object.fromEntries(formData).type,
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_EXPORT_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_EXPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getAllLocationsForDailyInventoryMoveReport({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GET_ALL_LOCATIONS_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_EXPORT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}