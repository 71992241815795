import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function unitOfMeasureCreate({ request, params }) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      let formData = await request.formData();
      const payload = {
      };
      payload.unit_of_measures = JSON.parse(
        Object.fromEntries(formData).unit_of_measures
      );
      payload.unit_of_measure = Object.fromEntries(formData).unit_of_measure;
      payload.purchasing_unit_type = Object.fromEntries(formData).purchasing_unit_type;
      payload.selling_unit_type = Object.fromEntries(formData).selling_unit_type;
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.UNIT_OF_MEASURE_CREATE_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.UNIT_OF_MEASURE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    });
  }

  export function getAllUnitOfMeasure({ request, params }) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.UNIT_OF_MEASURE_ROUTE,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.UNIT_OF_MEASURE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    });
  }

  export function getAllLocationGlobalSettings({ request, params }) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.GET_ALL_LOC_GLOBAL_SETTINGS_API_ROUTE,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.GET_ALL_LOC_GLOBAL_SETTINGS_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    });
  }