import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Collapse, Flex, IconButton, Image, Spacer, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useLocation, useParams } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import CouponDefaultImage from "../../../assets/images/public_gift.png";
import { AppContext } from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { commonDateFormate } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
import { getCampaignPublicPageData } from "../AuthService";

const PublicCampaign = (props) => {
    const effectRun = useRef(true);
    const [loading, setLoading] = useState(true);
    const myContext = useContext(AppContext);
    const { error } = PosErrorHook();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let obj = useParams();
    obj.key1 = queryParams.key1;
    obj.key2 = queryParams.key2;
    obj.count = 0;
    const [isMobile] = useMediaQuery("(max-width: 479px)");
    const [url, setUrl] = useState("");
    const [value, setValue] = useState("");
    const [dataExist, setDataExist] = useState(false);
    const [allCoupons, setAllCoupons] = useState([]);
    const [campaignDetails, setCampaignDetails] = useState({});
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        if (effectRun.current === true) {
            fetchData();
            effectRun.current = false;
        }
    }, []);

    const fetchData = async () => {
        myContext.handleLoading(true);
        let data = {
            key1: queryParams.get("key1"),
            key2: queryParams.get("key2"),
            count: 0,
        };
        setLoading(true);
        try {
            getCampaignPublicPageData(data)
                .then((response) => {
                    if (
                        undefined !== response.data.flag &&
                        null !== response.data.flag &&
                        response.data.flag == true
                    ) {
                        var campaignDetails = response.data.data;
                        if(campaignDetails.coupons_for_public_page.length > 0){
                            setDataExist(true);
                            if (null != campaignDetails.image_url && '' != campaignDetails.image_url) {
                                setUrl(campaignDetails.image_url);
                            }
                            campaignDetails.start_date = moment(campaignDetails.start_date + "Z");
                            campaignDetails.end_date = moment(campaignDetails.end_date + "Z");
                            setValue(campaignDetails.barcode);
                            var couponsArray = [];
                            for (let key in campaignDetails.coupons_for_public_page) {
                                if (campaignDetails.coupons_for_public_page[key].coupon_type == Constants.COUPON_TYPE_PRODUCT) {
                                    var uniqueProducts = campaignDetails.coupons_for_public_page[key].coupon_product_for_public_page.reduce((uniqueProdArray, element) => {
                                        if (!uniqueProdArray.find(v => v['barcode'] === element['barcode'])) {
                                            uniqueProdArray.push(element);
                                        }
                                        return uniqueProdArray;
                                    }, []);
                                    campaignDetails.coupons_for_public_page[key].uniqueProducts = uniqueProducts.map(a => a.name);
                                }
                                couponsArray.push(campaignDetails.coupons_for_public_page[key]);
                            }
                            setAllCoupons(couponsArray);
                        }
                        setCampaignDetails(campaignDetails);
                        setLoading(false);
                        myContext.handleLoading(false);
                    } else {
                        let actionData = response;
                        error({ actionData });
                        myContext.handleLoading(false);
                    }
                })
                .catch((err) => {
                    let actionData = err;
                    error({ actionData });
                    myContext.handleLoading(false);
                });
        } catch (error) {
            myContext.handleLoading(false);
        }
    };

    return (
        <Flex bg={Colors.loginAuthBackground} minHeight={"100vh"}>
            {/* <Box w={{ base: "30%", md: "50%" }}></Box> */}
            <Box w={"100%"} p={4}>
                {(null != url && '' != url) &&
                    <Box mt={{ base: "0.94rem", md: "0.94rem" }} display={"flex"} justifyContent={"center"}>
                        <Image src={url} maxW={"10.69rem"} maxH={"10.69rem"} objectFit={"contain"} alt='Business Logo' />
                    </Box>
                }
                {dataExist ? (
                    <>
                    <Box mt={{ base: "0.94rem", md: "0.94rem" }} textAlign={"center"}>
                    {null != campaignDetails.business_name && '' != campaignDetails.business_name ? (
                        <Text fontSize={"1.40rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"1.2"} letterSpacing={"-0.02rem"} color={Colors.posTextInfo}>{campaignDetails.business_name}'s Campaign : {campaignDetails.title}</Text>
                    ) : (
                        <Text fontSize={"1.40rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"1.2"} letterSpacing={"-0.02rem"} color={Colors.posTextInfo}>Campaign : {campaignDetails.title}</Text>
                    )}
                </Box>
                {allCoupons.map((coupon, i) => (
                    <Box
                        key={i}
                        bg={Colors.modifierTable}
                        m={"0 auto"}
                        mt={{ base: "1rem", md: "1rem", sm: "1rem" }}
                        borderRadius={"1.88rem"}
                        opacity={"0.9"}
                        boxShadow={"0px 4px 4px 0px rgba(197, 197, 197, 0.25)"}
                        maxW={{ base: "auto", md: "48.88rem" }}
                        height={"auto"}
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                        textAlign="center"
                        position="relative"
                        mb={"1rem"}
                        p={{ base: "1rem", md: "0" }}
                        pb={{ base: "1rem", md: "0" }}
                    >
                        {/* <Image
                            src={Ellipse}
                            alt="Ellipse"
                            style={{
                                width: "3rem",
                                position: "absolute",
                                top: "50%", // 50% of the parent's height
                                left: "0", // Center horizontally
                                transform: "translate(-50%, -50%)", // Center vertically
                                color: "white",
                            }}
                        /> */}
                        {/* <Image
                            src={coupon.image === 'undefined' || coupon.image === '' || coupon.image === null ? CouponDefaultImage : process.env.REACT_APP_S3_BUCKET_COUPON_IMAGE + coupon.image}
                            alt="coupon logo"
                            style={{
                                width: "7.5rem",
                                height: "7.5rem",
                                objectFit: "contain",
                                position: "absolute",
                                top: "-5.15rem",
                            }}
                        /> */}
                        {/* <Image
                            src={Ellipse}
                            alt="Ellipse"
                            style={{
                                width: "3rem",
                                position: "absolute",
                                top: "50%", // 50% of the parent's height
                                left: "100%", // Center horizontally
                                transform: "translate(-50%, -50%)", // Center vertically
                            }}
                        /> */}
                        <Box mb={3}>
                            <Text pt={{ base: "1rem", md: "1.5rem" }} fontSize={"1.5rem"} fontStyle={"normal"} fontWeight={"600"} lineHeight={"normal"} letterSpacing={"-0.02rem"} color={Colors.publicPageFontHeadColor}>
                                {coupon.title}
                            </Text>
                            {/* <Divider variant={"dashed"}/> */}
                            <Box 
                            mt={"1rem"}
                            mb={"1rem"}
                            height={"auto"}
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            alignItems="center"
                            // bg={"white"}
                            textAlign="center">
                            <Image
                            src={coupon.image == undefined || coupon.image == '' || coupon.image == null ? CouponDefaultImage : process.env.REACT_APP_S3_BUCKET_COUPON_IMAGE + coupon.image}
                            alt="coupon logo"
                            width={isMobile ? "70%" : "50%"}
                            style={{
                                // width: "70%",
                                height: "auto",
                                objectFit: "contain",
                                // padding:"0.5rem",
                                // backgroundColor:"white"
                                // position: "absolute",
                                // top: "-5.15rem",
                            }}
                            />
                            </Box>
                            <Flex
                            pt={{ base: "0.5rem", md: "0.5rem" }}
                            m={{ base: "1rem", md: "1rem" }}
                            direction={ "row" }
                            // direction={ isMobile? "column": "row" }
                            justifyContent="space-between"
                            alignItems="center"
                            spacing="0rem"
                            wrap="nowrap"
                            >
                            <Flex alignItems="center">
                                <Text
                                color={Colors.couponDropDownLabelColor}
                                fontSize="0.98rem"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="normal"
                                letterSpacing="-0.01rem"
                                >
                                Offer:
                                </Text>
                                <Text
                                pl={"0.40rem"}
                                fontSize="0.94rem"
                                fontStyle="normal"
                                fontWeight="700"
                                lineHeight="normal"
                                letterSpacing="-0.01rem"
                                color={Colors.posTextInfo}
                                >
                                {coupon.discount_type === Constants.DISCOUNT_TYPE_AMOUNT
                                    ? "$" + coupon.discount_value
                                    : coupon.discount_value + "%"} off
                                </Text>
                            </Flex>

                            <Flex alignItems="center">
                                <Text
                                color={Colors.couponDropDownLabelColor}
                                fontSize="0.98rem"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="normal"
                                letterSpacing="-0.01rem"
                                >
                                Usage:
                                </Text>
                                <Text
                                pl={"0.40rem"}
                                fontSize="0.94rem"
                                fontStyle="normal"
                                fontWeight="500"
                                lineHeight="normal"
                                letterSpacing="-0.01rem"
                                color={Colors.posTextInfo}
                                > 
                                    {coupon.pivot.time_used} / {coupon.pivot.max_usage} Time(s)
                                </Text>
                            </Flex>
                            </Flex>
                            {coupon.coupon_type != Constants.COUPON_TYPE_PRODUCT ? (
                                <Text w={coupon.image === 'undefined' || coupon.image === '' || coupon.image === null ? "23rem" : ""} pt={{ base: "1rem", md: "1rem" }} fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"normal"} letterSpacing={"-0.01rem"} color={Colors.posTextInfo}>This coupon is applicable on order of minimum amount <b>{coupon.min_amount}</b></Text>
                            ) : (
                                <>
                                <Text pt={{ base: "1rem", md: "1rem" }} fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"normal"} letterSpacing={"-0.01rem"} color={Colors.posTextInfo}>Max <b>{coupon.max_qty_per_coupon}</b> product quantity per usage.</Text>
                                <Text pt={{ base: "1rem", md: "1rem" }} fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"normal"} letterSpacing={"-0.01rem"} color={Colors.posTextInfo}>This coupon is applicable for {coupon.apply_on_all_prods == 0 ? 'below' : 'all'} products</Text>
                                </>
                            )}
                            {coupon.coupon_type == Constants.COUPON_TYPE_PRODUCT && coupon.uniqueProducts.length <= 2 ?
                                (<Box pt={{ base: "0.5rem", md: "0.5rem" }}>
                                    {coupon.uniqueProducts.map((product, i) => (
                                        <Text key={i} color={Colors.couponDropDownLabelColor} fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"700"} lineHeight={"normal"} letterSpacing={"-0.01rem"}>{product}</Text>
                                    ))}
                                </Box>) : (
                                    coupon.coupon_type == Constants.COUPON_TYPE_PRODUCT && coupon.uniqueProducts.length > 2 &&
                                    <>
                                    <Collapse in={showMore} animateOpacity>
                                    {coupon.uniqueProducts.map((product, index) => (
                                    <Text
                                        key={index}
                                        color={Colors.couponDropDownLabelColor}
                                        fontSize={"0.94rem"}
                                        fontStyle={"normal"}
                                        fontWeight={"700"}
                                        lineHeight={"normal"}
                                        letterSpacing={"-0.01rem"}
                                    >
                                        {product}
                                    </Text>
                                    ))}
                                    </Collapse>
                                     <IconButton
                                        onClick={() => setShowMore(!showMore)}
                                        aria-label={showMore ? 'Collapse' : 'Expand'}
                                        icon={showMore ? <ChevronUpIcon fontSize={"1.5rem"} /> : <ChevronDownIcon fontSize={"1.5rem"} />}
                                        mt={2}
                                        variant="ghost"
                                    />
                                  </>
                                )
                            }
                            {/* <Stack mt={{ base: "2rem", md: "2rem" }} direction={"column"} spacing={"0.31rem"}>
                                <Text fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"300"} lineHeight={"normal"} letterSpacing={"-0.01rem"} color={Colors.posTextInfo}>You can use this coupon</Text>
                                <Text fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"normal"} letterSpacing={"-0.01rem"} color={Colors.posTextInfo}>{coupon.pivot.max_usage} {coupon.pivot.max_usage > "1" ? "times" : "time"}</Text>
                            </Stack>
                            <Stack mt={{ base: "2rem", md: "2rem" }} mb={{ base: "2rem", md: "2rem" }} direction={"column"} spacing={"0.31rem"}>
                                <Text fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"300"} lineHeight={"normal"} letterSpacing={"-0.01rem"} color={Colors.posTextInfo}>You have used this coupon</Text>
                                <Text fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"normal"} letterSpacing={"-0.01rem"} color={Colors.posTextInfo}>{coupon.pivot.time_used} {coupon.pivot.time_used > "1" ? "times" : "time"}</Text>
                            </Stack> */}
                        </Box>
                    </Box>
                ))}
                </>):                         
                    <Text mt={5} fontSize={"1.40rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"1.2"} letterSpacing={"-0.02rem"} color={Colors.posTextInfo}>Sorry, no coupons are attached with this campaign.</Text>
                }

                <Stack mb={!isMobile ? "1rem" : "1.1rem"} mt={{ base: "0.94rem", md: "0.94rem" }} direction={!isMobile ? "row" : "column"} spacing='1rem' justifyContent={"center"}>
                    <Stack direction={'column'} spacing='1rem'>
                        <Box display={"flex"} justifyContent={"center"}>
                            <QRCode
                                value={value}
                                size={150}
                                // ecLevel="H"
                                // logoImage=""
                            // logoWidth={}
                            // logoHeight={}
                            />
                        </Box>
                        <Box textAlign={"center"}>
                            <Text fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"normal"} letterSpacing={"-0.02rem"} color={Colors.posTextInfo}>Scan to Apply</Text>
                            {/* <Text fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"normal"} letterSpacing={"-0.02rem"} color={Colors.posTextInfo}>checkout to get discount</Text> */}
                        </Box>
                    </Stack>
                    <Box>
                        <Text fontSize={"1.13rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"normal"} letterSpacing={"-0.02rem"} color={Colors.posTextInfo} pt={!isMobile? "1rem": ""} textAlign={!isMobile? "": "center"} pl={!isMobile? "5.13rem": ""}>Use Coupon code: {value}</Text>
                        <Text fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"300"} lineHeight={"normal"} letterSpacing={"-0.02rem"} color={Colors.posTextInfo} pt={!isMobile? "1rem": "1rem"} textAlign={!isMobile? "": "center"} pl={!isMobile? "5.13rem": ""}>Campaign Validity:</Text>
                        <Text fontSize={"0.94rem"} fontStyle={"normal"} fontWeight={"500"} lineHeight={"normal"} letterSpacing={"-0.02rem"} color={Colors.posTextInfo} textAlign={!isMobile? "": "center"} pl={!isMobile? "5.13rem": ""}>{commonDateFormate(campaignDetails.start_date)} to {commonDateFormate(campaignDetails.end_date)}</Text>                    
                    </Box>
                </Stack>
            </Box>
            {/* <Box w={{ base: "10%", md: "20%" }}></Box> */}
        </Flex>
    );
};

export default RouteWithLayout(PublicCampaign);
