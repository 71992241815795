import {
  Input,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField
} from "@chakra-ui/react";
import React from "react";

const PosInput = (props) => {
  if (props.posInput) {
    return (
      <Input
        id={props.id}
        width={props.width}
        placeholder={props.placeholder}
        onChange={props.handleInputChange}
        value={props.inputValue}
        onBlur={props.handleBlur}
        inputerror={props.inputError}
        // borderColor={Colors.posInputBorder}
        onKeyDown={props.onKeyDown}
        // background={Colors.posInputBackground}
        background={props.background}
        isDisabled={props.disabled}
        variant={props.darkPlaceholder ? "posDarkPlaceholder" : "posBasicInput"}
        isInvalid={props.inputError}
      />
    );
  } else if (props.posNumberInput) {
    return (
      // <NumberInput variant={"posBasicInput"} precision={props.precision}>
      //   <NumberInputField
      //     value={props.inputValue}
      //     isInvalid={props.inputError}
      //     isDisabled={props.disabled}
      //     id={props.id}
      //     width={props.width}
      //     background={props.background}
      //     placeholder={props.placeholder}
      //     onChange={props.handleInputChange}
      //     onKeyDown={props.onKeyDown}
      //     onBlur={props.handleBlur}
      //     inputerror={props.inputError}
      //   />
      // </NumberInput>
      <NumberInput
      variant={props.darkPlaceholder ? "posDarkPlaceholder" : "posBasicInput"}
        precision={props.precision}
        value={props.inputValue}
        isInvalid={props.inputError}
        isDisabled={props.disabled}
      >
        <NumberInputField
          id={props.id}
          width={props.width}
          background={props.background}
          placeholder={props.placeholder}
          onChange={props.handleInputChange}
          onKeyDown={props.onKeyDown}
          onBlur={props.handleBlur}
          inputerror={props.inputError}
        />
      </NumberInput>
    );
  } else if (props.posInputGroup) {
    return (
      <InputGroup>
        <InputLeftElement>{props.children}</InputLeftElement>
        <Input
          id={props.id}
          variant={props.darkPlaceholder ? "posDarkPlaceholder" : "posBasicInput"}
          width={props.width}
          placeholder={props.placeholder}
          onChange={props.handleInputChange}
          value={props.inputValue}
          onBlur={props.handleBlur}
          onKeyDown={props.onKeyDown}
          isInvalid={props.inputError}
          // borderColor={Colors.posInputBorder}
          background={props.background}
          isDisabled={props.disabled}
        />
      </InputGroup>
    );
  } else {
    return null;
  }
};

export default PosInput;
