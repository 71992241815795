import {
  Box,
  ButtonGroup,
  Flex,
  SimpleGrid,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLoaderData, useSubmit, useActionData } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import "../../../../../assets/styles/App.css";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
  ProductEditDrawer
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import { PosErrorHook } from "../../../../../hooks";
import { SimpleReportLayout } from "../../../../../layouts/index";
import { WithRouter } from "../../../../../navigators/WithRouter";
import {
  commonDateFormate,
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
  twofix
} from "./../../../../../helpers/utils/Utils";
import { getRecount } from "./RecountService";

const Recount = (props) => {
  const myContext = useContext(AppContext);
  const loaderResponse = useLoaderData();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);
  const [isExtraLarges] = useMediaQuery("(min-width: 1365px)");
  const { error } = PosErrorHook();
  const [locations, setLocations] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const { addToast } = PosTostMessage();
  const actionResponse = useActionData();
  const [tableData, setTableData] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isExtraLarge] = useMediaQuery("(min-width: 1360px)");
  const [isLarge] = useMediaQuery("(min-width: 1315px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1260px)");
  const [isMeduim2] = useMediaQuery("(min-width: 905px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1180px)");
  const [recountData, setRecountData] = useState([]);
  const submit = useSubmit();
  const [id, setId] = useState(0);
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const btnRef = React.useRef();
  const [editFlag, setEditFlag] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const searchInputHideRef = useRef(false);
  const sortRef = useRef(sortModel);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {

          if (undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0])
            formik.setFieldValue("selectedLoactions", loaderResponse?.data?.data?.location[0].name);
            setListOrderOlColumnVisibilityModel(prev => ({ ...prev, location_id: false }));
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  const [
    listOrderOlColumnVisibilityModel,
    setListOrderOlColumnVisibilityModel,
  ] = React.useState({
    id: false,
  });
  const formik = useFormik({
    initialValues: {
      selectedLoactions: ""
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
    }),
    onSubmit: async (values) => { },
  });
  useEffect(() => {
    if (effectRun.current === true) {
      // fetchData();
    }
  }, [
    paginationModel,
    sortModel,
    formik.values.selectedLoactions,
  ]);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumns = isMobile ? { width: 180 } : { flex: 1.5 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    { field: "id", headerName: "ID", sortable: true, width: 120 },
    {
      field: "product_name",
      headerName: "Product Name",
      sortable: true,
      ...actionColumnFlexDouble,
      renderCell: (params) => {
        const rowData = params.row;
        const handleProductDrawer = () => {
          myContext.handleLoading(true);
          let id = rowData.product_id_for_drawer;
          setEditFlag(true);
          setId(id);
        };
        return (
          <Text
            onClick={handleProductDrawer}
            cursor={"pointer"}
            color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={"underline"}
            fontWeight={600}
          >
            {rowData.product_name}
          </Text>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Previous Quantity",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "balance",
      headerName: "New Quantity",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "difference",
      headerName: "Difference",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "sale_price",
      headerName: "Sale Price($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "valuation_difference",
      headerName: "Revenue Impact($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "created_at",
      headerName: "Date Time",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "location_id",
      headerName: "Location",
      sortable: true,
      ...actionColumns,
    },
  ];

  const modifiedData = recountData.map((data) => {
    let quantitys;

    if (data.type === 'CR') {
      quantitys = parseFloat(data.balance) - parseFloat(data.quantity);
    } else if (data.type === 'DR') {
      quantitys = parseFloat(data.quantity) + parseFloat(data.balance);
    }

    let difference;
    if (data.type === 'CR') {
      difference = parseFloat(data.balance) - quantitys;
    } else if (data.type === 'DR') {
      difference = parseFloat(data.balance)- quantitys; 
      // - parseFloat(data.balance);
      // difference = difference; 
      // difference = -Math.abs(difference); 
    }
    const valuationDifference = difference * data.sale_price;
    // const valuationDifference = Math.abs(difference * data.sale_price);

    return {
      id: data.id,
      product_name: data.product_name,
      product_id_for_drawer: data.product_id,
      quantity: twofix(quantitys),
      balance: twofix(data.balance),
      difference: twofix(difference),
      sale_price: twofix(data.sale_price),
      valuation_difference: twofix(valuationDifference),
      created_at: commonDateFormate(data.created_at),
      location_id: data.location_name
    };
  });
  useEffect(() => {
    if (effectRun.current === true) {
      fetchData();
      effectRun.current = false;
    }
  }, [
    paginationModel,
    // searchQuery,
    sortModel,
    formik.values.selectedLoactions,
    loading,
    searchQuery,
    // paginationModelDeactive,
    // sortModelDeactive,
  ]);
  const handleSearchList = () => {
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    effectRun.current = true;
    // fetchData();
  };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    props.navigate(Constants.RECOUNT_REPORT_ROUTE);
  };
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      effectRun.current = true;
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };
  const handleOptionSelect = (e) => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
    }
  };

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Recount Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "export_recount_report.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  const handleExportReport = () => {
    myContext.handleLoading(true);
    let data = {
      location: JSON.stringify({
        // label: selectedLocation.name,
        value: {
          id: selectedLocation.id,
          // name: selectedLocation.name,
        },
      }),
      // zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      query: JSON.stringify({
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0
            ? sortModel[0].field
            : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
          filter: searchQuery,
      }),
    };

    submit(data, {
      method: Constants.POST,
      path: Constants.VOID_REPORT_ROUTE,
    });
  };


  const clearFilterDAta = () => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (searchQuery.length > 0) {
      setSearchQuery("");
    }
    if (locations.length > 1) {
      setSelectedLocation([]);
      formik.setFieldValue("selectedLoactions", "");
    }
  };

  const [timeoutId, setTimeoutId] = useState(null);
  const apply = () => {
    // effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    // setLoading(true);
    // setApplyButtonClicked(1);
    // if (timeoutId) {
    //   clearTimeout(timeoutId);
    // }
    // const newTimeoutId = setTimeout(() => {
    // fetchDataAfterSearch(value);
    // }, 250);
    // setTimeoutId(newTimeoutId);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    effectRun.current = true;
    if (locations.length > 1) {
      setSelectedLocation([]);
      formik.setFieldValue("selectedLoactions", "");
      formik.setFieldTouched("selectedLoactions", false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    if (Object.keys(selectedLocation).length > 0) {
      data.location = {
        label: selectedLocation.name,
        value: {
          id: selectedLocation.id,
          name: selectedLocation.name,
        },
      };
    } else {
      data.location = "";
    }
    try {
      getRecount(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setRecountData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setRecountData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !searchInputHideRef.current &&
            err?.response?.data?.data?.data === 0
          ) {
            searchInputHideRef.current = true;
          }
          setRecountData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setRecountData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const editModelClose = () => {
    onEditClose();
    setId(0);
    setEditFlag(false);
  };

  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <ProductEditDrawer
        fetchData={fetchData}
        isEditOpen={isEditOpen}
        placement="right"
        onEditClose={editModelClose}
        onEditOpen={onEditOpen}
        finalFocusRef={btnRef}
        editFlag={editFlag}
        id={id}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Recount"]}
          breadCrumTitle={"Recount"}
        />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow="0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Flex
          flexDirection={isMobile ? "column" : isMeduim ? "column" : "row"}
          // alignItems={{ base: "stretch", md: "start" }}
          mt={6}
          // mb={3}
          mr={4}
        >
          {!searchInputHideRef.current ? (
            <Flex flexDirection={isMobile ? "column" : "row"} ml={4}>
              <PosSearch
                value={searchQuery}
                onChange={onChange}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              {locations.length > 1 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  w="2.5rem"
                  ml={{ base: 0, md: 4 }}
                  mt={{ base: 4, md: 0 }}
                  mb={{ base: 4 }}
                >
                  <IconButton
                    onClick={toggleVisibility}
                    style={{
                      border: "1px solid #ccc",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                      color: Colors.posTextInfo,
                      borderColor: Colors.dividerColor,
                      borderRadius: "5px",
                      padding: 5,
                    }}
                  >
                    <FilterListIcon style={{ color: Colors.posTextInfo }} />
                  </IconButton>
                </Box>
              )}
            </Flex>) : null}
          {isVisible && (
            <Flex flexDirection={isMeduim ? "column" : "row"} ml={{ base: 4, md: 4 }} gap={4}>
              <Box  >
                <PosDropDown
                  id="selectedLoactions"
                  options={locations}
                  value={selectedLocation}
                  resetFilterOnHide={true}
                  onChange={handleOptionSelect}
                  onBlur={(e) => {
                    formik.setFieldTouched("selectedLoactions");
                    formik.handleBlur(e);
                  }}
                  width={
                    isExtraLarges
                      ? "20rem"
                      : isLarge
                        ? "17rem"
                        : isMeduim1
                          ? "13.32rem"
                          // : isMeduim2
                          // ? "32rem"
                          : "100%"
                  }
                  optionLabel="name"
                  placeholder="Location"
                  height={"2.5rem"}
                  className="w-full md:w-20rem"
                />
                <Text color={Colors.errorColor}>
                  {formik.touched.selectedLoactions && formik.errors.selectedLoactions ? (
                    <span>{formik.errors.selectedLoactions}</span>
                  ) : null}
                </Text>
              </Box>
              <Box >
                <PosFormButton
                  isDisabled={
                    searchQuery.length > 0 || formik.values.selectedLoactions !== ""
                      ? false
                      : true
                  }
                  onClick={clearFilterDAta}
                  buttonText={"Clear"}
                  CancelButton={true}
                />
              </Box>
            </Flex>
          )}
        </Flex>
        {loading ? (
          <PosProgress />
        ) : totalcount > 0 ? (
          <Box padding={"1rem"}>
            <PosDataGridTable
              columns={columnNames}
              rows={modifiedData}
              totalcount={totalcount}
              columnVisibilityModel={listOrderOlColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setListOrderOlColumnVisibilityModel(newModel)
              }
              paginationModel={paginationModel}
              paginationMode="server"
              sortingMode="server"
              onPaginationModelChange={(newPaginationModel) => {
                effectRun.current = true;
                setPaginationModel(newPaginationModel);
              }}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                effectRun.current = true;
                sortRef.current = sortModel;
                setSortModel(newSortModel);
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              fileName={Constants.RECOUNT_CSV}
            />
          </Box>
        ) : (
          <Box padding={"1rem"}>
            <PosNoDataFound title={Constants.RECOUNT_NOT_FOUND} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default WithRouter(Recount);
