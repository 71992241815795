import {
  Box,
  Button,
  Flex, HStack, Image, Text, VStack
} from "@chakra-ui/react";
import React from "react";
import Colors from "../../../assets/colors/Colors";
import noPageFound from "../../../assets/images/404.svg";
import eye from "../../../assets/images/eye.svg";
import mouth from "../../../assets/images/mouth.svg";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
import * as Constants from "../../../constants/Constants"

const UnAuthorize = (props) => {
  const handleOnClick = () => {
    props.navigate(Constants.DASHBOARD_PATH);
  };

  return (
    // <Flex alignItems={"center"} justifyContent={"center"} h="100vh">
    //   <Flex
    //     h="25rem"
    //     w="40rem"
    //     flexDirection={"column"}
    //     alignItems={"center"}
    //     justifyContent={"center"}
    //   >
    //     <Text fontSize="6xl" fontWeight={"bold"}>
    //       {"UNAUTHORIZED"}
    //     </Text>
    //     <Text fontSize="3xl" fontWeight={"bold"}>
    //       {"401"}
    //     </Text>
    //     <Text fontSize="4xl" fontWeight={"bold"} color="#f4516c">
    //       {"Access Denied"}
    //     </Text>
    //     <Text fontSize="xl">
    //       {"You do not have permission to access this page."}
    //     </Text>
    //     <Button colorScheme="blue" size="lg" mt="1rem" onClick={handleOnClick}>
    //       Home
    //     </Button>
    //   </Flex>
    // </Flex>
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      mt={"8.25rem"}
      bg={Colors.loginAuthBackground}
    >
      <Flex alignItems={"center"} justifyContent={"center"} flexDir={"column"}>
        <Flex
          width={{ base: "25rem", lg: "32.1rem" }}
          height={"24.1rem"}
          flexShrink={0}
          flexDirection={"column"}
          alignItems={"center"}
          backgroundImage={noPageFound}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"contain"}
        >
          <VStack
            width={"9.92rem"}
            height={"7.93rem"}
            transform={"rotate(-5.463deg)"}
            flexShrink={0}
            mt={"3.32rem"}
            mb={"4.094rem"}
            border={"0.7px solid rgba(197, 197, 197, 0.14)"}
            background={Colors.buttonOutlineFocus}
            // bg={"red"}
            boxShadow={"0px 10px 10px 0px #E9EFFE"}
            // alignItems={"center"}
          >
            <HStack
              transform={"rotate(-5.463deg)"}
              mt={"1.69rem"}
              spacing={"2.13rem"}
            >
              <Image src={eye} boxSize={"0.375rem"} />
              <Image src={eye} boxSize={"0.375rem"} />
            </HStack>
            <Image
              src={mouth}
              width={"4.125rem"}
              height={"1rem"}
              mt={"2.813rem"}
            />
          </VStack>
          <Text
            fontSize={"1.5rem"}
            fontWeight={"600"}
            color={Colors.posTextInfo}
            letterSpacing={"-0.36px"}
          >
            {"Access Denied"}
          </Text>
          <Text
            fontSize={"0.94rem"}
            fontWeight={"300"}
            color={Colors.posTextInfo}
            letterSpacing={"-0.225px"}
          >
            {"You do not have permission to access this page."}
          </Text>
        </Flex>
        <Button
          colorScheme="blue"
          marginTop={"-25px"}
          fontSize={"1.125rem"}
          fontWeight={500}
          color={Colors.loginAuthBackground}
          letterSpacing={"=0.27px"}
          size="lg"
          onClick={handleOnClick}
        >
          Back To Home
        </Button>
      </Flex>
    </Box>
  );
};

export default RouteWithLayout(UnAuthorize);
