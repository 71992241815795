import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Colors from "../../assets/colors/Colors";

const PosListHeader = (props) => {
  return (
    <Box p="2">
      <Heading
        as="h2"
        size="lg"
        fontWeight="600"
        fontSize="1.5rem"
        lineHeight="1.875rem"
        letterSpacing="-0.0225rem"
        color={Colors.posTextInfo}
      >
        {props.header}
      </Heading>
      <Text color={Colors.taxCreateExemptEbt} fontSize="sm">
        {props.discription}
      </Text>
    </Box>
  );
};

export default PosListHeader;
