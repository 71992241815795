import {
  Box,
  Divider,
  Flex,
  Img,
  Select,
  Image,
  Spacer,
  Stack,
  Text,
  Textarea,
  useMediaQuery,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Formik, Form, useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import Phone from "../../../assets/images/phone.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosCountryStateDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getStateListForSelectedCountry } from "./CustomerService";
import warnignIcon from "../../../assets/images/Ellipse21.svg";
import { editCustomerCredit } from "./CustomerService";
const CustomerCreditSchema = Yup.object().shape({
  amount: Yup.number()
    .required(Constants.CUSTOMER_PLEASE_ENTER_A_AMOUNT)
    .max(999999.99, Constants.CUSTOMER_AMOUNT_MAX_VALUE)
    .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
  external_reference: Yup.string()
  .max(191,Constants.CUSTOMER_EXTERNAL_REFERENCE_LENGTH_GREATE_THEN_191),
});
const CustomerEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [CustomerBalanceData, SetcustomerBalanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [curruntBalence, setCurruntBalence] = useState(0);
  const [customerDataBalance, setCustomerDataBalance] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [previousSelectedCountry, setPreviousSelectedCountry] = useState('');
  const [isScreenSize480] = useMediaQuery("(max-width: 3rem)");
  const [userBalance, setUserBalance] = useState(0);
  const [totalcount, setTotalCount] = useState(0);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          SetcustomerBalanceData(loaderResponse?.data?.data?.available_credit);
          const arrayCountries = Object.keys(
            loaderResponse.data.data.countries
          ).map((key) => ({
            label: loaderResponse.data.data.countries[key],
            value: key,
          }));
          setCountry(arrayCountries);

          if (
            loaderResponse?.data?.data?.customerData?.country &&
            null != loaderResponse?.data?.data?.customerData?.country
          ) {
            setSelectedCountry(
              loaderResponse?.data?.data?.customerData?.country
            );
            handleSelectedCountry(
              loaderResponse?.data?.data?.customerData?.country
            );
          }

          if (
            loaderResponse?.data?.data?.customerData?.state &&
            null != loaderResponse?.data?.data?.customerData?.state &&
            "N.A" != loaderResponse?.data?.data?.customerData?.state
          ) {
            setStatesAvailability(0);
            setSelectedState(loaderResponse?.data?.data?.customerData?.state);
          }
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.CUSTOMERS_PATH, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.CUSTOMERS_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const handleSelectedState = (value) => {
    setSelectedState(value);
    if (value == "N.A") {
      setStatesAvailability(1);
    } else {
      setStatesAvailability(0);
    }
  };

  const handleSelectedCountry = (value) => {
    try {
      setSelectedCountry(value);
      setStates([]);
      setSelectedState(null);
      setStateError("");
      formik.setFieldValue("selectedState", null);

      myContext.handleLoading(true);
      getStateListForSelectedCountry(value)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const statesData = response.data.data.states;
            const arrayStates = Object.keys(statesData).map((key) => ({
              label: statesData[key],
              value: key,
            }));
            setStates(arrayStates);
            const customerState =
              loaderResponse?.data?.data?.customerData?.state;
            if (
              customerState &&
              customerState !== "N.A" &&
              customerState !== null &&
              statesData[customerState]
            ) {
              if(loaderResponse?.data?.data?.customerData?.country !== previousSelectedCountry){
                setSelectedState(customerState); 
              }else if (loaderResponse?.data?.data?.customerData?.country === previousSelectedCountry){
                setSelectedState("");
              }
              setPreviousSelectedCountry(loaderResponse?.data?.data?.customerData?.country);
              formik.setFieldValue("selectedState", customerState);
              setStatesAvailability(0);
            } else {
              // setSelectedState("N.A");
              // formik.setFieldValue("selectedState", "N.A");
              setStatesAvailability(1);
            }
          } else {
            setStates([]);
            setSelectedState("N.A");
            formik.setFieldValue("selectedState", "N.A");
            setStatesAvailability(1);
          }
          myContext.handleLoading(false);
        })
        .catch((err) => {
          setStates([]);
          setSelectedState("N.A");
          formik.setFieldValue("selectedState", "N.A");
          setStatesAvailability(1);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setStates([]);
      setSelectedState("N.A");
      formik.setFieldValue("selectedState", "N.A");
      setStatesAvailability(1);
      let actionData = error;
      error({ actionData });
      myContext.handleLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name:
        undefined !== loaderResponse?.data?.data?.customerData?.name &&
        null != loaderResponse?.data?.data?.customerData?.name
          ? loaderResponse?.data?.data?.customerData?.name
          : "",
      phone:
        undefined !== loaderResponse?.data?.data?.customerData?.phone &&
        null != loaderResponse?.data?.data?.customerData?.phone
          ? loaderResponse?.data?.data?.customerData?.phone
          : "",
      email:
        undefined !== loaderResponse?.data?.data?.customerData?.email &&
        null != loaderResponse?.data?.data?.customerData?.email
          ? loaderResponse?.data?.data?.customerData?.email
          : "",
      address1:
        undefined !== loaderResponse?.data?.data?.customerData?.address1 &&
        null != loaderResponse?.data?.data?.customerData?.address1
          ? loaderResponse?.data?.data?.customerData?.address1
          : "",
      address2:
        undefined !== loaderResponse?.data?.data?.customerData?.address2 &&
        null != loaderResponse?.data?.data?.customerData?.address2
          ? loaderResponse?.data?.data?.customerData?.address2
          : "",
      city:
        undefined !== loaderResponse?.data?.data?.customerData?.city &&
        null != loaderResponse?.data?.data?.customerData?.city
          ? loaderResponse?.data?.data?.customerData?.city
          : "",
      notes:
        undefined !== loaderResponse?.data?.data?.customerData?.notes &&
        null != loaderResponse?.data?.data?.customerData?.notes
          ? loaderResponse?.data?.data?.customerData?.notes
          : "",
      credit_limit:
        undefined !== loaderResponse?.data?.data?.customerData?.credit_limit &&
        null != loaderResponse?.data?.data?.customerData?.credit_limit
          ? loaderResponse?.data?.data?.customerData?.credit_limit
          : "",
      tax_exempt:
        undefined !== loaderResponse?.data?.data?.customerData?.tax_exempt &&
        null != loaderResponse?.data?.data?.customerData?.tax_exempt
          ? loaderResponse?.data?.data?.customerData?.tax_exempt
          : "",
      exclude_from_reward_calculation:
        undefined !==
          loaderResponse?.data?.data?.customerData?.is_exclude_reward &&
        null != loaderResponse?.data?.data?.customerData?.is_exclude_reward
          ? loaderResponse?.data?.data?.customerData?.is_exclude_reward
          : "",
      country:
        undefined !== loaderResponse?.data?.data?.customerData?.country &&
        null != loaderResponse?.data?.data?.customerData?.country
          ? loaderResponse?.data?.data?.customerData?.country
          : "",
      state:
        undefined !== loaderResponse?.data?.data?.customerData?.state &&
        null != loaderResponse?.data?.data?.customerData?.state
          ? loaderResponse?.data?.data?.customerData?.state
          : "",
          selectedState: undefined !== loaderResponse?.data?.data?.customerData?.state &&
          null != loaderResponse?.data?.data?.customerData?.state
            ? loaderResponse?.data?.data?.customerData?.state
            : "",
          selectedCountry: undefined !== loaderResponse?.data?.data?.customerData?.country &&
          null != loaderResponse?.data?.data?.customerData?.country
            ? loaderResponse?.data?.data?.customerData?.country
            : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
      .trim()
        .required(Constants.CUSTOMER_NAME_REQUIRED)
        .max(191, Constants.CUSTOMER_NAME_LENGTH_GREATER_THAN_191),
      address1: Yup.string()
      .trim()
        .required(Constants.CUSTOMER_ADDRESS_NAME_REQUIRED)
        .max(5000, Constants.CUSTOMER_ADDRESS_LENGTH_GREATE_THEN_5000),
      address2: Yup.string().max(
        5000,
        Constants.CUSTOMER_ADDRESS_LENGTH_GREATE_THEN_5000
      ),
      city: Yup.string()
      .trim()
        .required(Constants.CUSTOMER_PLEASE_ENTER_A_CITY)
        .max(191, Constants.CUSTOMER_CITY_LENGTH_GREATE_THEN_191),
      phone: Yup.string()
        .required(Constants.CUSTOMER_PLEASE_ENTER_A_PHONE_NUMBER)
        .max(
          14,
          Constants.CUSTOMER_PHONE_NUMBER_MUST_BE_LESS_THAN_15_DIGITS_LONG
        )
        .min(
          3,
          Constants.CUSTOMER_PHONE_NUMBER_MUST_BE_GREATER_THAN_2_DIGITS_LONG
        )
        .matches(/^\d*$/, Constants.THIS_IS_NOT_A_VALID_PHONE_NUMBER),
      email: Yup.string()
        .required(Constants.USER_EMAIL_REQUIRED)
        .email(Constants.USER_EMAIL_NOT_VALID)
        .matches(Constants.EMAIL_REGEX, Constants.VENDOR_EMAIL_NOT_VALID)
        .max(191, Constants.CUSTOMER_EMAIL_LENGTH_GREATE_THEN_191),
      notes: Yup.string().max(
        191,
        Constants.CUSTOMER_NOTES_LENGTH_GREATE_THEN_191
      ),
      credit_limit: Yup.number()
        .typeError(Constants.CUSTOMER_PLEASE_ENTER_A_VALID_CREDIT_LIMIT)
        .max(999999.99, Constants.CUSTOMER_CREDIT_LIMIT_MAX_ERROR)
        .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
      // country: Yup.string().required(
      //   Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      // ),
      // state: Yup.string().required(Constants.CUSTOMER_PLEASE_SELECT_A_STATE),
      selectedCountry: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      ),
      selectedState: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_STATE
      ),
      tax_exempt: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_TAX_EXEMPT_OPTION
      ),
      exclude_from_reward_calculation: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_REWARD_CALCULATION_OPTION
      ),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        tax_exempt: values.tax_exempt ? 1 : 0,
        exclude_from_reward_calculation: values.exclude_from_reward_calculation
          ? 1
          : 0,
        country: selectedCountry,
        state: selectedState || "N.A",
        stateNotAvailable: stateAvailable,
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.CUSTOMER_CREATE_API_ROUTE,
      });
    },
  });
  
  const initialValues = {
      id: undefined !== loaderResponse?.data?.data?.customerData?.id &&
      null != loaderResponse?.data?.data?.customerData?.id
        ? loaderResponse?.data?.data?.customerData?.id
        : "",
      amount: "",
      external_reference: "",
  };
  const addCredit = async (values) => {
    myContext.handleLoading(true);
    let data = {
      id: loaderResponse?.data?.data?.customerData?.id,
      amount: values.amount,
      external_reference: values.external_reference,
      type: Constants.ADDCREDIT,
    };
    setLoading(true);
    try {
      editCustomerCredit(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            SetcustomerBalanceData(response.data.data.available_credit);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            SetcustomerBalanceData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          SetcustomerBalanceData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      SetcustomerBalanceData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (values,resetForm) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        await addCredit(values);
        onClose();
        resetForm();
        setLoading(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const closepop = (resetForm) => {
    onClose();
    setCurruntBalence(null);
    resetForm();
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
  };

  const handleaddPage = () => {
    myContext.handleLoading(true);
    onOpen();
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
    myContext.handleLoading(false);
    formik.setFieldTouched("amount", false);
    formik.setFieldTouched("external_reference", false);
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.name.length <= 0 ||
      formik.errors.name ||
      formik.errors.phone ||
      formik.errors.email ||
      formik.errors.address1 ||
      formik.errors.city ||
      formik.errors.country ||
      formik.errors.state ||
      formik.values.selectedState ||
      formik.values.selectedCountry ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };
  return (
    <Box flex="1" pb="2rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              // pass param data in tax list page
              props.navigate(Constants.CUSTOMERS_PATH, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Customer", "Edit Customer"]}
            breadCrumTitle={"Edit Customer"}
          />
        </Box>
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              // pass param data in modifier set list page
              props.navigate(Constants.CUSTOMERS_PATH, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            isDisabled={
              formik.values.name.length <= 0 ||
              formik.errors.name ||
              formik.errors.phone ||
              formik.errors.email ||
              formik.errors.address1 ||
              formik.errors.city ||
              formik.errors.credit_limit ||
              formik.errors.country ||
              formik.errors.state ||
              !formik.values.selectedState ||
              stateError.length > 0 ||
              buttonDisable
                ? true
                : false
            }
            buttonsubmit={"Submit"}
            SubmitButton={true}
            onClick={formik.handleSubmit}
          />
        </Stack>
      </Flex>
      <Box
        mt={{ base: 4, lg: 8 }}
        ml={{ base: 4, lg: 14 }}
        mr={{ base: 4, lg: 14 }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        p="8"
        onKeyDown={handleKeyDown}
      >
        <Text
          fontSize="1.23rem"
          w="10.5rem"
          pt={"1.56rem"}
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontSize: "1.13rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          Basic Details
        </Text>
        <Flex
          p={{ base: "0" }}
          gap={{ base: "0", md: "9" }}
          direction={{ base: "column", md: "row" }}
        >
          <Box flex="1">
            <Stack spacing={2} pt={5}>
              <PosLable
                name={"Name"}
                requiredLabel={true}
                fontSize="0.94rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                letterSpacing="-0.02rem"
              />
              <PosInput
                id="name"
                mt={"1rem"}
                placeholder={"Name"}
                color={Colors.posCommon}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.name}
                handleBlur={formik.handleBlur}
                // onKeyDown={handleKeyDown}
                posInputGroup={true}
                inputType={"text"}
                inputError={formik.touched.name && formik.errors.name}
              >
                <DriveFileRenameOutlineIcon
                  sx={{
                    height: "1.21rem",
                    width: "1.21rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
              </PosInput>
              <Text color={Colors.posCancleButtonRed}>
                {formik.touched.name && formik.errors.name ? (
                  <span>{formik.errors.name}</span>
                ) : null}
              </Text>
            </Stack>
          </Box>
          <Box flex="1">
            <Stack spacing={2} pt={5}>
              <PosLable
                name={"Phone Number"}
                requiredLabel={true}
                fontSize="0.94rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                letterSpacing="-0.02rem"
              />
              <PosInput
                id="phone"
                mt={"1rem"}
                placeholder={"phone"}
                color={Colors.posCommon}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.phone}
                handleBlur={formik.handleBlur}
                // onKeyDown={handleKeyDown}
                posInputGroup={true}
                inputType={"text"}
                inputError={formik.touched.phone && formik.errors.phone}
              >
                {/* <PhoneIcon
                  color={Colors.posNavbarLink}
                  style={{ fill: Colors.taxInfo }}
                /> */}
                <Img
                  src={Phone}
                  alt="Phone"
                  sx={{
                    height: "1.21rem",
                    width: "1.21rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
              </PosInput>
              <Text color={Colors.posCancleButtonRed}>
                {formik.touched.phone && formik.errors.phone ? (
                  <span>{formik.errors.phone}</span>
                ) : null}
              </Text>
            </Stack>
          </Box>
        </Flex>
        <Stack spacing={2} pt={5}>
          <PosLable
            name={"Email"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="email"
            mt={"1rem"}
            placeholder={"email"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.email}
            handleBlur={formik.handleBlur}
            // onKeyDown={handleKeyDown}
            posInputGroup={true}
            inputType={"text"}
            inputError={formik.touched.email && formik.errors.email}
          >
            <DriveFileRenameOutlineIcon
              sx={{
                height: "1.21rem",
                width: "1.21rem",
                background: Colors.posInputGroupBackground,
                borderRadius: "0.31rem",
                color: Colors.posNavbarLink,
              }}
            />
          </PosInput>
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.email && formik.errors.email ? (
              <span>{formik.errors.email}</span>
            ) : null}
          </Text>
        </Stack>
        <Stack spacing={2} pt={5}>
          <PosLable
            name={"Address Line 1"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="address1"
            mt={"1rem"}
            placeholder={"Enter Address Line 1"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.address1}
            handleBlur={formik.handleBlur}
            // onKeyDown={handleKeyDown}
            posInputGroup={true}
            inputType={"text"}
            inputError={formik.touched.address1 && formik.errors.address1}
          >
            <DriveFileRenameOutlineIcon
              sx={{
                height: "1.21rem",
                width: "1.21rem",
                background: Colors.posInputGroupBackground,
                borderRadius: "0.31rem",
                color: Colors.posNavbarLink,
              }}
            />
          </PosInput>
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.address1 && formik.errors.address1 ? (
              <span>{formik.errors.address1}</span>
            ) : null}
          </Text>
        </Stack>
        <Stack spacing={2} pt={5}>
          <PosLable
            name={"Address Line 2"}
            label={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="address2"
            mt={"1rem"}
            placeholder={"Enter Address Line 2"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.address2}
            handleBlur={formik.handleBlur}
            // onKeyDown={handleKeyDown}
            posInputGroup={true}
            inputType={"text"}
            inputError={formik.touched.address2 && formik.errors.address2}
          >
            <DriveFileRenameOutlineIcon
              sx={{
                height: "1.21rem",
                width: "1.21rem",
                background: Colors.posInputGroupBackground,
                borderRadius: "0.31rem",
                color: Colors.posNavbarLink,
              }}
            />
          </PosInput>
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.address2 && formik.errors.address2 ? (
              <span>{formik.errors.address2}</span>
            ) : null}
          </Text>
        </Stack>
        <Flex
          p={{ base: "0" }}
          gap={{ base: "0", md: "9" }}
          direction={{ base: "column", md: "row" }}
        >
          <Box flex="1">
            <PosLable
              name={"Country"}
              requiredLabel={true}
              fontStyle="normal"
              fontSize="0.94rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <PosCountryStateDropDown
              options={countries}
              value={formik.values.selectedCountry}
              countriesSelect={true}
              onChange={(e) => {
                if(undefined != e.target.value && null != e.target.value && '' != e.target.value){
                handleSelectedCountry(e.target.value);
                formik.setFieldValue("selectedCountry", e.target.value);
                }
              }}
              onBlur={(e) => {
                formik.setFieldTouched("selectedCountry", true);
                // if (!e.target.value) {
                //   setCountryError(Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY);
                // } else {
                //   setCountryError("");
                // }
              }}
              optionLabel="label"
              placeholder="--Select Country--"
              width={"100%"}
              maxWidth={"42rem"}
              height={"2.5rem"}
              resetFilterOnHide={true}
              className="w-full md:w-20rem"
              lableAvailable={true}
              disabled={false}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedCountry && countryError.length > 0 ? (
                <span>{countryError}</span>
              ) : null}
            </Text>
          </Box>

          <Box flex="1">
            <PosLable
              name={"State"}
              requiredLabel={true}
              fontStyle="normal"
              fontSize="0.94rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
              pb={"0.31rem"}
            />
            {states.length === 0 ? (
              <Select
                id="state"
                value={formik.values.selectedState || "N.A"}
                sx={{
                  color: "gray",
                  fontSize: "0.94rem !important",
                  fontWeight: 300,
                  letterSpacing: "-0.01rem !important",
                  backgroundColor: "#5881fe0d",
                  borderRadius: "0.625rem",
                  height: "2.5rem",
                  border: "1px solid #b7b7b71f",
                  "&[disabled]": {
                    backgroundColor: "#5881fe0d !important",
                    opacity: 1,
                  },
                }}
                // onChange={(e) => {
                //   handleSelectedState(e.target.value);
                //   formik.setFieldValue("selectedState", e.target.value);
                // }}
                isDisabled
                placeholder={states.length > 0 ? "N.A" : "--Select State--"}
              >
                <option value="N.A">N.A</option>
              </Select>
            ) : (
              <PosCountryStateDropDown
                options={states}
                value={selectedState}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  if(undefined != e.value && null != e.value && '' != e.value){
                  handleSelectedState(selectedValue); 
                  formik.setFieldValue("selectedState", selectedValue);
                  formik.setFieldTouched("selectedState", true); 
                  if (!selectedValue) {
                    formik.setFieldError("selectedState", Constants.CUSTOMER_PLEASE_SELECT_A_STATE); 
                  } else {
                    formik.setFieldError("selectedState", ""); 
                  }
                }
                }}
                onBlur={() => {
                  formik.setFieldTouched("selectedState", true);
                }}
                optionLabel="label"
                placeholder="--Select State--"
                width={"100%"}
                maxWidth={"42rem"}
                height={"2.5rem"}
                resetFilterOnHide={true}
                className="w-full md:w-20rem"
                lableAvailable={true}
                disabled={false}
              />
            )}
            {states.length > 0 && (
              <Text color={Colors.errorColor}>
                {formik.touched.selectedState && formik.errors.selectedState ? (
                  <span>{formik.errors.selectedState}</span>
                ) : null}
              </Text>
            )}
          </Box>
        </Flex>
        <Stack spacing={2} pt={5}>
          <PosLable
            name={"City"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="city"
            mt={"1rem"}
            placeholder={"--Select City--"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.city}
            handleBlur={formik.handleBlur}
            // onKeyDown={handleKeyDown}
            posInputGroup={true}
            inputType={"text"}
            inputError={formik.touched.city && formik.errors.city}
          >
            <DriveFileRenameOutlineIcon
              sx={{
                height: "1.21rem",
                width: "1.21rem",
                background: Colors.posInputGroupBackground,
                borderRadius: "0.31rem",
                color: Colors.posNavbarLink,
              }}
            />
          </PosInput>
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.city && formik.errors.city ? (
              <span>{formik.errors.city}</span>
            ) : null}
          </Text>
        </Stack>
      </Box>
      <Box
        mt={{ base: 2, lg: 2.5 }}
        ml={{ base: 4, lg: 14 }}
        mr={{ base: 4, lg: 14 }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        onKeyDown={handleKeyDown}
      >
        <Box
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center" }}
          p={"2.5rem"}
          gap={2}
        >
          <Text
            fontSize="1.13rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            Other Details
          </Text>
          <Flex
          gap="9"
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "end" }}
          pr={{ base: 4, md: "1.75rem" }}
        >
          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            width="100%"
          >
            <PosLable
              label={true}
              name={"Credit Balance"}
              mt="0.31rem"
              fontStyle="normal"
              fontSize="1.13rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <Flex
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
              alignItems={"center"}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Balance
              </Text>
              <Spacer />
              <Text color={Colors.taxInfo}>
                {CustomerBalanceData !== null &&
                CustomerBalanceData !== undefined &&
                CustomerBalanceData.length !== 0 &&
                CustomerBalanceData !== ""
                  ? `$${twofix(CustomerBalanceData)}`
                  : "N.A"}
              </Text>
            </Flex>
          </Box>
          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            alignItems={"center"}
            pb={{ base: "2rem", md: "0" }}
          >
            <PosFormButton
              buttonsubmit={"Add Credit"}
              bg={Colors.posNavbarLink}
              width={!isScreenSize480 ? "11.81rem" : "2.38rem"}
              style={{
                display: "flex",
                padding: "0.63rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.63rem",
                borderRadius: "0.31rem",
                border: "1px solid #E3E3E3",
                boxShadow: "0px 0.25rem 0.25rem 0px #e0e0e01a",
              }}
              onClick={() => handleaddPage()}
            />
          </Box>
        </Flex>
          <Formik
              initialValues={initialValues}
              validationSchema={CustomerCreditSchema}
              onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue, resetForm }) => (
              <Form>
                <Modal
                  isOpen={isOpen}
                  onClose={() => {
                    // formik.setFieldValue("amount", "");
                    setUserBalance(0);
                    onClose();
                  }}
                  isCentered
                  size="2xl"
                  width="35.63rem"
                  closeOnOverlayClick={false}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader
                      mt={{ base: "0", md: "1.56rem" }}
                      style={{ display: "inline-flex", justifyContent: "center" }}
                    >
                      Add Customer Credit
                    </ModalHeader>
                    <Divider />
                    <ModalBody
                      pb={{ base: 4, md: "1.38rem" }}
                      pl={{ base: 4, md: "1.06rem" }}
                      pr={{ base: 4, md: "1.75rem" }}
                    >
                      <Stack mt="0.87rem">
                        <Flex
                          bg={Colors.modifierTable}
                          style={{
                            display: "flex",
                            padding: "0.63rem",
                            alignItems: "center",
                            gap: "0.63rem",
                            flexShrink: 0,
                            borderRadius: "0.63rem",
                          }}
                        >
                          <Image
                            src={warnignIcon}
                            alt="Warning Icon"
                            boxSize="0.81rem"
                            mr="0.5rem"
                          />
                          <Text>
                            Your Available Credit Balance is: 
                            <Text as="span" fontWeight="bold">
                              {CustomerBalanceData !== null &&
                              CustomerBalanceData !== undefined &&
                              CustomerBalanceData.length !== 0 &&
                              CustomerBalanceData !== ""
                                ? ` $${twofix(CustomerBalanceData)}`
                                : ""}
                            </Text>
                          </Text>
                        </Flex>
                        <Text mt="2rem">* Amount:</Text>
                        <PosInput
                          id="amount"
                          placeholder="Enter an amount"
                          handleInputChange={(e) => {
                            let newBalance = 0;
                            if (
                              undefined !== e.target.value &&
                              null !== e.target.value &&
                              "" !== e.target.value
                            ) {
                              newBalance =
                                parseFloat(e.target.value) +
                                parseFloat(CustomerBalanceData);
                              setUserBalance(newBalance);
                              handleChange(e);
                            } else {
                              handleChange(e);
                              setUserBalance(parseFloat(customerDataBalance.balance));
                            }
                          }}
                          inputValue={values.amount}
                          handleBlur={(e) => {
                            handleBlur(e);
                            setFieldValue(
                              "amount",
                              twofix(values.amount) || ""
                            );
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "-" || e.key === "ArrowUp" || e.key === "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                          inputType="number"
                          posNumberInput
                        />
                        <Text color={Colors.posCancleButtonRed}>
                          {touched.amount && errors.amount ? (
                            <span>{errors.amount}</span>
                          ) : null}
                        </Text>
                        {/* {setAmount(values.amount)} */}
                      </Stack>
                      <Stack pt="0.88rem">
                        <Text>Reference/ Reason:</Text>
                        <PosInput
                          id="external_reference"
                          placeholder="Enter a reason"
                          color={Colors.posTextInfo}
                          handleInputChange={handleChange}
                          inputValue={values.external_reference}
                          handleBlur={handleBlur}
                          posInput
                          inputType="text"
                        />
                        <Text color={Colors.posCancleButtonRed}>
                          {touched.external_reference && errors.external_reference ? (
                            <span>{errors.external_reference}</span>
                          ) : null}
                        </Text>
                        {values.amount > 0 && (
                          <Text style={{ color: "#008000" }}>
                            After this transaction, the new Credit Balance for this
                            customer will be $
                            <Text as="span" fontWeight="bold">
                              {twofix(userBalance)}
                            </Text>
                            .
                          </Text>
                        )}
                        {/* {setExternal_reference(values.external_reference)} */}
                      </Stack>
                    </ModalBody>
                    <ModalFooter
                      flexDirection="column"
                      alignItems="end"
                      style={{ paddingInlineEnd: "var(--chakra-space-4" }}
                    >
                      <Flex mb="1.69rem" gap="0.5rem">
                        <PosFormButton
                          buttonText="Cancel"
                          CancelButton
                          onClick={() => closepop(resetForm)}
                          isDisabled={buttonDisable || isSubmitting}
                        />
                        <PosFormButton
                          isDisabled={
                            !values.amount ||
                            values.amount?.length <= 0 ||
                            errors.amount ||
                            errors.external_reference ||
                            buttonDisable ||
                            isSubmitting
                          }
                          buttonsubmit="Submit"
                          SubmitButton
                          onClick={() => handleSubmit(values,resetForm)}
                        />
                      </Flex>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Form>
            )}
          </Formik>
          <Stack spacing={3} mt="0.13rem">
            <PosLable
              label={true}
              name={"Notes"}
              mt="0.31rem"
              fontStyle="normal"
              fontSize="0.94rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <Textarea
              id="notes"
              placeholder=""
              borderRadius={"0.63rem"}
              border={Colors.posTextAreaBorder}
              background={Colors.posInputBackground}
              onChange={formik.handleChange}
              value={formik.values.notes}
              onBlur={formik.handleBlur}
              // onKeyDown={handleKeyDown}
              inputerror={formik.touched.notes && formik.errors.notes}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.notes && formik.errors.notes ? (
                <span>{formik.errors.notes}</span>
              ) : null}
            </Text>
          </Stack>
          {null != formik.values.notes &&
          "" != formik.values.notes &&
          191 - formik.values.notes.length > 0 ? (
            <Text
              pt={"0.13rem"}
              color={Colors.textareaColor}
              fontSize={"0.94rem"}
              fontStyle={"normal"}
              fontWeight={300}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
            >
              {191 - formik.values.notes.length} characters left for notes
            </Text>
          ) : (
            ""
          )}
          <Stack spacing={3}>
            <PosLable fontWeight={500} name={"Credit Limit"} label={true} />
            <PosInput
              id={"credit_limit"}
              placeholder={"Credit Limit"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.credit_limit}
              // handleBlur={formik.handleBlur}
              // onKeyDown={handleKeyDown}
              handleBlur={(e) => {
                formik.handleBlur(e);
                formik.setFieldValue(
                  "credit_limit",
                  twofix(formik.values.credit_limit) || ""
                );
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              inputType={"number"}
              posNumberInput={true}
            />
            <Text color={Colors.posCancleButtonRed}>
              {formik.touched.credit_limit && formik.errors.credit_limit ? (
                <span>{formik.errors.credit_limit}</span>
              ) : null}
            </Text>
          </Stack>
          <Text
            mt="2rem"
            w="10.44rem"
            color={Colors.posTextInfo}
            style={{
              flexShrink: "0",
              fontSize: "0.94rem",
              fontStyle: "normal",
              fontWeight: "300",
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            Tax Exempt
          </Text>
          <Stack
            direction="row"
            mt="0.313rem"
            w="2.75rem"
            height="1.38rem"
            flexShrink="0"
          >
            <PosSwitchButton
              id="tax_exempt"
              switchValue={formik.values.tax_exempt}
              onChange={formik.handleChange}
            />
          </Stack>
          <Text
            mt={"0.88rem"}
            style={{
              fontSize: "0.94rem",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
            color={Colors.posShowFields}
          >
            Turn on, to exempt this customer on tax payments
          </Text>
          <Text
            mt="2rem"
            color={Colors.posTextInfo}
            style={{
              flexShrink: "0",
              fontSize: "0.94rem",
              fontStyle: "normal",
              fontWeight: "300",
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            Exclude from Reward Calculation
          </Text>
          <Stack
            direction="row"
            mt="0.313rem"
            w="2.75rem"
            height="1.38rem"
            flexShrink="0"
          >
            <PosSwitchButton
              id="exclude_from_reward_calculation"
              switchValue={formik.values.exclude_from_reward_calculation}
              onChange={formik.handleChange}
            />
          </Stack>
          <Text
            mt={"0.88rem"}
            style={{
              fontSize: "0.94rem",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
            color={Colors.posShowFields}
          >
            Turn on, to exclude this customer from reward calculations
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default WithRouter(CustomerEdit);
