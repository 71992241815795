import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import Colors from "../../assets/colors/Colors";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const sanepos = definePartsStyle({
  field: {
    border: "1px",
    borderColor: Colors.inputBorderColor,
    borderRadius: "1rem",//"16px"
    marginTop: 1,
    _focus: {
      border: "0.125rem solid",
      borderColor: Colors.buttonHover,
      boxShadow: "0rem 0rem 0rem 0.188rem #E6D1FF ",
    },
  },
  icon: {
    color: Colors.mainHeader,
  },
});
const lg = defineStyle({
  fontSize: "sm",
  fontWeight: 400,
  lineHeight: 5,//"20px"
  color: Colors.mainHeader,
});

const sizes = {
  lg: definePartsStyle({ field: lg }),
};

export const selectTheme = defineMultiStyleConfig({
  variants: { sanepos },
  sizes,
});
