import { extendTheme } from "@chakra-ui/react";
import { alertTheme } from "./Alert";
import ButtonTheme from "./Button";
import { cardTheme } from "./Card";
import { dividerTheme } from "./Divider";
import { selectTheme } from "./Dropdown";
import { inputTheme } from "./Input";
import { menuTheme } from "./Menu";
import { tabsTheme } from "./Tab";
import { badgeTheme } from "./Badge";
import { radioTheme } from "./Radio";
import { drawerTheme } from './Drawer';
import { textareaTheme } from "./TextArea";

const theme = extendTheme({
  fonts: {
    body: "",
    heading: "",
    mono: "",
  },
  components: {
    Button: ButtonTheme,
    Select: selectTheme,
    Input: inputTheme,
    NumberInput: inputTheme,
    Tabs: tabsTheme,
    Alert: alertTheme,
    Divider: dividerTheme,
    Card: cardTheme,
    Menu: menuTheme,
    Badge: badgeTheme,
    Radio: radioTheme,
    Drawer: drawerTheme,
    Textarea: textareaTheme
}
});

export default theme;
