import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getAllCategories(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CATEGORY_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CATEGORY_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getGlobleRoutePermissionCategory() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GLOBLE_ROUTE_CHECK_PERMISSION,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CATEGORY_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function categoryCreate({ request }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = {
      name: Object.fromEntries(formData).name,
      stay_on_cat_prod_page: JSON.parse(
        Object.fromEntries(formData).stay_on_cat_prod_page
      ),
      hide_cat_from_pos_attr: JSON.parse(
        Object.fromEntries(formData).hide_cat_from_pos_attr
      ),
      show_in_online_ordering: JSON.parse(
        Object.fromEntries(formData).show_in_online_ordering
      ),
      category_sort: Object.fromEntries(formData).category_sort,
      online_ordering_sort: Object.fromEntries(formData).online_ordering_sort,
      background_color: Object.fromEntries(formData).background_color,
      border_color: Object.fromEntries(formData).border_color,
      text_color: Object.fromEntries(formData).text_color,
    };
    if (Object.fromEntries(formData).hasOwnProperty("categoryImage")) {
      payload.categoryImage = JSON.parse(
        Object.fromEntries(formData).categoryImage
      );
    }
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CATEGORY_CREATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CATEGORY_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function categoryUpdate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      name: Object.fromEntries(formData).name,
      stay_on_cat_prod_page: JSON.parse(
        Object.fromEntries(formData).stay_on_cat_prod_page
      ),
      hide_cat_from_pos_attr: JSON.parse(
        Object.fromEntries(formData).hide_cat_from_pos_attr
      ),
      show_in_online_ordering: JSON.parse(
        Object.fromEntries(formData).show_in_online_ordering
      ),
      category_sort:
        null != Object.fromEntries(formData).category_sort &&
        "" != Object.fromEntries(formData).category_sort
          ? JSON.parse(Object.fromEntries(formData).category_sort)
          : null,
      online_ordering_sort:
        null != Object.fromEntries(formData).online_ordering_sort &&
        "" != Object.fromEntries(formData).online_ordering_sort
          ? JSON.parse(Object.fromEntries(formData).online_ordering_sort)
          : null,
      background_color: Object.fromEntries(formData).background_color,
      border_color: Object.fromEntries(formData).border_color,
      text_color: Object.fromEntries(formData).text_color,
    };
    if (Object.fromEntries(formData).hasOwnProperty("categoryImage")) {
      payload.categoryImage = JSON.parse(
        Object.fromEntries(formData).categoryImage
      );
    }
    if (Object.fromEntries(formData).hasOwnProperty("image_base64")) {
      payload.image_base64 = JSON.parse(
        Object.fromEntries(formData).image_base64
      );
    }
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CATEGORY_UPDATE_API_ROUTE +
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CATEGORY_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getSingleCategory({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.CATEGORY_GET_DATA_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CATEGORY_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function categoryDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.CATEGORY_DEACTIVATE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.CATEGORY_DEACTIVATE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.CATEGORY_REACTIVATE_API_ROUTE +
            "/" +
            id,
          {},
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.CATEGORY_DEACTIVE_LIST_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

// export function deactivateCategory(id) {
//   return new Promise(async (resolve, reject) => {
//     let token = localStorage.getItem("token");
//     axios
//       .delete(
//         Constants.REACT_APP_API_URL +
//         process.env.REACT_APP_API_VERSION +
//         Constants.CATEGORY_DEACTIVATE_API_ROUTE + "/" +
//         id,
//         {
//           headers: {
//             "content-type": Constants.CONTANT_TYPE,
//             Authorization: "Bearer" + token,
//             Permission: Constants.CATEGORY_DEACTIVATE_API_PERMISSION,
//           },
//         }
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function reactiveCategory(id) {
//   var payload = {};
//   return new Promise(async (resolve, reject) => {
//     let token = localStorage.getItem("token");
//     axios
//       .post(
//         Constants.REACT_APP_API_URL +
//         process.env.REACT_APP_API_VERSION +
//         `${Constants.CATEGORY_REACTIVATE_API_ROUTE + "/" + id}`,
//         payload,
//         {
//           headers: {
//             "content-type": Constants.CONTANT_TYPE,
//             Authorization: "Bearer" + token,
//             Permission: Constants.CATEGORY_DEACTIVE_LIST_API_PERMISSION,
//           },
//         }
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

export function getAllDeactiveCategories(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CATEGORY_DEACTIVE_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CATEGORY_DEACTIVE_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
