import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import Colors from "../../assets/colors/Colors";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    // borderColor: '#938B9C',
  }
  
});


// define custom variant
  const variants = {
    sanepos: definePartsStyle({
    container: {
      border: "1px solid",
      borderRadius: "0.875rem",
      borderColor: Colors.inputBorderColor
    }
  })
};

// export the component theme
export const cardTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
});