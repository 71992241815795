import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  List,
  ListItem,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditIcon from "@mui/icons-material/Edit";
import { GridToolbar } from "@mui/x-data-grid";
import _ from "lodash";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useParams,
  useSubmit,
} from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosFormButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getFiltersList,
  // updateSegment,
  getManualSegmentRewardCard,
  showUsers,
} from "./SegmentService";

const RadioCard = ({ children, ...props }) => {
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();
  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius={props.borderRadius}
        boxShadow="md"
        _checked={{
          bg: "#5881FE",
          borderWidth: "2px",
          color: "white",
          borderColor: "#5881FE",
          borderRadius: props.borderRadius,
        }}
        _focus={
          {
            // boxShadow: 'outline',
          }
        }
        maxW={props.maxWidth}
        flexDirection={"column"}
      >
        {children}
      </Box>
    </Box>
  );
};

const SegmentView = (props) => {
  const { id } = useParams();
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation(); // get backraking data as param
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const effectPaginationRun = useRef(false);

  const [flag, setFlag] = useState(true);
  const [name, setName] = useState(null);

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");

  const [selectedSegmentType, setSelectedSegmentType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState("id");
  const [allRewardCards, setAllRewardCards] = useState([]);
  const [totalcount, setTotalCount] = useState(0);

  const [filterRewardCards, setFilterRewardCards] = useState([]);
  const [totalFilterCount, setFilterTotalCount] = useState(0);

  const [filterManualRewardCards, setFilterManualRewardCards] = useState([]);
  const [filterManualTotalCount, setFilterManualTotalCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsersData] = React.useState([]);
  const [newData, setNewData] = useState(false);

  const [checkedItems, setCheckedItems] = React.useState([]);
  const [viewSelectedRewardCards, setViewSelectedRewardCards] = React.useState(
    []
  );
  const checkRef = useRef(checkedItems);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const sortRef = useRef(sortModel);

  const [paginationShowUserModel, setPaginationShowUserModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortShowUserModel, setSortShowUserModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const sortShowUserRef = useRef(sortShowUserModel);

  // Smart Group
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const filterType = [
    { name: "Predefined Filter", code: "PRE" },
    { name: "Custom Filter", code: "CUST" },
  ];

  const [segmentId, setSegmentId] = useState(null);
  const [selectedCustomFilters, setSelectedCustomFilters] = useState(null);
  const [
    commaSeparatedDisplayCatNames,
    setCommaSeparatedDisplayCatNames,
  ] = useState(null);
  const [
    commaSeparatedDisplayProdNames,
    setCommaSeparatedDisplayProdNames,
  ] = useState(null);
  const [customFilters, setCustomFilters] = useState(null);
  const [selectedPredefinedFilters, setSelectedPredefinedFilters] = useState(
    null
  );
  const [predefinedFilters, setPredefinedFilters] = useState(null);
  const [categoryFilters, setCategoryFilters] = useState(null);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectCatFilter, setSelectCatFilter] = useState(null);
  const [selectedPurchaseDates, setSelectedPurchaseDates] = useState(null);
  const [selectedPurchaseDate, setSelectedPurchaseDate] = useState(null);
  const [selectedDateRanges, setSelectedDateRanges] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const calendarRef = useRef(Calendar);
  const fromCalendarRef = useRef(Calendar);
  const toCalendarRef = useRef(Calendar);

  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [onDateView, setOnDateView] = useState(null);

  const [startFromBetweenDate, setFromDate] = useState(null);
  const [startToBetweenDate, setToDate] = useState(null);

  const [purchaseNumber, setPurchaseNumber] = useState(null);

  const [selectItemFilters, setSelectItemFilters] = useState(null);
  const [selectItemFilter, setSelectItemFilter] = useState(null);

  const [selectedVisitDates, setSelectedVisitDates] = useState(null);
  const [selectedVisitDate, setSelectedVisitDate] = useState(null);
  const [visitNumber, setVisitNumber] = useState(null);
  const [lastVisitNumber, setLastVisitNumber] = useState(null);

  const [selectSpentFilters, setSelectSpentFilters] = useState(null);
  const [selectSpent, setSelectSpent] = useState(null);
  const [spentGreaterNumber, setSpentGreaterNumber] = useState(null);
  const [spentLessNumber, setSpentLessNumber] = useState(null);
  const [spentEqualNumber, setSpentEqualNumber] = useState(null);
  const [spentStartNumber, setSpentStartNumber] = useState(null);
  const [spentEndNumber, setSpentEndNumber] = useState(null);

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);

  const [showTable, setShowTable] = useState(true);
  const [showUserTable, setShowUserTable] = useState(false);

  const [dates, setDates] = useState(null);

  const [errors, setErrors] = useState({
    name: "",
    purchaseNumber: "",
    visitNumber: "",
    lastVisitNumber: "",
    spentGreaterNumber: "",
    spentLessNumber: "",
    spentEqualNumber: "",
    spentStartNumber: "",
    spentEndNumber: "",
  });

  const handleSegmentTypeChange = (value) => {
    setSelectedSegmentType(value);
  };

  const {
    getRootProps: getSegmentTypeRootProps,
    getRadioProps: getSegmentTypeRadioProps,
    setValue: setSegmentTypeValue,
  } = useRadioGroup({
    name: "type",
    defaultValue: "",
    // onChange: (value) => {
    //   handleSegmentTypeChange(value);
    //   handleRadioSelect(value, selectedLocationId);
    // },
  });
  const segmentTypeGroup = getSegmentTypeRootProps();

  const segmentTypeOptions = [
    {
      title: "Manual",
      body: "Option to use manually add people to the segment",
      type: "MANUAL",
      icon: <EditIcon style={{ fill: Colors.primeTaxInfo }} />,
      value: "Select Manual",
    },
    {
      title: "Smart Group",
      body: "Option to use the automatic feature powered by AI",
      type: "GROUP",
      icon: <EditIcon style={{ fill: Colors.primeTaxInfo }} />,
      value: "Select Smart Group",
    },
  ];
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      width: 90,
    },
    {
      headerName: "First Name",
      field: "first_name",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Last Name",
      field: "last_name",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Email Address",
      field: "email",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      headerName: "Phone Number",
      field: "phone",
      sortable: true,
      ...actionColumn,
    },
  ];

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setSegmentId(loaderResponse.data?.data?.segment?.id);
          setName(loaderResponse.data?.data?.segment?.name);
          setLocations(loaderResponse.data?.data?.locations);
          setSelectedLocationId(
            loaderResponse.data?.data?.segment?.location_id
          );
          setSelectedLocation(loaderResponse.data?.data?.segment?.location);
          setSelectedSegmentType(loaderResponse.data?.data?.segment?.type);
          if (loaderResponse.data?.data?.segment?.type == "MANUAL") {
            setViewSelectedRewardCards(loaderResponse.data?.data?.allUsers);
            setSelectedUsersData(loaderResponse.data?.data?.allUsers);
            setCheckedItems(loaderResponse.data?.data?.allUsersIds);
            setNewData(!newData);

            setTimeout(() => {
              let showRewardCardFlag = 1;
              handleSubmitManual(
                showRewardCardFlag,
                loaderResponse.data?.data?.segment?.id
              );
            }, 2000);
          } else if (loaderResponse.data?.data?.segment?.type == "GROUP") {
            handleRadioSelect(
              loaderResponse.data?.data?.segment?.type,
              loaderResponse.data?.data?.segment?.location_id
            );
            if (1 == loaderResponse.data?.data?.isPredefined) {
              //Predefined......
              setSelectedFilterType(filterType[0]);
              setSelectedPredefinedFilters(
                loaderResponse.data?.data?.subFilName
              );
            } else {
              //Custom......
              setSelectedFilterType(filterType[1]);
              setSelectedCustomFilters(
                loaderResponse.data?.data?.selectedFilterName
              );

              if (1 == loaderResponse.data?.data?.categoryFlag) {
                setSelectCatFilter(
                  loaderResponse.data?.data?.selectedCategoryFilter
                );
                setSelectedCategory(loaderResponse.data?.data?.catValue);
                const displayCatNames = loaderResponse.data?.data?.catValue.map(
                  (cat) => cat.name
                );
                setCommaSeparatedDisplayCatNames(displayCatNames.join(", "));
              }
              // dateFilters
              if (1 == loaderResponse.data?.data?.dateFlag) {
                setSelectedPurchaseDate(
                  loaderResponse.data?.data?.selectedPurchaseDate
                );
                //Date Range
                setSelectedDateRange(
                  loaderResponse.data?.data?.selectedDateRange
                );
                if (1 == loaderResponse.data?.data?.onDateRange) {
                  setPurchaseNumber(loaderResponse.data?.data?.purchaseNumber);
                  setSelectedDate(loaderResponse.data?.data?.selectedDate);
                }
                //On Date
                if (1 == loaderResponse.data?.data?.onDateFlag) {
                  setOnDateView(
                    new Date(moment(loaderResponse.data?.data?.start_date))
                  );
                }
                //Between Dates
                if (1 == loaderResponse.data?.data?.onBetweenDate) {
                  setFromDate(
                    new Date(
                      moment(
                        loaderResponse.data?.data?.between_start_date + "Z"
                      )
                    )
                  );
                  setToDate(
                    new Date(
                      moment(loaderResponse.data?.data?.between_end_date + "Z")
                    )
                  );
                }
              }
              // Item Filter
              if (1 == loaderResponse.data?.data?.itemFlag) {
                setSelectItemFilter(
                  loaderResponse.data?.data?.selectItemFilter
                );
                setSelectedProducts(loaderResponse.data?.data?.prodValue);
                const displayProdNames = loaderResponse.data?.data?.prodValue.map(
                  (prod) => prod.name
                );
                setCommaSeparatedDisplayProdNames(displayProdNames.join(", "));
              }
              // Visit Filter
              if (1 == loaderResponse.data?.data?.visitDateFlag) {
                setVisitNumber(loaderResponse.data?.data?.visitNumber);
                setLastVisitNumber(loaderResponse.data?.data?.lastVisitNumber);
                setSelectedVisitDate(
                  loaderResponse.data?.data?.selectedVisitDate
                );
              }
              // Spent Filter
              if (1 == loaderResponse.data?.data?.spentFlag) {
                setSelectSpent(loaderResponse.data?.data?.spentWasFilter);
                if (
                  null != loaderResponse.data?.data?.spentGreaterAmount &&
                  "" != loaderResponse.data?.data?.spentGreaterAmount
                ) {
                  setSpentGreaterNumber(
                    twofix(loaderResponse.data?.data?.spentGreaterAmount)
                  );
                } else if (
                  null != loaderResponse.data?.data?.spentLessAmount &&
                  "" != loaderResponse.data?.data?.spentLessAmount
                ) {
                  setSpentLessNumber(
                    twofix(loaderResponse.data?.data?.spentLessAmount)
                  );
                } else if (
                  null != loaderResponse.data?.data?.spentEqualAmount &&
                  "" != loaderResponse.data?.data?.spentEqualAmount
                ) {
                  setSpentEqualNumber(
                    twofix(loaderResponse.data?.data?.spentEqualAmount)
                  );
                } else if (
                  null != loaderResponse.data?.data?.spentBetweenStart &&
                  "" != loaderResponse.data?.data?.spentBetweenStart &&
                  null != loaderResponse.data?.data?.spentBetweenEnd &&
                  "" != loaderResponse.data?.data?.spentBetweenEnd
                ) {
                  setSpentStartNumber(
                    twofix(loaderResponse.data?.data?.spentBetweenStart)
                  );
                  setSpentEndNumber(
                    twofix(loaderResponse.data?.data?.spentBetweenEnd)
                  );
                }
              }
            }
            // setTimeout(() => {
            let showRewardCardFlag = 1;
            handleSubmitSmartGroup(
              showRewardCardFlag,
              loaderResponse.data?.data?.segment?.name,
              loaderResponse.data?.data?.segment?.location,
              loaderResponse.data?.data?.segment?.type,
              loaderResponse.data?.data?.allUsers,
              loaderResponse.data?.data?.isPredefined,
              loaderResponse.data?.data?.subFilName,
              loaderResponse.data?.data?.selectedFilterName,
              loaderResponse.data?.data?.selectedCategoryFilter,
              loaderResponse.data?.data?.catValue,
              loaderResponse.data?.data?.selectedPurchaseDate,
              loaderResponse.data?.data?.selectedDateRange,
              loaderResponse.data?.data?.purchaseNumber,
              loaderResponse.data?.data?.selectedDate,
              // loaderResponse.data?.data?.onDateFlag,
              new Date(moment(loaderResponse.data?.data?.start_date)),
              new Date(
                moment(loaderResponse.data?.data?.between_start_date + "Z")
              ),
              new Date(
                moment(loaderResponse.data?.data?.between_end_date + "Z")
              ),
              loaderResponse.data?.data?.selectItemFilter,
              loaderResponse.data?.data?.prodValue,
              loaderResponse.data?.data?.visitNumber,
              loaderResponse.data?.data?.lastVisitNumber,
              loaderResponse.data?.data?.selectedVisitDate,
              loaderResponse.data?.data?.spentWasFilter,
              loaderResponse.data?.data?.spentGreaterAmount,
              loaderResponse.data?.data?.spentLessAmount,
              loaderResponse.data?.data?.spentEqualAmount,
              loaderResponse.data?.data?.spentBetweenStart,
              twofix(loaderResponse.data?.data?.spentBetweenEnd)
            );
            // }, 2000);
          } else {
          }
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.SEGMENT_LIST, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.SEGMENT_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);

  const modifiedShowUserData = filterRewardCards?.map((data) => ({
    id: data.id,
    first_name:
      data.first_name == null || data.first_name == ""
        ? "N.A."
        : data.first_name,
    last_name:
      data.last_name == null || data.last_name == "" ? "N.A." : data.last_name,
    email: data.email == null || data.email == "" ? "N.A." : data.email,
    phone: data.phone,
  }));

  const modifiedShowManualUserData = filterManualRewardCards?.map((data) => ({
    id: data.id,
    first_name:
      data.first_name == null || data.first_name == ""
        ? "N.A."
        : data.first_name,
    last_name:
      data.last_name == null || data.last_name == "" ? "N.A." : data.last_name,
    email: data.email == null || data.email == "" ? "N.A." : data.email,
    phone: data.phone,
  }));

  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );

  const handleRadioSelect = (value, location_id) => {
    setSelectedFilterType("");
    setSelectedCustomFilters("");
    setSelectedPredefinedFilters("");
    setSelectedPurchaseDate("");
    setSelectedDateRange([]);
    setPurchaseNumber("");
    setSelectedDate("");
    setOnDateView("");
    setFromDate("");
    setToDate("");
    setSelectCatFilter("");
    setSelectedCategory([]);
    setSelectItemFilter("");
    setSelectedProducts([]);
    setVisitNumber("");
    setLastVisitNumber("");
    setSelectedVisitDate([]);
    setSelectSpent("");
    setSpentGreaterNumber("");
    setSpentLessNumber("");
    setSpentStartNumber("");
    setSpentEndNumber("");
    setSpentEqualNumber("");
    setShowUserTable(false);
    setCheckedItems([]);
    setViewSelectedRewardCards([]);
    setSelectedUsersData([]);
    checkRef.current = [];
    if (null != value && "" != value && value == "GROUP") {
      // setCheckedItems([]);
      // setViewSelectedRewardCards([]);
      let payload = {
        type: value,
        locationID: location_id,
      };
      try {
        getFiltersList(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setLoading(false);
              let allFilters = response?.data?.data;
              setCustomFilters(allFilters?.customFilters);
              setPredefinedFilters(allFilters?.predefinedFilters);

              setCategoryFilters(allFilters?.categoryFilter);
              setCategories(allFilters?.category);
              setSelectedPurchaseDates(allFilters?.purchaseDate);
              setSelectedDateRanges(allFilters?.dateRange);
              setSelectedDates(allFilters?.date);
              setSelectItemFilters(allFilters?.item);
              setSelectedVisitDates(allFilters?.visitDateAll);
              setSelectSpentFilters(allFilters?.spent);
              setProducts(allFilters?.product);
              myContext.handleLoading(false);
            } else {
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
  };

  const search = (event) => {
    setTimeout(() => {
      let _filteredProducts;
      if (!event.query.trim().length) {
        _filteredProducts = [...products];
      } else {
        _filteredProducts = products.filter((product) => {
          return product.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredProducts(_filteredProducts);
    }, 250);
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const handleSubmitManual = (showRewardCardFlag, segmentId) => {
    let submitDone = true;
    setLoading(true);
    let data = {
      id: segmentId,
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      type: "MANUAL",
    };
    try {
      getManualSegmentRewardCard(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            setFilterManualRewardCards(response.data.data.data);
            setFilterManualTotalCount(response.data.data.totalCount);
            myContext.handleLoading(false);
          } else {
            setFilterManualRewardCards([]);
            setFilterManualTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setFilterManualRewardCards([]);
          setFilterManualTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setFilterManualRewardCards([]);
      setFilterManualTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const handleSubmitSmartGroup = (
    showRewardCardFlag,
    segment_name,
    location,
    seg_type,
    viewRewards,
    isPredefined,
    predefinedFilters,
    customFilters,
    catFilter,
    catValue,
    selectPurDate,
    dateRage,
    purNum,
    selectDate,
    ondate,
    start_from,
    start_to,
    selectedItem,
    selecProd,
    visitNum,
    lastVisit,
    selectLastVisit,
    selectSpentW,
    spentGreater,
    spentLess,
    spentEqual,
    spentStart,
    spentEnd
  ) => {
    setLoading(true);
    if (
      null == segment_name ||
      "" == segment_name ||
      segment_name.length == 0
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_WARNING,
        alertDescription: Constants.SEGMENT_NAME_FIELD_REQUIRED,
      });
    } else if (null == location || "" == location || location.length == 0) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_WARNING,
        alertDescription: Constants.SEGMENT_LOCATION_FIELD_REQUIRED,
      });
    } else {
      if (null != seg_type && "" != seg_type) {
        if (seg_type == "MANUAL") {
          if (
            null == viewRewards ||
            "" == viewRewards ||
            viewRewards.length == 0
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.TOAST_HEADING_WARNING,
              alertDescription: Constants.SEGMENT_MANUAL_USERS_REQUIRED,
            });
          } else {
            const userIds = viewRewards?.map((user) => user.id);
            const matchedOption = segmentTypeOptions.find((option) => {
              return option.type === selectedSegmentType;
            });
            let payload = {
              // name: name,
              segment: {
                id: id,
                name: name,
                type: matchedOption?.type,
                location_id: location?.id,
                location: location,
              },
              type: {
                key: matchedOption?.type,
                value: matchedOption?.value,
              },
              location: {
                key: location?.id,
                value: location?.name,
              },
              fromCreateSegment: 0,
              pre: 0,
              userId: userIds,
            };
            // try {
            //   updateSegment(payload, id)
            //     .then((response) => {
            //       if (
            //         undefined !== response.data.flag &&
            //         null !== response.data.flag &&
            //         response.data.flag == true
            //       ) {
            //         setLoading(false);
            //         props.navigate(Constants.SEGMENT_LIST);
            //         addToast({
            //           alertStatus: Constants.TOAST_TYPE_SUCESS,
            //           alertTitle: Constants.SEGMENT_MODULE,
            //           alertDescription: response.data.message,
            //         });
            //         myContext.handleLoading(false);
            //       } else {
            //         setLoading(false);
            //         let actionData = response;
            //         error({ actionData });
            //         myContext.handleLoading(false);
            //       }
            //     })
            //     .catch((err) => {
            //       setLoading(false);
            //       let actionData = err;
            //       error({ actionData });
            //       myContext.handleLoading(false);
            //     });
            // } catch (error) {
            //   setLoading(false);
            //   myContext.handleLoading(false);
            // }
          }
        } else if (seg_type == "GROUP") {
          let submitDone = true;
          if (isPredefined >= 0) {
            if (isPredefined == 1) {
              if (
                null != predefinedFilters &&
                "" != predefinedFilters &&
                predefinedFilters.hasOwnProperty("name")
              ) {
                const matchedOption = segmentTypeOptions.find((option) => {
                  return option.type === seg_type;
                });
                let payload = {
                  segment: {
                    id: id,
                    name: name,
                    type: matchedOption?.type,
                    location_id: location?.id,
                    location: location,
                  },
                  type: {
                    key: matchedOption?.type,
                    value: matchedOption?.value,
                  },
                  location: {
                    key: location?.id,
                    value: location?.name,
                  },
                  filterType: {
                    name:
                      isPredefined == 1 ? "Predefined Filter" : "Custom Filter",
                    value: isPredefined == 1 ? "PRE" : "CUST",
                  },
                  fromCreateSegment: 0,
                  pre: 1,
                  predefinedFilter: {
                    label: predefinedFilters?.display_name,
                    value: predefinedFilters?.name,
                  },
                  customFilter: "",
                };
                // if (showRewardCardFlag == 0) {
                //   if (submitDone) {
                //     update(payload);
                //   }
                // } else {
                //   if (submitDone) {
                getSegmentRewardCard(payload);
                //   }
                // }
              } else {
                submitDone = false;
                // addToast({
                //   alertStatus: Constants.TOAST_TYPE_WARNING,
                //   alertTitle: Constants.TOAST_HEADING_WARNING,
                //   alertDescription:
                //     Constants.SEGMENT_PREDEFINED_FILTER_REQUIRED,
                // });
              }
            } else if (isPredefined == 0) {
              if (
                null != customFilters &&
                "" != customFilters &&
                customFilters.length > 0
              ) {
                // submitDone = false;
                const matchedOption = segmentTypeOptions.find((option) => {
                  return option.type === seg_type;
                });
                // Common Payload for custom filter.
                // selectedCustomFilters.forEach((option) => {
                //   option.value = option.name;
                //   option.label = option.display_name;
                //   // delete option.name;
                //   // delete option.display_name;
                // });
                // let selectedCustomFiltersSubmit = selectedCustomFilters;
                // selectedCustomFiltersSubmit.forEach((option) => {
                //   option.value = option.name;
                //   option.label = option.display_name;
                //   // delete option.name;
                //   // delete option.display_name;
                // });
                let payload = {
                  // name: name,
                  segment: {
                    id: id,
                    name: name,
                    type: matchedOption?.type,
                    location_id: location?.id,
                    location: location,
                  },
                  type: {
                    key: matchedOption?.type,
                    value: matchedOption?.value,
                  },
                  location: {
                    key: location?.id,
                    value: location?.name,
                  },
                  filterType: {
                    name:
                      isPredefined == 1 ? "Predefined Filter" : "Custom Filter",
                    value: isPredefined == 1 ? "PRE" : "CUST",
                  },
                  fromCreateSegment: 0,
                  pre: 0,
                  predefinedFilter: "",
                  customFilter: customFilters, //change display_name to label and name to value remaining
                };

                const hasCat = customFilters.some(
                  (item) => item.name === "category"
                );
                if (hasCat) {
                  if (
                    catFilter != null &&
                    catFilter != "" &&
                    catFilter.hasOwnProperty("name")
                  ) {
                    if (catValue.length > 0) {
                      // submitDone = true;
                      payload.selectCatFilter = {
                        label: catFilter?.display_name,
                        value: catFilter?.name,
                      };
                      // selectCatFilter; //change display_name to label and name to value remaining
                      payload.selectedCategory = catValue;
                      payload.createCategoryString = catFilter?.name;
                      payload.selectedCategoryNames = catValue?.map(
                        (category) => category.name
                      );
                      // update(payload);
                    } else {
                      submitDone = false;
                      // addToast({
                      //   alertStatus: Constants.TOAST_TYPE_WARNING,
                      //   alertTitle: Constants.TOAST_HEADING_WARNING,
                      //   alertDescription:
                      //     Constants.SEGMENT_SELECT_CATEGORY_EMPTY,
                      // });
                    }
                  } else {
                    submitDone = false;
                    // addToast({
                    //   alertStatus: Constants.TOAST_TYPE_WARNING,
                    //   alertTitle: Constants.TOAST_HEADING_WARNING,
                    //   alertDescription: Constants.SEGMENT_CAT_FILTER_REQUIRED,
                    // });
                  }
                }

                const hasDate = customFilters.some(
                  (item) => item.name === "date"
                );

                if (hasDate) {
                  if (
                    selectPurDate != null &&
                    selectPurDate != "" &&
                    selectPurDate.hasOwnProperty("name")
                  ) {
                    payload.selectedPurchaseDate = {
                      label: selectPurDate?.display_name,
                      value: selectPurDate?.name,
                    };
                    if (dateRage.hasOwnProperty("name")) {
                      payload.selectedDateRange = {
                        label: dateRage?.display_name,
                        value: dateRage?.name,
                      };
                      if (dateRage.name == "date_range") {
                        if (
                          null != purNum &&
                          purNum.length > 0 &&
                          selectDate.hasOwnProperty("name")
                        ) {
                          if (isPositiveInteger(purNum)) {
                            payload.purchaseNumber = purNum;
                            payload.selectedDate = {
                              label: selectDate?.display_name,
                              value: selectDate?.name,
                            };
                            payload.createPurchaseStringString =
                              selectPurDate?.name;
                            payload.selectDateRangeCreateString =
                              dateRage?.name;
                            payload.selectPurchaseDayCreateString =
                              selectDate?.name;
                            payload.createFinalStringForPurchaseDate =
                              selectPurDate?.name +
                              "." +
                              dateRage?.name +
                              "." +
                              selectDate?.name;
                          } else {
                            submitDone = false;
                            // addToast({
                            //   alertStatus: Constants.TOAST_TYPE_WARNING,
                            //   alertTitle: Constants.TOAST_HEADING_WARNING,
                            //   alertDescription:
                            //     Constants.SEGMENT_RANGE_VALID_NUMBER,
                            // });
                          }
                        } else {
                          submitDone = false;
                          addToast({
                            alertStatus: Constants.TOAST_TYPE_WARNING,
                            alertTitle: Constants.TOAST_HEADING_WARNING,
                            alertDescription:
                              Constants.SEGMENT_ENTER_RANGE_NUMBER_AND_SELECT_DURATION,
                          });
                        }
                      } else if (dateRage.name == "on_date") {
                        // Parse the original date string using moment
                        const parsedDate = moment(
                          ondate,
                          "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
                        );

                        // Convert to the desired format
                        const convertedDate = parsedDate.format(
                          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                        );
                        if (null != ondate && "" != ondate) {
                          payload.start_date = convertedDate; ///"start_date": "2024-04-24T18:30:00.000Z",

                          var month = ondate.getMonth() + 1;
                          var startDate =
                            ondate.getFullYear() +
                            "-" +
                            month +
                            "-" +
                            ondate.getDate();
                          payload.startDate = startDate; ////"startDate": "2024-4-25",
                          payload.createPurchaseStringString =
                            selectPurDate?.name;
                          payload.selectDateRangeCreateString = dateRage?.name;
                          payload.createFinalStringForPurchaseDate =
                            selectPurDate?.name + "." + dateRage?.name;
                        } else {
                          submitDone = false;
                          // addToast({
                          //   alertStatus: Constants.TOAST_TYPE_WARNING,
                          //   alertTitle: Constants.TOAST_HEADING_WARNING,
                          //   alertDescription:
                          //     Constants.SEGMENT_ON_DATE_REQUIRED,
                          // });
                        }
                      } else if (dateRage.name == "between_date") {
                        if (
                          null != start_from &&
                          "" != start_from &&
                          null != start_to &&
                          "" != start_to
                        ) {
                          // submitDone = true;
                          payload.between_start_date = start_from;
                          payload.between_end_date = start_to;

                          payload.createPurchaseStringString =
                            selectPurDate?.name;
                          payload.selectDateRangeCreateString = dateRage?.name;
                          payload.createFinalStringForPurchaseDate = "";
                          payload.createFinalStringForPurchaseDateStart =
                            selectPurDate?.name + "." + "between_start_date";
                          payload.createFinalStringForPurchaseDateEnd =
                            selectPurDate?.name + "." + "between_end_date";
                        } else {
                          submitDone = false;
                          // addToast({
                          //   alertStatus: Constants.TOAST_TYPE_WARNING,
                          //   alertTitle: Constants.TOAST_HEADING_WARNING,
                          //   alertDescription:
                          //     Constants.SEGMENT_FROM_DATE_AND_TO_DATE_REQUIRED,
                          // });
                        }
                      }
                    } else {
                      submitDone = false;
                      // addToast({
                      //   alertStatus: Constants.TOAST_TYPE_WARNING,
                      //   alertTitle: Constants.TOAST_HEADING_WARNING,
                      //   alertDescription:
                      //     Constants.SEGMENT_DATE_TYPE_FILTER_REQUIRED,
                      // });
                    }
                  } else {
                    submitDone = false;
                    // addToast({
                    //   alertStatus: Constants.TOAST_TYPE_WARNING,
                    //   alertTitle: Constants.TOAST_HEADING_WARNING,
                    //   alertDescription: Constants.SEGMENT_FILTER_FIELD_REQUIRED,
                    // });
                  }
                }

                const hasItem = customFilters.some(
                  (item) => item.name === "item"
                );

                if (hasItem) {
                  if (
                    selectedItem != null &&
                    selectedItem != "" &&
                    selectedItem.hasOwnProperty("name")
                  ) {
                    if (selecProd.length > 0) {
                      payload.selectItemFilter = {
                        label: selectedItem?.display_name,
                        value: selectedItem?.name,
                      };
                      payload.selectProduct = selecProd;
                      payload.createItemString = selectedItem?.name;
                      payload.selectedItemsNames = selecProd?.map(
                        (prod) => prod.name
                      );
                    } else {
                      submitDone = false;
                      // addToast({
                      //   alertStatus: Constants.TOAST_TYPE_WARNING,
                      //   alertTitle: Constants.TOAST_HEADING_WARNING,
                      //   alertDescription: Constants.SEGMENT_SELECT_ITEM_EMPTY,
                      // });
                    }
                  } else {
                    submitDone = false;
                    addToast({
                      alertStatus: Constants.TOAST_TYPE_WARNING,
                      alertTitle: Constants.TOAST_HEADING_WARNING,
                      alertDescription: Constants.SEGMENT_ITEM_FILTER_REQUIRED,
                    });
                  }
                }

                const hasVisit = customFilters.some(
                  (item) => item.name === "visit"
                );

                if (hasVisit) {
                  if (
                    null != visitNum &&
                    "" != visitNum &&
                    null != lastVisit &&
                    "" != lastVisit
                  ) {
                    if (selectLastVisit.hasOwnProperty("name")) {
                      payload.selectedVisitDate = {
                        label: selectLastVisit?.display_name,
                        value: selectLastVisit?.name,
                      };
                      if (!isPositiveInteger(visitNum)) {
                        submitDone = false;
                        // addToast({
                        //   alertStatus: Constants.TOAST_TYPE_WARNING,
                        //   alertTitle: Constants.TOAST_HEADING_WARNING,
                        //   alertDescription:
                        //     Constants.SEGMENT_MIN_VISIT_NOT_VALID_NUMBER_ERROR,
                        // });
                      } else if (!isPositiveInteger(lastVisit)) {
                        submitDone = false;
                        // addToast({
                        //   alertStatus: Constants.TOAST_TYPE_WARNING,
                        //   alertTitle: Constants.TOAST_HEADING_WARNING,
                        //   alertDescription:
                        //     Constants.SEGMENT_LAT_VISIT_NOT_VALID_NUMBER,
                        // });
                      } else {
                        payload.totalVisitNumber = visitNum;
                        payload.totalLastVisitNumber = lastVisit;
                        payload.selectedDayForVisit = selectLastVisit?.name;
                      }
                    } else {
                      submitDone = false;
                      // addToast({
                      //   alertStatus: Constants.TOAST_TYPE_WARNING,
                      //   alertTitle: Constants.TOAST_HEADING_WARNING,
                      //   alertDescription:
                      //     Constants.SEGMENT_DURATION_FILTER_REQUIRED,
                      // });
                    }
                  } else {
                    submitDone = false;
                    // addToast({
                    //   alertStatus: Constants.TOAST_TYPE_WARNING,
                    //   alertTitle: Constants.TOAST_HEADING_WARNING,
                    //   alertDescription:
                    //     Constants.SEGMENT_MINIMUM_VIST_AND_LAST_VISIT_REQUIRED,
                    // });
                  }
                }

                const hasSpent = customFilters.some(
                  (item) => item.name === "spent"
                );

                if (hasSpent) {
                  if (
                    selectSpentW != null &&
                    selectSpentW != "" &&
                    selectSpentW.hasOwnProperty("name")
                  ) {
                    payload.selectSpent = {
                      label: selectSpentW?.display_name,
                      value: selectSpentW?.name,
                    };
                    if (selectSpentW?.name == "greater_than") {
                      if (null != spentGreater && "" != spentGreater) {
                        payload.spentGreaterNumber = spentGreater;
                        payload.spentRecord = "spent_greater_than";
                        payload.finalSpentData = selectSpentW?.name;
                        payload.finalSpentAmount = spentGreater;
                      } else {
                        submitDone = false;
                        addToast({
                          alertStatus: Constants.TOAST_TYPE_WARNING,
                          alertTitle: Constants.TOAST_HEADING_WARNING,
                          alertDescription:
                            Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED,
                        });
                      }
                    } else if (selectSpentW?.name == "less_than") {
                      if (null != spentLess && "" != spentLess) {
                        payload.spentLessNumber = selectSpentW;
                        payload.spentRecord = "spent_less_than";
                        payload.finalSpentData = selectSpentW?.name;
                        payload.finalSpentAmount = selectSpentW;
                      } else {
                        submitDone = false;
                        addToast({
                          alertStatus: Constants.TOAST_TYPE_WARNING,
                          alertTitle: Constants.TOAST_HEADING_WARNING,
                          alertDescription:
                            Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED,
                        });
                      }
                    } else if (selectSpentW?.name == "equal_to") {
                      if (null != spentEqual && "" != spentEqual) {
                        payload.spentEqualNumber = spentEqual;
                        payload.spentRecord = "spent_equal_to";
                        payload.finalSpentData = selectSpentW?.name;
                        payload.finalSpentAmount = spentEqual;
                      } else {
                        submitDone = false;
                        addToast({
                          alertStatus: Constants.TOAST_TYPE_WARNING,
                          alertTitle: Constants.TOAST_HEADING_WARNING,
                          alertDescription:
                            Constants.SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED,
                        });
                      }
                    } else if (selectSpentW?.name == "between") {
                      if (
                        null != spentStart &&
                        "" != spentStart &&
                        null != spentEnd &&
                        "" != spentEnd
                      ) {
                        payload.spentStartNumber = spentStart;
                        payload.spentEndNumber = spentEnd;

                        payload.spentBetweenMinRecord = "spent_between_min";
                        payload.spentBetweenMaxRecord = "spent_between_max";
                        payload.spentRecord = "";
                        payload.finalSpentData = selectSpentW?.name;
                        payload.finalSpentAmount = "";
                        payload.finalSpentStartAmount = spentStart;
                        payload.finalSpentEndAmount = spentEnd;
                      } else {
                        submitDone = false;
                        addToast({
                          alertStatus: Constants.TOAST_TYPE_WARNING,
                          alertTitle: Constants.TOAST_HEADING_WARNING,
                          alertDescription:
                            Constants.SEGMENT_MIN_AMOUNT_AND_MAX_AMOUNT_REQUIRED,
                        });
                      }
                    }
                  } else {
                    submitDone = false;
                    addToast({
                      alertStatus: Constants.TOAST_TYPE_WARNING,
                      alertTitle: Constants.TOAST_HEADING_WARNING,
                      alertDescription: Constants.SEGMENT_SPENT_FILTER_REQUIRED,
                    });
                  }
                }

                // if (showRewardCardFlag == 0) {
                //   if (submitDone) {
                //     update(payload);
                //   }
                // } else {
                //   if (submitDone) {
                getSegmentRewardCard(payload);
                //   }
                // }
              } else {
                submitDone = false;
                addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.TOAST_HEADING_WARNING,
                  alertDescription: Constants.SEGMENT_CUSTOM_FILTER_REQUIRED,
                });
              }
            } else {
              submitDone = false;
              addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.TOAST_HEADING_WARNING,
                alertDescription:
                  Constants.SEGMENT_SELECT_FILTER_FIELD_REQUIRED,
              });
            }
          } else {
            submitDone = false;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.TOAST_HEADING_WARNING,
              alertDescription: Constants.SEGMENT_SELECT_FILTER_TYPE_REQUIRED,
            });
          }
        } else {
          //Segment type not manual and not group
        }
      } else {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TOAST_HEADING_WARNING,
          alertDescription: Constants.SEGMENT_SELECT_SEGMENT_TYPE_REQUIRED,
        });
      }
    }
  };

  const getSegmentRewardCard = (payload) => {
    let data = {
      limit: paginationShowUserModel.pageSize,
      page: paginationShowUserModel.page + 1,
      order:
        sortShowUserModel.length > 0
          ? sortShowUserModel[0].field
          : sortShowUserRef.current[0].field,
      order_type:
        sortShowUserModel.length > 0
          ? sortShowUserModel[0].sort
          : sortShowUserRef.current[0].sort,
      filter: searchQuery,
      data: payload,
    };
    setShowTable(false);
    setShowUserTable(true);
    // setLoading(true);
    try {
      showUsers(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            setFilterRewardCards(response.data.data.data);
            setFilterTotalCount(response.data.data.totalCount);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            setFilterRewardCards([]);
            setFilterTotalCount(0);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setFilterRewardCards([]);
          setFilterTotalCount(0);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setFilterRewardCards([]);
      setFilterTotalCount(0);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (filterRewardCards.length > 0) {
      let isPredefined = selectedFilterType?.code == "PRE" ? 1 : 0;
      handleSubmitSmartGroup(
        1,
        name,
        selectedLocation,
        selectedSegmentType,
        viewSelectedRewardCards,
        isPredefined,
        selectedPredefinedFilters,
        selectedCustomFilters,
        selectCatFilter,
        selectedCategory,
        selectedPurchaseDate,
        selectedDateRange,
        purchaseNumber,
        selectedDate,
        onDateView,
        startFromBetweenDate,
        startToBetweenDate,
        selectItemFilter,
        selectedProducts,
        visitNumber,
        lastVisitNumber,
        selectedVisitDate,
        selectSpent,
        spentGreaterNumber,
        spentLessNumber,
        spentEqualNumber,
        spentStartNumber,
        spentEndNumber
      );
    }
  }, [paginationShowUserModel, sortShowUserModel]);

  useEffect(() => {
    if (filterManualRewardCards.length > 0) {
      let showRewardCardFlag = 1;
      handleSubmitManual(showRewardCardFlag, segmentId);
    }
  }, [paginationModel, sortModel]);

  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1">
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.SEGMENT_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Segments", "View a Segment"]}
            breadCrumTitle={"View Segment"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.SEGMENT_LIST, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          p="8"
        >
          <Text
            fontSize="1.125rem"
            w="10.5rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Grid
            mt={{ base: 2, lg: 6 }}
            templateColumns="repeat(4, 1fr)"
            gap={6}
          >
            <GridItem w="100%">
              <List spacing={"2rem"}>
                <ListItem>
                  <Stack spacing={"0.5rem"}>
                    <Text
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                      color={Colors.orderViewLabel}
                    >
                      Name
                    </Text>
                    <Text
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"300"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                      color={Colors.posTextInfo}
                    >
                      {name}
                    </Text>
                  </Stack>
                </ListItem>
                <ListItem>
                  <Stack spacing={"0.5rem"}>
                    <Text
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                      color={Colors.orderViewLabel}
                    >
                      Location
                    </Text>
                    <Text
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"300"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                      color={Colors.posTextInfo}
                    >
                      {selectedLocation.name}
                    </Text>
                  </Stack>
                </ListItem>
              </List>
            </GridItem>
          </Grid>
          {null != selectedLocation && "" != selectedLocation ? (
            <>
              <Box pt={"2rem"}>
                <Divider h={"0.05rem"} />
              </Box>
              <Heading
                mt={8}
                fontWeight="500"
                fontSize="1.13rem"
                color="#010048"
              >
                Segment Type
              </Heading>
              {/* <Text fontWeight="300" color="#010048b3" fontSize="0.94rem">
                Select the method to add people to the segment
              </Text> */}

              <Stack
                mt={8}
                display={"flex"}
                flexDirection={!isMobile ? "row" : "column"}
                spacing={"2.13rem"}
                mb={8}
                {...segmentTypeGroup}
                w={{ base: "100%", md: "44.12rem" }}
              >
                {segmentTypeOptions.map((option, index) => {
                  const radio = getSegmentTypeRadioProps({
                    value: option.type,
                  });
                  return (
                    <RadioCard
                      key={index}
                      {...radio}
                      isChecked={selectedSegmentType === option.type}
                      maxWidth="28.50rem"
                      borderRadius="0.63rem"
                    >
                      <Box
                        p={"1.5rem"}
                        bg={
                          selectedSegmentType === option.type
                            ? "#5881FE"
                            : Colors.posPageDataBackground
                        }
                        borderRadius="0.63rem"
                      >
                        <Flex flexDirection="row" alignItems="center" gap={2}>
                          {selectedSegmentType === option.type ? (
                            <EditIcon style={{ fill: "#F4F4F4" }} />
                          ) : (
                            <Box>{option.icon}</Box>
                          )}
                          <Text
                            fontWeight={"500"}
                            fontSize={"md"}
                            color={
                              selectedSegmentType === option.type
                                ? "#F4F4F4"
                                : Colors.posTextInfo
                            }
                            textAlign="center"
                          >
                            {option.title}
                          </Text>
                        </Flex>
                        <Text
                          mt={"0.63rem"}
                          fontWeight={"300"}
                          fontSize={"md"}
                          color={
                            selectedSegmentType === option.type
                              ? "#F4F4F4"
                              : Colors.posViewTextColor
                          }
                        >
                          {option.body}
                        </Text>
                      </Box>
                    </RadioCard>
                  );
                })}
              </Stack>
            </>
          ) : null}
        </Box>

        {selectedSegmentType == "MANUAL" ? (
          <Box
            mt={{ base: 2, lg: 2 }}
            mb={{ base: 4, lg: 4 }}
            ml={{ base: 4, lg: 14 }}
            mr={{ base: 4, lg: 14 }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
            p="8"
          >
            <Flex
              flexDirection={!isMobile ? "row" : "column"}
              alignItems="center"
              gap={2}
              color={"#010048"}
              fontWeight={500}
              fontSize={"1.13rem"}
            >
              <EditIcon style={{ fill: Colors.primeTaxInfo }} /> Manual Group
              Type
              <Spacer />
            </Flex>
            <Box>
              <Box
                borderRadius="0.63rem"
                mt={8}
                bg={Colors.posPageDataBackground}
                boxShadow={
                  "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                }
                // p="5"
              >
                <Flex direction={"column"} gap={4}>
                  {loading ? (
                    <PosProgress />
                  ) : filterManualTotalCount > 0 ? (
                    <PosDataGridTable
                      columns={columnNames}
                      rows={modifiedShowManualUserData}
                      totalcount={filterManualTotalCount}
                      // noAction={true}
                      columnVisibilityModel={olColumnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel) =>
                        setOlColumnVisibilityModel(newModel)
                      }
                      paginationModel={paginationModel}
                      paginationMode="server"
                      onPaginationModelChange={(data) => {
                        effectPaginationRun.current = true;
                        setPaginationModel(data);
                      }}
                      sortModel={sortModel}
                      onSortModelChange={(newSortModel) => {
                        if (!_.isEqual(newSortModel, sortModel)) {
                          sortRef.current = sortModel;
                          effectPaginationRun.current = true;
                          setSortModel(newSortModel);
                        }
                      }}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      fileName={Constants.SEGMENT_USER_EXPORT_FILENAME}
                    />
                  ) : (
                    <PosNoDataFound
                      title={Constants.SEGMENT_REWARD_CARD_NOT_FOUND}
                    />
                  )}
                </Flex>
              </Box>
            </Box>
          </Box>
        ) : selectedSegmentType == "GROUP" ? (
          <>
            <Box
              mt={{ base: 2, lg: 2 }}
              // mb={{ base: 4, lg: 4 }}
              ml={{ base: 4, lg: 14 }}
              mr={{ base: 4, lg: 14 }}
              borderRadius="0.63rem"
              bg={Colors.posPageDataBackground}
              boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
              p="8"
            >
              <Flex
                flexDirection="row"
                alignItems="center"
                gap={2}
                color={"#010048"}
                fontWeight={500}
                fontSize={"1.13rem"}
              >
                <EditIcon style={{ fill: Colors.primeTaxInfo }} /> Smart Group
                Type
                <Spacer />
              </Flex>
              <Divider mt={4} mb={4} />
              <Flex
                // mt={8}
                p={2.5}
                borderRadius={"0.63rem"}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                <DriveFileRenameOutlineIcon
                  sx={{
                    height: "1.21rem",
                    width: "1.21rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
                <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                  Filter Type
                </Text>
                <Spacer />
                <Text
                  fontWeight={600}
                  fontSize={"md"}
                  color={Colors.posTextInfo}
                >
                  {selectedFilterType.name}
                </Text>
              </Flex>
              {selectedFilterType?.code == "CUST" ? (
                selectedCustomFilters?.map((item, index) => {
                  return (
                    <Flex
                      key={index}
                      mt={1.5}
                      p={2.5}
                      borderRadius={"0.63rem"}
                      border={"1px solid rgba(183, 183, 183, 0.12)"}
                      bg={Colors.posviewbgcolor}
                    >
                      <DriveFileRenameOutlineIcon
                        sx={{
                          height: "1.21rem",
                          width: "1.21rem",
                          background: Colors.posInputGroupBackground,
                          borderRadius: "0.31rem",
                          color: Colors.posNavbarLink,
                        }}
                      />
                      <Text
                        ml={2.5}
                        color={Colors.posViewTextColor}
                        fontWeight={300}
                        w={"20%"}
                      >
                        {item?.display_name} Filter
                      </Text>
                      <Spacer />
                      {item?.name == "category" ? (
                        <Text
                          fontWeight={600}
                          fontSize={"md"}
                          color={Colors.posTextInfo}
                          w={"80%"}
                          textAlign={"right"}
                        >
                          {selectCatFilter?.display_name}s (
                          <span style={{ fontWeight: 300 }}>
                            {commaSeparatedDisplayCatNames}
                          </span>
                          )
                        </Text>
                      ) : item?.name == "date" ? (
                        selectedDateRange?.name == "date_range" ? (
                          selectedPurchaseDate.name == "purchase_date_was" ? (
                            <Text
                              fontWeight={600}
                              fontSize={"md"}
                              color={Colors.posTextInfo}
                              w={"80%"}
                              textAlign={"right"}
                            >
                              {selectedDateRange?.display_name}{" "}
                              <span style={{ fontWeight: 300 }}>
                                {purchaseNumber}{" "}
                              </span>
                              {selectedDate?.display_name}
                            </Text>
                          ) : (
                            <Text
                              fontWeight={600}
                              fontSize={"md"}
                              color={Colors.posTextInfo}
                              w={"80%"}
                              textAlign={"right"}
                            >
                              Is not Before{" "}
                              <span style={{ fontWeight: 300 }}>
                                {purchaseNumber}{" "}
                              </span>
                              {selectedDate?.display_name}
                            </Text>
                          )
                        ) : selectedDateRange?.name == "on_date" ? (
                          selectedPurchaseDate.name == "purchase_date_was" ? (
                            <Text
                              fontWeight={600}
                              fontSize={"md"}
                              color={Colors.posTextInfo}
                              w={"80%"}
                              textAlign={"right"}
                            >
                              Is On{" "}
                              <span style={{ fontWeight: 300 }}>
                                {moment(onDateView).format("ddd, MMM D YYYY")}
                              </span>
                            </Text>
                          ) : (
                            <Text
                              fontWeight={600}
                              fontSize={"md"}
                              color={Colors.posTextInfo}
                              w={"80%"}
                              textAlign={"right"}
                            >
                              Is not On{" "}
                              <span style={{ fontWeight: 300 }}>
                                {moment(onDateView).format("ddd, MMM D YYYY")}
                              </span>
                            </Text>
                          )
                        ) : selectedDateRange?.name == "between_date" ? (
                          selectedPurchaseDate.name == "purchase_date_was" ? (
                            <Text
                              fontWeight={600}
                              fontSize={"md"}
                              color={Colors.posTextInfo}
                              w={"80%"}
                              textAlign={"right"}
                            >
                              {selectedDateRange?.display_name}{" "}
                              <span style={{ fontWeight: 300 }}>
                                {moment(startFromBetweenDate).format(
                                  "ddd, MMM D YYYY, H:mm"
                                )}{" "}
                              </span>
                              and{" "}
                              <span style={{ fontWeight: 300 }}>
                                {moment(startToBetweenDate).format(
                                  "ddd, MMM D YYYY, H:mm"
                                )}
                              </span>
                            </Text>
                          ) : (
                            <Text
                              fontWeight={600}
                              fontSize={"md"}
                              color={Colors.posTextInfo}
                              w={"80%"}
                              textAlign={"right"}
                            >
                              Is not Between{" "}
                              <span style={{ fontWeight: 300 }}>
                                {moment(startFromBetweenDate).format(
                                  "ddd, MMM D YYYY, H:mm"
                                )}{" "}
                              </span>
                              and{" "}
                              <span style={{ fontWeight: 300 }}>
                                {moment(startToBetweenDate).format(
                                  "ddd, MMM D YYYY, H:mm"
                                )}
                              </span>
                            </Text>
                          )
                        ) : null
                      ) : item?.name == "item" ? (
                        <Text
                          fontWeight={600}
                          fontSize={"md"}
                          color={Colors.posTextInfo}
                          w={"80%"}
                          textAlign={"right"}
                        >
                          {selectItemFilter?.display_name}s (
                          <span style={{ fontWeight: 300 }}>
                            {commaSeparatedDisplayProdNames}
                          </span>
                          )
                        </Text>
                      ) : item?.name == "visit" ? (
                        <Text
                          fontWeight={600}
                          fontSize={"md"}
                          color={Colors.posTextInfo}
                          w={"80%"}
                          textAlign={"right"}
                        >
                          Minimum Visit:{" "}
                          <span style={{ fontWeight: 300 }}>{visitNumber}</span>
                          , Last Visit:{" "}
                          <span style={{ fontWeight: 300 }}>
                            {lastVisitNumber}
                          </span>{" "}
                          {selectedVisitDate?.display_name} Ago
                        </Text>
                      ) : item?.name == "spent" ? (
                        selectSpent?.name == "greater_than" ? (
                          <Text
                            fontWeight={600}
                            fontSize={"md"}
                            color={Colors.posTextInfo}
                            w={"80%"}
                            textAlign={"right"}
                          >
                            {selectSpent?.display_name} (
                            <span style={{ fontWeight: 300 }}>
                              ${spentGreaterNumber}
                            </span>
                            )
                          </Text>
                        ) : selectSpent?.name == "less_than" ? (
                          <Text
                            fontWeight={600}
                            fontSize={"md"}
                            color={Colors.posTextInfo}
                            w={"80%"}
                            textAlign={"right"}
                          >
                            {selectSpent?.display_name} (
                            <span style={{ fontWeight: 300 }}>
                              ${spentLessNumber}
                            </span>
                            )
                          </Text>
                        ) : selectSpent?.name == "equal_to" ? (
                          <Text
                            fontWeight={600}
                            fontSize={"md"}
                            color={Colors.posTextInfo}
                            w={"80%"}
                            textAlign={"right"}
                          >
                            {selectSpent?.display_name} (
                            <span style={{ fontWeight: 300 }}>
                              ${spentEqualNumber}
                            </span>
                            )
                          </Text>
                        ) : selectSpent?.name == "between" ? (
                          <Text
                            fontWeight={600}
                            fontSize={"md"}
                            color={Colors.posTextInfo}
                            w={"80%"}
                            textAlign={"right"}
                          >
                            {selectSpent?.display_name} (
                            <span style={{ fontWeight: 300 }}>
                              ${spentStartNumber}
                            </span>
                            ) and (
                            <span style={{ fontWeight: 300 }}>
                              ${spentEndNumber}
                            </span>
                            )
                          </Text>
                        ) : null
                      ) : null}
                    </Flex>
                  );
                })
              ) : selectedFilterType?.code == "PRE" ? (
                <Flex
                  mt={1.5}
                  p={2.5}
                  borderRadius={"0.63rem"}
                  border={"1px solid rgba(183, 183, 183, 0.12)"}
                  bg={Colors.posviewbgcolor}
                >
                  <DriveFileRenameOutlineIcon
                    sx={{
                      height: "1.21rem",
                      width: "1.21rem",
                      background: Colors.posInputGroupBackground,
                      borderRadius: "0.31rem",
                      color: Colors.posNavbarLink,
                    }}
                  />
                  <Text
                    ml={2.5}
                    color={Colors.posViewTextColor}
                    fontWeight={300}
                    w={"20%"}
                  >
                    Predefined Filter
                  </Text>
                  <Spacer />
                  <Text
                    fontWeight={600}
                    fontSize={"md"}
                    color={Colors.posTextInfo}
                    w={"80%"}
                    textAlign={"right"}
                  >
                    {selectedPredefinedFilters.display_name}
                  </Text>
                </Flex>
              ) : null}
            </Box>
            <Box
              mt={{ base: 2, lg: 2 }}
              mb={{ base: 4, lg: 4 }}
              ml={{ base: 4, lg: 14 }}
              mr={{ base: 4, lg: 14 }}
              borderRadius="0.63rem"
              bg={Colors.posPageDataBackground}
              boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
              p="8"
            >
              {loading ? (
                <PosProgress />
              ) : // (
              totalFilterCount > 0 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedShowUserData}
                    totalcount={totalFilterCount}
                    // noAction={true}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationShowUserModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={setPaginationShowUserModel}
                    sortModel={sortShowUserModel}
                    onSortModelChange={(newSortModel) => {
                      sortShowUserRef.current = sortModel;
                      setSortShowUserModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.SEGMENT_USER_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound
                  title={Constants.SEGMENT_REWARD_CARD_NOT_FOUND}
                />
              )}
            </Box>
          </>
        ) : null}
      </Box>
    </Flex>
  );
};

export default WithRouter(SegmentView);
