import { Button, ButtonGroup } from "@chakra-ui/react";
import React from "react";

const PosFormButton = (props) => {
  if(props.ghostButton) {
    return (
      <ButtonGroup display="flex">
        <Button
          variant="posghost"
          onClick={props.onClick}
          isDisabled={props.isDisabled}
        >
          {props.buttonText}
        </Button>
      </ButtonGroup>
    );
  } else if (props.CancelButton) {
    return (
      <ButtonGroup display="flex">
        <Button
          variant="outline"
          onClick={props.onClick}
          width={props.buttonText == "Cancel" ? "7.37rem" : undefined}
          isDisabled={props.isDisabled}
        >
          {props.buttonText}
        </Button>
      </ButtonGroup>
    );
  } else if (props.SubmitButton) {
    return (
      <ButtonGroup
        display="flex"
        justifyContent={{ base: "center", md: "flex-end" }}
        mx={{ base: 0, md: 4 }}
      >
        <Button
          variant="solid"
          width={props.buttonsubmit == "Submit" ? "7.37rem" : undefined}
          onClick={props.onClick}
          isDisabled={props.isDisabled}
        >
          {props.buttonsubmit}
        </Button>
      </ButtonGroup>
    );
  } else {
    return (
      <Button
        variant="solid"
        width={props.width}
        onClick={props.onClick}
        isDisabled={props.isDisabled}
      >
        {props.buttonsubmit}
      </Button>
    );
  }
};

export default PosFormButton;
