import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Stack,
  StackDivider,
  Text,
  useMediaQuery
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosFormButton,
  PosTostMessage,
  PosLable,
  PosInput
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  createAccountSettings,
  getAllLocationsAdditionalSettingData,
} from "./LocationService";
import ImageUpload from '../../../components/slideShowImagesUpload/ImageUpload'; // Adjust the path as necessary
import { useFormik } from "formik";

const LocationSlideShowSettings = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const { id } = useParams();
  const [viewFlag, setViewFlag] = useState(false);
  const [timeInterval, setTimeInterval] = useState("");

  const [uploadedImages, setUploadedImages] = useState([]);

  const handleImagesChange = (images) => {    
    setUploadedImages(images);
  };  
  const [isMobile] = useMediaQuery("(max-width: 992px)");

  const formik = useFormik({
    initialValues: {
      time_interval:
        undefined !=timeInterval &&
        null != timeInterval
          ? timeInterval
          : "",
    },
    validationSchema: Yup.object({
      time_interval: Yup.number()
        // .required(Constants.SLIDE_SHOW_TIME_REQUIRED)
        .typeError(Constants.SLIDE_SHOW_TIME_VALID)
        .integer(Constants.SLIDE_SHOW_TIME_VALID)
        // .min(5, Constants.SLIDE_SHOW_TIME_MIN_2)
        // .max(60, Constants.SLIDE_SHOW_TIME_MAX_60),
    }),
    enableReinitialize:true,
    onSubmit: (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      let path = Constants.ESTABLISHMENT_LOCATION_PATH + id;
      submit(values, {
        method: Constants.POST,
        path: path,
      });
    },
  });

  useEffect(() => {    
    if (props.isSlideShowOpen) {
      myContext.handleLoading(true);
      fetchData(id);
      setButtonDisable(true);
      const timer = setTimeout(() => {
        setButtonDisable(false); // Enable the button after 2 seconds
      }, 1500);
    }
    return () => {};
  }, [props.isSlideShowOpen]);

  const fetchData = async (id) => {
    try {
      getAllLocationsAdditionalSettingData(id)
        .then((response) => {          
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            // setUploadedImages(response.data?.data?.locSlideShowImages);
            if(response.data?.data?.locSlideShowImages.length > 0 ){
              setUploadedImages(response.data?.data?.locSlideShowImages.map(item => ({
                file: new File([item.image_base64], item.image, { type: 'image/png' }), // Create a File object
                preview: item.image_url,
                base64: item.image_base64, // Assuming the base64 string is in the format of data URL
                fileName: item.image,
                fileType: 'image/png',
                fileSize: item.image_base64.length // This is a rough estimation, usually file size is in bytes
              })));
              setTimeInterval(response.data?.data?.locSlideShowImages[0].time_interval)
            }
            myContext.handleLoading(false);
            setViewFlag(true);
          } else {
            setUploadedImages([]);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setUploadedImages([]);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (err) {
      setUploadedImages([]);
      myContext.handleLoading(false);
    }
  };

  const handleClose = () => {
    formik.resetForm();
    setViewFlag(false);
    if (props.onSlideShowClose) {
      props.onSlideShowClose();
    }
    setUploadedImages([]);
  };

  const slideShowCreate = () => {
    setButtonDisable(true);
    try {
      myContext.handleLoading(true);
      let payload = {
        location_id: id,
        slide_show: true,
        images: uploadedImages.length > 0 ? uploadedImages : [],
        time_interval:formik.values.time_interval
      };
      createAccountSettings(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {            
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    } catch (err) {
      setUploadedImages([]);
      setButtonDisable(false);
      myContext.handleLoading(false);
    }
  }

  return (
    <PosDrawer
      visible={viewFlag}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box padding={2}>
        {/* <Card
          sx={{
            boxShadow: "none",
          }}
        > */}
        {/* <CardHeader alignItems={{ base: "flex-start" }} mt={10}> */}
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
        >
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={4}>
              <PosBreadCrumb
                handleClick={(i) => {
                  props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
                }}
                breadCrumNames={["Locations", "Customer Display Images"]}
                breadCrumTitle={"Customer Display Images"}
              />
            </Box>
            {/* <Spacer /> */}
            <ButtonGroup gap="2" alignSelf={"flex-end"}>
              {/* <PosFormButton
                onClick={() => {
                  // props.navigate(Constants.PRODUCT_LIST_PATH);
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              /> */}
              <PosFormButton
                isDisabled={
                  buttonDisable 
                  || formik.errors.time_interval
                  // || uploadedImages.length <=0
                    ?
                      true
                    : false
                }
                buttonsubmit={"Save Changes"}
                SubmitButton={true}
                onClick={slideShowCreate}
              />
            </ButtonGroup>
          </Flex>
        </Box>
        {/* </CardHeader> */}
        <Divider borderColor="#E6E6E6" mt={5} mb={5} />
        <Stack spacing="2">
          <Box>
            <Flex
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Box
                ml={{ base: "0", md: "1.9rem" }}
                w={{ base: "100%", md: "100%" }}
              >
                <Box mb={2}>
                <Text
                  style={{
                    fontSize: "xs",
                    color: "#010048",
                    fontWeight: 500,
                  }}
                >
                  Add Images
                </Text>
                </Box>
                <ImageUpload 
                  onImagesChange={handleImagesChange} 
                  initialImages={uploadedImages} // Pass your previously uploaded images here
                />
              </Box>
            </Flex>
          </Box>
          {uploadedImages.length > 0 &&
          // <Box
          // ml={{ base: "0", md: "1.9rem" }}
          // w={isMobile?"80%":"30%"}>
          //   <PosLable
          //     name={"Slide Show Time Interval (Seconds)"}
          //     label={true}
          //     fontWeight={350}
          //   />
          //   <PosInput
          //     posNumberInput={true}
          //     id={"time_interval"}
          //     inputValue={formik.values.time_interval || ""}
          //     handleBlur={formik.handleBlur}
          //     handleInputChange={formik.handleChange}
          //   ></PosInput>
          //   <Text color={Colors.errorColor}>
          //     { formik.errors.time_interval ? (
          //       <span>{formik.errors.time_interval}</span>
          //     ) : null}
          //   </Text>
          // </Box>
          <Flex ml={{ base: "0", md: "1.9rem" }} flexDirection={isMobile?"column":"row"} alignItems={{base:"flex-start",md:"flex-start"}}>
          <Flex>
            <PosLable
              name={"Slide Show Time Interval (In Seconds)"}
              label={true}
              fontWeight={350}
            />
            </Flex>
            <Box mt={isMobile?"1rem":"1.5rem"} ml={2}>
            <PosInput
              posNumberInput={true}
              id={"time_interval"}
              inputValue={formik.values.time_interval || ""}
              handleBlur={formik.handleBlur}
              handleInputChange={formik.handleChange}
              placeholder={"Enter Time Interval"}
            />
            </Box>
            <Text color={Colors.errorColor}>
              { formik.errors.time_interval ? (
                <span>{formik.errors.time_interval}</span>
              ) : null}
            </Text>
            </Flex>
          }
        </Stack>
      </Box>
    </PosDrawer>
  );
};
export default WithRouter(LocationSlideShowSettings);
