import {
  Box,
  ButtonGroup,
  ChakraProvider,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { commonDateFormate } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme, theme } from "../../../theme/index";
import {
  exportRewardCards,
  getAllDeactiveRewardCards,
  getAllRewardCards,
  getRewardDataForList,
} from "./RewardCardService";

const RewardCardsList = (props) => {
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const submit = useSubmit();
  const stateData = useLocation();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [rewardCardData, setRewardCardData] = useState([]);
  const [rewardData, setRewardData] = useState([]);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [rewardCardDeactiveData, setRewardCardDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [showFilter, setShowFilter] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.showFilter &&
      "" != stateData?.state?.showFilter
      ? stateData?.state?.showFilter
      : false
  );
  const rewardFilterOptions = Constants.REWARD_FILTER_OPTIONS;
  const [rewardFilter, setRewardFilter] = useState(undefined !== stateData?.state &&
    null !== stateData?.state &&
    "" !== stateData?.state &&
    null != stateData?.state?.rewardFilter &&
    "" != stateData?.state?.rewardFilter
    ? stateData?.state?.rewardFilter
    : "");
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const [rewardColumnVisibilityModel, setRewardColumnVisibilityModel] =
    React.useState({
      // total_cash_discount: false,
    });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page - 1
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const sortRef = useRef(sortModel);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const sortRefDeactive = useRef(sortModelDeactive);
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
    {
      field: "first_name",
      headerName: "First name",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "last_name",
      headerName: "Last name",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "email",
      headerName: "Email Address",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "created_at",
      headerName: "Created On(CST)",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "order_count",
      headerName: "Visited (times)",
      sortable: true,
      ...actionColumnSM,
    },
    {
      field: "view",
      headerName: "Reward Details",
      sortable: false,
      width: 150,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          myContext.handleLoading(true);
          getRewardData(rowData);
        };
        return (
          <PosIconButton
            name={Constants.VIEW_BUTTON}
            onClick={handleView}
            viewIcon={true}
            width={"4.625rem"}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
            showFilter: showFilter,
            rewardFilter: rewardFilter,
          };
          myContext.handleLoading(true);
          props.navigate(`${Constants.REWARD_CARDS_EDIT + item.id}`, {
            state: data,
          });
        };
        const handleViewPage = (item) => {
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
            showFilter: showFilter,
            rewardFilter: rewardFilter,
          };
          myContext.handleLoading(true);
          props.navigate(`${Constants.REWARD_CARDS_VIEW + item.id}`, {
            state: data,
          });
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.REWARD_CARDS_LIST,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            // {
            //   name: "View",
            //   icon: (
            //     <RemoveRedEyeOutlinedIcon
            //       style={{ color: Colors.posViewTextColor }}
            //     />
            //   ),
            //   onClick: (item) => handleViewPage(item),
            // },
            {
              name: "Deactive",
              disabledButton: buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];
  const getRewardData = (rowData) => {
    try {
      getRewardDataForList(rowData.id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            if (response.data.data.allRewardsData.length > 0) {
              setRewardData(response.data.data.allRewardsData);
              myContext.handleLoading(false);
              onOpen();
            } else {
              myContext.handleLoading(false);
              addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.REWARD_CARD_MODULE,
                alertDescription: "There are no Reward Details.",
              });
            }
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const RewardColumnNames = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "current_points",
      headerName: "Current Points",
      sortable: false,
      ...actionColumnMD,
    },
    {
      field: "required_points",
      headerName: "Required Points",
      sortable: false,
      ...actionColumnMD,
    },
    {
      field: "eligible",
      headerName: "Eligibility",
      sortable: false,
      ...actionColumnMD,
    },
    {
      field: "created_at",
      headerName: "Created At(CST)",
      sortable: false,
      ...actionColumnFlexDouble,
    },
  ];
  function reportDateFormat(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedDate = `${month} ${day}, ${year}, ${hours}:${minutes} ${ampm}`;
    return formattedDate;
  }
  const modifiedRewardData = rewardData.map((reward, i) => ({
    id: i + 1,
    name: reward.name,
    current_points: reward.current_points,
    required_points: reward.required_points,
    eligible: reward.eligible,
    created_at: reward.created_at,
  }));
  const deactiveColumnNames = [
    { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
    {
      field: "first_name",
      headerName: "First name",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "last_name",
      headerName: "Last name",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "email",
      headerName: "Email Address",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "created_at",
      headerName: "Created On(CST)",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "order_count",
      headerName: "Visited (times)",
      sortable: true,
      ...actionColumnSM,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;

        const handleReactive = () => {
          setButtonDisable(true);
          if (
            deactiveRewardCardData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.REWARD_CARDS_LIST,
          });
        };
        return (
          <Text
            // onClick={handleReactive}
            // cursor="pointer"
            color={Colors.posNavbarLink}
            // fontSize="1rem"
            // letterSpacing="-1.5%"
            // textDecoration="underline"
            // fontWeight={600}
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            // color={buttonDisable ? "gray" : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }}
          >
            Activate
          </Text>
        );
      },
    },
  ];
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);

  const exportRewardCard = async () => {
    myContext.handleLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      exportRewardCards(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const url = Constants.REACT_APP_API_URL + response.data.data;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(Constants.DOWNLOAD, Constants.RC_EXCEL_FILENAME);
            document.body.appendChild(link);
            window.stop();
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.REPORT_MODULE,
              alertDescription: actionResponse.data?.message,
            });
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      manualFilter: rewardFilter,
    };
    try {
      getAllRewardCards(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setRewardCardData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setRewardCardData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0 &&
          (null == rewardFilter || "" == rewardFilter)) {
            searchInputHideRef.current = true;
          }
          setRewardCardData([]);
          setTotalCount(0);
          setLoading(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      setRewardCardData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchDeActiveRewardCardData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllDeactiveRewardCards(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setRewardCardDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setRewardCardDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setRewardCardDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          myContext.handleLoading(false);

          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      setRewardCardDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveRewardCardData();
      }
      effectRun.current = false;
    }
  }, [
    paginationModel,
    sortModel,
    loading,
    searchQuery,
    paginationModelDeactive,
    sortModelDeactive,
    showFilter,
    rewardFilter,
  ]);
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REWARD_CARD_MODULE,
            alertDescription: Constants.REWARD_CARD_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          fetchDeActiveRewardCardData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REWARD_CARD_MODULE,
            alertDescription: Constants.REWARD_CARD_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
    }
  }, [actionResponse]);

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
    } else {
      searchInputHideRef.current = false;
    }
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setPaginationModelDeactive({
      page: 0,
      pageSize: 25,
    });
    setSortModelDeactive([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setSearchQuery("");
    effectRun.current = true;
    setLoading(true);
  };
  const handleSearchList = () => {
    paginationModel.page = 0;
    props.navigate(Constants.REWARD_CARDS_LIST);
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveRewardCardData();
    }
  };
  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.REWARD_CARDS_LIST);
  };
  const modifiedData = rewardCardData.map((rewardcard) => ({
    ...rewardcard,
    email:
      rewardcard.email == null || rewardcard.email == ""
        ? "N.A."
        : rewardcard.email,
    first_name:
      rewardcard.first_name == null || rewardcard.first_name == ""
        ? "N.A."
        : rewardcard.first_name,
    last_name:
      rewardcard.last_name == null || rewardcard.last_name == ""
        ? "N.A."
        : rewardcard.last_name,
    created_at: commonDateFormate(rewardcard.created_at),
    order_count: rewardcard.order_count,
  }));
  const deactiveRewardCardData = rewardCardDeactiveData.map((rewardcard) => ({
    ...rewardcard,
    email:
      rewardcard.email == null || rewardcard.email == ""
        ? "N.A."
        : rewardcard.email,
    first_name:
      rewardcard.first_name == null || rewardcard.first_name == ""
        ? "N.A."
        : rewardcard.first_name,
    last_name:
      rewardcard.last_name == null || rewardcard.last_name == ""
        ? "N.A."
        : rewardcard.last_name,
    created_at: commonDateFormate(rewardcard.created_at),
    order_count: rewardcard.order_count,
  }));
  const modalSizes = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "md",
    xl: "lg",
  });
  const showDropDown = () => {
    setShowFilter((prevState) => {
      const nextState = !prevState;
      if (prevState && rewardFilter != "") {
        paginationModel.page = 0;
        setRewardFilter("");
        effectRun.current = true;
      }
      return nextState; // Return the toggled state
    });
  };
  const clearFilterData = () => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (searchQuery.length > 0) {
      setSearchQuery("");
      fetchData();
    }
    setRewardFilter("");
    props.navigate(Constants.REWARD_CARDS_LIST);
  };

  const onChangeSearch = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    paginationModel.page = 0;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };

  const apply = () => {
    effectRun.current = true;
    setLoading(true);
    // fetchData();
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Reward Customers"}
          discription={
            "OctoPos has a ground up approach to loyalty, convert your handwork into repeated customers"
          }
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true);
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page + 1,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                filter: curruntTab == 1 ? "" : searchQuery,
                showFilter: showFilter,
                rewardFilter: rewardFilter,
              };
              props.navigate(Constants.REWARD_CARDS_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      {/* <Modal size={"5xl"} onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reward Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mt={2} mb={4} maxHeight="31.25rem" overflowY="auto">
              <PosDataGridTable
                columns={RewardColumnNames}
                rows={modifiedRewardData}
                hideFooter={true}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal> */}
      <ThemeProvider theme={Mtheme}>
        <Dialog
          open={isOpen}
          // onClose={onClose}
          fullWidth={true}
          maxWidth={modalSizes}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              marginTop: "2.37rem",
              fontSize: "1.5rem",
              fontWeight: 600,
              color: Colors.posTextInfo,
            }}
          >
            {"Reward Details"}
            <ChakraProvider theme={theme}></ChakraProvider>
          </DialogTitle>
          <DialogContent>
            <Box mt={2} maxHeight="31.25rem" overflowY="auto">
              <PosDataGridTable
                columns={RewardColumnNames}
                rows={modifiedRewardData}
                sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                columnVisibilityModel={rewardColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setRewardColumnVisibilityModel(newModel)
                }
                hideFooter={true}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.RC_REWARD_EXPORT_FILENAME}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <ChakraProvider theme={theme}>
              <Flex minWidth="max-content" gap="2" mr={4} mb={4}>
                <Spacer />
                <ButtonGroup>
                  <PosFormButton
                    onClick={onClose}
                    buttonText={"Cancel"}
                    CancelButton={true}
                  />
                </ButtonGroup>
              </Flex>
            </ChakraProvider>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <>
              <Flex
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "stretch", md: "center" }}
                gap={{ base: 2, md: 0 }}
                mt={6}
                // mb={3}
                ml={4}
                mr={4}
              >
                <Flex flexDirection={isMobile ? "column" : "row"}>
                <PosSearch
                  value={searchQuery}
                  // onChange={(e) => setSearchQuery(e.target.value)}
                  // onChange={(e) => {
                  //   let value =
                  //     searchQuery.length > 0
                  //       ? e.target.value
                  //       : e.target.value.trim();
                  //   setSearchQuery(value);
                  // }}
                  onChange={onChangeSearch}
                  onSearch={handleSearchList}
                  handleClear={handleClear}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  w="2.5rem"
                  ml={{ base: 0, md: 4 }}
                  mt={{ base: 4, md: 0 }}
                  mb={{ base: 4 }}
                >
                  <IconButton
                    onClick={() => {
                      showDropDown();
                    }}
                    // isRound={false}
                    style={{
                      border: "1px solid #ccc",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                      variant: "posoutline",
                      color: Colors.posTextInfo,
                      borderColor: Colors.dividerColor,
                      borderRadius: "5px",
                      padding: 5,
                    }}
                  >
                    <FilterListIcon style={{ color: Colors.posTextInfo }} />
                  </IconButton>
                </Box>

              {showFilter && (
                <Flex ml={{ base: 0, md: 4 }} gap={4}>
                  <Box>
                    <PosDropDown
                      options={rewardFilterOptions}
                      resetFilterOnHide={true}
                      value={rewardFilter}
                      onChange={(e) => {
                        setRewardFilter(e.value);
                        effectRun.current = true;
                      }}
                      // onBlur={(e) => {
                      //   setRewardFilter(e.value);
                      // }}
                      // multiSelect={true}
                      optionLabel="name"
                      placeholder="Select Type"
                      height={"2.5rem"}
                      className="w-full md:w-20rem"
                      // maxSelectedLabels={2}
                    />
                  </Box>
                  <Box>
                    <PosFormButton
                      isDisabled={
                        searchQuery.length > 0 || rewardFilter !== ""
                          ? false
                          : true
                      }
                      onClick={() => {
                        clearFilterData();
                      }}
                      buttonText={"Clear"}
                      CancelButton={true}
                    />
                  </Box>
                  {/* </Box> */}
                </Flex>
              )}
                </Flex>
              </Flex>
                {curruntTab === 0 && (
                  <Flex mr={4} direction={"flex-end"}>
                  <Spacer />
                  <PosIconButton
                    exportIcon={true}
                    name={"Export"}
                    onClick={exportRewardCard}
                  />
                  </Flex>
                )}
            </>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <PosDataGridTable
                  columns={columnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    effectRun.current = true;
                    setSortModel(newSortModel);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.ACTIVE_RC_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound title={Constants.REWARD_CARDS_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                <PosDataGridTable
                  columns={deactiveColumnNames}
                  rows={deactiveRewardCardData}
                  totalcount={deactiveTotalCount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  onPaginationModelChange={(newPaginationModelDeactive) => {
                    effectRun.current = true;
                    setPaginationModelDeactive(newPaginationModelDeactive);
                  }}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.DEACTIVE_RC_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound
                  title={Constants.DEACTIVE_REWARD_CARDS_NOT_FOUND}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(RewardCardsList);
