import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import Colors from "../../assets/colors/Colors";

const baseStyle = defineStyle({
  fontSize: "0.75rem",
  fontWeight: 500,
  lineHeight: "1rem",
  height: "1.25rem",
  borderRadius: "0.438rem",
  padding: "0.125rem 0.375rem 0.125rem 0.375rem",
});

const invoDefault = defineStyle({
  backgroundColor: Colors.buttonOutlineHover,
  color: Colors.authHeader,
});

const invoSuccess = defineStyle({
  backgroundColor: Colors.badgeSuccess,
  color: Colors.badgeSuccessFont,
});

const invoError = defineStyle({
  backgroundColor: Colors.badgeError,
  color: Colors.badgeErrorFont,
});

const invoNew = defineStyle({
  backgroundColor: Colors.badgeNew,
  color: Colors.badgeNewFont,
});

export const badgeTheme = defineStyleConfig({
  baseStyle,
  variants: { invoDefault, invoSuccess, invoError, invoNew },
});
