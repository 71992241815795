import { Box } from "@chakra-ui/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import Colors from "../../assets/colors/Colors";
import { Mtheme } from "../../theme";

const PosSpliteButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <Box>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button
          size="small"
          startIcon={props.spliteOptions.buttonIcon}
          onClick={(event) =>
            props.spliteOptions.handleClick(props.item, props.id)
          }
        >
          {props.spliteOptions.buttonName}
        </Button>
        <Button size="small" aria-describedby={id} onClick={handleClick}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper id={id} open={open} anchorEl={anchorEl} placement={"bottom-end"}>
        <ThemeProvider theme={Mtheme}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu" autoFocusItem>
                {props.spliteOptions.subButtons.map((option, index) => (
                  <MenuItem
                    style={{
                      color: Colors.posTextInfo,
                      fontWeight: 500,
                      fontSize: "0.94rem",
                    }}
                    disabled={option.disabledButton} 
                    key={index}
                    onClick={(event) => {
                      option.onClick(props.item, props.id);
                    }}
                  >
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText>{option.name}</ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </ThemeProvider>
      </Popper>
    </Box>
  );
};

export default PosSpliteButton;
