import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getProductsForSelectedLocation(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.COUPON_PRODUCT_CREATE_VIEW_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function couponCreate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = {
      title: Object.fromEntries(formData).name,
      coupon_type: Object.fromEntries(formData).coupon_type,
      discount_type: Object.fromEntries(formData).discount_type,
      discount_value: Object.fromEntries(formData).discount_value,
      min_amount: Object.fromEntries(formData).min_amount,
      is_order_dropdown: Number(Object.fromEntries(formData).is_order_dropdown),
      apply_on_all_prods: Number(Object.fromEntries(formData).apply_on_all_prods),
      max_qty_per_coupon: Object.fromEntries(formData).max_qty_per_coupon,
      audiance_type: Object.fromEntries(formData).audiance_type,
      is_print_on_ord_rcpt: Number(Object.fromEntries(formData).is_print_on_ord_rcpt),
      location: Number(Object.fromEntries(formData).location),
      location_id: Object.fromEntries(formData).location_id,
      start_date: Object.fromEntries(formData).start_date,
      end_date: Object.fromEntries(formData).end_date,
    };
    payload.products = JSON.parse(Object.fromEntries(formData).products);
    payload.allProductBarcodes = JSON.parse(
      Object.fromEntries(formData).allProductBarcodes
    );
    if (Object.fromEntries(formData).hasOwnProperty("couponImage")) {
      payload.couponImage = JSON.parse(
        Object.fromEntries(formData).couponImage
      );
    }
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.COUPON_CREATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getAllLocationOsSelectedProduct(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          "coupon-product-all",
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TAX_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllCoupons(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.COUPON_VIEW_ALL_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDeactiveCoupons(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.COUPON_DEACTIVE_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function couponDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.COUPON_DEACTIVE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.COUPON_DEACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            // Constants.COUPON_REACTIVE_API_ROUTE +
            // "/" +
            // id,
            `${Constants.COUPON_REACTIVE_API_ROUTE + "/" + id}`,
          // {},
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.COUPON_REACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getSingleCoupon({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.COUPON_VIEW_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function downloadPDF(id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.COUPON_PDF_FOR_BARCODE_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadCouponBarcodeImage(id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.COUPON_DOWN_IMAGE_BARCODE_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSingleCouponForEdit({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.COUPON_EDIT_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function couponUpdate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      title: Object.fromEntries(formData).name,
      time_used: Object.fromEntries(formData).time_used,
      coupon_type: Object.fromEntries(formData).coupon_type,
      discount_type: Object.fromEntries(formData).discount_type,
      discount_value: Object.fromEntries(formData).discount_value,
      min_amount: Object.fromEntries(formData).min_amount,
      is_order_dropdown: Object.fromEntries(formData).is_order_dropdown,
      apply_on_all_prods: Object.fromEntries(formData).apply_on_all_prods ,
      max_qty_per_coupon: Object.fromEntries(formData).max_qty_per_coupon == "null" ? null :Object.fromEntries(formData).max_qty_per_coupon,
      audiance_type: Object.fromEntries(formData).audiance_type,
      is_print_on_ord_rcpt: Object.fromEntries(formData).is_print_on_ord_rcpt,
      location: Object.fromEntries(formData).location,
      location_id: Object.fromEntries(formData).location_id,
      start_date: Object.fromEntries(formData).start_date,
      end_date: Object.fromEntries(formData).end_date,
      // image_base64: Object.fromEntries(formData).image_base64,
      // image: Object.fromEntries(formData).image,s
    };
    payload.products = JSON.parse(Object.fromEntries(formData).products);
    payload.allProductBarcodes = JSON.parse(
      Object.fromEntries(formData).allProductBarcodes
    );
    // if (Object.fromEntries(formData).hasOwnProperty("couponImage")) {
    //   payload.couponImage = JSON.parse(
    //     Object.fromEntries(formData).couponImage
    //   );
    // }
    if (Object.fromEntries(formData).hasOwnProperty("couponImage")) {
      payload.couponImage = JSON.parse(
        Object.fromEntries(formData).couponImage
      );
    }
    if ( Object.fromEntries(formData).image_base64 == null || Object.fromEntries(formData).image_base64 == "" ) {
     payload.image_base64= null
     payload.image= null
      // payload.image_base64 = JSON.parse(
      //   Object.fromEntries(formData).image_base64 
      // );
    } else {
      payload.image_base64= JSON.parse(Object.fromEntries(formData).image_base64)
      payload.image= Object.fromEntries(formData).image
    }
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.COUPON_UPDATE_API_ROUTE+`/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getAllLocationsForCreate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GET_ALL_LOCATION_AND_CATEGORY_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.COUPON_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}