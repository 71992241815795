import { Box, Flex, Image, Spacer, Stack, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import voriLogo from "../../../assets/images/Black_LOGO_with_Name copy.png";
import octoposLogo from "../../../assets/images/cropped_logo.png";

import {
  AppContext,
  PosFormButton,
  PosMainProgress,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";

const PublicRewardCardMobileSmsPrivacyPolicy = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [locationData, setLocationData] = useState([]);
  const [businessNameNotFound, setBusinessNameNotFound] = useState(1);
  const [locImgNotFound, setLocImgNotFound] = useState(1);
  const [locPhone, setLocPhone] = useState("");
  const [businessImage, setBusinessImage] = useState("");

  const effectRun = useRef(true);

  useEffect(() => {
    const subdomain = window.location.host.split(".");
    if (subdomain[1] !== Constants.SANE_POS_DOMAIN) {
      setBusinessImage(octoposLogo);
    } else {
      setBusinessImage(octoposLogo);
    }
  }, []);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocationData(loaderResponse.data?.data);

          if (
            null != loaderResponse.data?.data?.businessLogo &&
            "" != loaderResponse.data?.data?.businessLogo
          ) {
            setLocImgNotFound(0);
          }
          if (
            null != loaderResponse.data?.data?.businessName &&
            "" != loaderResponse.data?.data?.businessName
          ) {
            setBusinessNameNotFound(0);
          }
          let newVal = loaderResponse.data?.data?.location_data.phone
            .toString()
            .replace(/\D/g, "");
          if (
            loaderResponse.data?.data?.location_data.phone.toString().length ===
            0
          ) {
            newVal = "";
          } else if (
            loaderResponse.data?.data?.location_data.phone.toString().length <=
            3
          ) {
            newVal = newVal.replace(/^(\d{0,3})/, "($1)");
          } else if (
            loaderResponse.data?.data?.location_data.phone.toString().length <=
            6
          ) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
          } else if (
            loaderResponse.data?.data?.location_data.phone.toString().length <=
            10
          ) {
            newVal = newVal.replace(
              /^(\d{0,3})(\d{0,3})(\d{0,4})/,
              "($1) $2-$3"
            );
          } else {
            newVal = newVal.substring(0, 10);
            newVal = newVal.replace(
              /^(\d{0,3})(\d{0,3})(\d{0,4})/,
              "($1) $2-$3"
            );
          }
          setLocPhone(newVal);
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [loaderResponse]);

  const handleClose = () => {
    // window.location.reload(); // Reload the current page
    window.open(window.location.href, "_self").close(); // Attempt to close the current tab
  };

  return (
    <Flex
      bg={Colors.loginAuthBackground}
      minHeight={"100vh"}
      flexDirection={"column"}
    >
      <Box
        w={"100%"}
        sx={{
          position: "-webkit-sticky",
          /* Safari */ position: "sticky",
          top: "0",
          zIndex: 999,
        }}
      >
        <Box
          w={"100%"}
          sx={{
            /* Safari */ position: "absolute",
            top: "0",
            zIndex: 99999999,
          }}
        >
          {myContext.loading ? <PosMainProgress /> : null}
        </Box>
        <Flex
          // h={"100vh"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          <Box
            bg={Colors.loginAuthBackground}
            style={{
              width: "100rem",
              // borderRadius: "0.63rem",
              // border: "0.13rem solid #FFF",
              // boxShadow: "0rem 1.25rem 3.13rem 0rem #59595926",
              // paddingBottom: "2.81rem",
              // boxSizing: "border-box",
            }}
          >
            <Box
              display="flex"
              mt={"2rem"}
              justifyContent="center"
              alignItems="center"
            >
              {locationData.businessLogo != null &&
              "" != locationData.businessLogo ? (
                <Image
                  style={{
                    // width: "15.31rem",
                    // marginTop: "4.69rem",
                    height: "8.125rem",
                  }}
                  src={locationData.businessLogo}
                  alt="Octopos"
                />
              ) : (
                null
                // <Image
                //   style={{
                //     width: "15.31rem",
                //   }}
                //   src={businessImage}
                //   alt="Octopos"
                // />
              )}
            </Box>
            {/* <Box
              color={Colors.posTextInfo}
              mt={"0.625rem"}
              style={{
                fontSize: "1.5rem",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {businessNameNotFound == 0 && 0 === locImgNotFound && (
                <Text
                  style={{
                    fontSize: "1.13rem",
                    fontStyle: "1.9rem",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "-0.02rem",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {locationData.business_name}
                </Text>
              )}
              {businessNameNotFound == 0 && 1 == locImgNotFound && (
                <Text
                  style={{
                    fontSize: "1.13rem",
                    fontStyle: "1.9rem",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "-0.02rem",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {locationData.business_name}
                </Text>
              )}
            </Box> */}
            {/* <Box
              m={"0.625rem"}
              color={Colors.posTextInfo}
              style={{
                marginTop: "0.31rem",
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {null != loaderResponse.data?.data?.location_data.address1 &&
              "" != loaderResponse.data?.data?.location_data.address1
                ? loaderResponse.data?.data?.location_data.address1 + ", "
                : ""}
              {null != loaderResponse.data?.data?.location_data.city &&
              "" != loaderResponse.data?.data?.location_data.city
                ? loaderResponse.data?.data?.location_data.city + ", "
                : ""}
              {null != loaderResponse.data?.data?.location_data.state &&
              "" != loaderResponse.data?.data?.location_data.state
                ? loaderResponse.data?.data?.location_data.state + " "
                : ""}
              {null != loaderResponse.data?.data?.location_data?.zipcode &&
              "" != loaderResponse.data?.data?.location_data?.zipcode
                ? locationData.location_data?.zipcode + ", "
                : ""}
              {null != loaderResponse.data?.data?.location_data.country &&
              "" != loaderResponse.data?.data?.location_data.country
                ? loaderResponse.data?.data?.location_data.country
                : ""}
              .
            </Box> */}
            {/* <Box
              color={Colors.posTextInfo}
              style={{
                marginTop: "0.31rem",
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              Ph- {null != locPhone && "" != locPhone ? locPhone : "N.A."}
            </Box> */}
            <Box mx={{ base: 4, md: 156 }}>
              <Stack mt={"3rem"} spacing={2}>
                <Box textAlign="left">
                  <Text
                    color={Colors.posTextInfo}
                    fontSize="1.5rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    letterSpacing="-0.01rem"
                  >
                    MOBILE & SMS PRIVACY POLICY
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontStyle={"normal"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    We value your privacy and the information you consent to
                    share in relation to our SMS marketing service. We use this
                    information to send you text notifications (for your order),
                    text marketing offers, and transactional texts, including
                    requests for reviews from us. No mobile information will be
                    shared with third parties/affiliates for
                    marketing/promotional purposes. All other categories exclude
                    text messaging originator opt-in data and consent; this
                    information will not be shared with any third parties.
                  </Text>
                </Box>
                <Spacer />
                <Flex
                  flexDirection={"flex-end"}
                  alignContent={"end"}
                  alignSelf={"end"}
                  mt={"0.9rem"}
                >
                  <PosFormButton
                    buttonText={"Close"}
                    CancelButton={true}
                    // ref={cancelRef}
                    onClick={handleClose}
                  />
                </Flex>
              </Stack>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default RouteWithLayout(PublicRewardCardMobileSmsPrivacyPolicy);
