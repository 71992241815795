import {
  Box,
  // ButtonGroup,
  // Flex,
  // Spacer,
  // Stack,
  // TabPanel,
  // TabPanels,
  // Tabs,
  // Text,
} from "@chakra-ui/react";
import React, 
{ useContext,
  useEffect, 
  // useRef, useState 
}
 from "react";
// import { useSubmit } from "react-router-dom";
// import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
//   PosDataGridTable,
//   PosIconButton,
//   PosListHeader,
//   PosNoDataFound,
//   PosProgress,
//   PosSearch,
//   PosTab,
//   PosTostMessage,
} from "../../../components/index";
// import * as Constants from "../../../constants/Constants";
// import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
// import { getAllIntegration } from "./IntegrationService";

const IntegrationList = (props) => {
  // const { addToast } = PosTostMessage();
  // const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  // const submit = useSubmit();
  // const { error } = PosErrorHook();
  // const [kdsData, setkdsData] = useState([]);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [sortType, setSortType] = useState("desc");
  // const [sortColumn, setSortColumn] = useState("id");
  // const [totalcount, setTotalCount] = useState(0);
  // const [curruntTab, setCurruntTab] = useState(0);
  // const cancelRef = React.useRef();
  // const [selectedCouponType, setSelectedCouponType] = useState("");
  // const [kdsCode, setKdsCode] = useState("");

  // const columnNames = [
  //   { field: "sr_no", headerName: "Sr. No", sortable: false, flex: 1 },
  //   { field: "location", headerName: "Location Name", sortable: false, flex: 1 },
  //   { field: "platform", headerName: "Platform", sortable: false, flex: 1 },
  //   { field: "created_at", headerName: "Created Date", sortable: false, flex: 1 },
  //   { field: "updated_at", headerName: "Last Updated Date", sortable: false, flex: 1 },
  //   {
  //     field: "actions",
  //     headerName: "Action",
  //     sortable: false,
  //     flex: 1,
  //     renderCell: (params) => {
  //       const rowData = params.row;
  //       const sendCode = () => {
  //         setKdsCode(
  //           rowData.code
  //             .replace(/(.{4})/g, "$1 ")
  //             .toUpperCase()
  //             .trim()
  //         );
  //       };
  //       return (
  //         <Text
  //           onClick={sendCode}
  //           cursor="pointer"
  //           color={Colors.posNavbarLink}
  //           fontSize="1rem"
  //           letterSpacing="-1.5%"
  //           textDecoration="underline"
  //           fontWeight={600}
  //         >
  //           Send Code
  //         </Text>
  //       );
  //     },
  //   },
  // ];

  // const [paginationModel, setPaginationModel] = useState({
  //   page: 0,
  //   pageSize: 25,
  // });
  // const [sortModel, setSortModel] = useState([
  //   {
  //     field: "id",
  //     sort: "desc",
  //   },
  // ]);
  // const sortRef = useRef(sortModel);

  // const tabNames = ["Active"];
  // const [loading, setLoading] = useState(true);
  // const [topLoading, setTopLoading] = useState(false);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(25);
  // const effectRun = useRef(true);

  // const handlePageChange = (newPage) => {
  //   effectRun.current = true;
  //   setPage(newPage);
  // };

  // const handleRowsPerPageChange = (newRowsPerPage) => {
  //   effectRun.current = true;
  //   setRowsPerPage(newRowsPerPage);
  // };
  // const handleSortColumn = (column, sort) => {
  //   setLoading(true);
  //   setSortColumn(column.sortName);
  //   effectRun.current = true;
  //   setSortType(sort);
  // };
  // const fetchData = async () => {
  //   // let data = {
  //   //   limit: paginationModel.pageSize,
  //   //   page: paginationModel.page + 1,
  //   //   order:
  //   //     sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
  //   //   order_type:
  //   //     sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
  //   //   filter: searchQuery,
  //   // };
  //   try {
  //     getAllIntegration()
  //       .then((response) => {
  //         if (
  //           undefined !== response.data.flag &&
  //           null !== response.data.flag &&
  //           response.data.flag == true
  //         ) {
            // myContext.handleLoading(false);
  //           setkdsData(response.data.data.data);
  //           setTotalCount(response.data?.data?.totalCount);
  //           setLoading(false);
  //         } else {
  //           myContext.handleLoading(false);
  //           setkdsData([]);
  //           setTotalCount(0);
  //           setLoading(false);
  //           let actionData = response;
  //           error({ actionData });
  //         }
  //       })
  //       .catch((err) => {
  //         myContext.handleLoading(false);
  //         setkdsData([]);
  //         setLoading(false);
  //         setTotalCount(0);
  //         let actionData = err;
  //         error({ actionData });
  //       });
  //   } catch (error) {
  //     myContext.handleLoading(false);
  //     setkdsData([]);
  //     setTotalCount(0);
  //   }
  // };
  // useEffect(() => {
  //   if (effectRun.current === true) {
  //     if (curruntTab === 0) {
  //       fetchData();
  //     }
  //     effectRun.current = false;
  //   }
  // }, [paginationModel, sortModel, page, loading]);

  // const handleTabChange = (index) => {
  //   setCurruntTab(index);
  //   if (index == 0) {
  //     setSearchQuery("");
  //     setPage(0);
  //     setRowsPerPage(25);
  //     effectRun.current = true;
  //     setLoading(true);
  //   } else {
  //     setSearchQuery("");
  //     setPage(0);
  //     setRowsPerPage(25);
  //     effectRun.current = true;
  //     setLoading(true);
  //   }
  // };
  // const handleSearchList = () => {
  //   if (curruntTab == 0) {
  //     fetchData();
  //   }
  // };
  // let counter = 1;
  // const modifiedData = kdsData.map((kds) => ({
  //   sr_no: counter++,
  //   id:kds.id,
  //   location: kds.location,
  //   platform:kds.platform,
  //   created_at: kds.created_at,
  //   updated_at: kds.updated_at,
  // }));
  useEffect(() => {
    myContext.handleLoading(false);
  })
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      {/* <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={16}
      >
        <PosListHeader
          header={"Integration"}
          discription={"Manage Your Integrations"}
        />
        <Spacer />
      </Flex> */}
        {/* <div> */}
          <iframe src={process.env.REACT_APP_INTEGRATIONS_IFRAME_URL} width="100%" style={{ height:"78vh" }} title="Integration Iframe" />
        {/* </div> */}
      {/* <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onSearch={fetchData}
            />
            <Spacer />
          </Flex>
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 ? (
                <PosDataGridTable
                  columns={columnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    effectRun.current = true;
                    setSortModel(newSortModel);
                  }}
                />
              ) : (
                <PosNoDataFound title={Constants.INTEGRATIONS_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel></TabPanel>
          </TabPanels>
        </Tabs>
      </Box> */}
    </Box>
  );
};
export default WithRouter(IntegrationList);
