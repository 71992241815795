import { createTheme } from "@mui/material/styles";
import Colors from "../../assets/colors/Colors";

const Mtheme = createTheme({
  typography: {
    fontFamily: `'Lexend', sans-serif`,
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: ({ ownerState }) => ({
  //         ...(ownerState.variant === 'dashed' &&
  //           ownerState.color === 'primary' && {
  //             main: purple[500],
  //             color: purple[500],
  //           }),
  //       }),
  //     },
  //   },
  // },
  palette: {
    primary: {
      main: Colors.posNavbarLink,
    },
    // secondary: {
    //   main: green[500],
    // },
  },
  shadows: {
    1: "0px 25px 50px 0px rgba(143, 143, 143, 0.15)",
    8: "0px 25px 50px 0px rgba(143, 143, 143, 0.15)",
    24: "0px 25px 50px 0px rgba(143, 143, 143, 0.15)",
  },
});

export default Mtheme;
