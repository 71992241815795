import { Navigate, useLocation, useNavigate } from "react-router-dom";

export const WithRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    if (
      location.pathname.includes(
        "/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE
      )
    ) {
      if (
        undefined !== localStorage.getItem("master-token") &&
        null !== localStorage.getItem("master-token") &&
        "" !== localStorage.getItem("master-token")
      ) {
        return <Component navigate={navigate} {...props} />;
      } else {
        return (
          <>
            <Component navigate={navigate} {...props} />
            <Navigate
              to={"/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE}
            />
          </>
        );
      }
    } else {
      if (
        undefined !== localStorage.getItem("token") &&
        null !== localStorage.getItem("token") &&
        "" !== localStorage.getItem("token")
      ) {
        return <Component navigate={navigate} {...props} />;
      } else {
        return (
          <>
            <Component navigate={navigate} {...props} />
            <Navigate to="/" />
          </>
        );
      }
    }
  };

  return Wrapper;
};
