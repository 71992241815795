import { Text } from "@chakra-ui/react";
import React from "react";
import Colors from "../../assets/colors/Colors";
const PosNoDataFound = (props) => {
  return (
    <Text
      textAlign="left"
      fontSize="1.5rem"
      color={Colors.posTextInfo}
      fontWeight={600}
      mb={4}
    >
      {props.title}
    </Text>
  );
};
export default PosNoDataFound;
