import { drawerAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const full = defineStyle({
  maxW: "calc(100vw - 10vw)",
});
const sizes = {
  full: definePartsStyle({ dialog: full }),
};

export const drawerTheme = defineMultiStyleConfig({
  sizes,
});
