import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

import { getAllCategories, getAllDeactiveCategories } from "./CategoryService";

const CategoryList = (props) => {
  const { addToast } = PosTostMessage();
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const myContext = useContext(AppContext);
  const stateData = useLocation(); // get backraking data as param
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.columnModel
      : {}
  );
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionResponse = useActionData();
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDeactiveData, setCategoryDeactiveData] = useState([]);
  const [deactiveTotalCount, setDeactiveTotalCount] = useState(0);
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type
      : "desc"
  );
  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order
      : "id"
  );

  // backtracking
  const [paginationModel, setPaginationModel] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  // backtracking
  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);

  const [page, setPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.page
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.limit
      : 25
  );
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionExtraColumn = isMobile ? { width: 420 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 90,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      headerName: "Stay On Category Page",
      field: "stay_on_cat_prod_page",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Hide Category From Pos",
      field: "hide_cat_from_pos_attr",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Show in Online Ordering",
      field: "show_in_online_ordering",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Online Ordering Sort",
      field: "online_ordering_sort",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Actions",
      field: "actions",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          // backtracking
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
            columnModel: olColumnVisibilityModel,
          };
          props.navigate(`${Constants.CATEGORY_EDIT + item.id}`, {
            state: data,
          });
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.CATEGORY_LIST,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "Deactive",
              disabledButton: buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];

  // const options = {
  //   buttonName: "Edit",
  //   buttonIcon: <EditIcon />,
  //   handleClick: (item) => handleEditPage(item),
  //   subButtons: [
  //     {
  //       name: "Deactive",
  //       icon: <CloseIcon style={{ color: "red" }} />,
  //       onClick: (item) => handleDeactive(item),
  //     },
  //   ],
  // };

  const deaciveListColumnNames = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      width: 90,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      ...actionExtraColumn,
    },
    {
      headerName: "Reactive",
      field: "reactive",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      ...actionColumn,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          // backtracking
          setButtonDisable(true);
          if (
            categoryDeactiveData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.CATEGORY_LIST,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            // cursor="pointer"
            // color={Colors.posNavbarLink}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? "gray" : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            // textDecoration="underline"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);

  // const [deactiveId, setDeactiveId] = useState("");
  const effectRun = useRef(true);
  const handleDeactive = (item) => {
    setButtonDisable(true);
    if (modifiedData.length == 1 && paginationModel.page != 0) {
      paginationModel.page = paginationModel.page - 1;
    }
    myContext.handleLoading(true);
    let data = {
      id: item.id,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.CATEGORY_LIST,
    });
  };

  // const handleDeactive = (id) => {
  //   try {
  //     deactivateCategory(id)
  //       .then((response) => {
  //         if (
  //           response.data[Constants.FLAGE] !== null &&
  //           response.data[Constants.FLAGE] === true
  //         ) {
  //           setLoading(true);
  //           fetchData();
  //           addToast({
  //             alertStatus: Constants.TOAST_TYPE_SUCESS,
  //             alertTitle: "Success",
  //             alertDescription: Constants.CATEGORY_DEACTIVE_SUCCESS,
  //           });
  //         } else {
  //           if (
  //             response.data[Constants.CODE] === Constants.REQUEST_DENIED_CODE
  //           ) {
  //             //TODO Logout with backend message and empty all data from local storage
  //             addToast({
  //               alertStatus: Constants.TOAST_TYPE_WARNING,
  //               alertTitle: Constants.TOKEN_EXPIRTED,
  //               alertDescription: response.data.message,
  //             });
  //             props.navigate(Constants.LOGOUT_PAGE);
  //           } else if (
  //             response.data[Constants.CODE] === Constants.UNAUTHORIZE_CODE
  //           ) {
  //             props.navigate(Constants.UNAUTHORIZE_PAGE);
  //           } else if (response.data.validationCheck) {
  //             addToast({
  //               alertStatus: Constants.TOAST_TYPE_WARNING,
  //               alertTitle: Constants.FIELD_IS_REQUIRED,
  //               alertDescription: response.data.message,
  //             });
  //           } else {
  //             addToast({
  //               alertStatus: Constants.TOAST_TYPE_WARNING,
  //               alertTitle: Constants.FIELD_IS_REQUIRED,
  //               alertDescription: response.data.message,
  //             });
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching category data:", error);
  //       });
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    effectRun.current = true;
    setLoading(true);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    effectRun.current = true;
    setLoading(true);
  };

  const handleSortColumn = (column, sort) => {
    effectRun.current = true;
    setLoading(true);
    setSortColumn(column.sortName);
    setSortType(sort);
  };

  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.CATEGORY_LIST); // refresh page time param data clear
  };

  const fetchData = async () => {
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    try {
      getAllCategories(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setCategoryData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setCategoryData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setCategoryData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCategoryData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const fetchDeActiveCategoryData = async () => {
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    try {
      getAllDeactiveCategories(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setCategoryDeactiveData(response.data.data.data);
            setDeactiveTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setCategoryDeactiveData([]);
            setDeactiveTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setCategoryDeactiveData([]);
          setDeactiveTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCategoryDeactiveData([]);
      setDeactiveTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveCategoryData();
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [
    paginationModel,
    sortModel,
    loading,
    searchQuery,
    paginationModelDeactive,
    sortModelDeactive,
  ]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.CATEGORY_MODULE,
            alertDescription: Constants.CATEGORY_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          fetchDeActiveCategoryData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.CATEGORY_MODULE,
            alertDescription: Constants.CATEGORY_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
      // else {
      //   let actionData = actionResponse;
      //   error({ actionData });
      // }
    }
  }, [actionResponse]);

  const handleEditPage = (item) => {
    // backtracking
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    myContext.handleLoading(true);
    props.navigate(`${Constants.CATEGORY_EDIT + item.id}`, {
      state: data,
    });
  };

  const handleReactive = (id) => {
    myContext.handleLoading(true);
    let data = {
      id: id,
      type: Constants.REACTIVE,
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.CATEGORY_LIST,
    });
  };

  // const handleReactive = (id) => {
  //   try {
  //     reactiveCategory(id)
  //       .then((response) => {
  //         if (
  //           response.data[Constants.FLAGE] !== null &&
  //           response.data[Constants.FLAGE] === true
  //         ) {
  //           setLoading(true);
  //           fetchDeActiveCategoryData();
  //           addToast({
  //             alertStatus: Constants.TOAST_TYPE_SUCESS,
  //             alertTitle: "Success",
  //             alertDescription: Constants.CATEGORY_REACTIVE_SUCCESS,
  //           });
  //         } else {
  //           if (
  //             response.data[Constants.CODE] === Constants.REQUEST_DENIED_CODE
  //           ) {
  //             addToast({
  //               alertStatus: Constants.TOAST_TYPE_WARNING,
  //               alertTitle: Constants.TOKEN_EXPIRTED,
  //               alertDescription: response.data.message,
  //             });
  //             props.navigate(Constants.LOGOUT_PAGE);
  //           } else if (
  //             response.data[Constants.CODE] === Constants.UNAUTHORIZE_CODE
  //           ) {
  //             props.navigate(Constants.UNAUTHORIZE_PAGE);
  //           } else if (response.data.validationCheck) {
  //             addToast({
  //               alertStatus: Constants.TOAST_TYPE_WARNING,
  //               alertTitle: Constants.FIELD_IS_REQUIRED,
  //               alertDescription: response.data.message,
  //             });
  //           } else {
  //             addToast({
  //               alertStatus: Constants.TOAST_TYPE_WARNING,
  //               alertTitle: Constants.FIELD_IS_REQUIRED,
  //               alertDescription: response.data.message,
  //             });
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching user data:", error);
  //       });
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const handleSortType = (order) => {
    setSortType(order);
  };
  const handleTabChange = (index) => {
    // tab change time set currunt tab and pagination related clear all data
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      setSortModel([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      effectRun.current = true;
      setLoading(true);
    } else {
      searchInputHideRef.current = false;
      setSearchQuery("");
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      effectRun.current = true;
      setLoading(true);
    }
  };

  const handleSearchList = () => {
    // search time get active and deactive list
    paginationModel.page = 0;
    props.navigate(Constants.CATEGORY_LIST);
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveCategoryData();
    }
  };

  const modifiedData = categoryData.map((category) => ({
    ...category,
    stay_on_cat_prod_page: category.stay_on_cat_prod_page == 0 ? "No" : "Yes",
    hide_cat_from_pos_attr: category.hide_cat_from_pos_attr == 0 ? "No" : "Yes",
    show_in_online_ordering:
      category.show_in_online_ordering == 0 ? "No" : "Yes",
    online_ordering_sort:
      category.online_ordering_sort != null
        ? category.online_ordering_sort
        : "N.A.",
  }));

  return (
    // <MainLayout>
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={16}
      >
        <PosListHeader
          header={"Categories"}
          discription={"Manage Categories for your products"}
        />
        <Spacer />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true);  // loading start
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                filter: searchQuery,
                columnModel: olColumnVisibilityModel,
              };
              // pass all backtraking ralated all in create tax route
              props.navigate(Constants.CATEGORY_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              // onChange={(e) => setSearchQuery(e.target.value)}
              onChange={(e) => {
                let value =
                  searchQuery.length > 0
                    ? e.target.value
                    : e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
            <Spacer />
          </Flex> */}
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                // <PosTable
                //   columnNames={columnNames}
                //   rawdata={modifiedData}
                //   totalcount={totalcount}
                //   DectivatedId={handleDeactive}
                //   onPageChange={handlePageChange}
                //   onRowsPerPageChange={handleRowsPerPageChange}
                //   handleSortColumn={handleSortColumn}
                //   sortColumnName={sortColumn}
                //   order={sortType}
                //   spliteButton={true}
                //   spliteOptions={options}
                //   page={page}
                //   rowsPerPage={rowsPerPage}
                //   goEdit={(id) => handleEditPage(id)}
                // />
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    columnVisibilityModel={olColumnVisibilityModel}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      effectRun.current = true;
                      setSortModel(newSortModel);
                    }}
                    fileName={Constants.ACTIVE_CATEGORY_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.CATEGORY_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                // <PosTable
                //   columnNames={deaciveListColumnNames}
                //   rawdata={categoryDeactiveData}
                //   totalcount={deactiveTotalCount}
                //   reactive={true}
                //   DectivatedId={handleDeactive}
                //   sortColumnName={sortColumn}
                //   order={sortType}
                //   handleSortColumn={handleSortColumn}
                //   onPageChange={handlePageChange}
                //   onRowsPerPageChange={handleRowsPerPageChange}
                //   goEdit={(id) => handleEditPage(id)}
                //   page={page}
                //   rowsPerPage={rowsPerPage}
                //   goReactive={(id) => handleReactive(id)}
                // />
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={deaciveListColumnNames}
                    rows={categoryDeactiveData}
                    totalcount={deactiveTotalCount}
                    paginationModel={paginationModelDeactive}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModelDeactive) => {
                      effectRun.current = true;
                      setPaginationModelDeactive(newPaginationModelDeactive);
                    }}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    columnVisibilityModel={olColumnVisibilityModel}
                    sortModel={sortModelDeactive}
                    onSortModelChange={(newSortModelDeactive) => {
                      sortRefDeactive.current = sortModelDeactive;
                      effectRun.current = true;
                      setSortModelDeactive(newSortModelDeactive);
                    }}
                    fileName={Constants.DEACTIVE_CATEGORY_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_CATEGORY_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
    // </MainLayout>
  );
};
export default WithRouter(CategoryList);
