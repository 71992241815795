import { Box, ButtonGroup, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import voriLogo from "../../../assets/images/Black_LOGO_with_Name copy.png";
import octoposLogo from "../../../assets/images/cropped_logo.png";
import {
  AppContext,
  PosFormButton,
  PosMainProgress,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
import { checkUnsubKey, publicUnsubEmail } from "../AuthService";

const PublicCampaignUnsubscribe = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const location = useLocation();
  const [businessImage, setBusinessImage] = useState("");
  const [businessName, setBusinessName] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  let obj = useParams();
  obj.key = queryParams.key;
  obj.count = 0;
  const [loading, setLoading] = useState(true);

  const effectRun = useRef(true);

  useEffect(() => {
    const subdomain = window.location.host.split(".");
    if (subdomain[1] !== Constants.SANE_POS_DOMAIN) {
      setBusinessImage(voriLogo);
      setBusinessName("Vori");
    } else {
      setBusinessImage(octoposLogo);
      setBusinessName("Octopos");
    }
  }, []);

  useEffect(() => {
    if (effectRun.current === true) {
      myContext.handleLoading(true);
      fetchData();
      effectRun.current = false;
    }
  }, []);

  const fetchData = async () => {
    myContext.handleLoading(true);
    let data = {
      key: queryParams.get("key"),
    };
    setLoading(true);
    try {
      checkUnsubKey(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const unsubscribe = () => {
    myContext.handleLoading(true);
    setLoading(true);
    try {
      publicUnsubEmail(queryParams.get("key"))
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setShowSuccess(true);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const handleClose = () => {
    window.open(window.location.href, "_self").close(); // Attempt to close the current tab
  };

  return (
    <Flex
      bg={Colors.loginAuthBackground}
      minHeight={"100vh"}
      flexDirection={"column"}
    >
      <Box
        w={"100%"}
        sx={{
          position: "-webkit-sticky",
          /* Safari */ position: "sticky",
          top: "0",
          zIndex: 999,
        }}
      >
        <Box
          w={"100%"}
          sx={{
            /* Safari */ position: "absolute",
            top: "0",
            zIndex: 99999999,
          }}
        >
          {myContext.loading ? <PosMainProgress /> : null}
        </Box>
        <Flex
          // h={"100vh"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          <Box
            mt={{ base: 10, md: 20, sm: 16, lg: 28 }}
            // mb={{ base: 10, md: 20, sm: 16, lg: 28 }}
            bg={Colors.loginAuthBackground}
            style={{
              width: "54.13rem",
              borderRadius: "0.63rem",
              border: "0.13rem solid #FFF",
              boxShadow: "0rem 1.25rem 3.13rem 0rem #59595926",
              paddingBottom: "2.81rem",
              boxSizing: "border-box",
            }}
          >
            {/* <Box
              display="flex"
              mt={"2rem"}
              justifyContent="center"
              alignItems="center"
            >
              <Image
                style={{
                  width: "11.25rem",
                }}
                src={businessImage}
                alt="Octopos"
              />
            </Box> */}
            {showSuccess == false ? (
              <Box mx={{ base: 2, md: 78 }} textAlign="center">
                <Stack>
                  <Box mt={"2rem"}>
                    <Text
                      style={{
                        color: "red",
                        fontSize: "1.75rem",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        letterSpacing: "-0.02rem",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      Are you sure you want to unsubscribe?
                    </Text>
                    <Text
                      mt={"0.25rem"}
                      style={{
                        fontSize: "0.95rem",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        letterSpacing: "-0.02rem",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      By unsubscribing, you may miss the exciting offers from us.
                    </Text>
                  </Box>
                  <Box textAlign="center" mt={"1rem"}>
                    <ButtonGroup>
                      <PosFormButton
                        buttonText={"Yes"}
                        onClick={unsubscribe}
                        CancelButton={true}
                      />
                      <PosFormButton
                        onClick={handleClose}
                        buttonsubmit={"No"}
                        SubmitButton={true}
                      />
                    </ButtonGroup>
                  </Box>
                </Stack>
              </Box>
            ) : (
              <Box mx={{ base: 2, md: 78 }} textAlign="center">
                <Stack>
                  <Box mt={"2rem"}>
                    <Text
                      style={{
                        fontSize: "1.75rem",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        letterSpacing: "-0.02rem",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      You have successfully unsubscribed.
                    </Text>
                  </Box>
                  <Box textAlign="center" mt={"1rem"}>
                    <ButtonGroup>
                      <PosFormButton
                        onClick={handleClose}
                        buttonsubmit={"Ok"}
                        SubmitButton={true}
                      />
                    </ButtonGroup>
                  </Box>
                </Stack>
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default RouteWithLayout(PublicCampaignUnsubscribe);
