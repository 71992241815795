import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import Colors from "../../assets/colors/Colors";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const posBasicInput = definePartsStyle({
  field: {
    borderRadius: "0.31rem",
    border: "1px solid" + Colors.posInputBorder,
    fontSize: "0.94rem",
    fontWeight: 300,
    background: Colors.posInputBackground,
    _placeholder: {
      fontSize: "0.94rem",
      fontWeight: 300,
      color: "#01004821",
    },
    _focus: {
      border: "0.125rem solid",
      borderColor: Colors.buttonHover,
      boxShadow: "0rem 0rem 0rem 0.188rem " + Colors.inputHover,
       // borderColor: "#6366f1", TODO as per dropdown.
      // boxShadow: "0 0 0 0.2rem #c7d2fe !important", 
    },
    _invalid: {
      border: "0.125rem solid",
      borderColor: Colors.inputErrorBorder,
      boxShadow: "0px 0px 0px 3px " + Colors.badgeError,
    },
    // _hover: {
    //   borderColor: Colors.inputHover,
    // },
  },
});
const posDarkPlaceholder= definePartsStyle({
  field: {
    borderRadius: "0.31rem",
    border: "1px solid" + Colors.posInputBorder,
    fontSize: "0.94rem",
    fontWeight: 300,
    background: Colors.posInputBackground,
    _placeholder: {
      fontSize: "0.94rem",
      fontWeight: 300,
      color: "#8998b0",
    },
    _focus: {
      border: "0.125rem solid",
      borderColor: Colors.buttonHover,
      boxShadow: "0rem 0rem 0rem 0.188rem " + Colors.inputHover,
       // borderColor: "#6366f1", TODO as per dropdown.
      // boxShadow: "0 0 0 0.2rem #c7d2fe !important", 
    },
    _invalid: {
      border: "0.125rem solid",
      borderColor: Colors.inputErrorBorder,
      boxShadow: "0px 0px 0px 3px " + Colors.badgeError,
    },
    // _hover: {
    //   borderColor: Colors.inputHover,
    // },
  },
});
const posSearchInput = definePartsStyle({
  field: {
    borderRadius: "0.625rem", //"16px"
    border: "1px",
    fontSize: "0.94rem",
    fontWeight: 300,
    color: Colors.posTextInfo,
    borderColor: Colors.inputBorder,
    backgroundColor: Colors.loginAuthBackground,
    _placeholder: {
      fontSize: "0.94rem",
      fontWeight: 300,
      color: Colors.posTextInfo,
    },
    _hover: {
      boxShadow: "0rem 0rem 0rem 0.188rem " + Colors.inputHover,
    },
    _focus: {
      borderColor: Colors.buttonHover,
    },
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: { posBasicInput, posSearchInput, posDarkPlaceholder },
});
