import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import React from "react";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": {
    fontWeight: 300,
    fontSize: "0.94rem",
  },
}));

const PosRadio = (props) => {
  return (
    <RadioGroup
      row
      name={props.name}
      value={props.value}
      onChange={props.handleSelectType}
    >
      <StyledFormControlLabel
        value={props.firstValue}
        control={<Radio />}
        label={props.firstLable}
      />
      <StyledFormControlLabel
        value={props.secoundValue}
        control={<Radio />}
        label={props.secoundLable}
      />
    </RadioGroup>
  );
};

export default PosRadio;
