import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getCurrentLoginUser() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GET_MS_USER_DETAILS,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getBillingInformation(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.BILLING_DETAIL_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}

export function viewBillingDetails(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.BILLING_VIEW_DETAIL_API_ROUTE,

        payload,

        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}

export function getAllCountriesState() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GET_ALL_USER,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getStateListForSelectedCountry(countryISO) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GET_COUNTRY_STATE +
          countryISO,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadPDF(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GENERATE_BILLING_DETAILS_PDF,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_VIEW,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateBillingSettings({ request }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();

    const payload = {
      id: Object.fromEntries(formData).id,
      business_name: Object.fromEntries(formData).businessname,
      business_phone: Object.fromEntries(formData).businessphone,
      business_address: Object.fromEntries(formData).businessaddress,
      business_zip: Object.fromEntries(formData).businesszip,
      business_city: Object.fromEntries(formData).businesscity,
      card_holder_email: Object.fromEntries(formData).cardholderemail,
      card_holder_name: Object.fromEntries(formData).cardholdername,
      cancel_plan: Object.fromEntries(formData).cancelplan,
      sms_plan_id: Object.fromEntries(formData).smsplanid,
      sms_plans: Object.fromEntries(formData).smsplans,
      business_suite: Object.fromEntries(formData).businesssuite,
    };
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.UPDATE_BILLING_SETTINGS,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function updateSmsPlan(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.UPDATE_SMS_PLAN_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}

export function createStripe(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CREATE_NEW_CARD_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}

export function deleteCustomerCard(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.DELETE_CUSTOMER_CARD_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}

export function makeCardAsDefault(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.MAKE_CARD_AS_DEFAULT_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}

export function changePlan(id, payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CHANGE_PLAN_SUBSCRIPTION_API_ROUTE +
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.BILLING_VIEW,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // resolve(errorData);
        reject(error);
      });
  });
}
