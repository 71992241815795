import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text
} from "@chakra-ui/react";
import React from "react";
import Colors from "../../assets/colors/Colors";

const PosBreadCrumb = (props) => {
  let bredCrumArray = props.breadCrumNames.slice(0,-1)

  return (
    <Box>
      <Breadcrumb spacing="0.1rem" separator={<ChevronRightIcon color={Colors.posTextInfo} h="1.07rem" w="1.07rem"/>}>
        {bredCrumArray.map((name, i) => {
          if (bredCrumArray.length == i + 1) {
            return (
              // <BreadcrumbItem key={i}>
              //   <Text
              //     fontSize={{ base: "1rem", md: "1rem" }}
              //     color={Colors.posNavbarLink}
              //     style={{
              //       fontStyle: "normal",
              //       fontWeight: 300,
              //       lineHeight: "normal",
              //       letterSpacing: "-0.225px",
              //       fontSize: "0.94"
              //     }}
              //   >
              //     {name}
              //   </Text>
              // </BreadcrumbItem>
              <BreadcrumbItem key={i}>
              <BreadcrumbLink
                key={i}
                onClick={() => {
                  props.handleClick(i);
                }}
                fontSize={{ base: "1rem", md: "1rem" }}
                ml={{ base: "0", md: i === 0 ? "1.84rem" : "0rem" }}
                color={Colors.posNavbarLink}
                style={{
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "normal",
                  letterSpacing: "-0.225px",
                  fontSize: "0.94"
                }}
              >
                {name}
              </BreadcrumbLink>
            </BreadcrumbItem>
            );
          } else if (i === 0) {
            return (
              <BreadcrumbItem key={i}>
                <BreadcrumbLink
                  onClick={() => {
                    props.handleClick(i);
                  }}
                  fontSize={{ base: "1rem", md: "1rem" }}
                  ml={{ base: "0", md: "1.84rem" }}
                  color={Colors.taxcolor}
                  style={{
                    fontStyle: "normal",
                    fontWeight: 300,
                    lineHeight: "normal",
                    letterSpacing: "-0.225px",
                    fontSize: "0.94"
                  }}
                >
                  {name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            );
          } else {
            return (
              <BreadcrumbItem key={i}>
                <BreadcrumbLink
                  key={i}
                  onClick={() => {
                    props.handleClick(i);
                  }}
                  fontSize={{ base: "1rem", md: "1rem" }}
                  color={Colors.posNavbarLink}
                  style={{
                    fontStyle: "normal",
                    fontWeight: 300,
                    lineHeight: "normal",
                    letterSpacing: "-0.225px",
                    fontSize: "0.94"
                  }}
                >
                  {name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            );
          }
        })}
      </Breadcrumb>
      <Breadcrumb>
        <BreadcrumbItem
          isLastChild={true}
          ke={props.breadCrumTitle}
          mt={{ base: "0", md: "0.19rem" }}
          ml={{ base: "0", md: "1.84rem" }}
          mr={{ base: "0", md: "3.19rem" }}
        >
          <Text
            fontSize={"1.5rem"}
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "-0.36px",
            }}
          >
            {props.breadCrumTitle}
          </Text>
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
};

export default PosBreadCrumb;
