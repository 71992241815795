import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import { SimpleReportLayout } from "../../../../../layouts/index";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDialogAlert,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
  commonDateFormate
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { handleProductDelete } from "./ProductNotFoundBarcodesService";

const ProductNotFoundBarcodes = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const cancelRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [productNotFoundBarcode, setProductNotFoundBarcode] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [applyClicked, setApplyClicked] = useState(false);
  const [isExtraLarge] = useMediaQuery("(min-width: 1700px)");
  const [isLarge] = useMediaQuery("(min-width: 1550px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1375px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const pageRef = useRef(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "asc",
    },
  ]);
  const paginationRef = useRef(paginationModel);

  const handleProductNotFoundDelete = (params) => {
    let data = {
      delete_all: 0,
      barcode: params.row.barcode,
      location_id: params.row.location_id,
    };
    setLoading(true);
    try {
      handleProductDelete(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            if (response.data?.message === "Data deleted successfully.") {
              addToast({
                alertStatus: Constants.TOAST_TYPE_SUCESS,
                alertTitle: Constants.REPORT_MODULE,
                alertDescription: response.data?.message,
              });
              myContext.handleLoading(false);
            }
            getProductNotFoundBarcode();
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    id: false,
    location_id: false,
  });
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1 };
  const columnNames = [
    { field: "id", headerName: "id", sortable: true, width: 90 },
    {
      field: "barcode",
      headerName: "Barcode",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "location",
      headerName: "Location Name",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "created_at",
      headerName: "Created At",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "",
      headerName: "Delete",
      sortable: false,
      disableExport: true,
      resizable: false,
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleProductNotFoundDelete(params)}
          style={{
            backgroundColor: "#F4F4F4",
            color: "#CD1919",
            transition: "background-color 0.3s, color 0.3s",
          }}
        >
          <DeleteIcon width="1.38rem" height="1.38rem" />
        </IconButton>
      ),
    },
  ];
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions: undefined !== loaderResponse?.data?.data?.location &&
      null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1
        ? loaderResponse?.data?.data?.location : [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if(undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1){
              setOlColumnVisibilityModel(prev => ({...prev, location: false }));
            }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  // useEffect(() => {
  //   if (paginationRef.current !== paginationModel) {
  //     getProductNotFoundBarcode();
  //     paginationRef.current = paginationModel;
  //   } else if (sortRef.current !== sortModel) {
  //     getProductNotFoundBarcode();
  //     sortModel.current = sortModel;
  //   }
  // }, [paginationModel, sortModel]);

  useEffect(() => {
    if (pageRef.current) {
      getProductNotFoundBarcode();
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Product not found barcode Report successfully exported."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "ProductNotFoundBarcodes.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          setProductNotFoundBarcode();
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);

  const handleExportReport = () => {
    if (
      formik.values.selectedLoactions.length > 0 &&
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (
        null != formik.values.fromDate &&
        "" != formik.values.fromDate){
        if(null != formik.values.toDate &&
        "" != formik.values.toDate
      ) {
    myContext.handleLoading(true);
      let data = {
        exportReport: true,
        location: JSON.stringify(formik.values.selectedLoactions),
        dateFrom: reportDateFormate(formik.values.fromDate),
        dateTo: reportDateFormate(formik.values.toDate),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.PRODUCT_NOT_FOUND_BARCODE,
      });
    } else {
      clearTableData(Constants.TO_DATE_IS_REQUIRED);
    }
  } else {
    clearTableData(Constants.FORM_DATE_IS_REQUIRED);
  } } else {
    clearTableData(Constants.LOCATION_IS_REQUIRED);
  }
  };

  const handleModelOpen = (item) => {
    onOpen();
    setDeactiveItem(item);
  };

  // const handleDeleteAllData = () => {
  //   myContext.handleLoading(true);
  //   let data = {
  //     delete: true,
  //     delete_all: parseInt(1),
  //     barcode: "",
  //     location_id: "",
  //   };
  //   submit(data, {
  //     method: Constants.POST,
  //     path: Constants.PRODUCT_NOT_FOUND_BARCODE,
  //   });
  //   onClose();
  // };
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleDeleteAllData = (params) => {
    let data = {
      delete_all: parseInt(1),
      barcode: "",
      location_id: "",
    };
    setLoading(true);
    setIsButtonDisabled(true);
    try {
      handleProductDelete(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            if (response.data?.message === "All data deleted successfully.") {
              addToast({
                alertStatus: Constants.TOAST_TYPE_SUCESS,
                alertTitle: Constants.REPORT_MODULE,
                alertDescription: response.data?.message,
              });
              onClose();
            }
            getProductNotFoundBarcode();
            setLoading(false);
            setIsButtonDisabled(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            setIsButtonDisabled(false);
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          setIsButtonDisabled(false);
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setIsButtonDisabled(false);
      myContext.handleLoading(false);
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getProductNotFoundBarcode = () => {
    if (formik.values.selectedLoactions.length > 0) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          setApplyClicked(true);
          let data = {
            getreport: true,
            location: JSON.stringify(formik.values.selectedLoactions),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            query: JSON.stringify({
              limit: paginationModel.pageSize,
              page: paginationModel.page + 1,
              order:
                sortModel.length > 0
                  ? sortModel[0].field
                  : sortRef.current[0].field,
              order_type:
                sortModel.length > 0
                  ? sortModel[0].sort
                  : sortRef.current[0].sort,
            }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.PRODUCT_NOT_FOUND_BARCODE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLoactions", e.value);
  };

  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
    setApplyClicked(false);
  };

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        // let s moment(e.value).set({ hour: 23, minute: 59 }).toDate();
        // formik.setFieldValue("fromDate", start_date);
        // formik.setFieldValue("toDate", end_date);
        // setFromDateView(start_date);
        // setToDateView(end_date);
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };

  const [deactiveItem, setDeactiveItem] = useState({});

  const modifiedData = tableData.map((data) => ({
    id: data.id,
    barcode: data.barcode,
    location_id: data.location.id,
    location: data.location.name,
    created_at: commonDateFormate(data.created_at)
  }));

  const renderReportLayout = () => {
    return (
      <>
        <Box className="card flex justify-content-center">
          <PosDropDown
            options={locations}
            value={formik.values.selectedLoactions}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            resetFilterOnHide={true}
            multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
            maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              value={formik.values.fromDate}
              label={"From Date*"}
              readOnlyInput={true}
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              value={formik.values.toDate}
              label={"To Date*"}
              readOnlyInput={true}
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        title={"Delete All"}
        discription={"Are you sure you want to delete all data?"}
        onClick={handleDeleteAllData}
        buttonNames={["Cancel", "Yes"]}
        isDisabled={isButtonDisabled}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Product Not Found Barcodes"]}
          breadCrumTitle={"Product Not Found Barcodes"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={"space-between"}
        >
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isMeduim ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            {totalcount <= 0 ? (
              <Box ml="1rem"></Box>
            ) : (
              <PosFormButton
                onClick={handleModelOpen}
                buttonText={"Delete All"}
                ghostButton={true}
              />
            )}
            <PosFormButton
              isDisabled={applyClicked ? false : true}
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getProductNotFoundBarcode}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                // noAction={true}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                // onPaginationModelChange={setPaginationModel}
                // sortModel={sortModel}
                // onSortModelChange={setSortModel}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.PRODUCT_NOT_FOUND_BARCODES_REPORT_CSV}
              />
            </Flex>
          ) : (
            <PosNoDataFound
              title={Constants.PRODUCT_NOT_FOUND_BARCODES_NO_DATA}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(ProductNotFoundBarcodes);
