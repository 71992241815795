import {
  Box, HStack, Text,
  VStack
} from "@chakra-ui/react";
import QRCode from "qrcode.react";
import React, {
  forwardRef,
  useImperativeHandle, useRef
} from "react";
import Colors from "../../../assets/colors/Colors";
import giftImage from "../../../assets/images/public_gift.png";
import RectangleImage from "../../../assets/images/Rectangle 5590.svg";


const CouponImage = forwardRef((props, ref) => {
  const childRef = useRef();
  useImperativeHandle(ref, () => ({
    getValue: () => {
      return childRef.current;
    },
  }));
  return (
    <Box
      ref={childRef}
      h={"59.563rem"}
      w={"49.375rem"}
      paddingX={"4.69rem"}
      paddingY={"3.94rem"}
      position="absolute"
      top="-10000px"
      left="-10000px"
      bg="#f5f5f5"
    >
      <VStack spacing={20}>
        <HStack spacing={16}>
          <VStack spacing={4} w={"16.31rem"}>
            <QRCode value={props.couponData?.couponCode} size={230} />
            <Text
              fontSize={"0.938rem"}
              fontWeight={500}
              letterSpacing={"-0.27px"}
              color={Colors.posTextInfo}
              align={"center"}
            >
              {"Use the QR Code when you checkout to get discount"}
            </Text>
          </VStack>
          <VStack spacing={4} w={"16.31rem"}>
            <Text
              fontSize={"0.938rem"}
              fontWeight={500}
              letterSpacing={"-0.27px"}
              color={Colors.posTextInfo}
              align={"center"}
            >
              {"OR"}
            </Text>
            <Text
              fontSize={"1.125rem"}
              fontWeight={500}
              letterSpacing={"-0.27px"}
              color={Colors.posTextInfo}
              align={"center"}
            >
              {"Use Coupon code: "+ props.couponData?.couponCode}
            </Text>
          </VStack>
        </HStack>
        <Box
          h={"8rem"}
          w={"8rem"}
          position={"absolute"}
          top={"22.5rem"}
          backgroundImage={giftImage}
          backgroundRepeat="no-repeat"
        ></Box>
        <Box h={"27.19rem"} w={"40rem"} backgroundImage={RectangleImage}>
          <VStack spacing={12} mt={"7.5rem"}>
            <VStack spacing={0}>
              <Text
                fontSize={"0.938rem"}
                fontWeight={500}
                letterSpacing={"-0.27px"}
                color={"#010048b3"}
                align={"center"}
              >
                {"Save up to"}
              </Text>
              <Text
                fontSize={"1.5rem"}
                fontWeight={600}
                letterSpacing={"-0.36px"}
                color={"#D42266"}
                align={"center"}
              >
                {props.couponData?.discount_type ==="Amount" ? `$ ${props.couponData?.discount_value}` : `${props.couponData?.discount_value} %` } on {props.couponData?.type === "Product" ? "Selected Products":`$ ${props.couponData?.min_amount } Order` }
              </Text>
              <Text
                fontSize={"0.938rem"}
                fontWeight={500}
                letterSpacing={"-0.27px"}
                color={"#010048b3"}
                align={"center"}
              >
                {"off your order"}
              </Text>
            </VStack>
            <VStack spacing={0}>
              <Text
                fontSize={"0.938rem"}
                fontWeight={300}
                letterSpacing={"-0.27px"}
                color={Colors.posTextInfo}
                align={"center"}
              >
                {"Coupon Type"}
              </Text>
              <Text
                fontSize={"0.938rem"}
                fontWeight={500}
                letterSpacing={"-0.27px"}
                color={Colors.posTextInfo}
                align={"center"}
              >
                {props.couponData?.type === "Product" ? "Applies to Selected Products" : "Applies to Order"}
              </Text>
            </VStack>
            {/* <VStack spacing={0}>
              <Text
                fontSize={"0.938rem"}
                fontWeight={300}
                letterSpacing={"-0.27px"}
                color={Colors.posTextInfo}
                align={"center"}
              >
                {"Time Used"}
              </Text>
              <Text
                fontSize={"0.938rem"}
                fontWeight={500}
                letterSpacing={"-0.27px"}
                color={Colors.posTextInfo}
                align={"center"}
              >
                {props.couponData?.time_used}
              </Text>
            </VStack> */}
            {/* <Box
              w={"40rem"}
              borderWidth={1}
              borderStyle="dashed"
              borderColor={"#C7C7C7"}
            ></Box>
            <VStack spacing={0}>
              <Text
                fontSize={"0.938rem"}
                fontWeight={300}
                letterSpacing={"-0.27px"}
                color={Colors.posTextInfo}
                align={"center"}
              >
                {"Campaign Validity: "}
              </Text>
              <Text
                fontSize={"0.938rem"}
                fontWeight={500}
                letterSpacing={"-0.27px"}
                color={Colors.posTextInfo}
                align={"center"}
              >
                {"1st Dec 2023 06:00  to 31st Dec 2024 06:00"}
              </Text>
            </VStack> */}
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
});

export default CouponImage;
