import { Sidebar } from "primereact/sidebar";
import React from "react";

const PosDrawer = (props) => {
  return (
    <Sidebar
      visible={props.visible}
      onHide={props.onHide}
      position="right"
      showCloseIcon={props.showCloseIcon}
      // className="p-sidebar-lg"
      style={{
        width: "90vw",
        height: "100vh",
        fontFamily: "Lexend, sans-serif",
        color: "#28222D",
        backgroundColor: "#F4F4F4",
      }}
    >
      {props.children}
    </Sidebar>
  );
};

export default PosDrawer;
