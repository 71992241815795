import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function getAllPosStation({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TILL_ALL_LOCATION_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PERMISSION_TILLS_VIEW,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getTillList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TILL_EDIT_ALL_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PERMISSION_TILLS_VIEW,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllDeactiveTill(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TILL_DEACTIVE_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PERMISSION_TILLS_REACTIVATE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllCreatePageData({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TILL_GET_CREATE_PAGE_DATA_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PERMISSION_TILLS_CREATE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function createTill({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = {
      opened_by: Object.fromEntries(formData).opened_by,
      opened_by_dropdown: JSON.parse(
        Object.fromEntries(formData).opened_by_dropdown
      ),
      pos_station_id: Object.fromEntries(formData).pos_station_id,
      pos_station_id_posStation: JSON.parse(
        Object.fromEntries(formData).pos_station_id_posStation
      ),
      starting_amount: Object.fromEntries(formData).starting_amount,
    };
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TILL_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PERMISSION_TILLS_CREATE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getTillsById({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TILL_EDIT_ROUTE +
          `${id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PERMISSION_TILLS_EDIT
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function tillEdit({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = JSON.stringify({
      closed_by: Object.fromEntries(formData).closed_by,
      closed_by_dropdown: JSON.parse(
        Object.fromEntries(formData).closed_by_dropdown
      ),
      pos_station_id: Object.fromEntries(formData).pos_station_id,
      closing_amount: Object.fromEntries(formData).closing_amount,
      difference: Object.fromEntries(formData).difference,
      id: Object.fromEntries(formData).id,
      is_closed: Object.fromEntries(formData).is_closed,
      opened_by: Object.fromEntries(formData).opened_by,
      starting_amount: Object.fromEntries(formData).starting_amount,
      station: JSON.parse(Object.fromEntries(formData).station),
    });
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.TILLS_ROUTE + id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PERMISSION_TILLS_EDIT,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function showTillsById({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TILLS_SHOW_ROUTE +
          `${id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PERMISSION_TILLS_VIEW,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function generateTillDetailsPdf(id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TILL_PDF_ROUTE,
        id,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PERMISSION_TILLS_VIEW,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function tillDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.TILLS_DEACTIVATE_ROUTE +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PERMISSION_TILLS_DEACTIVATE,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.TILLS_REACTIVE_ROUTE +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PERMISSION_TILLS_REACTIVATE,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else {
      let token = localStorage.getItem("token");
      let formData = await request.formData();
      if (Object.fromEntries(formData).getreport === "true") {
        const payload = JSON.stringify({
          filter: Object.fromEntries(formData).filter,
          location: JSON.parse(Object.fromEntries(formData).location),
          dateFrom: Object.fromEntries(formData).dateFrom,
          dateTo: Object.fromEntries(formData).dateTo,
          zone: Object.fromEntries(formData).zone,
          query: JSON.parse(Object.fromEntries(formData).query),
        });
        axios
          .post(
            Constants.REACT_APP_API_URL +
              process.env.REACT_APP_API_VERSION +
              Constants.TILLS_EDIT_ALL_LIST_ROUTE,
            payload,
            {
              headers: {
                "content-type": Constants.CONTANT_TYPE,
                Authorization: "Bearer" + token,
                Permission: Constants.PERMISSION_TILLS_VIEW,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            let errorData = {
              error: true,
              response: error.response,
              code: error.code,
            };
            resolve(errorData);
          });
      }
    }
  });
}
