import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  SimpleGrid,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  useMediaQuery,
} from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTab,
  PosTostMessage,
  PosDatePicker,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { endOfTodaysDay, startOfTodaysDay,reportDateFormate,commonDateFormate } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getAllTransferOrder,
  getPdfTransferOrder,
} from "./TransferOrderService";

const TransferOrderList = (props) => {
  const stateData = useLocation();
  const [showTable, setShowTable] = useState(true);
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const effectRun = useRef(true);
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [curruntTab, setCurruntTab] = useState(0);
  const tabNames = ["Active"];
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [deactivePurchaseOrderData, setdeactivePurchaseOrderData] = useState(
    []
  );
  const [totalDeactivatecount, setDeactiveTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonDisables, setButtonDisables] = useState(false);
  const [locations, setLocations] = useState([]);
  // const [locations, setlocations] = useState([]);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRunListDropDown = useRef(true);
  const loaderResponse = useLoaderData();
  const [loading, setLoading] = useState(true);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.filter &&
      "" != stateData?.state?.filter
      ? stateData?.state?.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.order_type &&
      "" != stateData?.state?.order_type
      ? stateData?.state?.order_type
      : "desc"
  );
  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.order &&
      "" != stateData?.state?.order
      ? stateData?.state?.order
      : "id"
  );
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);
  const [showFilter, setShowFilter] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.showFilter &&
      "" != stateData?.state?.showFilter
      ? stateData?.state?.showFilter
      : false
  );
  const [selectedSourceLocations, setSelectedSourceLocations] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.source_locations &&
      "" != stateData?.state?.source_locations
      ? (stateData?.state?.source_locations)
      : []
  );
  const [selectedStatus, setSelectedStatus] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.selectedStatus &&
      "" != stateData?.state?.selectedStatus
      ? (stateData?.state?.selectedStatus)
      : []
  );
  const [selectedDestinationLocations, setSelectedDestinationLocations] =
    useState(
      undefined !== stateData?.state &&
        null !== stateData?.state &&
        "" !== stateData?.state &&
        null != stateData?.state?.destination_locations &&
        "" != stateData?.state?.destination_locations
        ? (stateData?.state?.destination_locations)
        : []
    );
  const [page, setPage] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [applyButtonClicked, setApplyButtonClicked] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.apply &&
      "" != stateData?.state?.apply
      ? stateData?.state?.apply
      : 0
  );

  // useEffect(() => {
  //   if (
  //     undefined != actionResponse &&
  //     null != actionResponse &&
  //     {} != actionResponse
  //   ) {
  //     if (
  //       undefined != actionResponse?.error &&
  //       null != actionResponse?.error &&
  //       actionResponse?.error
  //     ) {
  //       let actionData = actionResponse;
  //       error({ actionData });
  //       myContext.handleLoading(false);
  //       setButtonDisable(false);
  //     } else if (
  //       actionResponse.data[Constants.FLAGE] !== null &&
  //       actionResponse.data[Constants.FLAGE] === true
  //     ) {
  //       if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
  //         setLoading(true);
  //         if (modifiedData.length === 1 && paginationModel?.page !== 0) {
  //           effectRun.current = true;
  //           setPaginationModel((prevPaginationMod) => {
  //             return {
  //               page: prevPaginationMod?.page - 1,
  //               pageSize: prevPaginationMod?.pageSize,
  //             };
  //           });
  //         } else {
  //           fetchData();
  //         }
  //         // const ven = loaderResponse?.data?.data?.vendors;
  //         // let vendorsDropDownData;
  //         // if (null != ven && "" != ven) {
  //         //   vendorsDropDownData = ven?.map((vendor) => ({
  //         //     label: vendor.name,
  //         //     value: { name: vendor.name, id: vendor.id },
  //         //   }));
  //         // } else {
  //         //   vendorsDropDownData = [];
  //         // }
  //         // setVendors(vendorsDropDownData);
  //         addToast({
  //           alertStatus: Constants.TOAST_TYPE_SUCESS,
  //           alertTitle: Constants.TRANSFER_ORDER_MODULE,
  //           alertDescription: Constants.PURCHASING_ORDER_DEACTIVE_SUCCESS,
  //         });
  //         myContext.handleLoading(false);
  //         setButtonDisables(false);
  //       } else if (
  //         actionResponse.data.message.trim() === Constants.REACTIVES_SUCCESS
  //       ) {
  //         setLoading(true);
  //         if (
  //           modifiedDeactiveData.length === 1 &&
  //           modifiedDeactiveData?.page !== 0
  //         ) {
  //           effectRun.current = true;
  //           setPaginationModel((prevPaginationMod) => {
  //             return {
  //               page: prevPaginationMod?.page - 1,
  //               pageSize: prevPaginationMod?.pageSize,
  //             };
  //           });
  //         }
  //         // else {
  //         //   fetchDeActivePurchaseOrderData();
  //         // }
  //         addToast({
  //           alertStatus: Constants.TOAST_TYPE_SUCESS,
  //           alertTitle: Constants.TRANSFER_ORDER_MODULE,
  //           alertDescription: Constants.PURCHASING_ORDER_REACTIVE_SUCCESS,
  //         });
  //         myContext.handleLoading(false);
  //         // setButtonDisable(false);
  //         setButtonDisables(false);
  //       } else if (
  //         actionResponse.data?.message ===
  //         "Transfer Order exported successfully."
  //       ) {
  //         const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute(Constants.DOWNLOAD, "TransferOrder.xlsx");
  //         document.body.appendChild(link);
  //         window.stop();
  //         link.click();
  //         window.URL.revokeObjectURL(url);
  //         link.remove();
  //         addToast({
  //           alertStatus: Constants.TOAST_TYPE_SUCESS,
  //           alertTitle: Constants.TRANSFER_ORDER_MODULE,
  //           alertDescription: actionResponse.data?.message,
  //         });
  //         myContext.handleLoading(false);
  //         setButtonDisables(false);
  //       }
  //     }
  //     // else {
  //     //   let actionData = actionResponse;
  //     //   error({ actionData });
  //     //   setTableData([]);
  //     //   setTotalCount(0);
  //     //   setLoading(false);
  //     //   setButtonDisables(false);
  //     // }
  //   }
  // }, [actionResponse]);
  const showDropDown = () => {
    setShowFilter((prevState) => {
      // Toggle the state
      const nextState = !prevState;
      // Conditionally set another state based on the previous state value
      if (
        prevState &&
        (selectedSourceLocations.length > 0 ||
          selectedDestinationLocations.length > 0) ||
          (selectedStatus.length>0)
      ) {
        setPage(0);
        // Set other state based on prevState being false
        setSelectedSourceLocations([]);
        setSelectedDestinationLocations([]);
        setSelectedStatus([]);
        formik.setFieldValue("fromDate", "");
        formik.setFieldValue("toDate", "");
        effectRun.current = true;
      }
      return nextState; // Return the toggled state
    });
    // setShowFilter(prevState => !prevState); // Toggle the state
  };

  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    setPage(0);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      apply();
    }, 250);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    if (effectRunListDropDown.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          if (
            loaderResponse.data.data.source_location === "" ||
            loaderResponse.data.data.source_location === null
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.TRANSFER_ORDER_MODULE,
              alertDescription: Constants.LOCATION_NOT_FOUND,
            });
            props.navigate(Constants.PURCHASING_ORDER_PATH);
          }
          if (
            loaderResponse.data.data.destination_location === "" ||
            loaderResponse.data.data.destination_location === null
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.TRANSFER_ORDER_MODULE,
              alertDescription: Constants.LOCATION_NOT_FOUND,
            });
            props.navigate(Constants.PURCHASING_ORDER_PATH);
          }
          const ven = loaderResponse?.data?.data?.location;
          // const len = loaderResponse?.data?.data?.location;
          let vendorsDropDownData;
          // let locationsDropDownData;
          if (Array.isArray(ven) && ven.length > 0) {
            vendorsDropDownData = ven.map((vendor) => ({
              label: vendor.name,
              value: { name: vendor.name, id: vendor.id },
            }));
            // locationsDropDownData = len.map((location) => ({
            //   label: location.name,
            //   value: { name: location.name, id: location.id },
            // }));
          } else {
            vendorsDropDownData = [];
            // locationsDropDownData = [];
          }
          setLocations(vendorsDropDownData);
          // setlocations(locationsDropDownData);
        }
      }
      return () => {
        effectRunListDropDown.current = false;
      };
    }
  }, [loaderResponse]);

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedVendors", e.value);
  };

  // const handlelocationOptionSelect = (e) => {
  //   formik.setFieldValue("selectedlocations", e.value);
  // };

  const handlePdfReport = async (item) => {
    myContext.handleLoading(true);
    let data = {
      id: item.id,
    };
    try {
      getPdfTransferOrder(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.TRANSFER_ORDER_MODULE,
              alertDescription: response.data.message,
            });
            let url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const handleSearchList = () => {
    // search time get active and deactive list
    // paginationModelDeactive.page = 0;
    props.navigate(Constants.TRANSFER_ORDER_PATH);
    setPage(0);
    // setRowsPerPage(25);
    if (curruntTab == 0) {
      if (null != searchQuery && "" != searchQuery) {
        setSortColumn("");
        effectRun.current = true;
        setSortType("");
      } else {
        setSortColumn("id");
        effectRun.current = true;
        setSortType("desc");
      }
      // fetchData();
    }
    // else {
    //   fetchDeActiveProductData();
    // }
    setLoading(true);
  };

  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.TRANSFER_ORDER_PATH);
  };

  const formik = useFormik({
    initialValues: {
      fromDate:  undefined !== stateData?.state &&
        null !== stateData?.state &&
        "" !== stateData?.state &&
        null != stateData?.state?.fromDate &&
        "" != stateData?.state?.fromDate ? stateData?.state?.fromDate : "",
      toDate: undefined !== stateData?.state &&
        null !== stateData?.state &&
        "" !== stateData?.state &&
        null != stateData?.state?.toDate &&
        "" != stateData?.state?.toDate ? stateData?.state?.toDate : "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      dateFrom: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      dateTo: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        effectRun.current = true;
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        effectRun.current = true;
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      effectRun.current = true;
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };

  const fetchData = async () => {
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      // van_filter: formik.values.selectedVendors,
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      source_locations: selectedSourceLocations,
      selected_status:selectedStatus,
      destination_locations: selectedDestinationLocations,
    };
    if (null !== formik.values.fromDate && "" !== formik.values.fromDate) {
      data.from_date = reportDateFormate(formik.values.fromDate);
    }
    if (null !== formik.values.toDate && "" !== formik.values.toDate) {
      data.to_date = reportDateFormate(formik.values.toDate);
    }
    setLoading(true);
    setShowTable(false);
    try {
      getAllTransferOrder(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setPurchaseOrderData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setPurchaseOrderData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !searchInputHideRef.current &&
            err?.response?.data?.data === 0 &&
            showFilter == false
          ) {
            searchInputHideRef.current = true;
          }
          setPurchaseOrderData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setPurchaseOrderData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const apply = () => {
    effectRun.current = true;
    setLoading(true);
    setButtonDisable(true);
  };

  const handleResetAllData = () => {
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    effectRun.current = true;
    setLoading(true);
    formik.resetForm();
    setButtonDisable(false);
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      setSortModel([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      effectRun.current = true;
      setLoading(true);
    } else {
      searchInputHideRef.current = false;
      setSearchQuery("");
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      effectRun.current = true;
      setLoading(true);
    }
  };

  const handleExportReport = (item) => {
    myContext.handleLoading(true);
    if (item?.id) {
      let data = {
        exportReport: true,
        id: item?.id,
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.PURCHASING_ORDER_PATH,
      });
    }
  };

  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      }
      // else {
      //   fetchDeActivePurchaseOrderData();
      // }
      effectRun.current = false;
    }
  }, [
    searchQuery,
    paginationModel,
    sortModel,
    loading,
    curruntTab,
    paginationModelDeactive,
    sortModelDeactive,
    showFilter,
  ]);

  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "reference_number",
      headerName: "Reference Number",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "source_location",
      headerName: "Source Location",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "destination_location",
      headerName: "Destination Location",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "created_by",
      headerName: "Created By",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "created_on",
      headerName: "Created On",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      ...actionColumn,
      renderCell: (params) => (
        <span
          style={{
            padding: "0.150rem 0.625rem",
            borderRadius: "0.63rem",
            backgroundColor: params.value === "Generated" ? "#FCE6E5" : "#E4FEE2",
            color: params.value === "Generated" ? "#CD1919" : "#0B8C20B5",
            fontWeight:"300",
            justifyContent: "center",
            alignItems: "center",
            width:"2.5rem",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => (
        <>
          {params.row.status == "Generated" ? (
            <IconButton
              style={{
                backgroundColor: "#F4F4F4",
                color: "#010048",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onClick={() => {
                handleEditDetails(params.row);
              }}
            >
              <ModeEditIcon />
            </IconButton>
          ) : (
            <IconButton
              style={{
                backgroundColor: "#F4F4F4",
                color: "#010048",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onClick={() => {
                handleViewDetails(params.row);
              }}
            >
              <RemoveRedEyeIcon />
            </IconButton>
          )}
        </>
      ),
      // renderCell: (params) => {
      //   const rowData = params.row;
      //   const handleEditPage = (item) => {
      //     myContext.handleLoading(true);
      //     let data = {
      //       limit: paginationModel.pageSize,
      //       page: paginationModel.page,
      //       order:
      //         sortModel.length > 0
      //           ? sortModel[0].field
      //           : sortRef.current[0].field,
      //       order_type:
      //         sortModel.length > 0
      //           ? sortModel[0].sort
      //           : sortRef.current[0].sort,
      //       filter: searchQuery,
      //     };
      //     // pass backtraking relate all data
      //     props.navigate(`${Constants.PURCHASING_ORDER_EDIT + item.id}`, {
      //       state: data,
      //     });
      //   };

      //   const handleDeactive = (item) => {
      //     setButtonDisables(true);
      //     if (modifiedData.length == 1 && paginationModel.page != 0) {
      //       paginationModel.page = paginationModel.page - 1;
      //     }
      //     myContext.handleLoading(true);
      //     let data = {
      //       id: item.id,
      //       type: Constants.DEACTIVE,
      //     };
      //     submit(data, {
      //       method: Constants.DELETE,
      //       path: Constants.PURCHASING_ORDER_PATH,
      //     });
      //   };
      //   const options = {
      //     buttonName: rowData.status == 'Generated'? "Edit": "View",
      //     buttonIcon: <CreateOutlinedIcon />,
      //     handleClick: (item) => handleEditPage(item),
      //     subButtons: [
      //       {
      //         name: "View Pdf",
      //         icon: (
      //           <PictureAsPdfIcon style={{ color: Colors.posViewTextColor }} />
      //         ),
      //         onClick: (item) => handlePdfReport(item),
      //       },
      //       {
      //         name: "View Excel",
      //         icon: <FaFileExcel style={{ color: Colors.posViewTextColor }} />,
      //         onClick: (item) => handleExportReport(item),
      //       },
      //       {
      //         name: "Deactive",
      //         disabledButton: buttonDisables,
      //         icon: (
      //           <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
      //         ),
      //         onClick: (item) => handleDeactive(item),
      //       },
      //     ],
      //   };
      //   return (
      //     <PosSpliteButton
      //       spliteOptions={options}
      //       item={rowData}
      //       id={rowData.id}
      //     />
      //   );
      // },
      pinned: true,
    },
  ];
  const handleEditDetails = (row) => {
    myContext.handleLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      showFilter: showFilter,
      fromDate: formik.values.fromDate,
      toDate: formik.values.toDate,
      source_locations:selectedSourceLocations,
      destination_locations:selectedDestinationLocations,
      selectedStatus:selectedStatus
    };
    // pass backtraking relate all data
    props.navigate(`${Constants.TRANSFER_ORDER_EDIT + row.id}`, {
      state: data,
    });
  };
  const handleViewDetails = (row) => {
    myContext.handleLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      showFilter: showFilter,
      fromDate: formik.values.fromDate,
      toDate: formik.values.toDate,
      source_locations:selectedSourceLocations,
      destination_locations:selectedDestinationLocations,
      selectedStatus:selectedStatus
    };
    // pass backtraking relate all data
    props.navigate(`${Constants.TRANSFER_ORDER_EDIT + row.id}`, {
      state: data,
    });
  };
  // const deactiveColumnNames = [
  //   { field: "id", headerName: "Id", sortable: true, width: 90 },
  //   {
  //     field: "vendor_name",
  //     headerName: "Vendor",
  //     sortable: false,
  //     ...actionColumnFlexDouble,
  //   },
  //   {
  //     field: "user_name",
  //     headerName: "User",
  //     sortable: false,
  //     ...actionColumn,
  //   },
  //   {
  //     field: "location_name",
  //     headerName: "Source Location",
  //     sortable: false,
  //     ...actionColumn,
  //   },
  //   {
  //     field: "reactive",
  //     headerName: "Reactive",
  //     disableExport: true,
  //     resizable: false,
  //     filterable: false,
  //     sortable: false,
  //     width: 150,
  //     renderCell: (params) => {
  //       const rowData = params.row;
  //       const handleReactive = () => {
  //         setButtonDisables(true);
  //         if (
  //           modifiedDeactiveData.length == 1 &&
  //           paginationModelDeactive.page != 0
  //         ) {
  //           paginationModelDeactive.page = paginationModelDeactive.page - 1;
  //         }
  //         myContext.handleLoading(true);
  //         let data = {
  //           id: rowData.id,
  //           type: Constants.REACTIVE,
  //         };
  //         submit(data, {
  //           method: Constants.POST,
  //           path: Constants.PURCHASING_ORDER_PATH,
  //         });
  //       };
  //       return (
  //         <Text
  //           onClick={handleReactive}
  //           cursor={buttonDisables ? "not-allowed" : "pointer"}
  //           color={buttonDisables ? Colors.posNavbarLink : Colors.posNavbarLink}
  //           fontSize="1rem"
  //           letterSpacing="-1.5%"
  //           textDecoration={buttonDisables ? "none" : "underline"}
  //           fontWeight={600}
  //           style={{ pointerEvents: buttonDisables ? "none" : "auto" }} // Ensures that click is disabled
  //         >
  //           Activate
  //         </Text>
  //       );
  //     },
  //   },
  // ];

  const modifiedData = purchaseOrderData.map((data) => ({
    id: data.id,
    reference_number: data.reference_number,
    source_location: data.source_location.name,
    destination_location: data.destination_location.name,
    created_by: data.users.name,
    created_on: commonDateFormate(data.created_on + "Z"),
    status:
      data.status === "GEN"
        ? "Generated"
        : "Received"
  }));

  // const modifiedDeactiveData = deactivePurchaseOrderData.map((data) => ({
  //   id: data.id,
  //   vendor_name: data.vendors.name,
  //   user_name: data.users.name,
  //   location_name: data.locations.name,
  // }));
  const clickIfapplySource = (loc) => {
    setSelectedSourceLocations(loc);
    // if (
    //   loc.length == 0 &&
    //   selectedCategories?.length == 0 &&
    //   selectedVendors?.length == 0
    // ) {
    setPage(0);
    apply();
    // }
  };
  const clickIfapplyStatus = (st) => {
    setSelectedStatus(st);
    // if (
    //   loc.length == 0 &&
    //   selectedCategories?.length == 0 &&
    //   selectedVendors?.length == 0
    // ) {
    setPage(0);
    apply();
    // }
  };
  const clickIfapplyDestination = (loc) => {
    setSelectedDestinationLocations(loc);
    // if (
    //   loc.length == 0 &&
    //   selectedCategories?.length == 0 &&
    //   selectedVendors?.length == 0
    // ) {
    setPage(0);
    apply();
    // }
  };
  const clearDropDown = () => {
    setSearchQuery("");
    setLoading(true);
    setSelectedDestinationLocations([]);
    setSelectedSourceLocations([]);
    setSelectedStatus([]);
    effectRun.current = true;
    setApplyButtonClicked(0);
    formik.setFieldValue("fromDate", "");
    formik.setFieldValue("toDate", "");
    // setSortField(null);
    // setSortOrder(null);
    // fetchData();
    props.navigate(Constants.TRANSFER_ORDER_PATH);
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        mb={8}
      >
        <PosListHeader
          header={"Transfer Orders"}
          discription={"Manage and create transfer order on OctoPos"}
        />
        <Spacer />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            display={"flex"}
            width={"8.5rem"}
            height={"2.81rem"}
            padding={"0.63rem"}
            justify-content={"center"}
            align-items={"center"}
            gap={"0.31rem"}
            flex-shrink={"0"}
            onClick={() => {
              myContext.handleLoading(true);
              // backtracking
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                filter: curruntTab == 1 ? "" : searchQuery,
                showFilter: showFilter,
                fromDate: formik.values.fromDate,
                toDate: formik.values.toDate,
                source_locations:selectedSourceLocations,
                destination_locations:selectedDestinationLocations,
                selectedStatus:selectedStatus
              };
              // pass all backtraking ralated all in create modifier set route
              props.navigate(Constants.TRANSFER_ORDER_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Divider h={"0.05rem"} borderColor={Colors.listShadow} />
      <Box
        mt={8}
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <>
              <Flex
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "stretch", md: "center" }}
                gap={{ base: 2, md: 0 }}
                mt={6}
                mb={3}
                ml={4}
                mr={4}
              >
                <PosSearch
                  value={searchQuery}
                  // onChange={(e) => {
                  //   let value =
                  //     searchQuery.length > 0
                  //       ? e.target.value
                  //       : e.target.value.trim();
                  //   setSearchQuery(value);
                  // }}
                  onChange={onChange}
                  onSearch={handleSearchList}
                  handleClear={handleClear}
                />

                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  w={"3rem"}
                  ml={{ base: 0, md: "1rem" }}
                  mt={{ base: "1rem", md: 0 }}
                >
                  <IconButton
                    onClick={() => {
                      showDropDown();
                    }}
                    // isRound={false}
                    style={{
                      border: "1px solid #ccc",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                      variant: "posoutline",
                      color: Colors.posTextInfo,
                      borderColor: Colors.dividerColor,
                      borderRadius: "5px",
                      padding: 5,
                    }}
                  >
                    <FilterListIcon style={{ color: Colors.posTextInfo }} />
                  </IconButton>
                </Box>
                {/* <Spacer /> */}
              </Flex>
              {showFilter ? (
            <SimpleGrid
              columns={{ base: 2, sm: 2, md: 3, lg: 3, xl: 6 }}
              // minChildWidth='150px'
              // spacing='20px'
              ml={4}
              mr={4}
              spacing={{
                base: "0.5rem",
                sm: "0.5rem",
                md: "0.5rem",
                lg: "0.5rem",
                xl: "0.5rem",
              }}
            >
              <Box mt="1rem">
                <PosDropDown
                  options={locations}
                  value={selectedSourceLocations}
                  onChange={(e) => {
                    clickIfapplySource(e.value);
                  }}
                  multiSelect={true}
                  optionLabel="label"
                  placeholder="Source Locations"
                  lableAvailable={true}
                  width={"100%"}
                  height={"2.5rem"}
                  className="w-full md:w-20rem"
                  maxSelectedLabels={2}
                />
              </Box>
              {locations?.length > 1 && (
                <Box mt="1rem">
                  <PosDropDown
                    options={locations}
                    value={selectedDestinationLocations}
                    onChange={(e) => {
                      clickIfapplyDestination(e.value);
                    }}
                    multiSelect={true}
                    optionLabel="label"
                    placeholder="Destination Locations"
                    lableAvailable={true}
                    width={"100%"}
                    height={"2.5rem"}
                    className="w-full md:w-20rem"
                    // display={"chip"}
                    maxSelectedLabels={2}
                  />
                </Box>
              )}
              <Box mt="1rem">
                <PosDropDown
                  options={Constants.TRANSFER_ORDER_STATUS_OPTIONS}
                  value={selectedStatus}
                  onChange={(e) => {
                    clickIfapplyStatus(e.value);
                  }}
                  multiSelect={true}
                  optionLabel="name"
                  placeholder="Status"
                  lableAvailable={true}
                  width={"100%"}
                  height={"2.5rem"}
                  className="w-full md:w-20rem"
                  maxSelectedLabels={2}
                />
              </Box>
              <Box mt="1rem">
                <PosDatePicker
                  id={"fromDate"}
                  ref={calendarRef}
                  readOnlyInput={true}
                  value={formik.values.fromDate}
                  label={"From Date"}
                  width={"100%"}
                  onFocus={() => {
                    if (
                      null !== toDateCalendarRef.current &&
                      toDateCalendarRef.current
                    ) {
                      toDateCalendarRef.current.hide();
                    }
                    setToDateFocus(false);
                    setTimeout(() => {
                      setFromDateFocus(true);
                    }, 1000);
                    formik.setFieldTouched("fromDate", true);
                  }}
                  handleSelectData={hadleFromDateSelect}
                  yearRange={"2000:2030"}
                  maxDate={dateTimeValue}
                  viewDate={fromDateView}
                  showButtonBar={true}
                  showTime={true}
                />
              </Box>
              <Box mt="1rem">
                <PosDatePicker
                  id={"toDate"}
                  ref={toDateCalendarRef}
                  readOnlyInput={true}
                  value={formik.values.toDate}
                  label={"To Date"}
                  width={"100%"}
                  onFocus={() => {
                    if (
                      null !== calendarRef.current &&
                      calendarRef.current
                    ) {
                      calendarRef.current.hide();
                    }
                    setFromDateFocus(false);
                    setTimeout(() => {
                      setToDateFocus(true);
                    }, 1000);
                    formik.setFieldTouched("toDate", true);
                  }}
                  handleSelectData={(e) => {
                    effectRun.current = setPaginationModel({
                      page: 0,
                      pageSize: 25,
                    });
                    formik.setFieldValue("toDate", e.value);
                    setToDateView(e.value);
                    effectRun.current = true;
                  }}
                  yearRange={"2000:2030"}
                  minDate={
                    null !== formik.values.fromDate &&
                    "" !== formik.values.fromDate
                      ? formik.values.fromDate
                      : undefined
                  }
                  maxDate={dateTimeValue}
                  viewDate={toDateView}
                  showButtonBar={true}
                  showTime={true}
                />
              </Box>
              <Box alignContent={"end"} mt={{ base: 2, md: 0 }}>
                <ButtonGroup>
                  <PosFormButton
                    isDisabled={
                      searchQuery.length > 0 ||
                      selectedSourceLocations.length > 0 ||
                      selectedStatus.length > 0 ||
                      selectedDestinationLocations.length > 0 ||
                      ((formik.values.fromDate != "" && formik.values.fromDate != null) ||
                      (formik.values.toDate != "" && formik.values.toDate != null) &&
                      applyButtonClicked == 1)
                      ? false
                      : true
                    }
                    onClick={() => {
                      clearDropDown();
                    }}
                    buttonText={"Clear"}
                    CancelButton={true}
                  />
                </ButtonGroup>
              </Box>
            </SimpleGrid>
          ) : null}
              {/* </Flex> */}
            </>
          ) : // : curruntTab === 1 && !deactiveSearchHideRef.current ? (
          //   <Flex
          //     direction={{ base: "column", md: "row" }}
          //     alignItems={{ base: "stretch", md: "center" }}
          //     gap={{ base: 2, md: 0 }}
          //     mt={6}
          //     mb={3}
          //     ml={4}
          //     mr={4}
          //   >
          //     <PosSearch
          //       value={searchQuery}
          //       onChange={(e) => {
          //         let value =
          //           searchQuery.length > 0
          //             ? e.target.value
          //             : e.target.value.trim();
          //         setSearchQuery(value);
          //       }}
          //       onSearch={handleSearchList}
          //       handleClear={handleClear} // clear input value and get all data
          //     />
          //     <Spacer />
          //   </Flex>
          // ) :
          null}
          <TabPanels>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <PosDataGridTable
                  columns={columnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    effectRun.current = true;
                    setSortModel(newSortModel);
                  }}
                  fileName={Constants.ACTIVE_PURCHASE_ORDER_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound title={Constants.TRANSFER_ORDER_NOT_FOUND} />
              )}
            </TabPanel>
            {/* <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalDeactivatecount > 0 && curruntTab === 1 ? (
                <PosDataGridTable
                  columns={deactiveColumnNames}
                  rows={modifiedDeactiveData}
                  totalcount={totalDeactivatecount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  // sortModel={sortModel}
                  // onSortModelChange={(newSortModelDeactive) => {
                  //   sortRefDeactive.current = sortModelDeactive;
                  //   effectRun.current = true;
                  //   setSortModel(newSortModelDeactive);
                  // }}
                  fileName={Constants.DEACTIVE_PURCHASE_ORDER_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound
                  title={Constants.DEACTIVE_TRANSFER_ORDER_NOT_FOUND}
                />
              )}
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(TransferOrderList);
