import { Button } from "@chakra-ui/react";
import React from "react";
import Colors from "../../assets/colors/Colors";

const PosOutlineButton = (props) => {
  return (
    <Button
      variant="posoutline"
      color={Colors.posTextInfo}
      borderColor={Colors.dividerColor}
      onClick={props.onClick}
      fontSize={"0.94rem"}
      fontWeight={props.weight ? props.weight : 300 }
      letterSpacing={"-0.225px"}
      width={props.width}
      isDisabled={props.disabled}
    >
      {props.name}
    </Button>
  );
};

export default PosOutlineButton;
