import {
  Box,
  Flex,
  HStack,
  Image,
  Spacer,
  useMediaQuery,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Colors from "../../assets/colors/Colors";
import logo from "../../assets/images/newcropped.png";
import { CgDetailsMore } from "react-icons/cg";
import {
  AppContext,
  MasterAdminSideBar,
  PosFormButton,
  PosMainProgress,
} from "../../components/index";

const MasterAdminLayout = (props) => {
  const myContext = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("master-token");
    localStorage.removeItem("ttl");
    navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE);
  };

  const handleClick = (open) => {
    setOpen(!open);
  };
  return (
    <div>
      <Flex
        bg={Colors.loginAuthBackground}
        minHeight={"100vh"}
        flexDirection={"column"}
      >
        <Box
          w={"100%"}
          sx={{
            position: "-webkit-sticky",
            /* Safari */ position: "sticky",
            top: "0",
            zIndex: 999,
          }}
        >
          <Box
            w={"100%"}
            sx={{
              /* Safari */ position: "absolute",
              top: "0",
              zIndex: 99999999,
            }}
          >
            {myContext.loading ? <PosMainProgress /> : null}
          </Box>
          <Flex bg={Colors.headerColor}>
            <HStack
              h={{ base: "3.75rem", md: "4.69rem" }}
              pl={{ base: 4, md: 8 }}
            >
              <Image
                style={{ width: "7.81rem" }}
                src={logo}
                alt="sanepos"
                onClick={() => handleClick(open)}
              />
            </HStack>
            <Spacer />
            <HStack
              h={{ base: "3.75rem", md: "4.75rem" }}
              pr={{ base: 4, md: 8 }}
            >
              <Box zIndex={999} borderRadius={4}>
                <HStack gap={3}>
                  {isMobile ? (
                    <Icon
                      as={CgDetailsMore}
                      boxSize={"2.375rem"}
                      onClick={() => handleClick(open)}
                    />
                  ) : null}
                  <PosFormButton
                    buttonsubmit={"Logout"}
                    SubmitButton={true}
                    onClick={handleLogout}
                  />
                </HStack>
              </Box>
            </HStack>
          </Flex>
        </Box>
        <Flex>
          <MasterAdminSideBar isOpen={open} onClose={() => handleClick(open)} />
          <VStack
            ml={isMobile ? 0 : "16.45rem"}
            justifyContent={"space-between"}
            className={isMobile ? "mobileMainLayout" : "mainLayout"}
            minH={"calc(100vh - 76px)"}
          >
            <Box w={"100%"}>
              <Outlet />
            </Box>
            <HStack
              w={"100%"}
              mb={4}
              justifyContent={"flex-end"}
              alignContent={"flex-end"}
              pr={4}
              pb={4}
            >
              <Text className="blinking">
                {
                  "Note:- If you are inactive for 15 minutes, your session will be expired."
                }
              </Text>
            </HStack>
          </VStack>
        </Flex>
      </Flex>
    </div>
  );
};

export default MasterAdminLayout;
