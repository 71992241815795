import { PhoneIcon } from "@chakra-ui/icons";
import { Box, Flex, Spacer, Stack, Text, Select } from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDropDown,
  PosFormButton,
  PosImageCropper,
  PosInput,
  PosLable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getStateListForSelectedCountry } from "./MasterAdminLocationService";

const MasterAdminLocationCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [timezones, setTimezone] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedLocationType, setSelectedLocationType] = useState("");
  const [imageUploadedFlag, setImageUploadedFlag] = useState(0);
  const [base64OfUploadedImg, setBase64OfUploadedImg] = useState("");
  const [croppedImgDetails, setCroppedImgDetails] = useState({
    filename: "",
    filetype: "",
    filesize: "",
    base64: "",
  });
  const [kdsOptions, setKdsOptions] = useState([]);
  const [selectedKDS, handleSelectedKDS] = useState("");
  // const [selectedOnline, setSelectedOnline] = useState("");

  useEffect(() => {
  if (effectRun.current === true) {
    if (
      undefined != loaderResponse &&
      null != loaderResponse &&
      {} != loaderResponse
    ) {
      if (
        undefined != loaderResponse?.error &&
        null != loaderResponse?.error &&
        loaderResponse?.error
      ) {
        let actionData = loaderResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        null !== loaderResponse.data[Constants.FLAGE] &&
        true === loaderResponse.data[Constants.FLAGE]
      ) {
        myContext.handleLoading(false);
        const arrayTimezone = Object.keys(
          loaderResponse.data.data.timezoneList
        ).map((key) => ({
          label: loaderResponse.data.data.timezoneList[key],
          value: loaderResponse.data.data.timezoneList[key],
        }));
        setTimezone(arrayTimezone);
        setLocations(loaderResponse.data.data?.locations);
        const arrayCoutries = Object.keys(
          loaderResponse.data.data.countries
        ).map((key) => ({
          label: loaderResponse.data.data.countries[key],
          value: key,
        }));
        setCountry(arrayCoutries);
        const arrayKDS = loaderResponse.data.data.getKdsSetting.map((dt) => ({
          label: dt.value,
          value: dt.key,
        }));
        setKdsOptions(arrayKDS);
        // if (
        //   loaderResponse?.data?.data?.location?.country &&
        //   null != loaderResponse?.data?.data?.location?.country
        // ) {
        //   setSelectedCountry(loaderResponse?.data?.data?.location?.country);
        //   handleSelectedCountry(
        //     loaderResponse?.data?.data?.location?.country
        //   );
        // }

        // if (
        //   loaderResponse?.data?.data?.location?.state &&
        //   null != loaderResponse?.data?.data?.location?.state &&
        //   "N.A." != loaderResponse?.data?.data?.location?.state
        // ) {
        //   setStatesAvailability(0);
        //   setSelectedState(loaderResponse?.data?.data?.location?.state);
        // }
        // if (
        //   loaderResponse?.data?.data?.location?.time_zone &&
        //   null != loaderResponse?.data?.data?.location?.time_zone &&
        //   "N.A." != loaderResponse?.data?.data?.location?.time_zone
        // ) {
        //   setSelectedTimezone(
        //     loaderResponse?.data?.data?.location?.time_zone
        //   );
        // }

        setSelectedLocationType(
          loaderResponse?.data?.data?.locationSettings?.value
        );
        const arrayLocationType = loaderResponse.data.data.getLocType.map(
          (dt) => ({
            label: dt.value,
            value: dt.key,
          })
        );
        setLocationTypes(arrayLocationType);
      }
    }
    // return () => {
    effectRun.current = false;
    };
  },[loaderResponse]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      myContext.handleLoading(false);
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        myContext.handleLoading(false);
        let actionData = actionResponse;
        error({ actionData });
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        myContext.handleLoading(false);
        props.navigate(
          "/" +
            process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
            Constants.MASTER_ADMIN_LOCATIONS_PATH
        );
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.LOCATION_MODULE,
          alertDescription: actionResponse.data.message,
        });
      }
    }
  }, [actionResponse]);

  const handleCallback = (base64Details, fileDetails) => {
    if (
      null != base64Details &&
      "" != base64Details &&
      null != fileDetails &&
      "" != fileDetails
    ) {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: fileDetails["name"],
          filetype: fileDetails["type"],
          filesize: fileDetails["size"],
          base64: base64Details.toDataURL().split(",")[1],
        };
      });
      setBase64OfUploadedImg(base64Details.toDataURL());
      setImageUploadedFlag(1);
    } else {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: "",
          filetype: "",
          filesize: "",
          base64: "",
        };
      });
      setBase64OfUploadedImg("");
      setImageUploadedFlag(0);
    }
  };

  // const handleSelectedCountry = (value) => {
  //   myContext.handleLoading(true);
  //   setSelectedCountry(value);
  //   getStateListForSelectedCountry(value)
  //     .then((response) => {
  //       if (
  //         response.data[Constants.FLAGE] !== null &&
  //         response.data[Constants.FLAGE] === true
  //       ) {
  //         if (undefined != response.data.data.states) {
  //           const arrayStates = Object.keys(response.data.data.states).map(
  //             (key) => ({
  //               label: response.data.data.states[key],
  //               value: key,
  //             })
  //           );
  //           setStates(arrayStates);
  //           setStatesAvailability(0);
  //           setSelectedState("");
  //           myContext.handleLoading(false);
  //           // if (
  //           //   loaderResponse?.data?.data?.vendor?.state &&
  //           //   null != loaderResponse?.data?.data?.vendor?.state &&
  //           //   "N.A." != loaderResponse?.data?.data?.vendor?.state
  //           // ) {
  //           //   setStatesAvailability(0);
  //           //   setSelectedState(loaderResponse?.data?.data?.vendor?.state);
  //           // } else {
  //           //   setSelectedState("N.A.");
  //           // }
  //         } else {
  //           setStates([]);
  //           setStatesAvailability(1);
  //           setSelectedState("N.A.");
  //           myContext.handleLoading(false);
  //         }
  //       } else {
  //         myContext.handleLoading(false);
  //         if (response.data[Constants.CODE] === Constants.REQUEST_DENIED_CODE) {
  //           addToast({
  //             alertStatus: Constants.TOAST_TYPE_WARNING,
  //             alertTitle: Constants.TOKEN_EXPIRTED,
  //             alertDescription: response.data.message,
  //           });
  //           props.navigate(Constants.LOGOUT_PAGE);
  //         } else if (
  //           response.data[Constants.CODE] === Constants.UNAUTHORIZE_CODE
  //         ) {
  //           props.navigate(Constants.UNAUTHORIZE_PAGE);
  //         } else if (response.data.validationCheck) {
  //           addToast({
  //             alertStatus: Constants.TOAST_TYPE_WARNING,
  //             alertTitle: Constants.FIELD_IS_REQUIRED,
  //             alertDescription: response.data.message,
  //           });
  //         } else {
  //           addToast({
  //             alertStatus: Constants.TOAST_TYPE_WARNING,
  //             alertTitle: Constants.FIELD_IS_REQUIRED,
  //             alertDescription: response.data.message,
  //           });
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       let actionData = error;
  //       error({ actionData });
  //       myContext.handleLoading(false);
  //       console.error("Error fetching user data:", error);
  //     });
  // };

  const handleSelectedCountry = (value) => {
    try {
      setSelectedCountry(value);
      setStates([]);
      setSelectedState(null);
      myContext.handleLoading(true);
      getStateListForSelectedCountry(value)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const statesData = response.data.data.states;
            const arrayStates = Object.keys(statesData).map((key) => ({
              label: statesData[key],
              value: key,
            }));
            setStates(arrayStates);
          } else {
            setStates([]);
            setSelectedState("N.A.");
            setStatesAvailability(1);
          }
          myContext.handleLoading(false);
        })
        .catch((err) => {
          setStates([]);
          setSelectedState("N.A.");
          setStatesAvailability(1);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setStates([]);
      setSelectedState("N.A.");
      setStatesAvailability(1);
      let actionData = error;
      error({ actionData });
      myContext.handleLoading(false);
    }
  };

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      timzone: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      phone: "",
      location_type: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.LOCATION_NAME_REQUIRED)
        .max(254, Constants.LOCATION_NAME_LESS_THEN_OR_EQUAL_255_CHARACTER),
      address: Yup.string()
        .trim()
        .required(Constants.LOCATION_ADDRESS_REQUIRED)
        .max(
          5000,
          Constants.LOCATION_ADDRESS_LESS_THEN_OR_EQUAL_5000_CHARACTER
        ),
      zipcode: Yup.number()
        .required(Constants.LOCATION_ZIP_REQUIRED)
        .integer(Constants.LOCATION_ZIP_VALID)
        .typeError(Constants.LOCATION_ZIP_VALID)
        .min(9, Constants.LOCATION_ZIP_MUST_BE_2_DIGITS)
        .max(9999999999, Constants.LOCATION_ZIP_MUST_BE_10_DIGITS),
      city: Yup.string()
        .trim()
        .required(Constants.LOCATION_CITY_REQUIRED)
        .max(254, Constants.LOCATION_CITY_LESS_THEN_OR_EQUAL_191_CHARACTER),
      phone: Yup.number()
        .required(Constants.PHONE_REQUIRED)
        .integer(Constants.LOCATION_PHONE_NOT_VALID)
        .typeError(Constants.LOCATION_PHONE_NOT_VALID)
        .min(10000, Constants.LOCATION_PHONE_MUST_BE_5_DIGITS)
        .max(99999999999999, Constants.LOCATION_PHONE_LESS_THAN_15_DIGITS),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        country: selectedCountry,
        state: selectedState,
        stateNotAvailable: stateAvailable,
        timezone: selectedTimezone,
        location_type: selectedLocationType,
        kds: selectedKDS,
        // online_order:selectedOnline,
      };
      if (imageUploadedFlag == 1) {
        var imageDetails = {
          locationpic: croppedImgDetails,
        };
        payload.locationImage = JSON.stringify(imageDetails);
      }
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.LOCATION_CREATE_API_ROUTE,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.name.length <= 0 ||
      formik.errors.name ||
      formik.errors.address ||
      formik.errors.city ||
      formik.errors.zipcode ||
      formik.errors.phone ||
      selectedCountry == null ||
      selectedCountry == "" ||
      selectedTimezone == "" ||
      selectedTimezone == null ||
      selectedState == "" ||
      selectedState == null ||
      selectedLocationType == "" ||
      selectedLocationType == null ||
      selectedKDS == null ||
      selectedKDS == "" ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" onKeyDown={handleKeyDown}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(
                "/" +
                  process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
                  Constants.MASTER_ADMIN_LOCATIONS_PATH
              );
            }}
            breadCrumNames={["Location", "Create Location"]}
            breadCrumTitle={"Create Location"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(
                  "/" +
                    process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
                    Constants.MASTER_ADMIN_LOCATIONS_PATH
                );
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={
                formik.values.name.length <= 0 ||
                formik.errors.name ||
                formik.errors.address ||
                formik.errors.city ||
                formik.errors.zipcode ||
                formik.errors.phone ||
                selectedCountry == null ||
                selectedCountry == "" ||
                selectedTimezone == "" ||
                selectedTimezone == null ||
                selectedState == "" ||
                selectedState == null ||
                selectedLocationType == "" ||
                selectedLocationType == null ||
                selectedKDS == null ||
                selectedKDS == "" ||
                // selectedOnline == ''|| selectedOnline==null ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Text
            // ml={6}
            fontSize="1.125rem"
            color={Colors.taxShow}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Store Details
          </Text>
          <Stack spacing={1}>
            <PosLable
              fontWeight={500}
              label={true}
              name={"Upload Location Logo"}
            />
            <PosImageCropper
              parentCallback={handleCallback}
              canvasWidth={236}
              canvasHeight={236}
              cropperMinHeight={115}
              cropperWidth={136}
              cropperHeight={115}
              cropperMaxWidth={236}
              cropperMaxHeight={236}
            />
            <PosLable
              fontWeight={500}
              requiredLabel={true}
              name={"Location Name"}
              mt="0.31rem"
            />
            <PosInput
              posInputGroup={true}
              id={"name"}
              inputType={"text"}
              placeholder={"Enter Location Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.name}
              handleBlur={formik.handleBlur}
              inputError={formik.touched.name && formik.errors.name}
            >
              <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
            spacing={1}
          >
            <Box w={{ base: "100%", md: "45%" }}>
              <PosLable
                name={"Address"}
                label={true}
                fontWeight={500}
                requiredLabel={true}
              />
              {/* <Text>
                Address
                <Text as="span" color={Colors.errorColor}>
                  *
                </Text>
              </Text> */}
              <PosInput
                posInputGroup={true}
                id={"address"}
                inputType={"text"}
                placeholder={"Enter Address"}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.address}
                handleBlur={formik.handleBlur}
                inputError={formik.touched.address && formik.errors.address}
              >
                <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
              </PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.address && formik.errors.address ? (
                  <span>{formik.errors.address}</span>
                ) : null}
              </Text>
              <PosLable
                label={true}
                name={"Country"}
                requiredLabel={true}
                fontWeight={500}
              ></PosLable>
              <PosDropDown
                width={"100%"}
                lableAvailable={true}
                id="country"
                value={selectedCountry}
                // filter
                onChange={(e) => {
                  if(undefined != e.value && null != e.value && '' != e.value){
                  handleSelectedCountry(e.target.value);
                  }
                }}
                // onBlur={() => formik.handleBlur("selectedLocation")}
                options={countries}
                optionLabel={"label"}
                className="w-full md:w-20rem"
                style={{ width: "100%" }}
                placeholder={"Select Country"}
              ></PosDropDown>
              {/* <Text
                    color="red"
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null}
                  </Text> */}
              <PosLable
                name={"City"}
                label={true}
                requiredLabel={true}
                fontWeight={500}
              ></PosLable>
              <PosInput
                posInputGroup={true}
                placeholder={"Enter City"}
                id={"city"}
                inputType={"password"}
                inputValue={formik.values.city}
                handleBlur={formik.handleBlur}
                handleInputChange={formik.handleChange}
                inputError={formik.touched.city && formik.errors.city}
              >
                <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
              </PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.city && formik.errors.city ? (
                  <span>{formik.errors.city}</span>
                ) : null}
              </Text>
              <Text
                color="rgba(1, 0, 72, 0.2)"
                fontWeight="300"
                fontSize="0.75rem"
                lineHeight="1.25rem"
                letterSpacing="-0.09px"
              ></Text>
              <PosLable
                name={"Phone Number"}
                label={true}
                requiredLabel={true}
                fontWeight={500}
              ></PosLable>
              <PosInput
                posInputGroup={true}
                // posNumberInput={true}
                inputType={"number"}
                id={"phone"}
                placeholder="5555551234"
                inputValue={formik.values.phone}
                handleBlur={formik.handleBlur}
                handleInputChange={formik.handleChange}
                inputError={formik.touched.phone && formik.errors.phone}
              >
                <PhoneIcon
                  color={Colors.posNavbarLink}
                  style={{ fill: "#5881FE" }}
                />
              </PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.phone && formik.errors.phone ? (
                  <span>{formik.errors.phone}</span>
                ) : null}
              </Text>
              <PosLable
                label={true}
                name={"KDS"}
                requiredLabel={true}
                fontWeight={500}
              ></PosLable>
              <PosDropDown
                id="kds"
                width={"100%"}
                lableAvailable={true}
                value={selectedKDS}
                // filter
                onChange={(e) => {
                  if(undefined != e.value && null != e.value && '' != e.value){
                  handleSelectedKDS(e.target.value);
                  }
                }}
                // onBlur={() => formik.handleBlur("selectedLocation")}
                options={kdsOptions}
                optionLabel={"label"}
                className="w-full md:w-20rem"
                style={{ width: "100%" }}
                placeholder={"Select KDS"}
              ></PosDropDown>
            </Box>
            <Box w={{ base: "100%", md: "50%" }}>
              <PosLable
                name={"Timezone"}
                label={true}
                requiredLabel={true}
                fontWeight={500}
              />
              {/* <Text>
                Timezone
                <Text as="span" color={Colors.errorColor}>
                  *
                </Text>
              </Text> */}
              <PosDropDown
                value={selectedTimezone}
                lableAvailable={true}
                width={"100%"}
                // filter
                onChange={(e) => {
                  // formik.setFieldValue("selectedLocations", e.value);
                  if(undefined != e.value && null != e.value && '' != e.value){
                    setSelectedTimezone(e.value);
                  }
                }}
                // onBlur={() => formik.handleBlur("selectedLocation")}
                options={timezones}
                optionLabel={"label"}
                className="w-full md:w-20rem"
                style={{ width: "100%" }}
                placeholder={"Select Timezone"}
              ></PosDropDown>
              <PosLable
                name={"State"}
                label={true}
                requiredLabel={true}
                fontWeight={500}
              ></PosLable>
              {/* <PosDropDown
                width={"100%"}
                lableAvailable={true}
                value={selectedState}
                // filter
                onChange={(e) => {
                  // formik.setFieldValue("selectedLocations", e.value);
                  if(undefined != e.value && null != e.value && '' != e.value){
                  setSelectedState(e.value);
                  }
                }}
                // onBlur={() => formik.handleBlur("selectedLocation")}
                options={states}
                optionLabel={"label"}
                className="w-full md:w-20rem"
                style={{ width: "100%" }}
                placeholder={"Select State"}
              ></PosDropDown> */}
              {states.length === 0 ? (
              <Select
                id="state"
                value={selectedState || "N.A."}
                sx={{
                  color: "gray",
                  fontSize: "0.94rem !important",
                  fontWeight: 300,
                  letterSpacing: "-0.01rem !important",
                  backgroundColor: "#5881fe0d",
                  borderRadius: "0.625rem",
                  height: "2.5rem",
                  border: "1px solid #b7b7b71f",
                  "&[disabled]": {
                    backgroundColor: "#5881fe0d !important",
                    opacity: 1,
                  },
                }}
                // onChange={(e) => {
                //   handleSelectedState(e.target.value);
                //   formik.setFieldValue("selectedState", e.target.value);
                // }}
                isDisabled
                placeholder={states.length <= 0 ? "N.A." : "Select State"}
              >
                <option value="N.A.">N.A.</option>
              </Select>
              ) : (
              <PosDropDown
                width={"100%"}
                lableAvailable={true}
                value={selectedState}
                // filter
                onChange={(e) => {
                  // formik.setFieldValue("selectedLocations", e.value);
                  if(undefined != e.value && null != e.value && '' != e.value){
                  setSelectedState(e.value);
                  }
                }}
                // onBlur={() => formik.handleBlur("selectedLocation")}
                options={states}
                optionLabel={"label"}
                className="w-full md:w-20rem"
                style={{ width: "100%" }}
                placeholder={"Select State"}
              ></PosDropDown>
              )}
              {/* <Text
                    color="red"
                    fontWeight="300"
                    fontSize="0.75rem"
                    lineHeight="1.25rem"
                    letterSpacing="-0.09px"
                  >
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null}
                  </Text> */}
              <PosLable
                name={"Zipcode"}
                label={true}
                requiredLabel={true}
                fontWeight={500}
              ></PosLable>
              <PosInput
                posInputGroup={true}
                id={"zipcode"}
                posNumberInput={true}
                inputType={"number"}
                placeholder={"Enter Zipcode"}
                inputValue={formik.values.zipcode}
                handleBlur={formik.handleBlur}
                handleInputChange={formik.handleChange}
                inputError={formik.touched.zipcode && formik.errors.zipcode}
              >
                <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
              </PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.zipcode && formik.errors.zipcode ? (
                  <span>{formik.errors.zipcode}</span>
                ) : null}
              </Text>
              <PosLable
                name={"Location Type"}
                label={true}
                requiredLabel={true}
                fontWeight={500}
              ></PosLable>
              <PosDropDown
                width={"100%"}
                value={selectedLocationType}
                // filter
                onChange={(e) => {
                  // formik.setFieldValue("selectedLocations", e.value);
                  if(undefined != e.value && null != e.value && '' != e.value){
                  setSelectedLocationType(e.value);
                  }
                }}
                // onBlur={() => formik.handleBlur("selectedLocation")}
                options={locationTypes}
                optionLabel={"label"}
                lableAvailable={true}
                className="w-full md:w-20rem"
                style={{ width: "100%" }}
                placeholder={"Select Location Type"}
              ></PosDropDown>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(MasterAdminLocationCreate);
