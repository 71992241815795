import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosOutlineButton,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  createAccountSettings,
  getAllLocationsAdditionalSettingData,
} from "./MasterAdminLocationService";

const MasterAdminLocationReward = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const [setSettings, setLocationAdditionalSettings] = useState([]);
  const [rewardLink, setRewardLink] = useState("");
  const [rewardSms, setRewardSms] = useState();
  const [rewardVerification, setRewardVerification] = useState();
  const { id } = useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const cancelRef = React.useRef();
  const [modifiers, setModifiers] = useState([]);
  const [fieldTypes, setRewardFieldTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [viewFlag, setViewFlag] = useState(false);

  const {
    isOpen: isRewardModalOpen,
    onOpen: onRewardModalOpen,
    onClose: onRewardModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (props.isRewardOpen) {
      myContext.handleLoading(true);
      fetchData(id);
    }
    return () => {};
  }, [props.isRewardOpen]);

  const findAndReturnValue = (array, criteria, valueToReturn) => {
    const foundObject = array.find((item) => item.key == criteria);
    if (foundObject) {
      return foundObject[valueToReturn];
    } else {
      return null;
    }
  };

  const fetchData = async (id) => {
    try {
      getAllLocationsAdditionalSettingData(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setRewardLink(
              window.location.origin + Constants.LOCATION_CREATE_REWARD
            );
            setRewardFieldTypes(Constants.REWARD_FEILD_TYPES);

            response.data?.data?.locationSettings.map((dt) => {
              if (dt.key == "reward_sms" || dt.key == "reward_verification") {
                if (dt.value == "yes") {
                  dt.value = true;
                } else {
                  dt.value = false;
                }
              }
            });

            setModifiers(response.data?.data?.locRewardCardCust);
            setLocationAdditionalSettings(
              response.data?.data?.locationSettings
            );

            setRewardVerification(
              findAndReturnValue(
                response.data?.data?.locationSettings,
                "reward_verification",
                "value"
              )
            );
            setRewardSms(
              findAndReturnValue(
                response.data?.data?.locationSettings,
                "reward_sms",
                "value"
              )
            );
            myContext.handleLoading(false);
            setViewFlag(true);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !buttonDisable) {
      formik.handleSubmit();
    }
  };
  const formik = useFormik({
    initialValues: {
      // reward_sms: findAndReturnValue(setSettings, "reward_sms", "value"),
      // reward_verification: findAndReturnValue(
      //   setSettings,
      //   "reward_verification",
      //   "value"
      // ),
      field_name: "",
      field_type: "",
      field_required: false,
      field_display: false,
      modalOpen: isRewardModalOpen,
    },
    validationSchema: Yup.object({
      modalOpen: Yup.boolean(),
      field_name: Yup.string().trim().when("modalOpen", {
        is: true,
        then: (schema) =>
          schema
            .required(Constants.FIELD_NAME_REQUIRED)
            .max(191, Constants.FIELD_NAME_LENGTH_GREATER_THAN_191),
        otherwise: (schema) => schema.optional(),
      }),
      field_type: Yup.string().when("modalOpen", {
        is: true,
        then: (schema) => schema.required(Constants.FIELD_TYPE_REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      let payload = {
        ...values,
        location_id: id,
        reward_sms: rewardSms,
        reward_verification: rewardVerification,
        reward_setting: true,
        showCustomFields: modifiers.length > 0 ? modifiers : [],
      };
      setButtonDisable(true);
      myContext.handleLoading(true);
      createAccountSettings(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err?.response?.data;
          error({ actionData });
        });
    },
  });
  const handleClose = () => {
    formik.resetForm();
    setViewFlag(false);
    onRewardModalClose();
    if (props.onRewardClose) {
      props.onRewardClose();
    }
  };

  const openDialog = (index) => {
    setIsDialogOpen(true);
    if (modifiers && modifiers.length > 0) {
      formik.values.field_name = "";
      formik.values.field_type = "";
      formik.values.field_required = false;
      formik.values.field_display = false;
    }
    onRewardModalOpen();
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    onRewardModalClose();
  };

  const handleSaveData = () => {
    const FieldFound = modifiers.find(
      (item) =>
        item.field_name.trim().toLowerCase() ==
        formik.values.field_name.trim().toLowerCase()
    );
    if (undefined != FieldFound && null != FieldFound && "" != FieldFound) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
        alertDescription: formik.values.field_name + " already exist.",
      });
    } else {
      let updatedModifiers = [];
      updatedModifiers = [
        ...modifiers,
        {
          field_name: formik.values.field_name.trim(),
          field_type: formik.values.field_type,
          field_required: formik.values.field_required,
          field_display: formik.values.field_display,
        },
      ];
      setModifiers(updatedModifiers);
      setIsDialogOpen(false);
      onRewardModalClose();
    }
  };

  const handleDeleteData = (index) => {
    const updatedModifiers = modifiers.filter((_, i) => i !== index);
    formik.values.field_name = "";
    formik.values.field_type = "";
    formik.values.field_required = false;
    formik.values.field_display = false;
    setModifiers(updatedModifiers);
  };
  const handleCopyClick = () => {
    const textArea = document.createElement('textarea');
    textArea.value = rewardLink;
    textArea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
          alertDescription: "Reward customer create link copied successfully.",
        });
    } catch (err) {
      console.log('Failed to copy text: ', err);
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
          alertDescription: 'Failed to copy text to clipboard.',
        });
    }
  };
  return (
    <PosDrawer
      visible={viewFlag}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box padding={2}>
        {/* <Card
          sx={{
            boxShadow: "none",
          }}
        > */}
        {/* <CardHeader alignItems={{ base: "flex-start" }} mt={10}> */}
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
        >
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={{ base: 5, md: 0 }}>
              <PosBreadCrumb
                handleClick={(i) => {
                  props.navigate(
                    "/" +
                      process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
                      Constants.MASTER_ADMIN_LOCATIONS_PATH
                  );
                }}
                breadCrumNames={["Locations", "Reward Settings"]}
                breadCrumTitle={"Reward Settings"}
              />
            </Box>
            <Spacer />
            <ButtonGroup mr={{ base: 0, md: "0.88rem" }} gap="2" alignSelf={"flex-end"}>
              {/* <PosFormButton
                onClick={() => {
                  // props.navigate(Constants.PRODUCT_LIST_PATH);
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              /> */}
              <PosFormButton
                isDisabled={buttonDisable ? true : false}
                buttonsubmit={"Save Changes"}
                SubmitButton={true}
                onClick={formik.handleSubmit}
              />
            </ButtonGroup>
          </Flex>
        </Box>
        {/* </CardHeader> */}
        <Divider borderColor="#E6E6E6" mt={"1rem"} mb={"2rem"} />
        {/* <CardBody> */}
        <Card
          margin={{ base: 0, sm: 0, md: "1.88rem", lg: "1.88rem" }}
          sx={{
            boxShadow: "none",
          }}
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
          borderRadius={"0.625rem"}
        >
          {/* <CardHeader alignItems={{ base: "flex-start" }} mt={10}> */}
          <CardBody>
            <Stack divider={<StackDivider />} spacing="2">
              <Box>
                <Flex
                  justifyContent={{ base: "center", md: "space-between" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    ml={{ base: "0", md: "1.9rem" }}
                    w={{ base: "100%", md: "100%" }}
                  >
                  <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}
                  >
                    <Flex w={{base:"60rem",md:"40%"}}>
                    <PosLable
                      label={true}
                      fontWeight={500}
                      name={"Reward Creation through SMS"}
                      mt="0.31rem"
                    />
                    </Flex>
                      <PosSwitchButton
                        id="reward_sms"
                        switchValue={rewardSms || ""}
                        onChange={(e) => {
                          setRewardSms(e.target.checked);
                        }}
                        mt={"2rem"}
                        ml={"1.5rem"}
                        // onChange={formik.handleChange}
                      />
                    </Flex>
                    <Flex mb={5} flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}} >
                      <Flex w={{base:"60rem",md:"40%"}}>
                    <PosLable
                      label={true}
                      fontWeight={500}
                      name={
                        "Enable Mobile Number Verification for New Reward Customer"
                      }
                      mt="0.31rem"
                    />
                    </Flex>
                      <PosSwitchButton
                        id="reward_verification"
                        switchValue={rewardVerification || ""}
                        onChange={(e) => {
                          setRewardVerification(e.target.checked);
                        }}
                        mt={"2rem"}
                        ml={"1.5rem"}
                        // onChange={formik.handleChange}
                      />
                      </Flex>
                  </Box>
                </Flex>
              </Box>
              <Box ml={{ base: "0", md: "1.5rem" }} padding={1}>
                <Flex
                  justifyContent={{ base: "center", md: "space-between" }}
                  alignItems={"flex-end"}
                  flexDirection={{ base: "column", md: "row" }}
                  // pt={{ base: "0.8rem", md: "0.8rem" }}
                  pb={{ base: "1.5rem", md: "2rem" }}
                  // pl={{ base: "1rem", lg: "1.5rem" }}
                  // pr={{ base: "0.5rem", md: "1.56rem" }}
                  // gap={1}
                >
                  <Box
                    w={{ base: "100%", md: "100%" }}
                    alignItems={"flex-start"}
                  >
                    <PosLable
                      label={true}
                      fontWeight={500}
                      name={"Your Public Reward Creation Page Link"}
                      mt="0.31rem"
                    />
                    <Text
                      color={Colors.posNavbarLink}
                      fontSize={"0.94rem"}
                      mr={2}
                      mt="2rem"
                      as="u"
                    >
                      <a
                        target="_blank"
                        href={rewardLink}
                        style={{
                          cursor: "pointer",
                          flexShrink: "0",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          letterSpacing: "-0.18px",
                          color: Colors.posNavbarLink,
                        }}
                      >
                        {rewardLink}
                      </a>
                    </Text>
                    <PosOutlineButton
                      onClick={handleCopyClick}
                      name={<ContentCopyIcon />}
                    ></PosOutlineButton>
                    {/* <Stack
                      direction={"row"}
                      mt={"0.313rem"}
                      w={"44px"}
                      height={"22px"}
                    >
                      <Text
                        h={"1.44rem"}
                        color={Colors.posNavbarLink}
                        as="u"
                        style={{
                          flexShrink:"0",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          letterSpacing: "-0.18px",
                        }}
                      >
                        <a
                          target="_blank"
                          href={rewardLink}
                          style={{ cursor: "pointer" }}
                        >
                          {rewardLink}
                        </a>
                      </Text>
                    </Stack> */}
                  </Box>
                  <Flex
                    mr={{ base: "0", md: "0.9rem" }}
                    w={{ base: "100%", md: "100%" }}
                    alignItems={"flex-end"}
                    mt={3}
                  >
                    <Spacer />
                    {/* <Stack
                  direction={"row"}
                  mt={"0.313rem"}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                ></Stack> */}
                    {/* <Button
                  bg={Colors.posNavbarLink}
                  size={{ base: "sm", md: "md" }}
                  style={{
                    display: "flex",
                    padding: "0.63rem",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.63rem",
                    borderRadius: "0.31rem",
                    border: "1px solid #E3E3E3",
                    boxShadow: "0px 0.25rem 0.25rem 0px #e0e0e01a",
                    backgroundColor: Colors.posNavbarLink,
                  }}
                  onClick={() => openDialog()}
                >
                  Add Custom Fields to Reward
                </Button> */}
                    <PosOutlineButton
                      onClick={() => openDialog()}
                      name="Add Custom Fields to Reward"
                    ></PosOutlineButton>
                    {/* <Spacer /> */}
                  </Flex>
                </Flex>
                <>
                  <Modal
                    isOpen={isRewardModalOpen}
                    onClose={onRewardModalClose}
                    size={"lg"}
                    scrollBehavior={"inside"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Add Reward Customer Custom Field</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <PosLable
                          fontWeight={500}
                          requiredLabel={true}
                          name={"Field Name"}
                          label={true}
                        />
                        <PosInput
                          id="field_name"
                          placeholder={"Field Name"}
                          color={Colors.posTextInfo}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.field_name}
                          handleBlur={formik.handleBlur}
                          posInput={true}
                          inputType={"text"}
                          inputError={
                            formik.touched.field_name &&
                            formik.errors.field_name
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.field_name &&
                          formik.errors.field_name ? (
                            <span>{formik.errors.field_name}</span>
                          ) : null}
                        </Text>
                        <Flex mt={{ base: "0", md: "0.88rem" }}>
                          <Box>
                            <PosLable
                              fontWeight={500}
                              name={"Field Type"}
                              mb={"0.88rem"}
                              label={true}
                              requiredLabel={true}
                            />
                            <PosDropDown
                              value={formik.values.field_type}
                              onChange={(e) => {
                                formik.setFieldValue("field_type", e.value);
                              }}
                              onBlur={(e) => {
                                formik.setFieldTouched("field_type");
                                formik.handleBlur(e);
                              }}
                              options={fieldTypes}
                              optionLabel={"name"}
                              style={{ width: "100%" }}
                              placeholder={" Select Field Type"}
                            ></PosDropDown>
                            <Text color={Colors.posCancleButtonRed}>
                              {formik.touched.field_type &&
                              formik.errors.field_type ? (
                                <span>{formik.errors.field_type}</span>
                              ) : null}
                            </Text>
                          </Box>
                        </Flex>
                        <Flex mt={{ base: "0" }}>
                          <Box>
                            <PosLable
                              label={true}
                              fontWeight={500}
                              name={"Is Field Required?"}
                              mt="0.31rem"
                              requiredLabel={true}
                            />
                            <Stack
                              direction={"row"}
                              mt={"0.313rem"}
                              w={"44px"}
                              height={"22px"}
                              flexShrink={"0"}
                            >
                              <PosSwitchButton
                                id="field_required"
                                switchValue={formik.values.field_required}
                                onChange={formik.handleChange}
                              />
                            </Stack>
                          </Box>
                        </Flex>

                        <Flex mt={{ base: "0" }}>
                          <Box>
                            <PosLable
                              fontWeight={500}
                              label={true}
                              name={
                                "Show this field on Customer Display or Mobile Signup Page"
                              }
                              mt="0.31rem"
                              requiredLabel={true}
                            />
                            <Stack
                              direction={"row"}
                              mt={"0.313rem"}
                              w={"44px"}
                              height={"22px"}
                              flexShrink={"0"}
                            >
                              <PosSwitchButton
                                id="field_display"
                                switchValue={formik.values.field_display}
                                onChange={formik.handleChange}
                              />
                            </Stack>
                          </Box>
                        </Flex>
                      </ModalBody>
                      <ModalFooter>
                        <Flex mb={"2.69rem"}>
                          <PosFormButton
                            buttonText={"Cancel"}
                            CancelButton={true}
                            // ref={cancelRef}
                            onClick={handleCloseDialog}
                            isDisabled={buttonDisable}
                          />
                          <Button
                            colorScheme="messenger"
                            ml={3}
                            onClick={handleSaveData}
                            isDisabled={
                              formik.values.field_name.length <= 0 ||
                              formik.errors.field_name ||
                              formik.errors.field_type ||
                              buttonDisable
                                ? true
                                : false
                            }
                          >
                            {"Add Field"}
                          </Button>
                        </Flex>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  {/* {modifiers.length > 0 && (
                <DataTable style={{ marginRight: '0.9rem' }} value={modifiers}>
                  <Column
                    field="field_name"
                    header="Field Name"
                    headerStyle={{
                      backgroundColor: "#5881fe1a",
                      fontWeight: "500",
                      color: "#38128A",
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                    style={{
                      color: "#010048",
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                      width: "20%",
                      textTransform: "capitalize",
                    }}
                  />
                  <Column
                    field="field_type"
                    header="Field Type"
                    headerStyle={{
                      backgroundColor: "#5881fe1a",
                      fontWeight: "500",
                      color: "#38128A",
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                    // bodyStyle={{
                    //   padding: "1rem 1.9rem",
                    // }}
                    style={{
                      color: "#010048",
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                      width: "20%",
                      textTransform: "capitalize",
                    }}
                  />
                  <Column
                    field="field_required"
                    header="Is Field Required"
                    headerStyle={{
                      backgroundColor: "#5881fe1a",
                      fontWeight: "500",
                      color: "#38128A",
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                    // bodyStyle={{
                    //   padding: "1rem 1.9rem",
                    // }}
                    style={{
                      color: "#010048",
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                      width: "20%",
                      textTransform: "capitalize",
                    }}
                    body={(rowData) =>
                      rowData.field_required == null ||
                      "" == rowData.field_required ||
                      false == rowData.field_required
                        ? "No"
                        : "Yes"
                    }
                  />
                  <Column
                    field="field_display"
                    header="Show This Field"
                    headerStyle={{
                      backgroundColor: "#5881fe1a",
                      fontWeight: "500",
                      color: "#38128A",
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                    // bodyStyle={{
                    //   padding: "1rem 1.9rem",
                    // }}
                    style={{
                      color: "#010048",
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                      width: "20%",
                      textTransform: "capitalize",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      // gap: "0.31rem",
                      // flexShrink: 0,
                    }}
                    body={(rowData) =>
                      rowData.field_display == null ||
                      "" == rowData.field_display ||
                      false == rowData.field_display
                        ? "No"
                        : "Yes"
                    }
                  />
                  <Column
                    field="delete"
                    header="Delete"
                    headerStyle={{
                      backgroundColor: "#5881fe1a",
                      fontWeight: "500",
                      color: "#38128A",
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                    style={{
                      fontSize: "0.93rem",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                    body={(rowData) => (
                      // <Button
                      //   p={2.5}
                      //   w={20}
                      //   h={8}
                      //   bg={Colors.posCancleButtonRed}
                      //   style={{
                      //     display: "flex",
                      //     justifyContent: "center",
                      //     alignItems: "center",
                      //     gap: "0.31rem",
                      //     flexShrink: 0,
                      //   }}
                      //   onClick={() =>
                      //     handleDeleteData(modifiers.indexOf(rowData))
                      //   }
                      // >
                      //   <DeleteIcon style={{ fontSize: 13 }} />
                      //   Delete
                      // </Button>
                      <DeleteIcon
                        bg={Colors.posCancleButtonRed}
                        onClick={() =>
                          handleDeleteData(modifiers.indexOf(rowData))
                        }
                        sx={{ color: Colors.posCancleButtonRed }}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "0.31rem",
                          flexShrink: 0,
                          cursor: "pointer",
                        }}
                      />
                    )}
                  />
                </DataTable>
              )} */}
                </>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        {modifiers.length > 0 && (
          <Box margin={{ base: 0, sm: 0, md: "1.88rem", lg: "1.88rem" }} mt={"0.88rem"}>
          <DataTable value={modifiers}>
            <Column
              field="field_name"
              header="Field Name"
              headerStyle={{
                backgroundColor: "#5881fe1a",
                fontWeight: "500",
                color: "#38128A",
                fontSize: "0.93rem",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
              style={{
                color: "#010048",
                fontSize: "0.93rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
                width: "20%",
                textTransform: "capitalize",
              }}
            />
            <Column
              field="field_type"
              header="Field Type"
              headerStyle={{
                backgroundColor: "#5881fe1a",
                fontWeight: "500",
                color: "#38128A",
                fontSize: "0.93rem",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
              // bodyStyle={{
              //   padding: "1rem 1.9rem",
              // }}
              style={{
                color: "#010048",
                fontSize: "0.93rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
                width: "20%",
                textTransform: "capitalize",
              }}
            />
            <Column
              field="field_required"
              header="Is Field Required"
              headerStyle={{
                backgroundColor: "#5881fe1a",
                fontWeight: "500",
                color: "#38128A",
                fontSize: "0.93rem",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
              // bodyStyle={{
              //   padding: "1rem 1.9rem",
              // }}
              style={{
                color: "#010048",
                fontSize: "0.93rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
                width: "20%",
                textTransform: "capitalize",
              }}
            //   body={(rowData) =>
            //     rowData.field_required == null ||
            //     "" == rowData.field_required ||
            //     false == rowData.field_required
            //     || "no" == rowData.field_required
            //       ? "No"
            //       : "Yes"
            //   }
            // />
            body={(rowData) => {
              const displayRequired =
                rowData.field_required == null ||
                rowData.field_required === "" ||
                rowData.field_required === false ||
                rowData.field_required === "no"
                  ? "No"
                  : "Yes";
              return (
                <Text
                  sx={{
                    padding: "0.150rem 0.625rem",
                    borderRadius: "0.63rem",
                    backgroundColor: displayRequired === "No" ? "#FCE6E5" : "#E4FEE2",
                    color: displayRequired === "No" ? "#CD1919" : "#0B8C20B5",
                    fontWeight: "300",
                    width: "2.9rem",
                  }}
                >
                  {displayRequired}
                </Text>
              );
            }}
            />
            <Column
              field="field_display"
              header="Show This Field"
              headerStyle={{
                backgroundColor: "#5881fe1a",
                fontWeight: "500",
                color: "#38128A",
                fontSize: "0.93rem",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
              // bodyStyle={{
              //   padding: "1rem 1.9rem",
              // }}
              style={{
                color: "#010048",
                fontSize: "0.93rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
                width: "20%",
                textTransform: "capitalize",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                // gap: "0.31rem",
                // flexShrink: 0,
              }}
            //   body={(rowData) =>
            //     rowData.field_display == null ||
            //     "" == rowData.field_display ||
            //     false == rowData.field_display
            //     || "no" == rowData.field_display 
            //       ? "No"
            //       : "Yes"
            //   }
            // />
            body={(rowData) => {
              const displayStatus =
                rowData.field_display == null ||
                rowData.field_display === "" ||
                rowData.field_display === false ||
                rowData.field_display === "no"
                  ? "No"
                  : "Yes";
              return (
                <Text
                  sx={{
                    padding: "0.150rem 0.625rem",
                    borderRadius: "0.63rem",
                    backgroundColor: displayStatus === "No" ? "#FCE6E5" : "#E4FEE2",
                    color: displayStatus === "No" ? "#CD1919" : "#0B8C20B5",
                    fontWeight: "300",
                    width: "2.9rem",
                  }}
                >
                  {displayStatus}
                </Text>
              );
            }}
            />
            <Column
              field="delete"
              header="Delete"
              headerStyle={{
                backgroundColor: "#5881fe1a",
                fontWeight: "500",
                color: "#38128A",
                fontSize: "0.93rem",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
              style={{
                fontSize: "0.93rem",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
              body={(rowData) => (
                // <Button
                //   p={2.5}
                //   w={20}
                //   h={8}
                //   bg={Colors.posCancleButtonRed}
                //   style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     gap: "0.31rem",
                //     flexShrink: 0,
                //   }}
                //   onClick={() =>
                //     handleDeleteData(modifiers.indexOf(rowData))
                //   }
                // >
                //   <DeleteIcon style={{ fontSize: 13 }} />
                //   Delete
                // </Button>
                <DeleteIcon
                  bg={Colors.posCancleButtonRed}
                  onClick={() => handleDeleteData(modifiers.indexOf(rowData))}
                  sx={{ color: Colors.posCancleButtonRed }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.31rem",
                    flexShrink: 0,
                    cursor: "pointer",
                  }}
                />
              )}
            />
          </DataTable>
          </Box>
        )}
        {/* <Flex
          minWidth="max-content"
          gap="2"
          mr={"0.88rem"}
          mt={{ base: "4.72rem", md: "4.72rem" }}
        >
          <Spacer />
          <ButtonGroup>
            <PosFormButton
              onClick={() => {
                // props.navigate(Constants.PRODUCT_LIST_PATH);
                handleClose();
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={buttonDisable ? true : false}
              buttonsubmit={"Save Changes"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </ButtonGroup>
        </Flex> */}
        {/* </CardBody>
        </Card> */}
      </Box>
    </PosDrawer>
  );
};
export default WithRouter(MasterAdminLocationReward);
