import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Divider,
  Flex,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PublicIcon from "@mui/icons-material/Public";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosOutlineButton,
  PosProgress,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getAllDeactiveLocation,
  getAllLocationsForPOS,
} from "./LocationService";

const LocationList = (props) => {
  const { addToast } = PosTostMessage();
  const submit = useSubmit();
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [posData, setPosData] = useState([]);
  const [locationDeactiveData, setLocationDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locationData, setSelectedLocationData] = useState({});
  const [value, setValue] = useState(null);
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const effectRun = useRef(true);
  const loaderRun = useRef(true);
  const actionRun = useRef(false);
  const [curruntTab, setCurruntTab] = useState(0);
  const [showTable, setShowTable] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(false);

  const columnNames = [
    {
      columnNames: "ID",
      sortName: "id",
    },
    {
      columnNames: "Name",
      sortName: "name",
    },
    {
      columnNames: "Location",
      sortName: "location_name",
    },
    {
      columnNames: "Actions",
      sortName: "",
    },
  ];

  const handleDeactive = (item) => {
    setButtonDisable(true);
    myContext.handleLoading(true);
    let data = {
      id: item,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.ESTABLISHMENT_LOCATION_PATH,
    });
  };
  const fetchData = async () => {
    let locationId = null;
    if (
      null != selectedLocation &&
      "" != selectedLocation &&
      undefined != selectedLocation
    ) {
      locationId = selectedLocation.id;
    }
    let data = {
      limit: rowsPerPage,
      page: page + 1,
      order: sortColumn,
      order_type: sortType,
      filter: searchQuery,
      // location_id: locationId,
    };
    setShowTable(false);
    setLoading(true);
    try {
      getAllLocationsForPOS(data)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            myContext.handleLoading(false);
            setLocations(response.data?.data?.data);
            // const locType = response.data?.data?.location_settings
            // .find((person) => person.key === "location_type")
            // if(null != locType && ''!= locType){
            //   response.data.data.location_type = locType.value;
            // }
            // else{
            //   response.data.data.location_type = 'N.A.';
            // }
            // setPosData(response.data.data.data);
            setTotalCount(response.data?.data?.totalItems);
            setLoading(false);
          } else {
            myContext.handleLoading(false);
            setSelectedLocationData({});
            // setPosData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          myContext.handleLoading(false);
          setSelectedLocationData({});
          setLoading(false);
          setTotalCount(0);
          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      myContext.handleLoading(false);
      // setPosData([]);
      setSelectedLocationData({});
      setTotalCount(0);
    }
  };
  const fetchDeActiveLocationData = async () => {
    let data = {
      limit: rowsPerPage,
      page: page + 1,
      order: sortColumn,
      order_type: sortType,
      filter: searchQuery,
    };
    setShowTable(false);
    setLoading(true);
    try {
      getAllDeactiveLocation(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);
            setLocationDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
          } else {
            myContext.handleLoading(false);
            setLocationDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          myContext.handleLoading(false);
          setLocationDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      myContext.handleLoading(false);
      setLocationDeactiveData([]);
      setDetactiveTotaCount(0);
    }
  };
  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveLocationData();
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [sortType, searchQuery, rowsPerPage, page, loading, sortColumn]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        setButtonDisable(false);
        if (
          undefined !== actionResponse?.response &&
          null !== actionResponse?.response &&
          typeof actionResponse?.response === "object"
        ) {
          let actionData = actionResponse;
          error({ actionData });
        }
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        setButtonDisable(false);
        // props.navigate(Constants.TAX_LIST);
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.LOCATION_MODULE,
            alertDescription: Constants.LOCATION_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
        } else {
          setLoading(true);
          fetchDeActiveLocationData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.LOCATION_MODULE,
            alertDescription: Constants.LOCATION_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
        }
      }
    }
  }, [actionResponse]);

  const handleEditPage = (item) => {
    // setTopLoading(true);
    myContext.handleLoading(true);
    props.navigate(`${Constants.LOCATION_EDIT + item}`);
  };

  const handleSettingsEditPage = (item) => {
    // setTopLoading(true);
    props.navigate(`${Constants.LOCATION_SETTING_OPTIONS_PAGE + item}`);
  };

  const handleReactive = (id) => {
    setButtonDisable(true);
    myContext.handleLoading(true);
    let data = {
      id: id,
      type: Constants.REACTIVE,
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.ESTABLISHMENT_LOCATION_PATH,
    });
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      setSearchQuery("");
      setPage(0);
      setRowsPerPage(100);
      effectRun.current = true;
      setLoading(true);
    } else {
      setSearchQuery("");
      setPage(0);
      setRowsPerPage(100);
      effectRun.current = true;
      setLoading(true);
    }
  };

  // const modifiedData = locationData.map((pos) => ({
  //   ...pos,
  // }));

  // const deactivePosData = locationDeactiveData.map((pos) => ({
  //   ...pos,
  // }));
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Locations"}
          discription={"Manage and create your business location on OctoPos"}
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Add Location"
            onClick={() => {
              myContext.handleLoading(true);
              props.navigate(Constants.LOCATION_CREATE);
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        // borderRadius="0.63rem"
        // bg="white"
        // boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          <TabPanels>
            <TabPanel pl={0} pr={0} key={1}>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 ? (
                <>
                  {locations.map((loc) => (
                    <Box key={loc.id} padding={1}>
                      <Card
                        bg={Colors.posPageDataBackground}
                        sx={{
                          boxShadow: "none",
                        }}
                      >
                        <CardBody>
                          <Flex
                            direction={{ base: "column", md: "row" }}
                            alignItems={{ base: "flex-start", md: "center" }}
                            justify="space-between"
                          >
                            <Box
                              borderRadius="0.63rem"
                              // bg={Colors.white}
                              // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                              // pl={35}
                              pl={{ md: 35 }}
                              pt={2}
                              pb={5}
                              width={"100%"}
                            >
                              {loc.image_url ? (
                                <Flex
                                  pb={"1.4rem"}
                                  flexDirection={{
                                    base: "column",
                                    md: "row",
                                  }}
                                  spacing={3}
                                >
                                  <Box 
                                    mt={4}
                                    mb={4}>
                                    <img
                                      src={`${loc.image_url}`}
                                      style={{
                                        width: "2.56694rem",
                                        height: "2.06475rem",
                                      }}
                                    />
                                  </Box>
                                  <Text
                                    pl={{ md: "0.6rem" }}
                                    fontSize={"20px"}
                                    fontStyle={"normal"}
                                    fontWeight={"500"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    mt={4}
                                    mb={4}
                                    color={Colors.posTextInfo}
                                  >
                                    {loc.name}
                                  </Text>
                                  <Spacer />
                                  <Flex alignSelf={"flex-end"}>
                                    <Stack direction={"row"}>
                                      <PosOutlineButton
                                        weight={"400"}
                                        onClick={() => {
                                          handleEditPage(loc.id);
                                        }}
                                        name="Edit"
                                        disabled={buttonDisable}
                                      ></PosOutlineButton>
                                      <PosOutlineButton
                                        weight={"400"}
                                        onClick={() => {
                                          handleSettingsEditPage(loc.id);
                                        }}
                                        disabled={buttonDisable}
                                        name="Location Settings"
                                      ></PosOutlineButton>
                                      <PosOutlineButton
                                        weight={"400"}
                                        disabled={buttonDisable}
                                        onClick={() => handleDeactive(loc.id)}
                                        name={
                                          <DeleteIcon
                                            style={{
                                              color: "red",
                                              fontSize: 20,
                                            }}
                                          />
                                        }
                                      ></PosOutlineButton>
                                    </Stack>
                                  </Flex>
                                </Flex>
                              ) : (
                                <Flex
                                  pb={"1.4rem"}
                                  flexDirection={{
                                    base: "column",
                                    md: "row",
                                  }}
                                  direction={["row"]}
                                  spacing={3}
                                >
                                  <Text
                                    fontSize={"20px"}
                                    fontStyle={"normal"}
                                    lineHeight={"normal"}
                                    fontWeight={"500"}
                                    letterSpacing={"-0.01rem"}
                                    mt={4}
                                    mb={4}
                                    // width={"60%"}
                                    // width={"20%"}
                                    color={Colors.posTextInfo}
                                  >
                                    {loc.name}
                                  </Text>
                                  <Spacer />
                                  <Flex alignSelf={"flex-end"}>
                                    <Stack direction={"row"}>
                                      <PosOutlineButton
                                        weight={"400"}
                                        onClick={() => {
                                          handleEditPage(loc.id);
                                        }}
                                        name="Edit"
                                        disabled={buttonDisable}
                                      ></PosOutlineButton>
                                      <PosOutlineButton
                                        weight={"400"}
                                        onClick={() => {
                                          handleSettingsEditPage(loc.id);
                                        }}
                                        name="Location Settings"
                                        disabled={buttonDisable}
                                      ></PosOutlineButton>
                                      <PosOutlineButton
                                        weight={"400"}
                                        disabled={buttonDisable}
                                        onClick={() => handleDeactive(loc.id)}
                                        name={
                                          <DeleteIcon
                                            style={{
                                              color: "red",
                                              fontSize: 20,
                                            }}
                                          />
                                        }
                                      ></PosOutlineButton>
                                    </Stack>
                                  </Flex>
                                </Flex>
                              )}
                              <Divider pl={0} width={"100%"} mb={"2rem"} />
                              <Stack
                                direction={["row"]}
                                spacing={3}
                                mb="1rem"
                              >
                                <Box w={{ base: "100%", md: "25%" }}>
                                  <Text
                                    height={"1.4375rem"}
                                    width={"4.25rem"}
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"400"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    Address:
                                  </Text>
                                </Box>
                                <FmdGoodIcon fontSize={"0.93rem"} />
                                <Flex w={{ base: "100%", md: "100%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    {null != loc.address1 && "" != loc.address1
                                      ? loc.address1
                                      : "N.A."}
                                  </Text>
                                </Flex>
                              </Stack>
                              <Stack
                                direction={["row"]}
                                spacing={3}
                                mb="1rem"
                              >
                                <Box w={{ base: "100%", md: "25%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"400"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    Phone Number:
                                  </Text>
                                </Box>
                                <LocalPhoneIcon fontSize={"0.93rem"} />
                                <Box w={{ base: "100%", md: "100%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    {null != loc.phone && "" != loc.phone
                                      ? loc.phone
                                      : "N.A."}
                                  </Text>
                                </Box>
                              </Stack>
                              <Stack
                                direction={["row"]}
                                spacing={3}
                                mb="1rem"
                              >
                                <Box w={{ base: "100%", md: "25%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"400"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    Timezone:
                                  </Text>
                                </Box>
                                <PublicIcon fontSize={"0.93rem"} />
                                <Box w={{ base: "100%", md: "100%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    {null != loc.time_zone &&
                                    "" != loc.time_zone
                                      ? loc.time_zone
                                      : "N.A."}
                                  </Text>
                                </Box>
                              </Stack>
                              <Stack
                                direction={["row"]}
                                spacing={3}
                                mb="1rem"
                              >
                                <Box w={{ base: "100%", md: "25%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"400"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    Location Type:
                                  </Text>
                                </Box>
                                <Box w={{ base: "100%", md: "100%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    {null != loc.location_type &&
                                    "" != loc.location_type
                                      ? loc.location_type
                                      : "N.A."}
                                  </Text>
                                </Box>
                              </Stack>
                            </Box>
                          </Flex>
                        </CardBody>
                      </Card>
                    </Box>
                  ))}
                </>
              ) : (
                <PosNoDataFound title={Constants.NO_LOCATION_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel pl={0} pr={0} key={2}>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 ? (
                <>
                  {locationDeactiveData.map((loc) => (
                    <Box key={loc.id} padding={2}>
                      <Card
                        bg={Colors.posPageDataBackground}
                        sx={{
                          boxShadow: "none",
                        }}
                      >
                        <CardBody>
                          <Flex
                            direction={{ base: "column", md: "row" }}
                            alignItems={{ base: "flex-start", md: "center" }}
                            justify="space-between"
                          >
                            <Box
                              borderRadius="0.63rem"
                              // bg={Colors.white}
                              // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                              // pl={35}
                              pl={{ md: 35 }}
                              pt={2}
                              pb={5}
                              width={"100%"}
                            >
                              {loc.image_url ? (
                                <Flex
                                  pb={"1.4rem"}
                                  flexDirection={{
                                    base: "column",
                                    md: "row",
                                  }}
                                  spacing={3}
                                >
                                  <Box
                                  mt={4}
                                  mb={4}>
                                    <img
                                      src={`${loc.image_url}`}
                                      style={{
                                        width: "2.56694rem",
                                        height: "2.06475rem",
                                      }}
                                    />
                                  </Box>
                                  <Text
                                    pl={{ md: "0.6rem" }}
                                    fontSize={"20px"}
                                    fontStyle={"normal"}
                                    fontWeight={"500"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    mt={4}
                                    mb={4}
                                    color={Colors.posTextInfo}
                                  >
                                    {loc.name}
                                  </Text>
                                  <Spacer />
                                  <Box>
                                    <ButtonGroup gap="2">
                                      <Button
                                        isDisabled={buttonDisable}
                                        onClick={() => {
                                          handleReactive(loc.id);
                                        }}
                                      >
                                        Activate
                                      </Button>
                                    </ButtonGroup>
                                  </Box>
                                </Flex>
                              ) : (
                                <Flex
                                  pb={"1.4rem"}
                                  flexDirection={{
                                    base: "column",
                                    md: "row",
                                  }}
                                  direction={["row"]}
                                  spacing={3}
                                >
                                  <Text
                                    fontSize={"20px"}
                                    fontStyle={"normal"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    fontWeight={"500"}
                                    mt={4}
                                    mb={4}
                                    // width={"60%"}
                                    // width={"20%"}
                                    color={Colors.posTextInfo}
                                  >
                                    {loc.name}
                                  </Text>
                                  <Spacer />
                                  <Box>
                                    <ButtonGroup gap="2">
                                      <Button
                                        isDisabled={buttonDisable}
                                        onClick={() => {
                                          handleReactive(loc.id);
                                        }}
                                      >
                                        Activate
                                      </Button>
                                    </ButtonGroup>
                                  </Box>
                                </Flex>
                              )}
                              <Divider mb={"2rem"} />
                              <Stack
                                direction={["row"]}
                                spacing={3}
                                mb="1rem"
                              >
                                <Box w={{ base: "100%", md: "25%" }}>
                                  <Text
                                    height={"1.4375rem"}
                                    width={"4.25rem"}
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"400"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    Address:
                                  </Text>
                                </Box>
                                <FmdGoodIcon fontSize={"0.93rem"} />
                                <Flex w={{ base: "100%", md: "100%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    {null != loc.address1 && "" != loc.address1
                                      ? loc.address1
                                      : "N.A."}
                                  </Text>
                                </Flex>
                              </Stack>
                              <Stack
                                direction={["row"]}
                                spacing={3}
                                mb="1rem"
                              >
                                <Box w={{ base: "100%", md: "25%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"400"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    Phone Number:
                                  </Text>
                                </Box>
                                <LocalPhoneIcon fontSize={"0.93rem"} />
                                <Box w={{ base: "100%", md: "100%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    {null != loc.phone && "" != loc.phone
                                      ? loc.phone
                                      : "N.A."}
                                  </Text>
                                </Box>
                              </Stack>
                              <Stack
                                direction={["row"]}
                                spacing={3}
                                mb="1rem"
                              >
                                <Box w={{ base: "100%", md: "25%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"400"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    Timezone:
                                  </Text>
                                </Box>
                                <PublicIcon fontSize={"0.93rem"} />
                                <Box w={{ base: "100%", md: "100%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    {null != loc.time_zone &&
                                    "" != loc.time_zone
                                      ? loc.time_zone
                                      : "N.A."}
                                  </Text>
                                </Box>
                              </Stack>
                              <Stack
                                direction={["row"]}
                                spacing={3}
                                mb="1rem"
                              >
                                <Box w={{ base: "100%", md: "25%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"400"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    Location Type:
                                  </Text>
                                </Box>
                                <Box w={{ base: "100%", md: "100%" }}>
                                  <Text
                                    fontSize={"0.93rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                    // mt={2}
                                    color={Colors.posTextInfo}
                                  >
                                    {null != loc.location_type &&
                                    "" != loc.location_type
                                      ? loc.location_type
                                      : "N.A."}
                                  </Text>
                                </Box>
                              </Stack>
                            </Box>
                          </Flex>
                        </CardBody>
                      </Card>
                      {/* <Divider /> */}
                    </Box>
                  ))}
                </>
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_LOCATION_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(LocationList);
