/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

export default {
  posTextInfo: "#010048 !important",
  toolTipColor : "#5759F9",
  posCancleButtonMuiRed: "#CD1919 ",
  primeposTextInfo: '#010048',
  posCancleButtonRed: "#CD1919 !important",
  posPageDataBackground: "#FAFAFA !important",
  headerColor: "#ECEEF6 !important",
  toastWarning: "#feebc8 !important",
  //auth colors
  signInBoxShadow: "#59595926 !important",
  errorsign: "#fc951a17 !important",
  orangeButton: "#FB951A !important",
  loginMainBackground: "linear-gradient(180deg, #FFFEFF 0%, #F1EBFF 100%)",
  loginAuthBackground: "#F4F4F4 !important",
  loginAuthInput: "#5881fe12 !important",
  loginButtonBackground: "#B582F4 !important",
  loginButtonBoarder: "#DEE6F9 !important",
  loginButtonShadow: "#e3e3e340 !important",
  loginFooter: "#38128A !important",
  inputBorder: "#E5E5E5 !important",
  searchInput: "#EDEDED !important",
  subMenu: "#E4E6EC !important",
  searchInputBoarder: "#EEE !important",
  userAvtarBackground: "#E8E8EB !important",
  blackIcon: "#000000 !important",
  forgotBackButtone: "#5881FE12 !important",
  posViewTextColor: "rgba(1, 0, 72, 0.7)  ",
  posImageIconColor: "rgba(1, 0, 72, 0.80)  ",
  posviewbgcolor: "rgba(88, 129, 254, 0.03) !important",
  taxInfo: "#5881FE !important",
  primeTaxInfo: "#5881FE",
  resetButton: "#F8F9FB !important",
  primeResetButton: "#F8F9FB !important",
  ghostBackground: "#5881fe05 !important",
  textareaColor: "#0100484d",
  inputBorder: "#E9E4E4 !important",
  inputHover: "#E6D1FF !important",
  //component
  //input
  posInputBorder: "#e5e5e5b3 !important",
  posInputBackground: "#F8F8F8 !important",
  //input Group
  posInputGroupBackground: "#5881fe24 !important",
  // PosInputGroupIcon: "#5881FE !important",
  posTextAreaBackground: "#5881fe08 !important",
  posTextAreaBorder: "1px solid #b7b7b71f !important",
  //PosTostMessage
  posNavbarLink: "#5881FE !important",
  toastBoxShadow: "#4c4c4c40 !important",
  //Taxes
  taxCreateExemptEbt: "#01004899 !important",
  errorColor: "#CD1919 !important",
  switch: "#9CE500 !important",
  taxcolor: "#01004838 !important",
  taxDeactive: "#FF0000 !important",
  listShadow: "#0000001a !important",
  createEditBoxShadow: "#5a5a5a0a !important",

  //Modifierset
  posShowFields: "#01004880 !important",
  modifierTable: "#5881fe1a !important",

  //Invo
  authHeader: "#45404B!important",
  inputBorderColor: "#E2DEE7!important",
  formLable: "#665E6E!important",
  mainHeader: "#28222D!important",
  white: "#ffffff!important",
  inputErrorBorder: "#F26464!important",
  alertInfo: "#EEE0FF!important",
  placeholder: "#ADA6B4!important",
  buttonHover: "#934CE5 !important",
  buttonPress: "#5B0EB4 !important",
  buttonDisable: "#DCC0FC !important",
  buttonOutlineHover: "#EDE9F0 !important",
  buttonOutlineFocus: "#FFF !important",
  buttonOutlineDisable: "#F2EFF5!important",
  inputHover: "#D8D3DE !important",
  inputErrorMessage: "#E53E3E!important",
  notificationIcon: "#938B9C!important",
  brandGrey: "#F8F6FA !important",
  redButton: "#C53434 !important",
  darkHeader: "#1E1D1F !important",
  errorAlert: "#FFEBEB !important",
  avtarBackground: "#BEE3F8 !important",
  borderColor: "#E7E3EB !important",
  badgeSuccess: "#D8F8E7 !important",
  badgeSuccessFont: "#196742 !important",
  badgeError: "#FEE6E6 !important",
  badgeErrorFont: "#952D2D !important",
  badgeNew: "#FFE8D1 !important",
  badgeNewFont: "#A05C1C !important",
  warningAlert: "#FFF0E0 !important",
  warningIconeColor: "#F59638 !important",
  buttonHoverColor: "#F4EBFF !important",
  borderColorFile: "#B372FE !important",
  RelodIconeColor: "#4F9F2E  !important",
  black: "#000 !important",
  dividerColor: "#D9D9D9 !important",
  orderViewLabel: "#5881fecc !important",
  campCardBgColor: "#5881fe0d !important",
  primeCampCardBgColor: "#5881fe0d",

  // ---Campaign--- //
  couponDropDownLabelColor: "#010048b3 !important",
  emailTempDividerColor: "#353c4d26 !important",
  sendTestEmailTextInfoColor: "#01004866 !important",
  publicPageFontHeadColor: "#D42266 !important"
};
