import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getLocationGlobalSettingsData({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.GET_ALL_LOC_GLOBAL_SETTINGS_DATA_API + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GET_ALL_LOC_GLOBAL_SETTINGS_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function updateLocationGlobalSetting({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      sys_global_settings_category_id: id,
      // key:Object.fromEntries(formData).allValue
    };
    if (Object.fromEntries(formData).hasOwnProperty("allValue")) {
      payload.key = JSON.parse(Object.fromEntries(formData).allValue);
    }
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ALL_LOC_UPDATE_SETTING_API_ROUTE_SETTING+
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GET_ALL_LOC_GLOBAL_SETTINGS_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}