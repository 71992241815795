import { ChevronDownIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useMediaQuery
} from "@chakra-ui/react";
import { BarChart } from "@mui/x-charts/BarChart";
import { lineElementClasses } from "@mui/x-charts/LineChart";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useLoaderData, useLocation, useSubmit } from "react-router-dom";
import {
  AppContext,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosListHeader,
  PosNoDataFound,
  PosOutlineButton,
  PosProgress,
  PosSearch,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { itemBySalesReport } from "./DashboardService";
const moment = require("moment");

const ItemsBySalesReport = (props) => {
  const stateData = useLocation(); // get backraking data as param
  const { addToast } = PosTostMessage();

  const isExtraMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
    xl: false,
  });
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isOpen, setIsOpen] = useState(false);

  const effectRun = useRef(true);
  const effectLocationRun = useRef(false);
  const [loading, setLoading] = useState(true);

  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const myContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  // const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(
    stateData.state != undefined &&
      stateData.state != null &&
      stateData.state != ""
      ? stateData.state.location
      : ""
  );
  const stateRefSelectedLocation = useRef(selectedLocation);
  const [ordersAvailable, setOrdersAvailable] = useState(0);

  // Set All Chart Loading.
  const [barHorizontalChartLoading, setBarHorizontalChartLoading] = useState(
    false
  );
  const [itemNameBarChart, setItemNameBarChart] = useState([]);
  const [itemSalesBarChartData, setItemSalesBarChartData] = useState([]);
  const [itemBySalesTop5Data, setItemBySalesTop5Data] = useState([]);
  const [totalProductSalesData, setTotalProductSalesData] = useState([]);
  const [totalcount, setTotalCount] = useState(0);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: "totalsale",
      sort: "desc",
    },
  ]);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type
      : "desc"
  );
  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order
      : "totalsale"
  );
  const paginationRef = useRef(paginationModel);
  // Calender Default Setting
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 6);
  const [dateRange, setDateRange] = useState([
    {
      startDate: sevenDaysAgo,
      endDate: today,
      key: "selection",
    },
  ]);

  // Calender Popover
  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  // Select Date from Calender Popover
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    products: false,
    id: false,
  });

  const actionColumn = isMobile ? { width: 300 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const columnNames = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      width: 90,
      filterable: false,
      resizable: false,
    },
    {
      headerName: "Product Name",
      field: "product",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      headerName: "Total Sales Amount($)",
      field: "totalsale",
      sortable: true,
      sortable: true,
      ...actionColumn,
    },
  ];

  const [page, setPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.page
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.limit
      : 25
  );

  // On Loading all locations, last created order location and tenant order available check.
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocations(loaderResponse?.data?.data?.locations);
          if (null != stateData?.state && "" != stateData?.state) {
            setSelectedLocation(stateData?.state?.location);
            getItemBySales(
              stateData?.state?.location?.id,
              stateData?.state?.myStartDate,
              stateData?.state?.myEndDate
            );
            setDateRange([
              {
                startDate: new Date(stateData?.state?.myStartDate),
                endDate: new Date(stateData?.state?.myEndDate),
                key: "selection",
              },
            ]);
          } else {
            if(undefined !== loaderResponse?.data?.data?.locations &&
              null !== loaderResponse?.data?.data?.locations && loaderResponse?.data?.data?.locations?.length === 1 &&
              loaderResponse?.data?.data?.location?.name === ""){
                setSelectedLocation(loaderResponse?.data?.data?.locations[0])
                getItemBySales(
                  loaderResponse?.data?.data?.locations[0]?.id,
                  dateRange[0]?.startDate,
                  dateRange[0]?.endDate
                );
              } else {
                setSelectedLocation(loaderResponse?.data?.data?.location);
                getItemBySales(
                  loaderResponse?.data?.data?.location?.id,
                  dateRange[0]?.startDate,
                  dateRange[0]?.endDate
                );
              }
            setDateRange([
              {
                startDate: dateRange[0]?.startDate,
                endDate: dateRange[0]?.endDate,
                key: "selection",
              },
            ]);
          }
          setOrdersAvailable(loaderResponse?.data?.data?.ordersAvailable);
          myContext.handleLoading(false);
        } else {
          setLocations([]);
          setSelectedLocation([]);
          setOrdersAvailable(0);
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  });

  // Any change in Location Dropdown and Calender Submit Button and On load method call.
  useEffect(() => {
    if (effectLocationRun.current === true) {
      if (stateRefSelectedLocation.current != selectedLocation?.id) {
        // applyButton(selectedLocation?.id, ordersAvailable);
        getItemBySales(
          selectedLocation?.id,
          dateRange[0]?.startDate,
          dateRange[0]?.endDate
        );
      }
      stateRefSelectedLocation.current = selectedLocation?.id;
      effectLocationRun.current = false;
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (paginationRef.current !== paginationModel) {
      getItemBySales(
        selectedLocation?.id,
        dateRange[0]?.startDate,
        dateRange[0]?.endDate
      );
      paginationRef.current = paginationModel;
    } else if (sortRef.current !== sortModel) {
      getItemBySales(
        selectedLocation?.id,
        dateRange[0]?.startDate,
        dateRange[0]?.endDate
      );
      sortModel.current = sortModel;
    }
  }, [paginationModel, sortModel]);

  const sortRef = useRef(sortModel);

  const applyButton = (id) => {
    // setIsOpen(false);
    getItemBySales(id, dateRange[0]?.startDate, dateRange[0]?.endDate);
  };

  const getItemBySales = (locId, start, end) => {
    setIsOpen(false);
    if (null != locId && "" != locId && undefined != locId) {
      setBarHorizontalChartLoading(true);
      // var id = locId;
      let start_date = new Date(moment(start));
      let end_date = new Date(moment(end));
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      let payload = {
        id: locId,
        myStartDate: myStartDate,
        myEndDate: myEndDate,
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
      };
      setLoading(true);
      try {
        setDateRange([
          {
            startDate: start,
            endDate: end,
            key: "selection",
          },
        ]);
        itemBySalesReport(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              // if (
              //   (payload?.id == null &&
              //     payload?.myStartDate == null &&
              //     payload?.myEndDate == null) ||
              //   (id == location?.id && myStartDate == requestMyDate)
              // ) {
              var totalproductsale =
                response?.data?.data?.totalproductsale?.data;
              var top5Items = response?.data?.data?.top5Items;
              var productsforsale = [];
              var sale = [];
              top5Items?.map((val, index) => {
                const spaces = " ".repeat(index);
                productsforsale.push(val["product"] + spaces);
                sale.push(val["totalsale"]);
              });
              setItemBySalesTop5Data(top5Items);
              setItemNameBarChart(productsforsale);
              setItemSalesBarChartData(sale);
              if (totalproductsale == "N.A.") {
                setTotalProductSalesData([]);
                setTotalCount(0);
              } else {
                setTotalProductSalesData(totalproductsale);
                setTotalCount(response?.data?.data?.totalItems);
              }
              setLoading(false);
              // }
              // setLoading(false);
              setBarHorizontalChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setLoading(false);
              setTotalProductSalesData([]);
              setTotalCount(0);
              setItemBySalesTop5Data([]);
              setItemNameBarChart([]);
              setItemSalesBarChartData([]);
              setBarHorizontalChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            setTotalProductSalesData([]);
            setTotalCount(0);
            setItemBySalesTop5Data([]);
            setItemNameBarChart([]);
            setItemSalesBarChartData([]);
            setBarHorizontalChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        setTotalProductSalesData([]);
        setTotalCount(0);
        setItemBySalesTop5Data([]);
        setItemNameBarChart([]);
        setItemSalesBarChartData([]);
        setBarHorizontalChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_WARNING,
        alertDescription: Constants.DASHBOARD_LOCATION_REQUIRED,
      });
    }
  };

  const handleSearchList = () => {
    getItemBySales(
      selectedLocation?.id,
      dateRange[0]?.startDate,
      dateRange[0]?.endDate
    );
  };

  const handleClear = () => {
    // clear input value and get all data
    // effectRun.current = true;
    setSearchQuery("");
    getItemBySalesAfterClear(
      selectedLocation?.id,
      dateRange[0]?.startDate,
      dateRange[0]?.endDate
    );
  };

  const getItemBySalesAfterClear = (locId, start, end) => {
    setIsOpen(false);
    if (null != locId && "" != locId && undefined != locId) {
      setBarHorizontalChartLoading(true);
      // var id = locId;
      let start_date = new Date(moment(start));
      let end_date = new Date(moment(end));
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      let payload = {
        id: locId,
        myStartDate: myStartDate,
        myEndDate: myEndDate,
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: "",
      };
      setLoading(true);
      try {
        setDateRange([
          {
            startDate: start,
            endDate: end,
            key: "selection",
          },
        ]);
        itemBySalesReport(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              // if (
              //   (payload?.id == null &&
              //     payload?.myStartDate == null &&
              //     payload?.myEndDate == null) ||
              //   (id == location?.id && myStartDate == requestMyDate)
              // ) {
              var totalproductsale =
                response?.data?.data?.totalproductsale?.data;
              var top5Items = response?.data?.data?.top5Items;
              var productsforsale = [];
              var sale = [];
              top5Items?.map((val, index) => {
                const spaces = " ".repeat(index);
                productsforsale.push(val["product"] + spaces);
                sale.push(val["totalsale"]);
              });
              setItemBySalesTop5Data(top5Items);
              setItemNameBarChart(productsforsale);
              setItemSalesBarChartData(sale);
              if (totalproductsale == "N.A.") {
                setTotalProductSalesData([]);
                setTotalCount(0);
              } else {
                setTotalProductSalesData(totalproductsale);
                setTotalCount(response?.data?.data?.totalItems);
              }
              setLoading(false);
              // }
              // setLoading(false);
              setBarHorizontalChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setLoading(false);
              setTotalProductSalesData([]);
              setTotalCount(0);
              setItemBySalesTop5Data([]);
              setItemNameBarChart([]);
              setItemSalesBarChartData([]);
              setBarHorizontalChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            setTotalProductSalesData([]);
            setTotalCount(0);
            setItemBySalesTop5Data([]);
            setItemNameBarChart([]);
            setItemSalesBarChartData([]);
            setBarHorizontalChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        setTotalProductSalesData([]);
        setTotalCount(0);
        setItemBySalesTop5Data([]);
        setItemNameBarChart([]);
        setItemSalesBarChartData([]);
        setBarHorizontalChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_WARNING,
        alertDescription: Constants.DASHBOARD_LOCATION_REQUIRED,
      });
    }
  };

  const modifiedData = totalProductSalesData?.map((item, index) => ({
    id: index + 1,
    product: item.product,
    totalsale: item.totalsale,
  }));

  const allZeroItemSalesBarChartData = itemBySalesTop5Data?.every(
    (value) => value == "0.00"
  );

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        mb={!isExtraMobile ? "2rem" : "1rem"}
        direction={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
        alignItems={"flex-start"}
        justify="space-between"
      >
        <HStack
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
          alignItems={"flex-start"}
        >
          <PosOutlineButton
            height={"1rem"}
            width={"1rem"}
            onClick={() => {
              stateData?.state?.screen == "Dashboard"
                ? props.navigate(Constants.DASHBOARD_PATH, {
                    state: stateData.state,
                  })
                : props.navigate(Constants.REPORT_PATH);
            }}
            name={
              <ChevronLeftIcon
                style={{
                  fontSize: 22,
                  color: "#010048",
                }}
              />
            }
          ></PosOutlineButton>
          <PosListHeader header={"Top Items By Sales"} discription={""} />
        </HStack>
        <ButtonGroup
          gap="2"
          alignSelf={"flex-end"}
          justifyContent={"flex-end"}
          flexWrap={"wrap"}
        >
          <PosDropDown
            id="selectedSize"
            value={selectedLocation}
            onChange={(e) => {
              effectLocationRun.current = true;
              setSelectedLocation(e.value);
            }}
            options={locations}
            optionLabel={"name"}
            className="w-full md:w-20rem"
            width={"100%"}
            placeholder={"Select a Location"}
          ></PosDropDown>
          <Popover
            isOpen={isOpen}
            onOpen={togglePopover}
            onClose={togglePopover}
          >
            <PopoverTrigger>
              <Button
                rightIcon={<ChevronDownIcon />}
                variant="avtar"
                h="38px"
                fontWeight={300}
                lineHeight={5}
                color={"#010048"}
                backgroundColor={"rgba(88, 129, 254, 0.05)"}
              >
                {moment(dateRange[0]?.startDate).format("D MMM, YYYY") +
                  " - " +
                  moment(dateRange[0]?.endDate).format("D MMM, YYYY")}
              </Button>
            </PopoverTrigger>
            <PopoverContent width="min-content">
              <PopoverArrow />
              <PopoverBody>
                <Flex direction={isExtraMobile ? "column" : "row"} align="center">
                  <DateRangePicker
                    ranges={dateRange}
                    // presets={customRanges}
                    onChange={handleSelect}
                    // defaultValue={null}
                    rangeColors={["#126BE9"]}
                    months={2}
                    direction={isExtraMobile ? "vertical" : "horizontal"}
                  />
                </Flex>
                <PosFormButton
                  buttonsubmit={"Apply"}
                  SubmitButton={true}
                  onClick={() => applyButton(selectedLocation?.id)}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </ButtonGroup>
      </Flex>
      {selectedLocation?.name?.length > 0 ? (
        <>
          <Box
            flex={1}
            flexShrink={"0"}
            bg={"var(--Neutral-Colors-White, #FFF)"}
            mb={2.5}
            boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
            borderRadius={"1.25rem"}
          >
            <Box
              align={"stretch"}
              // pl={{
              //   base: "1rem",
              //   sm: "2rem",
              //   md: "2rem",
              //   lg: "1rem",
              //   xl: "2rem",
              // }}
              // pt={{
              //   base: "1rem",
              //   sm: "3.30rem",
              //   md: "3.30rem",
              //   lg: "3.30rem",
              //   xl: "3.30rem",
              // }}
              // pr={{
              //   base: "1rem",
              //   sm: "2rem",
              //   md: "2rem",
              //   lg: "1rem",
              //   xl: "2rem",
              // }}
              pb={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              }}
              gap={"0rem"}
            >
              <Flex
                direction="row"
                alignItems={{ base: "flex-start", md: "center" }}
                justify="space-between"
                pl={{
                  base: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "1rem",
                  xl: "2rem",
                }}
                pt={{
                  base: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "2rem",
                  xl: "2rem",
                }}
                pr={{
                  base: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "1rem",
                  xl: "2rem",
                }}
                // pb={{
                //   base: "1rem",
                //   sm: "3.30rem",
                //   md: "3.30rem",
                //   lg: "3.30rem",
                //   xl: "3.30rem",
                // }}
              >
                <Box>
                  {/* <Text fontSize={"sm"} color={"#9291A5"} fontWeight={"light"}>
                    Statistics
                  </Text> */}
                  <Text fontSize={"2xl"} color={"#1E1B39"} fontWeight={"bold"}>
                    Top Items by Sales
                  </Text>
                </Box>
              </Flex>

              {barHorizontalChartLoading ? (
                <Box color={"#00126833"} margin={30}>
                  <Stack>
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="180" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="280" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="180" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="150" />
                  </Stack>
                </Box>
              ) : (
                <BarChart
                  grid={{ vertical: true }}
                  borderRadius={4}
                  sx={{
                    //bottom label color.
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                      fill: "#615E83",
                      fontSize: "18px",
                      // lineHeight: "16px",
                      fontWeight: 500,
                    },
                    //side label color.
                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                      // display: "none",
                      fill: "#615E83",
                      fontSize: "18px",
                      // transform: "translateY(-20px) !important" ,
                      // lineHeight: "16px",
                      fontWeight: 500,
                    },
                    //both side label stroke black color remove.
                    "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                      stroke: "none",
                    },
                    //graph both dashed line color.
                    "& .MuiChartsGrid-root .MuiChartsGrid-line": {
                      stroke: "#E5E5EF",
                      shapeRendering: "crispEdges",
                      strokeWidth: 1,
                    },
                    //vertical line color and width.
                    "& .MuiChartsGrid-line.MuiChartsGrid-verticalLine": {
                      stroke: "#E5E5EF",
                      shapeRendering: "crispEdges",
                      strokeWidth: 1.5,
                      strokeDasharray: "8 4", // This makes the line dashed
                    },
                    //bottom and side axis line color change.
                    "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                      stroke: "#E5E5EF",
                      shapeRendering: "crispEdges",
                      strokeWidth: 0,
                    },
                    //graph line color.
                    [`& .${lineElementClasses.root}`]: {
                      stroke: "#6585F7",
                      strokeWidth: 2,
                    },
                  }}
                  yAxis={[
                    {
                      scaleType: "band",
                      data: itemNameBarChart,
                      categoryGapRatio: 0.69,
                      // barGapRatio: 0.1,
                      colorMap: {
                        type: "ordinal",
                        colors: [
                          "#6D3AFF",
                          "#E642F4",
                          "#FD9393",
                          "#FFDB80",
                          "#F6D1A6",
                        ],
                      },
                      tickLabelStyle: {
                        textAnchor: "start",
                        dominantBaseline: "central",
                        // transform: " translateX(9px) translateY(-20px)",
                        transform:
                          itemNameBarChart.length == 1
                            ? " translateX(9px) translateY(-15%)"
                            : itemNameBarChart.length == 2
                            ? " translateX(9px) translateY(-11%)"
                            : itemNameBarChart.length == 3
                            ? " translateX(9px) translateY(-8%)"
                            : itemNameBarChart.length == 4
                            ? "translateX(9px) translateY(-5%)"
                            : "translateX(9px) translateY(-20px)", // 2 band = 70px // 1 band= 32%
                      },
                    },
                  ]}
                  slotProps={{
                    bar: {
                      clipPath: `inset(0px round 0px 4px 4px 0px)`,
                    },
                    noDataOverlay: {
                      // message: "There are no Top Items by Sales Data.",
                      message: "",
                    },
                  }}
                  xAxis={[
                    {
                      min: 0,
                      max:
                        ordersAvailable == 1
                          ? allZeroItemSalesBarChartData
                            ? 10
                            : null
                          : 10,
                    },
                  ]}
                  series={[
                    {
                      data: itemSalesBarChartData,
                      valueFormatter: (value) =>
                        // `$${parseFloat(value).toFixed(2)}`, // Formatter function to add $ sign
                      value > 0.00 ? `$${Number(parseFloat(value).toFixed(2)).toLocaleString('en', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}`:'$0.00',
                    },
                  ]}
                  height={315}
                  layout="horizontal"
                />
              )}
            </Box>
          </Box>
          <Box pt={{ base: "2rem", sm: "2rem", md: "2rem", lg: "3rem" }}></Box>
          <Flex
            // mb={!isExtraMobile ? "2rem" : "1rem"}
            direction={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
            alignItems={"flex-start"}
            justify="space-between"
          >
            <Text fontSize={"lg"} fontWeight={500}>
              Report Overview
            </Text>
          </Flex>
          <Box
            pt={1}
            borderRadius="0.63rem"
            bg="white"
            boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
            mt={"0.88rem"}
            pb={1}
          >
            {itemNameBarChart.length > 0 ? (
              <Flex
                direction={{
                  base: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                }}
                alignItems={"flex-start"}
                gap={{ base: 2, md: 0 }}
                mt={{ base: 2, sm: 2, md: 3, lg: 3 }}
                mb={{ base: 6, sm: 6, md: 6, lg: 6 }}
                ml={{ base: 2, sm: 2, md: 2, lg: 4 }}
                mr={{ base: 2, sm: 2, md: 2, lg: 4 }}
              >
                <PosSearch
                  value={searchQuery != undefined ? searchQuery : ""}
                  onChange={(e) => {
                    let value =
                      searchQuery?.length > 0
                        ? e.target.value
                        : e.target.value.trim();
                    setSearchQuery(value);
                  }}
                  onSearch={handleSearchList}
                  handleClear={handleClear}
                />
              </Flex>
            ) : null}

            {loading ? (
              <PosProgress />
            ) : totalcount > 0 ? (
              <Flex
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "stretch", md: "center" }}
                gap={{ base: 2, md: 0 }}
                mt={6}
                mb={3}
                ml={4}
                mr={4}
              >
                <PosDataGridTable
                  columns={columnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModel}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    // effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    // effectRun.current = true;
                    sortRef.current = sortModel;
                    setSortModel(newSortModel);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.DASHBOARD_TOP_ITEM_SALES_EXPORT_FILENAME}
                />
              </Flex>
            ) : (
              <Flex
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "stretch", md: "center" }}
                gap={{ base: 2, md: 0 }}
                mt={6}
                mb={3}
                ml={4}
                mr={4}
              >
                <PosNoDataFound
                  title={Constants.REPORT_ITEMS_BY_SALES_NO_DATA}
                />
              </Flex>
            )}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default WithRouter(ItemsBySalesReport);
