import {
  Alert,
  AlertDescription,
  AlertTitle,
  Image,
  useToast,
  VStack
} from "@chakra-ui/react";
import React from "react";
import Colors from "../../assets/colors/Colors";
import warningImage from "../../assets/images/danger.svg";
import errorImage from "../../assets/images/octicon_x-16.svg";
import SuccessImage from "../../assets/images/typcn_tick.svg";
import * as Constants from "../../constants/Constants";

const PosTostMessage = (props) => {
  const toast = useToast();

  const addToast = (newRes) => {
    toast({
      position: Constants.TOAST_POSITION,
      duration: 2000,
      render: () => (
        <Alert
          status={newRes.alertStatus}
          variant="left-accent"
          width={"24.68rem"}
          // height={"5.313rem"}
          bg={
            newRes.alertStatus == Constants.TOAST_TYPE_SUCESS
              ? Colors.loginAuthBackground
              : newRes.alertStatus == Constants.TOAST_TYPE_WARNING
              ? Colors.toastWarning
              : Colors.loginAuthBackground
          }
          style={{
            boxShadow: "0px 4px 30px 0px" + Colors.toastBoxShadow,
          }}
        >
          <Image
            boxSize={10}
            src={
              newRes.alertStatus == Constants.TOAST_TYPE_SUCESS
                ? SuccessImage
                : newRes.alertStatus == Constants.TOAST_TYPE_WARNING
                ? warningImage
                : errorImage
            }
            p="0.38rem"
          />
          <VStack alignItems="flex-start" ml={1}>
            <AlertTitle
              align={"left"}
              fontSize="1.125rem"
              fontWeight={500}
              color={Colors.posTextInfo}
            >
              {newRes.alertTitle}
            </AlertTitle>
            <AlertDescription
              pt={0}
              fontSize="0.938rem"
              fontWeight={300}
              color={Colors.posTextInfo}
            >
              {newRes.alertDescription}
            </AlertDescription>
          </VStack>
        </Alert>
      ),
    });
  };
  return { addToast };
};

export default PosTostMessage;
