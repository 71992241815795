import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation
} from "react-router-dom";
import { AppContext, PosProgress } from "../../../components/index";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
import { getDountimeDetector } from "../AuthService";

const DountimeDetector = (props) => {
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();
  const effectRun = useRef(true);
  const [loading, setLoading] = useState(true);
  const [DountimeDeather, setDountimeDeather] = useState("");

  useEffect(() => {
    if (effectRun.current === true) {
      fetchData();
      effectRun.current = false;
    }
  }, [loading]);

  const fetchData = async () => {
    myContext.handleLoading(true);
    setLoading(true);
    try {
      getDountimeDetector()
        .then((response) => {
          if (
            undefined !== response.data &&
            null !== response.data
          ) {
            setDountimeDeather(response.data.status);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setDountimeDeather([]);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setDountimeDeather([]);
          setLoading(false);
          // let actionData = err;
          // error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setDountimeDeather([]);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  return (
    <Flex
      bg="black"
      minHeight={"100vh"}
      flexDirection={"column"}
    >
   {loading ? (
      <PosProgress />
    ) : (
      DountimeDeather === "Server is running" ? (
        <Box p={4}>
          <Text
            style={{
              color: "white",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            {"{\"status\" : \"Server is running\"}"}
          </Text>
        </Box>
      ) : (
        <PosProgress />
      )    
    )}
    </Flex>
  );
};

export default RouteWithLayout(DountimeDetector);
