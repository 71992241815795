import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getLocationsAndCategories({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_LOCATION_AND_CATEGORY_GET_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getLocationsCategoriesAndTaxs(props) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_LOCATION_AND_CATEGORY_AND_TAX_GET_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getVendorList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_VENDOR_LIST_GET_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createProduct(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_CREATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// export function createProduct({ request, params }) {
//   return new Promise(async (resolve, reject) => {
//     let token = localStorage.getItem("token");
//     let formData = await request.formData();
//     const payload = {
//       name: Object.fromEntries(formData).name,
//       description: Object.fromEntries(formData).description,
//       barcode: Object.fromEntries(formData).barcode,
//       sku: Object.fromEntries(formData).sku,
//       sale_price: Object.fromEntries(formData).sale_price,
//       cost: Object.fromEntries(formData).cost,
//       additional_cost: Object.fromEntries(formData).additional_cost,
//       no_of_eaches: Object.fromEntries(formData).no_of_eaches,
//       custom_per: Object.fromEntries(formData).custom_per,
//       in_stock_qty: Object.fromEntries(formData).in_stock_qty,
//       reward_points: Object.fromEntries(formData).reward_points,
//       threshold: Object.fromEntries(formData).threshold,
//       background_color: Object.fromEntries(formData).background_color,
//       border_color: Object.fromEntries(formData).border_color,
//       text_color: Object.fromEntries(formData).text_color,
//       age_verification:
//         Object.fromEntries(formData).age_verification == "true" ||
//         Object.fromEntries(formData).age_verification == true
//           ? true
//           : false,
//       age_verification_selected:
//         Object.fromEntries(formData).age_verification == "true" ||
//         Object.fromEntries(formData).age_verification == true
//           ? true
//           : false,
//       min_age_verification:
//         Object.fromEntries(formData).age_verification == "true" ||
//         Object.fromEntries(formData).age_verification == true
//           ? Object.fromEntries(formData).min_age_verification
//           : 0, //TODO
//       // min_age_verification: 0,//TODO
//       exclude_ebt:
//         Object.fromEntries(formData).exclude_ebt == "true" ||
//         Object.fromEntries(formData).exclude_ebt == true
//           ? true
//           : false,
//       is_exclude_kds:
//         Object.fromEntries(formData).is_exclude_kds == "true" ||
//         Object.fromEntries(formData).is_exclude_kds == true
//           ? true
//           : false,
//       is_variable_price:
//         Object.fromEntries(formData).is_variable_price == "true" ||
//         Object.fromEntries(formData).is_variable_price == true
//           ? true
//           : false,
//       prompt_qty:
//         Object.fromEntries(formData).prompt_qty == "true" ||
//         Object.fromEntries(formData).prompt_qty == true
//           ? true
//           : false,
//       sold_by_weight:
//         Object.fromEntries(formData).sold_by_weight == "true" ||
//         Object.fromEntries(formData).sold_by_weight == true
//           ? true
//           : false,

//       online_is_featured_product:
//         Object.fromEntries(formData).online_is_featured_product == "true" ||
//         Object.fromEntries(formData).online_is_featured_product == true
//           ? true
//           : false,
//       online_ordering_price: Object.fromEntries(formData).online_ordering_price,

//       selectedLocations: JSON.parse(
//         Object.fromEntries(formData).selectedLocations
//       ),
//       role: JSON.parse(Object.fromEntries(formData).selectedCategories),

//       // tax: Object.fromEntries(formData).tax,
//       selectedTaxs:
//         null != Object.fromEntries(formData).selectedTaxes &&
//         "" != Object.fromEntries(formData).selectedTaxes
//           ? JSON.parse(Object.fromEntries(formData).selectedTaxes)
//           : [], //TODO

//       // modifierset: Object.fromEntries(formData).modifierset,
//       selectedModifier:
//         null != Object.fromEntries(formData).selectedModifierSets &&
//         "" != Object.fromEntries(formData).selectedModifierSets
//           ? JSON.parse(Object.fromEntries(formData).selectedModifierSets)
//           : [], //TODO

//       // tare_containers: Object.fromEntries(formData).tare_containers,
//       selected_tare_containers:
//         Object.fromEntries(formData).sold_by_weight == "true" ||
//         Object.fromEntries(formData).sold_by_weight == true
//           ? JSON.parse(Object.fromEntries(formData).selectedTareContainers)
//           : [], //TODO

//       vendors: JSON.parse(Object.fromEntries(formData).selectedVendors),
//       // vendors: [],
//     };
//     axios
//       .post(
//         Constants.REACT_APP_API_URL +
//           process.env.REACT_APP_API_VERSION +
//           Constants.PRODUCT_CREATE_API_ROUTE,
//         payload,
//         {
//           headers: {
//             "content-type": Constants.CONTANT_TYPE,
//             Authorization: "Bearer" + token,
//             Permission: Constants.PRODUCT_CREATE_API_PERMISSION,
//           },
//         }
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         let errorData = {
//           error: true,
//           response: error.response,
//         };
//         resolve(errorData);
//       });
//   });
// }

export function showProductData(id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_EDIT_API_ROUTE +
          `/${id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// export function showProductData({ request, params }) {
//   return new Promise(async (resolve, reject) => {
//     const id = 182;
//     let token = localStorage.getItem("token");
//     axios
//       .get(
//         Constants.REACT_APP_API_URL +
//           process.env.REACT_APP_API_VERSION +
//           Constants.PRODUCT_EDIT_API_ROUTE +
//           `/${id}`,
//         {
//           headers: {
//             "content-type": Constants.CONTANT_TYPE,
//             Authorization: "Bearer" + token,
//             Permission: Constants.PRODUCT_EDIT_API_PERMISSION,
//           },
//         }
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

export function updateProduct(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_EDIT_API_ROUTE +
          `/${payload.id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_EDIT_API_PERMISSION,
          },
          timeout:7200000
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        // };
        reject(error);
      });
  });
}

// export function updateProduct({ request, params }) {
//   return new Promise(async (resolve, reject) => {
//     let token = localStorage.getItem("token");
//     const id = params.id;
//     let formData = await request.formData();
//     const payload = {
//       id: id,
//       name: Object.fromEntries(formData).name,
//       description:
//         null != Object.fromEntries(formData).description &&
//         "null" != Object.fromEntries(formData).description
//           ? Object.fromEntries(formData).description
//           : "",
//       barcode: Object.fromEntries(formData).barcode,
//       sku:
//         null != Object.fromEntries(formData).sku &&
//         "null" != Object.fromEntries(formData).sku
//           ? Object.fromEntries(formData).sku
//           : "",
//       sale_price: Object.fromEntries(formData).sale_price,
//       cost: Object.fromEntries(formData).cost,
//       additional_cost: Object.fromEntries(formData).additional_cost,
//       no_of_eaches: Object.fromEntries(formData).no_of_eaches,
//       custom_per: Object.fromEntries(formData).custom_per,
//       in_stock_qty: Object.fromEntries(formData).in_stock_qty,
//       reward_points: Object.fromEntries(formData).reward_points,
//       threshold:
//         null != Object.fromEntries(formData).threshold &&
//         "" != Object.fromEntries(formData).threshold
//           ? Object.fromEntries(formData).threshold
//           : null,
//       background_color: Object.fromEntries(formData).background_color,
//       border_color: Object.fromEntries(formData).border_color,
//       text_color: Object.fromEntries(formData).text_color,
//       age_verification:
//         Object.fromEntries(formData).age_verification == "true" ||
//         Object.fromEntries(formData).age_verification == true
//           ? true
//           : false,
//       age_verification_selected:
//         Object.fromEntries(formData).age_verification == "true" ||
//         Object.fromEntries(formData).age_verification == true
//           ? true
//           : false,
//       min_age_verification:
//         Object.fromEntries(formData).age_verification == "true" ||
//         Object.fromEntries(formData).age_verification == true
//           ? Object.fromEntries(formData).min_age_verification
//           : 0, //TODO
//       // min_age_verification: 0,//TODO
//       exclude_ebt:
//         Object.fromEntries(formData).exclude_ebt == "true" ||
//         Object.fromEntries(formData).exclude_ebt == true
//           ? true
//           : false,
//       is_exclude_kds:
//         Object.fromEntries(formData).is_exclude_kds == "true" ||
//         Object.fromEntries(formData).is_exclude_kds == true
//           ? true
//           : false,
//       is_variable_price:
//         Object.fromEntries(formData).is_variable_price == "true" ||
//         Object.fromEntries(formData).is_variable_price == true
//           ? true
//           : false,
//       prompt_qty:
//         Object.fromEntries(formData).prompt_qty == "true" ||
//         Object.fromEntries(formData).prompt_qty == true
//           ? true
//           : false,
//       sold_by_weight:
//         Object.fromEntries(formData).sold_by_weight == "true" ||
//         Object.fromEntries(formData).sold_by_weight == true
//           ? true
//           : false,

//       online_is_featured_product:
//         Object.fromEntries(formData).online_is_featured_product == "true" ||
//         Object.fromEntries(formData).online_is_featured_product == true
//           ? true
//           : false,
//       online_ordering_price:
//         null != Object.fromEntries(formData).online_ordering_price &&
//         "" != Object.fromEntries(formData).online_ordering_price
//           ? Object.fromEntries(formData).online_ordering_price
//           : null,
//       tax: "",
//       additional:
//         null != Object.fromEntries(formData).additional &&
//         "" != Object.fromEntries(formData).additional
//           ? JSON.parse(Object.fromEntries(formData).additional)
//           : [],
//       location_id: Object.fromEntries(formData).location_id,
//       // selectedLocations: JSON.parse(
//       //   Object.fromEntries(formData).selectedLocations
//       // ),
//       location: JSON.parse(Object.fromEntries(formData).selectedLocations),
//       role: JSON.parse(Object.fromEntries(formData).selectedCategories),

//       // tax: Object.fromEntries(formData).tax,
//       selected_tax:
//         null != Object.fromEntries(formData).selectedTaxes &&
//         "" != Object.fromEntries(formData).selectedTaxes
//           ? JSON.parse(Object.fromEntries(formData).selectedTaxes)
//           : [], //TODO

//       // modifierset: Object.fromEntries(formData).modifierset,
//       selected_modifierSet:
//         null != Object.fromEntries(formData).selectedModifierSets &&
//         "" != Object.fromEntries(formData).selectedModifierSets
//           ? JSON.parse(Object.fromEntries(formData).selectedModifierSets)
//           : [], //TODO

//       // tare_containers: Object.fromEntries(formData).tare_containers,
//       selected_tare_containers:
//         Object.fromEntries(formData).sold_by_weight == "true" ||
//         Object.fromEntries(formData).sold_by_weight == true
//           ? JSON.parse(Object.fromEntries(formData).selectedTareContainers)
//           : [], //TODO

//       vendors: JSON.parse(Object.fromEntries(formData).selectedVendors),
//       // vendors: [],
//     };
//     axios
//       .put(
//         Constants.REACT_APP_API_URL +
//           process.env.REACT_APP_API_VERSION +
//           Constants.PRODUCT_EDIT_API_ROUTE +
//           `/${id}`,
//         payload,
//         {
//           headers: {
//             "content-type": Constants.CONTANT_TYPE,
//             Authorization: "Bearer" + token,
//             Permission: Constants.PRODUCT_EDIT_API_PERMISSION,
//           },
//         }
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         let errorData = {
//           error: true,
//           response: error.response,
//         };
//         resolve(errorData);
//       });
//   });
// }

export function getProductListForEdit(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          "product-paginate",
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function productDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PRODUCT_DEACTIVE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.TAX_VIEW_API_PERMISSION,
              Authorization: "Bearer" + token,
              Permission: Constants.PRODUCT_DEACTIVE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PRODUCT_REACTIVE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PRODUCT_REACTIVE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getProductListForDeactive(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_DEACTIVE_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_DEACTIVE_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function productSalePriceChange(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_SALE_PRICE_CHANGE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_SALE_PRICE_CHANGE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function viewProductData(id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_VIEW_API_ROUTE +
          `/${id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProductListForQuickEdit(id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_GET_QUICK_EDIT_API_ROUTE +
          `/${id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_QUICK_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function quickEditProduct(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_QUICK_EDIT_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_QUICK_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function duplicateProduct(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_DUPLICATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_DUPLICATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function printLargeSign(id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_PRINT_LARGE_SIGN_API_ROUTE +
          `/${id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_PRINT_LARGE_SIGN_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllLocation({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_GET_LOCATION_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_IMPORT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getUploadedFilesList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_GET_UPLOADED_FILE_DTLS_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_IMPORT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function importProductData(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_IMPORT_CLICK_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_IMPORT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function exportErrorExcel(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_EXPORT_ERROR_EXCEL_FILE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_IMPORT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function exportSuccessCSV(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_EXPORT_SUCCESS_CSV_FILE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_IMPORT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteProcessingFile(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_DELETE_FILE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_IMPORT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function exportProductData(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_EXPORT_API_PERMISSION,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_IMPORT_API_PERMISSION,
          },
          timeout:7200000
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function bulkUploadProductsGet(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_GET_BULK_UPLOAD_PRODUCT_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PRODUCT_SALE_PRICE_CHANGE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
