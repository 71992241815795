import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import Colors from "../../assets/colors/Colors";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
  tab: {
    fontSize: "0.94rem",
    fontWeight: 500,
    width: "9.38rem",
    height: "3.38rem",
    color: Colors.posTextInfo,
    borderTopRadius: "lg",
    borderBottom: "1px",
    overflowY: "hidden",
    _selected: {
      fontSize: "0.94rem",
      fontWeight: 500,
      color: Colors.posTextInfo,
      borderBottom: "3px solid ",
      borderBottomColor: Colors.taxInfo,
    },
    _hover: {
      borderBottom: "2x solid",
      // borderBottomColor: Colors.notificationIcon,
      borderBottomColor: Colors.taxInfo
    },
  },
  tablist: {
    color: Colors.posTextInfo,
  },
  tabpanel: {},
});

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ baseStyle });
