import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AppContext } from "..";
import Colors from "../../assets/colors/Colors";

const NavItem = ({
  active,
  item,
  curruntRoute,
  handleChangeRoute,
  subnav,
  handleChangeMainRoute,
  handleClose,
}) => {
  const myContext = useContext(AppContext);
  const { pathname } = useLocation();
  const Navindex = item?.subNav?.findIndex((x) => x.title === curruntRoute);

  return (
    <Box w={"100%"}>
      <VStack
        h={"2.38rem"}
        w={"100%"}
        justifyContent={subnav ? "flex-end" : "center"}
        alignItems={"flex-start"}
      >
        {item.subNav ? (
          <>
            <HStack
              spacing={4}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              onClick={() => {
                handleChangeMainRoute(item.title);
              }}
              p="0rem 0.63rem 0.63rem 1.94rem"
              className="pointer"
            >
              <Image
                boxSize={"0.82rem"}
                src={active && Navindex > -1 ? item.activeIcon : item.icon}
                alt={item.title}
              />
              <Text
                align="left"
                fontSize="0.85rem"
                textTransform={"capitalize !important"}
                fontWeight={active ? 600 : 400}
                color={
                  active && Navindex > -1 ? Colors.mainHeader : Colors.formLable
                }
                lineHeight={5}
              >
                {item.title === "HR" ? "HR" : item.title.toLowerCase()}
              </Text>
            </HStack>
          </>
        ) : (
          <NavLink
            to={item.path}
            onClick={() => {
              if (item.path !== pathname) {
                myContext.handleLoading(true);
                handleChangeRoute("");
                handleChangeMainRoute("");
                handleClose();
              }
            }}
          >
            <HStack
              spacing={4}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              p="0rem 0.63rem 0.63rem 1.94rem"
            >
              <Image
                boxSize={"0.81rem"}
                src={active ? item.activeIcon : item.icon}
                alt={item.title}
              />
              <Text
                align="left"
                fontSize="0.85rem"
                textTransform={"capitalize !important"}
                fontWeight={active ? 600 : 400}
                color={active ? Colors.posNavbarLink : Colors.formLable}
              >
                {item.title.toLowerCase()}
              </Text>
            </HStack>
          </NavLink>
        )}
      </VStack>
      {subnav &&
        item.subNav.map((subitem, index) => {
          return (
            <VStack
            h={"2.38rem"}
              p={0}
              key={index}
              justifyContent="center"
              alignItems={"flex-start"}
              mb={item.subNav.length === index + 1 ? 1 : 0}
              bg={
                subitem.title === curruntRoute ? Colors.subMenu : "transparent"
              }
            >
              <NavLink
                to={subitem.path}
                onClick={() => {
                  if (subitem.path !== pathname) {
                    myContext.handleLoading(true);
                    handleChangeRoute(subitem.title);
                    handleClose();
                  }
                }}
              >
                <HStack
                  spacing={4}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  pl={"4.4rem"}
                >
                  <Text
                    align="left"
                    fontSize="0.85rem"
                    fontWeight={subitem.title === curruntRoute ? 600 : 400}
                    color={
                      subitem.title == curruntRoute
                        ? Colors.posNavbarLink
                        : Colors.formLable
                    }
                  >
                    {subitem.title}
                  </Text>
                </HStack>
              </NavLink>
            </VStack>
          );
        })}
    </Box>
  );
};

export default NavItem;
