import establishmentBlack from "../../assets/images/master-admin/establishmentBlack.png";
import establishmentBlue from "../../assets/images/master-admin/establishmentBlue.png";
import handshakeBlack from "../../assets/images/master-admin/handshakeBlack.png";
import handshakeBlue from "../../assets/images/master-admin/handshakeBlue.png";
import hrBlack2 from "../../assets/images/master-admin/hrBlack2.png";
import hrBlue from "../../assets/images/master-admin/hrBlue.png";
import integrationBlack2 from "../../assets/images/master-admin/integrationBlack2.png";
import integrationBlue from "../../assets/images/master-admin/integrationBlue.png";
import postatBlack from "../../assets/images/master-admin/postatBlack.png";
import postatBlue from "../../assets/images/master-admin/postatBlue.png";
import * as Constants from "../../constants/Constants";

export const MasterAdminSidebarData = [
  {
    title: Constants.MASTER_ADMIN_WELCOME_TITLE,
    path:
      Constants.SIGNIN_PATH +
      process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
      Constants.MASTER_ADMIN_WELCOME_PATH,
    icon: handshakeBlack,
    activeIcon: handshakeBlue,
  },
  {
    title: Constants.MASTER_ADMIN_ROLES_TITLE,
    path:
      Constants.SIGNIN_PATH +
      process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
      Constants.MASTER_ADMIN_ROLES_PATH,
    icon: hrBlack2,
    activeIcon: hrBlue,
    CrudPath: [Constants.SIGNIN_PATH+process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +Constants.MASTER_ADMIN_ROLES_PATH,
      Constants.SIGNIN_PATH + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_ROLE_CREATE,
      Constants.SIGNIN_PATH + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_ROLE_EDIT]
  },
  {
    title: Constants.MASTER_ADMIN_LOCATIONS_TITLE,
    path:
      Constants.SIGNIN_PATH +
      process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
      Constants.MASTER_ADMIN_LOCATIONS_PATH,
    icon: establishmentBlack,
    activeIcon: establishmentBlue,
    CrudPath: [Constants.SIGNIN_PATH +process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_LOCATIONS_PATH,
      Constants.SIGNIN_PATH +process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_LOCATION_CREATE,
      Constants.SIGNIN_PATH +process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_LOCATION_SETTING_OPTIONS_PAGE,
      Constants.SIGNIN_PATH + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_LOCATION_EDIT]
  },
  {
    title: Constants.MASTER_ADMIN_POS_STATIONS_TITLE,
    path:
      Constants.SIGNIN_PATH +
      process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
      Constants.MASTER_ADMIN_POS_STATIONS_PATH,
    icon: postatBlack,
    activeIcon: postatBlue,
    CrudPath: [Constants.SIGNIN_PATH +process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_STATIONS_PATH,
      Constants.SIGNIN_PATH +process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_CREATE,
      Constants.SIGNIN_PATH + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_EDIT,
      Constants.SIGNIN_PATH + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_SETTING_DUPLICATE,
      Constants.SIGNIN_PATH + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_VIEW_SETTING]
  },
  {
    title: Constants.MASTER_ADMIN_GLOBLE_SETTINGS_TITLE,
    path:
      Constants.SIGNIN_PATH +
      process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
      Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
    icon: integrationBlack2,
    activeIcon: integrationBlue,
    CrudPath: [Constants.SIGNIN_PATH +process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
      Constants.SIGNIN_PATH +process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_GLOBLE_SETTINGS_CREATE_PATH,
      Constants.SIGNIN_PATH + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_GLOBLE_SETTINGS_EDIT_PATH]
  },
];
