import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getAllDeactiveModifierSet,
  getAllModifierSet,
} from "./ModifierSetService";
const ModifierSetList = (props) => {
  const { addToast } = PosTostMessage();
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isMobile] = useMediaQuery("(max-width:768px)");
  const [modifierSetData, setModifierSetData] = useState([]);
  const [modifierSetDeactiveData, setModifierSetDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90},
    {
      field: "name",
      headerName: "Modifier Set Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "only_one",
      headerName: "Only One",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "modifier_set_sort",
      headerName: "Modifier Set Sort",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          // pass backtraking relate all data
          props.navigate(`${Constants.MODIFIER_SET_EDIT + item.id}`, {
            state: data,
          });
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.MODIFIER_SET_LIST,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <EditIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "Deactive",
              disabledButton:buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
      pinned: true
    },
  ];

  const columnNamesDeactive = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Modifier Set Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "only_one",
      headerName: "Only One",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          setButtonDisable(true);
          if (
            deactiveModifierSetData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.MODIFIER_SET_LIST,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? Colors.posNavbarLink : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];
  const [paginationModel, setPaginationModel] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );
  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);

  const fetchData = async () => {
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getAllModifierSet(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setModifierSetData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setModifierSetData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setModifierSetData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setModifierSetData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const fetchDeActiveModifierSetData = async () => {
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].field : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].sort : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getAllDeactiveModifierSet(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setModifierSetDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setModifierSetDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!deactiveSearchHideRef.current && err?.response?.data?.data === 0) {
            deactiveSearchHideRef.current = true;
          }
          setModifierSetDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setModifierSetDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data.message.trim() ===
          Constants.MODIFIER_SET_DEACTIVE_SUCCESS
        ) {
          setLoading(true);
          if(modifiedData.length === 1 && paginationModel?.page !== 0){
            effectRun.current = true
              setPaginationModel((prevPaginationMod) => {
                return {
                  page:prevPaginationMod?.page - 1,
                  pageSize:prevPaginationMod?.pageSize,
                }
              })
          } else {
            fetchData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.MODIFIER_SET,
            alertDescription: Constants.MODIFIER_SET_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          if(deactiveModifierSetData.length === 1 && deactiveModifierSetData?.page !== 0){
            effectRun.current = true
              setPaginationModel((prevPaginationMod) => {
                return {
                  page:prevPaginationMod?.page - 1,
                  pageSize:prevPaginationMod?.pageSize,
                }
              })
          } else {
            fetchDeActiveModifierSetData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.MODIFIER_SET,
            alertDescription: Constants.MODIFIER_SET_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
    }
  }, [actionResponse]);

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      effectRun.current = true;
      setLoading(true);
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      setSortModel([
        {
          field: "id",
          sort: "desc",
        },
      ]);
    } else {
      searchInputHideRef.current = false;
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      setSearchQuery("");
      effectRun.current = true;
      setLoading(true);
    }
  };

  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveModifierSetData();
      }
      effectRun.current = false;
    }
  }, [
    searchQuery,
    paginationModel,
    sortModel,
    loading,
    curruntTab,
    paginationModelDeactive,
    sortModelDeactive
  ]);

  const handleSearchList = () => {
    paginationModel.page = 0;
    props.navigate(Constants.MODIFIER_SET_LIST); 
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveModifierSetData();
    }
  };

  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.MODIFIER_SET_LIST); 
  };

  const modifiedData = modifierSetData.map((modifierSet) => ({
    ...modifierSet,
    only_one: modifierSet.only_one == 0 ? "No" : "Yes",
    modifier_set_sort:
      modifierSet.modifier_set_sort == null ||
      modifierSet.modifier_set_sort == ""
        ? "N.A."
        : modifierSet.modifier_set_sort,
  }));

  const deactiveModifierSetData = modifierSetDeactiveData.map(
    (modifierSet) => ({
      ...modifierSet,
      only_one: modifierSet.only_one == 0 ? "No" : "Yes",
      modifier_set_sort:
        modifierSet.modifier_set_sort == null ||
        modifierSet.modifier_set_sort == ""
          ? "N.A."
          : modifierSet.modifier_set_sort,
    })
  );
  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Modifiers"}
          discription={"Manage modifiers for your products"}
        />
        <Spacer />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true);
              // backtracking
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                    filter:curruntTab == 1 ? "":searchQuery ,
              };
              // pass all backtraking ralated all in create modifier set route
              props.navigate(Constants.MODIFIER_SET_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={(e) => {
                let value =
                  searchQuery.length > 0
                    ? e.target.value
                    : e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
            <Spacer />
          </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      effectRun.current = true;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.ACTIVE_MODIFIER_SET_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.MODIFIER_SET_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNamesDeactive}
                    rows={deactiveModifierSetData}
                    totalcount={deactiveTotalCount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModelDeactive}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModelDeactive) => {
                      effectRun.current = true;
                      setPaginationModelDeactive(newPaginationModelDeactive);
                    }}
                    // sortModel={sortModelDeactive}
                    // onSortModelChange={(newSortModelDeactive) => {
                    //   sortRefDeactive.current = sortModelDeactive;
                    //   effectRun.current = true;
                    //   setSortModel(newSortModelDeactive);
                    // }}
                    sortModel={sortModelDeactive}
                    onSortModelChange={(newSortModelDeactive) => {
                      effectRun.current = true
                      sortRef.current = sortModelDeactive;
                      setSortModelDeactive(newSortModelDeactive);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.DEACTIVE_MODIFIER_SET_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound
                  title={Constants.DEACTIVE_MODIFIER_SET_NOT_FOUND}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(ModifierSetList);
