import { Box, Flex } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useActionData, useLocation, useParams } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosMainProgress,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";
import { downloadDailyLowInventoryProducts } from "../AuthService";

const DailyLowInventoryProducts = (props) => {
  const [loading, setLoading] = useState(true);
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let obj = useParams();
  obj.key1 = queryParams.key1;
  obj.key2 = queryParams.key2;
  obj.count = 0;
  const { addToast } = PosTostMessage();
  useEffect(() => {
    fetchData();
  }, [actionResponse]);

  const fetchData = async () => {
    myContext.handleLoading(true);
    let data = {
      key1: queryParams.get("key1"),
      key2: queryParams.get("key2"),
    };
    setLoading(true);
    try {
      downloadDailyLowInventoryProducts(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            // const generateURL = Constants.REACT_APP_API_URL + response.data.data;
            // const url = window.URL.createObjectURL(new Blob([generateURL]));
            // const link = document.createElement("a");
            // link.href = url;
            // link.setAttribute(Constants.DOWNLOAD, Constants.PUBLIC_DOWNLOAD_DAILY_LOW_INVENTORY_PRODUCTS_EXPORT);
            // document.body.appendChild(link);
            // window.stop();
            // link.click();
            // window.URL.revokeObjectURL(url);
            // link.remove();
            const url = Constants.REACT_APP_API_URL + response.data.data;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              Constants.DOWNLOAD,
              Constants.PUBLIC_DOWNLOAD_DAILY_LOW_INVENTORY_PRODUCTS_EXPORT
            );
            document.body.appendChild(link);
            window.stop();
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
            window.open(window.location.href, "_self").close(); // Attempt to close the current tab
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  return (
    <Flex
      minHeight={"100vh"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      background={Colors.loginMainBackground}
    >
      <Box
        width={"100%"}
        sx={{
          /* Safari */ position: "absolute",
          top: "0",
          zIndex: 99999999,
        }}
      >
        {myContext.loading ? <PosMainProgress /> : null}
      </Box>
      Please Wait...
    </Flex>
  );
};

export default RouteWithLayout(DailyLowInventoryProducts);
