import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getAllEmployees(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.EMPLOYEES_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.EMPLOYEES_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllDeactiveEmployees(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.EMPLOYEES_DEACTIVE_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.EMPLOYEES_DEACTIVE_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function employeesDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      let data = {
        email: Object.fromEntries(formData).email,
        name: Object.fromEntries(formData).name,
      };
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.EMPLOYEES_DEACTIVE_API_ROUTE +
            "/" +
            id,
          {
            data: data,
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.EMPLOYEES_DEACTIVE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      let data = {
        email: Object.fromEntries(formData).email,
        name: Object.fromEntries(formData).name,
      };
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.EMPLOYEES_REACTIVE_API_ROUTE +
            "/" +
            id,
          data,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.EMPLOYEES_REACTIVE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    }
  });
}

export function getAllRoles() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLES_FOR_EMPLOYEES_CREATE_API_ROUTE,
        {},
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLES_PERMISSION_FOR_EMPLOYEES_CREATE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function employeesCreate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let data = await request.formData();
    const payload = {
      name: Object.fromEntries(data).name,
      email: Object.fromEntries(data).email,
      password: Object.fromEntries(data).password,
      confirmPassword: Object.fromEntries(data).confirmPassword,
      pin: Object.fromEntries(data).pin,
      wage_per_hour: Object.fromEntries(data).wagesPerHour,
    };
    payload.role = JSON.parse(Object.fromEntries(data).role);
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.EMPLOYEES_CREATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.EMPLOYEES_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getSingleEmployees({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.EMPLOYEES_VIEW_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.EMPLOYEES_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}
export function getSingleEmployeesForView({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.EMPLOYEES_VIEW_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.EMPLOYEES_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}
export function employeesUpdate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let data = await request.formData();
    const payload = {
      name: Object.fromEntries(data).name,
      email: Object.fromEntries(data).email,
      pin: Object.fromEntries(data).pin,
      wage_per_hour: Object.fromEntries(data).wage_per_hour,
      id: Object.fromEntries(data).id,
      oldemail: Object.fromEntries(data).oldemail,
    };
    payload.role = JSON.parse(Object.fromEntries(data).role);
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.EMPLOYEES_UPDATE_API_ROUTE +
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.EMPLOYEES_UPDATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

// export function employeesClockInOut({ request, params }) {
//   return new Promise(async (resolve, reject) => {
//     let token = localStorage.getItem("token");
//     // const id = params.id;
//     let data = await request.formData();
//     const payload = {
//       clock_in_flag: Object.fromEntries(data).flag,
//       user_id: Object.fromEntries(data).id,
//       location_id: Object.fromEntries(data).locId,
//     };
//     axios
//       .post(
//         Constants.REACT_APP_API_URL +
//           process.env.REACT_APP_API_VERSION +
//           "update-clock-in-out-status",
//         payload,
//         {
//           headers: {
//             "content-type": Constants.CONTANT_TYPE,
//             Authorization: "Bearer" + token,
//             Permission: Constants.EMPLOYEES_UPDATE_API_PERMISSION,
//           },
//         }
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         let errorData = {
//           error: true,
//           response: error.response,
//           code: error.code
//         };
//         resolve(errorData);
//       });
//   });
// }

export function employeesClockInOut(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.EMPLOYEES_CLOCK_IN_OUT_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.EMPLOYEES_UPDATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
