import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useSubmit,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
//   import Phone from "../../../../../assets/images/";
import {
  AppContext,
  PosBreadCrumb,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosTextArea,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { twofix } from "../../../../../helpers/utils/Utils";

const PayInEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [posStation, setposStationData] = useState([]);
  const [users, setUserData] = useState([]);
  const [selectedPosstationData, setSelectedPosstationDataData] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state); // store in state

  useEffect(() => {
    if (loaderResponse?.response?.data?.data?.tillCheck === 1) {
      // addToast({
      //   alertStatus: Constants.TOAST_TYPE_WARNING,
      //   alertTitle: Constants.PAYIN_TITLE,
      //   alertDescription: Constants.PAY_IN_TILL_IS_OPENED,
      // });
      myContext.handleLoading(true);
      props.navigate(`${Constants.PAY_IN_REPORT_ROUTE}`,{
        state: stateData.state,
      });
    }
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setposStationData(loaderResponse?.data?.data?.stations);
          const posStation = loaderResponse.data.data.stations;
          const PosLocationId = loaderResponse.data.data.payIn.pos_station_id;
          const matchingPoslocations = posStation.find(
            (posLocation) => posLocation.id === PosLocationId
          );
          setSelectedPosstationDataData(matchingPoslocations);
          setUserData(loaderResponse?.data?.data?.users);
          const UserData = loaderResponse.data.data.users;
          const UserId = loaderResponse.data.data.payIn.user_id;
          const matchingUsers = UserData.find((users) => users.id === UserId);
          setSelectedUserData(matchingUsers);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.PAY_IN_REPORT_ROUTE, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.PAYIN_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedPosstationDataData(e.value);
      formik.setFieldValue("selectedPosStation", e.value.name);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedPosStation:
        undefined !== loaderResponse?.data?.data?.payIn?.station?.name &&
        null != loaderResponse?.data?.data?.payIn?.station?.name
          ? loaderResponse?.data?.data?.payIn?.station?.name
          : "",
      selectedUsers:
        undefined !== loaderResponse?.data?.data?.payIn?.users?.name &&
        null != loaderResponse?.data?.data?.payIn?.users?.name
          ? loaderResponse?.data?.data?.payIn?.users?.name
          : "",
      amount:
        loaderResponse?.data?.data?.payIn?.amount &&
        null != loaderResponse?.data?.data?.payIn?.amount
          ? loaderResponse?.data?.data?.payIn?.amount
          : "",
      reason:
        loaderResponse?.data?.data?.payIn?.reason &&
        null != loaderResponse?.data?.data?.payIn?.reason
          ? loaderResponse?.data?.data?.payIn?.reason
          : "",
    },
    validationSchema: Yup.object({
      selectedPosStation: Yup.string().required(
        Constants.PAY_IN_SELECT_POS_STATION
      ),
      selectedUsers: Yup.string().required(Constants.PAY_IN_SELECT_USER),
      amount: Yup.number()
        .required(Constants.PAY_IN_ENTER_AMOUNT)
        .max(999999.99, Constants.PAY_IN_MAX_VALUE)
        .min(0.01, Constants.PAY_IN_MIN_VALUE)
        .typeError(Constants.PAYOUT_PLEASE_ENTER_A_VALID_AMOUNT),
      reason: Yup.string()
        .required(Constants.PAY_IN_ENTER_REASON)
        .max(5000, Constants.PAY_IN_REASON_GREATER_VALUE),
    }),
    onSubmit: async () => {
      // myContext.handleLoading(true);
      // setButtonDisable(true);
      // actionRun.current = true;
      // submit(payload, {
      //   method: Constants.POST,
      //   path: Constants.PAY_IN_REPORT_ROUTE,
      // });
    },
  });

  const handleSubmitButton = () => {
    myContext.handleLoading(true);
    const payload = {
      amount: formik.values.amount,
      id: selectedUserData.id,
      pos_station_id: selectedPosstationData.id,
      reason: formik.values.reason,
      users: JSON.stringify({
        id: selectedUserData.id,
        name: selectedUserData.name,
      }),
      user_id: selectedUserData.id,
      station: JSON.stringify({
        id: selectedPosstationData.id,
        name: selectedPosstationData.name,
      }),
    };
    actionRun.current = true;
    setButtonDisable(true);
    submit(payload, {
      method: Constants.POST,
      path: Constants.PAY_IN_REPORT_ROUTE,
    });
  };

  const handleUserSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedUserData(e.value);
      formik.setFieldValue("selectedUsers", e.value.name);
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.selectedPosStation.length <= 0 ||
      formik.values.selectedUsers.length <= 0 ||
      formik.errors.selectedPosStation ||
      formik.errors.selectedUsers ||
      formik.errors.amount ||
      formik.errors.reason ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };

  return (
    <Box flex="1">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              if (i === 0) {
                props.navigate(Constants.REPORT_PATH, {
                  state: stateData.state,
                });
              } else if (i === 1) {
                props.navigate(Constants.PAY_IN_REPORT_ROUTE, {
                  state: stateData.state,
                });
              }
            }}
            breadCrumNames={["Report", "Pay In", "Edit a Pay In"]}
            breadCrumTitle={"Edit a Pay In"}
          />
        </Box>
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              props.navigate(Constants.PAY_IN_REPORT_ROUTE, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            buttonsubmit={"Submit"}
            SubmitButton={true}
            isDisabled={
              formik.values.selectedPosStation?.length === 0 ||
              formik.values.selectedUsers?.length === 0 ||
              formik.values.amount === "" ||
              formik.values.reason === "" ||
              formik.errors.selectedPosStation ||
              formik.errors.selectedUsers ||
              formik.errors.amount ||
              formik.errors.reason ||
              buttonDisable
            }
            onClick={handleSubmitButton}
          />
        </Stack>
      </Flex>

      <Box
        mt={{ base: 2, md: "2rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        p="8"
        onKeyDown={handleKeyDown}
      >
        <Text
          fontSize="1.23rem"
          pt={"1.56rem"}
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontSize: "1.13rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          Edit a Pay In
        </Text>
        <Stack spacing={2}>
          <PosLable
            name={"Pos Station"}
            requiredLabel={true}
            fontSize="0.94rem"
            fontStyle="normal"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          {/* <Wrap
            // alignItems={{ base: "stretch", md: "center" }}
            // spacing={2}
            // pt={5}
            // mt={6}
            // mb={4}
            // ml={4}
            // mr={4}
            > */}
          {/* <WrapItem flexDirection={"column"}> */}
          <PosDropDown
            id="selectedPosStation"
            options={posStation}
            value={selectedPosstationData}
            onChange={handleOptionSelect}
            className="w-full md:w-20rem"
            width={"100%"}
            onBlur={(e) => {
              formik.setFieldTouched("selectedPosStation");
              formik.handleBlur(e);
            }}
            multiSelect={false}
            optionLabel="name"
            placeholder="Select A Pos Station"
          />
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.selectedPosStation &&
            formik.errors.selectedPosStation ? (
              <span>{formik.errors.selectedPosStation}</span>
            ) : null}
          </Text>
          {/* </WrapItem>
            </Wrap> */}
        </Stack>
        <Stack spacing={2}>
          <PosLable
            name={"User"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosDropDown
            id="selectedUsers"
            options={users}
            value={selectedUserData}
            onChange={handleUserSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedUsers");
              formik.handleBlur(e);
            }}
            className="w-full md:w-20rem"
            width={"100%"}
            multiSelect={false}
            optionLabel="name"
            placeholder="Select A User"
          />
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.selectedUsers && formik.errors.selectedUsers ? (
              <span>{formik.errors.selectedUsers}</span>
            ) : null}
          </Text>
        </Stack>
        <Stack spacing={2}>
          <PosLable
            name={"Amount"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="amount"
            mt={"1rem"}
            placeholder={"Enter a Amount"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.amount}
            handleBlur={(e) => {
              formik.handleBlur(e);
              formik.setFieldValue("amount", twofix(formik.values.amount) || "");
            }}
            onKeyDown={(e) => {
              if (e.key === "-" || e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
            }}
            posNumberInput={true}
            inputType={"number"}
            inputError={formik.touched.amount && formik.errors.amount}
          />
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.amount && formik.errors.amount ? (
              <span>{formik.errors.amount}</span>
            ) : null}
          </Text>
        </Stack>
        <Stack spacing={2}>
          <PosLable
            requiredLabel={true}
            name={"Reason"}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosTextArea
            id="reason"
            placeholder="Enter a Reason"
            onChange={formik.handleChange}
            value={formik.values.reason}
            onBlur={formik.handleBlur}
            onKeyDown={(e) => {
              if (e.key === " " && !formik.values.reason) {
                e.preventDefault();
              }
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            inputerror={formik.touched.reason && formik.errors.reason}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.reason && formik.errors.reason ? (
              <span>{formik.errors.reason}</span>
            ) : null}
          </Text>
        </Stack>
        {null != formik.values.reason &&
        "" != formik.values.reason &&
        5000 - formik.values.reason.length > 0 ? (
          <Text
            pt={"0.13rem"}
            color={Colors.textareaColor}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={300}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
          >
            {5000 - formik.values.reason.length} characters left for reason
          </Text>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default WithRouter(PayInEdit);
