import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function getAllLocationsAndAllCustomers({ request, params }) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.GET_CUSTOMER_AND_LOCATION_API_ROUTE,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_PDF_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    });
  }

  export function getCustomerCreditAndExportReport({ request, params }) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      let formData = await request.formData();
      if (Object.fromEntries(formData).getreport === "true") {
        
        const payload = JSON.stringify({
          location: JSON.parse(Object.fromEntries(formData).location),
          allCustomers: JSON.parse(Object.fromEntries(formData).allCustomers),
          // customer: JSON.parse(Object.fromEntries(formData).customer),
          customer: JSON.parse(Object.fromEntries(formData).customer).length === 0 ? null: JSON.parse(Object.fromEntries(formData).customer),
          // paidTypes: JSON.parse(Object.fromEntries(formData).paidTypes),
          paidTypes: JSON.parse(Object.fromEntries(formData).paidTypes).length === 0 ? null: JSON.parse(Object.fromEntries(formData).paidTypes),
          dateFrom: Object.fromEntries(formData).dateFrom,
          dateTo: Object.fromEntries(formData).dateTo,
          zone: Object.fromEntries(formData).zone,
          query: JSON.parse(Object.fromEntries(formData).query),
        });
        axios
          .post(
            Constants.REACT_APP_API_URL +
              process.env.REACT_APP_API_VERSION +
              Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_API_ROUTE,
            payload,
            {
              headers: {
                "content-type": Constants.CONTANT_TYPE,
                Authorization: "Bearer" + token,
                Permission: Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_API_PERMISSION,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            let errorData = {
              error: true,
              response: error.response,
              code: error.code
            };
            resolve(errorData);
          });
      } else {
        const payload = JSON.stringify({
          location: JSON.parse(Object.fromEntries(formData).location),
          allCustomers: JSON.parse(Object.fromEntries(formData).allCustomers),
          // customer: JSON.parse(Object.fromEntries(formData).customer),
          customer: JSON.parse(Object.fromEntries(formData).customer).length === 0 ? null: JSON.parse(Object.fromEntries(formData).customer),
          // paidTypes: JSON.parse(Object.fromEntries(formData).paidTypes),
          paidTypes: JSON.parse(Object.fromEntries(formData).paidTypes).length === 0 ? null: JSON.parse(Object.fromEntries(formData).paidTypes),
          dateFrom: Object.fromEntries(formData).dateFrom,
          dateTo: Object.fromEntries(formData).dateTo,
          zone: Object.fromEntries(formData).zone,
          type: Object.fromEntries(formData).type,
        });
        axios
          .post(
            Constants.REACT_APP_API_URL +
              process.env.REACT_APP_API_VERSION +
              Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_EXPORT_API_ROUTE,
            payload,
            {
              headers: {
                "content-type": Constants.CONTANT_TYPE,
                Authorization: "Bearer" + token,
                Permission: Constants.CUSTOMER_CREDIT_ACCOUNT_EXPORT_API_PERMISSION,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            let errorData = {
              error: true,
              response: error.response,
              code: error.code
            };
            resolve(errorData);
          });
      }
    });
  }

  export function downloadPDF(payload) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_PDF_API_ROUTE,
            payload,
            // `${Constants.SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_PDF_API_ROUTE + "/" + id}`,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.CUSTOMER_CREDIT_ACCOUNT_REPORT_PDF_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  export function sendInvoiceToCustomer(payload) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.SEND_INVOICE_EMAIL_API_ROUTE,
            payload,
            // `${Constants.SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_PDF_API_ROUTE + "/" + id}`,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.SEND_INVOICE_EMAIL_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  export function getCustomerLatestBalance(payload) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      const id = payload;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            // Constants.SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_PDF_API_ROUTE,
            // payload,
            `${Constants.GET_CUSTOMER_LATEST_BALANCE_API_ROUTE + "/" + id}`,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.GET_CUSTOMER_LATEST_BALANCE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  export function payOrderUnpaidAmount(payload) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PAY_ORDER_UNPAID_AMOUNT_API_ROUTE,
            payload,
            // `${Constants.SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_PDF_API_ROUTE + "/" + id}`,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PAY_ORDER_UNPAID_AMOUNT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }