import React from 'react';

const StopClickPropagation = ({ children }) => {
  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleMouseDown = (event) => {
    event.stopPropagation();
  };

  return (
    <div onClick={handleClick} onMouseDown={handleMouseDown}>
      {children}
    </div>
  );
};

export default StopClickPropagation;