import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import { AppContext } from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { WithRouter } from "../../../navigators/WithRouter";

const salesData = [
  {
    title: "Total Daily Sales",
    href: Constants.TOTAL_DAILY_SALE,
  },
  {
    title: "Total Sales By Day",
    href: Constants.TOTAL_SALE_BY_DAY,
  },
  {
    title: "Total Daily Category Sales",
    href: Constants.TOTAL_CATEGORY_SALE,
  },
  {
    title: "Total Daily Sales by POS Station",
    href: Constants.TOTAL_SALE_BY_POS_STATION,
  },
  {
    title: "Total Daily Sales by Payment Type",
    href: Constants.TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_ROUTE,
  },
  {
    title: "Tax Report",
    href: Constants.TAXES_REPORT_ROUTE,
  },
  // {
  //   title: "Outstanding Gift Card Liability",
  //   href: Constants.OUTSTATING_GIFT_CARD_LIABILITY,
  // },
  {
    title: "Sales Summary For All Locations",
    href: Constants.SALES_SUMMARY_FOR_ALL_LOCATIONS_ROUTE,
  },
  {
    title: "Sales Summary For Single Location",
    href: Constants.SALES_SUMMARY_FOR_SINGLE_LOCATIONS_ROUTE,
  },
  // {
  //   title: "Online Gift Card Sales",
  //   href: Constants.ONLINE_GIFT_CARD_SALE,
  // },
  {
    title: "Top Items By Sales",
    href: Constants.TOP_SALE_REPORT,
  },
  {
    title: "Top Items By Volume",
    href: Constants.TOP_VOLUME_REPORT,
  },
];

const InventoryData = [
  {
    title: "Inventory Movement",
    href: Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_ROUTE,
  },
  {
    title: "Inventory Summary",
    href: Constants.INVENTORY_SUMMARY_REPORT_ROUTE,
  },
  {
    title: "Inventory Trail",
    href: Constants.INVENTORY_TRAIL_REPORT_ROUTE,
  },
  {
    title: "Shrinkage",
    href: Constants.SHRINKAGE_REPORT_ROUTE,
  },
  {
    title: "Products not Found barcodes",
    href: Constants.PRODUCT_NOT_FOUND_BARCODE_DATA,
  },
  {
    title: "Recount",
    href: Constants.RECOUNT_REPORT_ROUTE,
  },
  {
    title: "Price Updates",
    href: Constants.PRICE_UPDATES_REPORT_ROUTE,
  },
];

const customerData = [
  {
    title: "Customers Invoices & Statement",
    href: Constants.CUSTOMER_CREDIT_ACCOUNT_ROUTE,
  },
  {
    title: "Customers Unused Credit",
    href: Constants.CUSTOMER_UNUSED_CREDIT_ROUTE,
  },
  {
    title: "Customers Credit Ledger",
    href: Constants.CUSTOMER_CREDIT_LEDGER_ROUTE,
  },
  {
    title: "Customers Aged Receivable",
    href: Constants.CUSTOMER_AGED_RECEIVABLE_ROUTE,
  },
  // {
  //   title: "Customers Statement",
  //   href: Constants.CUSTOMER_STATEMENT_ROUTE,
  // }
];

const employeeData = [
  {
    title: "Employees Work-Time",
    href: Constants.EMPLOYEES_WORK_TIME_REPORT_ROUTE,
  },
  {
    title: "Employees Tip-Amount",
    href: Constants.EMPLOYEE_TIPS_AMOUNT_ROUTE,
  },
];
const giftCardData = [
  {
    title: "Outstanding Gift Card Liability",
    href: Constants.OUTSTATING_GIFT_CARD_LIABILITY,
  },
  {
    title: "Online Gift Card Sales",
    href: Constants.ONLINE_GIFT_CARD_SALE,
  },
];
const orderData = [
  {
    title: "All Orders",
    href: Constants.ORDER_LIST_PATH,
  },
  // {
  //   title: "External Orders",
  //   // href: Constants.EMPLOYEE_TIPS_AMOUNT_ROUTE,
  // },
  {
    title: "Cashback",
    href: Constants.CASHBACK_REPORT_ROUTE,
  },
  {
    title: "Discount Report",
    href: Constants.DISCOUNT_REPORT_ROUTE,
  },
];
const refundsData = [
  {
    title: "Refund With Orders",
    href: Constants.REFUND_WITH_ORDER_ROUTE,
  },
  {
    title: "Refund Without Orders",
    href: Constants.REFUND_WITHOUT_ORDER,
  },
  {
    title: "Bottle Deposit Refund",
    href: Constants.BOTTLE_DEPOSIT_REFUND,
  },
];

const oprationsData = [
  {
    title: "Tills",
    href: Constants.TILL_REPORT_ROUTE,
  },
  {
    title: "Pay In",
    href: Constants.PAY_IN_REPORT_ROUTE,
  },
  {
    title: "Pay Out",
    href: Constants.PAY_OUT_REPORT_ROUTE,
  },
  // {
  //   title: "Refund Order",
  //   href: Constants.REFUND_WITH_ORDER_ROUTE,
  // },
  {
    title: "Extra Item",
    href: Constants.EXTRA_ITEM_ROUTE,
  },
  {
    title: "Variable Item",
    href: Constants.VARIABLE_ITEM_ROUTE,
  },
  {
    title: "Void",
    href: Constants.VOID_REPORT_ROUTE,
  },
  // {
  //   title: "Bottle Deposit Refund",
  //   href: Constants.BOTTLE_DEPOSIT_REFUND,
  // },
  // {
  //   title: "Refund Without Order",
  //   href: Constants.REFUND_WITHOUT_ORDER,
  // },
  // {
  //   title: "Order Discount",
  //   href: Constants.ORDER_DISCOUNT_REPORT_ROUTE,
  // },
];

const Report = (props) => {
  const [isScreenSize992] = useMediaQuery("(max-width: 992px)");
  const [isScreenSize480] = useMediaQuery("(max-width: 480px)");
  const myContext = useContext(AppContext);

  useEffect(() => {
    myContext.handleLoading(false);
  }, []);

  const renderNavItems = (route, index) => {
    return (
      <ListItem key={index}>
        <NavLink to={route.href} onClick={() => {
          myContext.handleLoading(true);
        }}>
          <Text
            color={Colors.posTextInfo}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"300"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
          >
            {route.title}
          </Text>
        </NavLink>
      </ListItem>
    );
  };

  return (
    <Box
      mt={{ base: "3.31rem", md: "3.31rem" }}
      ml={{ base: "3.69rem", md: "3.69rem" }}
      mr={{ base: "2rem", md: "2rem" }}
      mb={{ base: "3.31rem", md: "3.31rem" }}
    >
      <Flex h="auto">
        <Stack spacing={"0rem"}>
          <Box>
            <Heading
              fontSize={"1.5rem"}
              fontStyle={"normal"}
              fontWeight={"600"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              color={Colors.posTextInfo}
            >
              Reports
            </Heading>
          </Box>
          <Box>
            <Text
              fontSize={"0.94rem"}
              fontStyle={"normal"}
              fontWeight={"300"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              color={Colors.taxCreateExemptEbt}
            >
              Reports provides performance data and analytics for your business
            </Text>
          </Box>
        </Stack>
      </Flex>
      <Divider
        pt={{ base: "1.44rem", md: "1.44rem" }}
        h={"0.8px"}
        color={Colors.dividerColor}
      />
      <Grid
        gap={6}
        mt={{ base: "2rem", md: "2rem" }}
        templateColumns={
          isScreenSize992 && isScreenSize480
            ? "repeat(1, 1fr)"
            : isScreenSize992 && !isScreenSize480
            ? "repeat(2, 1fr)"
            : "repeat(4, 1fr)"
        }
      >
        <GridItem w="100%">
          <Heading
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            SALES
          </Heading>
          <List spacing={"0.88rem"} pt={{ base: "2rem", md: "2rem" }}>
            {salesData.map((route, index) => {
              return renderNavItems(route, index);
            })}
          </List>
        </GridItem>
        <GridItem w="100%">
          <Heading
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            INVENTORY
          </Heading>
          <List spacing={"0.88rem"} pt={{ base: "2rem", md: "2rem" }}>
            {InventoryData.map((route, index) => {
              return renderNavItems(route, index);
            })}
          </List>
          <Heading
            pt={{ base: "4rem", md: "4rem" }}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            CUSTOMERS
          </Heading>
          <List spacing={"0.88rem"} pt={{ base: "2rem", md: "2rem" }}>
            {customerData.map((route, index) => {
              return renderNavItems(route, index);
            })}
          </List>
          {/* <Heading
            pt={{ base: "4rem", md: "4rem" }}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            GIFT CARDS
          </Heading>
          <List spacing={"0.88rem"} pt={{ base: "2rem", md: "2rem" }}>
            {giftCardData.map((route, index) => {
              return renderNavItems(route, index);
            })}
          </List> */}
        </GridItem>
        <GridItem>
          <Heading
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            EMPLOYEES
          </Heading>
          <List spacing={"0.88rem"} pt={{ base: "2rem", md: "2rem" }}>
            {employeeData.map((route, index) => {
              return renderNavItems(route, index);
            })}
          </List>
          <Heading
            pt={{ base: "4rem", md: "4rem" }}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            ORDERS
          </Heading>
          <List spacing={"0.88rem"} pt={{ base: "2rem", md: "2rem" }}>
            {orderData.map((route, index) => {
              return renderNavItems(route, index);
            })}
          </List>
          <Heading
            pt={{ base: "4rem", md: "4rem" }}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            GIFT CARDS
          </Heading>
          <List spacing={"0.88rem"} pt={{ base: "2rem", md: "2rem" }}>
            {giftCardData.map((route, index) => {
              return renderNavItems(route, index);
            })}
          </List>
          {/* <Heading
            pt={{ base: "4rem", md: "4rem" }}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            <Link
              onClick={() => {
                props.navigate(Constants.ORDER_LIST_PATH);
              }}
            >
              ORDERS
            </Link>
          </Heading> */}
          {/* <Heading
            pt={{ base: "4rem", md: "4rem" }}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            <Link
              onClick={() => {
                props.navigate(Constants.CASHBACK_REPORT_ROUTE);
              }}
            >
              CASHBACK
            </Link>
          </Heading> */}
          {/* <Heading
            pt={{ base: "4rem", md: "4rem" }}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            EXTERNAL ORDER
          </Heading> */}
        </GridItem>
        <GridItem w="100%">
          <Heading
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            OPERATIONS
          </Heading>
          <List spacing={"0.88rem"} pt={{ base: "2rem", md: "2rem" }}>
            {oprationsData.map((route, index) => {
              return renderNavItems(route, index);
            })}
          </List>
          <Heading
            pt={{ base: "4rem", md: "4rem" }}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={"500"}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
            color={Colors.posNavbarLink}
          >
            REFUNDS
          </Heading>
          <List spacing={"0.88rem"} pt={{ base: "2rem", md: "2rem" }}>
            {refundsData.map((route, index) => {
              return renderNavItems(route, index);
            })}
          </List>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default WithRouter(Report);
