import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getAllRoles(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getAllPermissions() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_GET_ALL_PERMISSION,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function roleCreate({ request }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = {
      role: JSON.parse(Object.fromEntries(formData).role),
      permission: JSON.parse(Object.fromEntries(formData).permission),
    };
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_CREATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function roleUpdate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      role: JSON.parse(Object.fromEntries(formData).role),
      permission: JSON.parse(Object.fromEntries(formData).permission),
    };
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_UPDATE_API_ROUTE +
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getEditRoleById({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.ROLE_EDIT_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}
// export function deactivateRole(id) {
//   return new Promise(async (resolve, reject) => {
//     let token = localStorage.getItem("token");
//     axios
//       .delete(
//         Constants.REACT_APP_API_URL +
//           process.env.REACT_APP_API_VERSION +
//           Constants.ROLE_DEACTIVE_API_ROUTE +
//           "/" +
//           id,
//         {
//           headers: {
//             "content-type": Constants.CONTANT_TYPE,
//             Authorization: "Bearer" + token,
//             Permission: Constants.ROLE_DEACTIVE_LIST_API_PERMISSION,
//           },
//         }
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

// export function reactiveRole(id) {
//   return new Promise(async (resolve, reject) => {
//     let token = localStorage.getItem("token");
//     axios
//       .get(
//         Constants.REACT_APP_API_URL +
//           process.env.REACT_APP_API_VERSION +
//           Constants.ROLE_REACTIVE_API_ROUTE +
//           "/" +
//           id,
//         {
//           headers: {
//             "content-type": Constants.CONTANT_TYPE,
//             Authorization: "Bearer" + token,
//             Permission: Constants.ROLE_REACTIVE_API_PERMISSION,
//           },
//         }
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// }

export function roleDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.ROLE_DEACTIVE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.ROLE_DEACTIVE_LIST_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.ROLE_REACTIVE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.ROLE_REACTIVE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    }
  });
}

export function getAllDeactiveRole(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_DEACTIVE_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_DEACTIVE_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
