import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import moment from "moment";
// import ButtonGroup from '@mui/material/ButtonGroup';
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLoaderData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import ProductImportBlankListImage from "../../../assets/images/product_import_blank_list_image.png";

import ProductImportFileImage from "../../../assets/images/solar_file-line-duotone.svg";

import ProductImportFileImage1 from "../../../assets/images/solar_file-line-duotone.png";

import { DownloadIcon } from "@chakra-ui/icons";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosLable,
  PosProgress,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  exportErrorExcel,
  exportProductData,
  exportSuccessCSV,
  getUploadedFilesList,
  importProductData,
  deleteProcessingFile
} from "./ProductService";

const ProductImportExport = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const stateData = useLocation();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const tabNames = ["Import Products", "Export Products"];
  const [inImport, setInImport] = useState(true);
  const [showImportBox, setShowImportBox] = useState(false);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();

  const [totalcount, setTotalCount] = useState(0);
  const [exportButtonDisable, setExportButtonDisable] = useState(false);
  const [uploadedFileData, setUploadedFileData] = useState([]);
  const [curruntTab, setCurruntTab] = useState(0);
  const effectRun = useRef(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");
  const [loading, setLoading] = useState(true);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(25);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const sortRef = useRef(sortModel);

  const [isDragging, setIsDragging] = useState(false);

  const [locationData, setLocationData] = useState([]);
  const [selectedImportLocationData, setSelectedImportLocationData] = useState(
    ""
  );
  const [selectedExportLocationData, setSelectedExportLocationData] = useState(
    ""
  );
  const [fileCard, setFileCard] = useState([]);
  const [base64Data, setBase64Data] = useState(null);
  const [base64textString, setBase64textString] = useState(""); // State to store base64 string
  const fileInputRef = useRef(null);

  // useEffect(() => {
  //   if (effectRun.current === true) {
  //     if (curruntTab === 0) {
  //       fetchData();
  //     } else {
  //       fetchDeActiveProductData();
  //     }
  //     effectRun.current = false;
  //   }
  // }, [
  //   sortType,
  //   searchQuery,
  //   rowsPerPage,
  //   page,
  //   loading,
  //   sortColumn,
  //   selectedLocations,
  //   selectedCategories,
  //   selectedVendors,
  // ]);

  // useEffect(() => {
  //   if (
  //     undefined != actionResponse &&
  //     null != actionResponse &&
  //     {} != actionResponse
  //   ) {
  //     if (
  //       undefined != actionResponse?.error &&
  //       null != actionResponse?.error &&
  //       actionResponse?.error
  //     ) {
  //       let actionData = actionResponse?.response?.data;
  //       error({ actionData });
  //       myContext.handleLoading(false);
  //     } else if (
  //       actionResponse.data[Constants.FLAGE] !== null &&
  //       actionResponse.data[Constants.FLAGE] === true
  //     ) {
  //       if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
  //         setLoading(true);
  //         fetchData();
  //         addToast({
  //           alertStatus: Constants.TOAST_TYPE_SUCESS,
  //           alertTitle: Constants.PRODUCT_MODULE,
  //           alertDescription: Constants.PRODUCT_DEACTIVE_SUCCESS,
  //         });
  //         myContext.handleLoading(false);
  //       } else {
  //         setLoading(true);
  //         fetchDeActiveProductData();
  //         addToast({
  //           alertStatus: Constants.TOAST_TYPE_SUCESS,
  //           alertTitle: Constants.PRODUCT_MODULE,
  //           alertDescription: Constants.PRODUCT_REACTIVE_SUCCESS,
  //         });
  //         myContext.handleLoading(false);
  //       }
  //     }
  //     // else {
  //     //   let actionData = actionResponse;
  //     //   error({ actionData });
  //     // }
  //   }
  // }, [actionResponse]);

  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
    // total_unpaid_amount: false,
    // total_tax_refunded: false,
    // total_tip_refunded: false,
    // total_amount_refunded: false,
  });
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.6 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const columnNames = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      ...actionColumnXS,
    },
    {
      headerName: "Uploaded On",
      field: "uploaded_date",
      sortable: true,
      ...actionColumnLG,
    },
    {
      headerName: "Uploaded By",
      field: "uploaded_by",
      sortable: true,
      ...actionColumnLG,
    },
    {
      headerName: "File Name",
      field: "file_name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      headerName: "Location",
      field: "location_name",
      sortable: true,
      ...actionColumnSM,
    },
    {
      headerName: "File Status",
      field: "process_status",
      sortable: false,
      ...actionColumnMD,
      renderCell: (params) => (
        <span
          style={{
            padding: "0.150rem 0.625rem",
            borderRadius: "0.63rem",
            backgroundColor: params.value === "Deleted" ? "#FCE6E5" : "",
            color: params.value === "Deleted" ? "#CD1919" : "",
            fontWeight:"300",
            justifyContent: "center",
            alignItems: "center",
            width:"2.5rem",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      headerName: "Download Error Data",
      field: "download_error_data",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      ...actionColumnLG,
      renderCell: (params) =>
        params.row.process_status_initial == "COMPLETED" &&
        null != params.row.result &&
        "" != params.row.result &&
        "error" == params.row.result ? (
          <DescriptionIcon
            aria-label="view"
            onClick={() => exportErrorExcelFile(params)}
            sx={{ color: "#5881FE" }}
          >
            <VisibilityIcon />
          </DescriptionIcon>
        ) : (
          "N.A."
        ),
    },
    {
      headerName: "Download Uploaded Data",
      field: "download_uploaded_data",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      ...actionColumnLG,
      renderCell: (params) =>
        params.row.process_status_initial == "COMPLETED" &&
        null != params.row.result &&
        "" != params.row.result &&
        null != params.row.uploaded_product_ids_flag &&
        "" != params.row.uploaded_product_ids_flag &&
        1 == params.row.uploaded_product_ids_flag ? (
          <InsertDriveFileIcon
            aria-label="view"
            onClick={() => exportSuccessCSVFile(params)}
            sx={{ color: "#5881FE" }}
          >
            <VisibilityIcon />
          </InsertDriveFileIcon>
        ) : (
          "N.A."
        ),
    },
    {
      headerName: "Delete to Re-Upload",
      field: "delete_and_reupload_again",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      ...actionColumnLG,
      renderCell: (params) =>
        1 == params.row.set_delete ? (
          <DeleteIcon
            bg={Colors.posCancleButtonRed}
            onClick={() =>
              deleteFile(params)
            }
            sx={{ color: Colors.posCancleButtonRed }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.31rem",
              flexShrink: 0,
            }}
          />
        ) : (
          "N.A."
        ),
    },
  ];
  const modifiedData = uploadedFileData.map((uploadedFile) => ({
    ...uploadedFile,
    uploaded_date: moment(uploadedFile.uploaded_date)
      .format("MM/DD/YYYY HH:mm:ss")
      .toString(),
    process_status:
      uploadedFile.process_status == "UPLOADED"
        ? "Processing"
        : uploadedFile.process_status == "PROCESSING"
        ? "Processing"
        : uploadedFile.process_status == "DELETED"
        ? "Deleted"
        : uploadedFile.process_status == "COMPLETED" &&
          uploadedFile.result != null &&
          uploadedFile.result != "" &&
          uploadedFile.result == "success"
        ? "Completed Successfully"
        : "Completed With Some Errors",
    process_status_initial: uploadedFile.process_status,
  }));

  const exportErrorExcelFile = (params) => {
    var data = params?.id;
    try {
      exportErrorExcel(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: "Product",
              alertDescription: response.data.message,
            });
            let url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const exportSuccessCSVFile = (params) => {
    var data = params?.id;
    try {
      exportSuccessCSV(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: "Product",
              alertDescription: response.data.message,
            });
            let url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const deleteFile = (params) => {
    var data = params?.id;
    try {
      deleteProcessingFile(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: "Product",
              alertDescription: response.data.message,
            });
            let url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            refresh();
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };


  useEffect(() => {
    setLocationData(loaderResponse?.data?.data?.locations);
    // refresh();
  }, [loaderResponse]);

  useEffect(() => {
    refresh();
  }, [paginationModel, sortModel]);

  // const handlePageChange = (newPage) => {
  //   setPage(newPage);
  //   effectRun.current = true;
  //   setLoading(true);
  // };

  // const handleRowsPerPageChange = (newRowsPerPage) => {
  //   setRowsPerPage(newRowsPerPage);
  //   effectRun.current = true;
  //   setLoading(true);
  // };
  // const handleSortColumn = (column, sort) => {
  //   setLoading(true);
  //   setSortColumn(column.sortName);
  //   effectRun.current = true;
  //   setSortType(sort);
  // };

  const refresh = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      getUploadedFilesList(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setUploadedFileData(response.data.data.productImportFileDtls);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setUploadedFileData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setUploadedFileData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setUploadedFileData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      setInImport(true);
      // setLoading(true);
      setShowImportBox(false);
      setSelectedImportLocationData("");
      setSelectedExportLocationData("");
      //   fetchData();
      // setSearchQuery("");
      // setPage(0);
      // setRowsPerPage(25);
      // effectRun.current = true;
      // setLoading(false);

      setSearchQuery("");
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      setSortModel([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      effectRun.current = true;
      setLoading(false);
    } else {
      setInImport(false);
      setShowImportBox(false);
      // setSelectedLocationData("");
      setSelectedImportLocationData("");
      setSelectedExportLocationData("");
      setBase64Data("");
      setFileCard([]);
      effectRun.current = true;
      setLoading(false);
    }
  };

  const handleImportSelectedLocation = (value) => {
    setSelectedImportLocationData(value);
    setSelectedExportLocationData("");
    setShowImportBox(true);
  };

  const handleExportSelectedLocation = (value) => {
    setSelectedExportLocationData(value);
    setSelectedImportLocationData("");
    setShowImportBox(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);

    setFileCard(files);
    // Process each dropped file
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        // Handle loaded file data (e.g., set state with base64 data)
        handleReaderLoaded(reader.result); // Assuming handleReaderLoaded is defined correctly
      };
      reader.readAsBinaryString(file); // Read file as binary string
    });
  };
  const handleRemove = () => {
    if (!loading) {
      setBase64Data("");
      setFileCard([]);
    }
  };
  // Define handleReaderLoaded function to process loaded file data
  const handleReaderLoaded = (result) => {
    setBase64Data(btoa(result));
  };

  const handleFileSelect = () => {
    // Check if file input reference is available
    if (fileInputRef.current) {
      // Trigger the click event of the file input element
      fileInputRef.current.click();
    }
  };

  // Function to handle file selection change
  const handleFileChange = (event) => {
    const selectedFile = Array.from(event.target.files);
    setFileCard(selectedFile);
    selectedFile.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        handleReaderLoaded(reader.result); // Assuming handleReaderLoaded is defined correctly
      };
      reader.readAsBinaryString(file); // Read file as binary string
    });
  };

  const exportProduct = () => {
    myContext.handleLoading(true);
    setExportButtonDisable(true);
    var data = {
      location: selectedExportLocationData,
    };
    setLoading(true);
    try {
      exportProductData(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: "Export Successfully",
              alertDescription: response.data.message,
            });
            let url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            setExportButtonDisable(false);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            setExportButtonDisable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setExportButtonDisable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setExportButtonDisable(false);
      myContext.handleLoading(false);
    }
  };

  const downloadSampleFile = () => {
    let url = `${Constants.REACT_APP_API_URL}${"api/v1/sample-file"}`;
    window.open(url, "_blank");
  };

  const handleSubmit = () => {
    if (!fileCard[0].name || fileCard[0].name.trim() === '' || fileCard[0].name.includes('%')) {
      return addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.PRODUCT_MODULE,
        alertDescription: 'It is not possible to import this file name since it contains a percentage symbol (%).',
      });
    } else {
      setLoading(true);
      var data = {
        base64: base64Data,
        data: fileCard[0].name,
        location: selectedImportLocationData,
      };
      try {
        importProductData(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              addToast({
                alertStatus: Constants.TOAST_TYPE_SUCESS,
                alertTitle: "Import Successfully",
                alertDescription: response.data.message,
              });
              setSelectedImportLocationData("");
              setShowImportBox(false);
              setLoading(false);
              myContext.handleLoading(false);
              setBase64Data("");
              setFileCard([]);
              refresh();
            } else {
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
  };

  const clearFileData = () => {
    setSelectedImportLocationData("");
    setShowImportBox(false);
    setLoading(false);
    setBase64Data("");
    setFileCard([]);
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInKb = sizeInBytes / 1024;
    return `${sizeInKb.toFixed(2)} KB`;
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={"2.81rem"}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            const updatedState = {
              ...stateData,
              state: {
                ...stateData.state,
                filter: "",
              },
            };
            props.navigate(Constants.PRODUCT_LIST_PATH, {
              state: updatedState.state,
            });
          }}
          breadCrumNames={["Products", "Import/Export"]}
          breadCrumTitle={"Import/Export"}
        />
        <Spacer />
        <Stack
          direction={"row"}
          alignSelf={"flex-end"}
          mr={{ base: "0", md: "1.84rem" }}
        >
          <PosFormButton
            onClick={() => {
              const updatedState = {
                ...stateData,
                state: {
                  ...stateData.state,
                  filter: "",
                },
              };
              props.navigate(Constants.PRODUCT_LIST_PATH, {
                state: updatedState.state,
              });
            }}
            buttonText={"Cancel"}
            isDisabled={loading ? true : false}
            CancelButton={true}
          />
        </Stack>
      </Flex>

      <Tabs onChange={(index) => handleTabChange(index)}>
        <PosTab tabNames={tabNames} />
        <TabPanels>
          <TabPanel>
            <Box
              className="card flex justify-content-center"
              mb={{ base: 4, md: 4 }}
              borderRadius="0.63rem"
              bg={Colors.posPageDataBackground}
              boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
              p="4"
            >
              <Text
                style={{
                  fontSize: "xs",
                  color: "#010048",
                  fontWeight: 500,
                }}
              >
                Upload a File
              </Text>
              <Divider
                mt={4}
                mb={4}
                h={"0.05rem"}
                borderColor={Colors.listShadow}
              />
              <Box className="card flex justify-content-center">
                <PosLable requiredLabel={true} name={"Location"} />

                {/* <Dropdown
                value={selectedImportLocationData}
                onChange={(e) => setSelectedImportLocationData(e.value)}
                options={locationData}
                optionLabel="name"
                placeholder="Select a Location"
                style={{ width: "100%" }}
                className="w-full md:w-14rem"
                checkmark={true}
                highlightOnSelect={false}
              /> */}
                <PosDropDown
                  id="selectedSize"
                  value={selectedImportLocationData}
                  onChange={(e) => {
                    setSelectedImportLocationData(e.value);
                  }}
                  options={locationData}
                  optionLabel={"name"}
                  className="w-full md:w-20rem"
                  width={"100%"}
                  placeholder={"Select a Location"}
                ></PosDropDown>
              </Box>
              {selectedImportLocationData?.id > 0 ? (
                <>
                  <Box p={8}>
                    <Text
                      style={{
                        fontSize: "xs",
                        color: "#010048",
                        fontWeight: 500,
                      }}
                    >
                      Choose Import type
                    </Text>
                    <a
                      style={{
                        fontSize: "xs",
                        color: "#5881FE",
                        fontWeight: 300,
                        marginTop: 2.5,
                      }}
                    >
                      <button onClick={downloadSampleFile}>
                        <DownloadIcon />{" "}
                        <u>Download Sample CSV file (Recommended)</u>
                      </button>
                    </a>

                    {fileCard[0]?.name ? (
                      <>
                        {/* <Box> */}
                        <Box
                          style={{
                            position: "absolute",
                            right: "3.13rem",
                            marginTop: "0.63rem",
                            borderWidth: 2,
                            borderRadius: 30,
                            background: "black",
                            color: "white",
                            width: "1.8rem",
                            height: "1.8rem",
                          }}
                        >
                          <CloseIcon onClick={() => handleRemove()} />
                        </Box>
                        <Box
                          p={6}
                          my={6}
                          borderWidth={2}
                          borderRadius="md"
                          onDragEnter={handleDragEnter}
                          onDragOver={handleDragEnter}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                          cursor="pointer"
                          // position="relative"
                        >
                          <HStack spacing={4}>
                            <Image
                              w={6}
                              // mt={4}
                              h={6}
                              src={ProductImportFileImage1}
                              alt="Product Image"
                            />

                            <Text
                              style={{
                                fontSize: "xs",
                                color: "#010048",
                                fontWeight: 500,
                                wordBreak: "break-word",
                              }}
                            >
                              {fileCard[0]?.name} <br />
                              <span
                                style={{
                                  display: "block",
                                  // textAlign: "center",
                                  fontSize: "xs",
                                  color: "rgba(1, 0, 72, 0.60)",
                                  fontWeight: 300,
                                }}
                              >
                                {" "}
                                {formatFileSize(fileCard[0].size)}
                              </span>
                            </Text>
                          </HStack>
                        </Box>
                        {/* <Box style={{position:'absolute',right:'34px',borderWidth:2,borderRadius:30,background:'black',color:'white',width:'1.8rem',height:'1.8rem'}}>
                        <CloseIcon onClick={() => handleRemove()} />
                      </Box> */}
                        {/* </Box> */}
                        <ButtonGroup display="flex">
                          <input
                            ref={fileInputRef}
                            type="file"
                            style={{
                              display: "none",
                              fontWeight: 300,
                              fontSize: "xs",
                            }}
                            onChange={handleFileChange}
                            accept=".xls,.xlsx,.ods"
                          />
                          <Button
                            // variant="outline"
                            onClick={handleFileSelect}
                            isDisabled={loading ? true : false}

                            // onClick={props.onClick}
                            // width={props.buttonText == "Cancel" ? "7.37rem" : undefined}
                            // isDisabled={props.isDisabled}
                          >
                            Choose File
                          </Button>
                        </ButtonGroup>
                        <Box
                          display={"flex"}
                          direction={"row"}
                          justifyContent={"flex-end"}
                          p={6}
                        >
                          <ButtonGroup>
                            {/* <PosFormButton
                            onClick={() => {
                              props.navigate(Constants.PRODUCT_LIST_PATH);
                            }}
                            buttonText={"Cancel"}
                            CancelButton={true}
                          /> */}
                            <PosFormButton
                              // onClick={() => {
                              //   props.navigate(Constants.PRODUCT_LIST_PATH);
                              // }}
                              onClick={() => {
                                clearFileData();
                              }}
                              isDisabled={loading ? true : false}
                              buttonText={"Reset"}
                              CancelButton={true}
                            />
                            <PosFormButton
                              buttonsubmit={"Import"}
                              SubmitButton={true}
                              isDisabled={loading ? true : false}
                              onClick={() => {
                                handleSubmit();
                              }}
                            />
                          </ButtonGroup>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          p={6}
                          my={6}
                          borderWidth={2}
                          borderStyle={isDragging ? "solid" : "dashed"}
                          // borderStyle="dashed"
                          borderColor={"#CBCFD1"}
                          borderRadius="md"
                          onDragEnter={handleDragEnter}
                          onDragOver={handleDragEnter}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                          cursor="pointer"
                        >
                          <VStack spacing={4}>
                            <Image
                              w={6}
                              mt={4}
                              h={6}
                              src={ProductImportFileImage}
                              alt="Product Image"
                            />

                            <Text
                              style={{
                                fontSize: "xs",
                                color: "#010048",
                                fontWeight: 500,
                              }}
                            >
                              Drag and Drop File here or
                              <br />
                              <input
                                ref={fileInputRef}
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                accept=".xls,.xlsx,.ods"
                              />
                              <span
                                onClick={handleFileSelect}
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontSize: "xs",
                                  color: "#5881FE",
                                  fontWeight: 300,
                                }}
                              >
                                {" "}
                                Choose file
                              </span>
                            </Text>
                          </VStack>
                        </Box>
                        <Text
                          style={{
                            fontSize: "xs",
                            color: "#010048",
                            fontWeight: 300,
                            marginTop: 2.5,
                          }}
                        >
                          Upload file only with .xls, .xlsx, .ods extensions.
                        </Text>
                      </>
                    )}
                  </Box>
                </>
              ) : null}
              <Box>
                {loading ? (
                  <PosProgress />
                ) : totalcount > 0 && curruntTab === 0 ? (
                  <>
                    <Box
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"flex-end"}
                      p={6}
                    >
                      <ButtonGroup>
                        <PosFormButton
                          buttonsubmit={"Refresh"}
                          SubmitButton={true}
                          onClick={() => {
                            refresh();
                          }}
                        />
                      </ButtonGroup>
                    </Box>
                    <Box
                    // align="center"
                    // justify={"center"}
                    // bg={"#FFF"}
                    // pt={"2.4rem"}
                    // pb={"2.4rem"}
                    >
                      {/* <PosTable
                  columnNames={columnNames}
                  rawdata={uploadedFileData}
                  totalcount={totalcount}
                  // spliteButton={true}
                  // spliteOptions={options}
                  // DectivatedId={handleDeactive}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  handleSortColumn={handleSortColumn}
                  sortColumnName={sortColumn}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  order={sortType}
                  // goEdit={(id) => handleEditPage(id)}
                /> */}
                      <PosDataGridTable
                        columns={columnNames}
                        rows={modifiedData}
                        totalcount={totalcount}
                        // noAction={true}
                        columnVisibilityModel={olColumnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                          setOlColumnVisibilityModel(newModel)
                        }
                        paginationModel={paginationModel}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => {
                          sortRef.current = sortModel;
                          setSortModel(newSortModel);
                        }}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        fileName={Constants.IMPORTED_PRODUCT_EXPORT_FILENAME}
                      />
                    </Box>
                  </>
                ) : (
                  <Stack
                    align="center"
                    justify={"center"}
                    // bg={"#FFF"}
                    pt={"4.4rem"}
                    pb={"4.4rem"}
                  >
                    <Box>
                      <Image
                        w={40}
                        h={36}
                        src={ProductImportBlankListImage}
                        alt="Product Import List Image"
                      />
                    </Box>
                    <Box pt={3.5} pb={3.5} maxW={"35.88rem"} mx="auto">
                      <Text
                        color={"#010048"}
                        align={"center"}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"semibold"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        {"Import Products"}
                      </Text>
                      <Text
                        color={"#010048"}
                        align={"center"}
                        mt={"0.06rem"}
                        fontSize={"0.94rem"}
                        fontWeight={300}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        {"Import products list will show here"}
                      </Text>
                    </Box>
                  </Stack>
                )}
              </Box>
              <Flex
                gap={"0.63rem"}
                flexDirection={!isMobile ? "row" : "column"}
                mt={!isMobile ? "3.06rem" : "1rem"}
                ml={!isMobile ? "3.06rem" : "1rem"}
                mr={!isMobile ? "3.06rem" : "1rem"}
                mb={!isMobile ? "3.06rem" : "1rem"}
              >
                <Spacer />
              </Flex>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box
              className="card flex justify-content-center"
              mb={{ base: 4, md: 4 }}
              borderRadius="0.63rem"
              bg={Colors.posPageDataBackground}
              boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
              p="4"
            >
              <Box className="card flex justify-content-center">
                <PosLable requiredLabel={true} name={"Location"} />

                {/* <Dropdown
                value={selectedExportLocationData}
                onChange={(e) => setSelectedExportLocationData(e.value)}
                options={locationData}
                optionLabel="name"
                placeholder="Select a Location"
                style={{ width: "100%" }}
                className="w-full md:w-14rem"
                checkmark={true}
                highlightOnSelect={false}
              /> */}
                <PosDropDown
                  id="selectedSize"
                  value={selectedExportLocationData}
                  onChange={(e) => {
                    setSelectedExportLocationData(e.value);
                  }}
                  options={locationData}
                  optionLabel={"name"}
                  className="w-full md:w-20rem"
                  width={"100%"}
                  placeholder={"Select a Location"}
                ></PosDropDown>
              </Box>
              <Box
                display={"flex"}
                p={6}
                direction={"row"}
                justifyContent={"flex-end"}
              >
                <ButtonGroup>
                  {/* <PosFormButton
                  onClick={() => {
                    props.navigate(Constants.PRODUCT_LIST_PATH);
                  }}
                  buttonText={"Cancle"}
                  CancelButton={true}
                /> */}

                  <PosFormButton
                    isDisabled={
                      selectedExportLocationData?.length == 0 ||
                      exportButtonDisable
                        ? true
                        : false
                    }
                    buttonsubmit={"Export"}
                    SubmitButton={true}
                    onClick={() => {
                      exportProduct();
                    }}
                  />
                </ButtonGroup>
              </Box>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
export default WithRouter(ProductImportExport);
