import {
  Box,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text
} from "@chakra-ui/react";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import CreditCardIcon from '@mui/icons-material/CreditCard'; // Import the CreditCard icon
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import React, { useEffect, useContext, useRef, useState } from "react";
import Colors from "../../../assets/colors/Colors";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit
} from "react-router-dom";
import {
  AppContext,
  PosListHeader,
  PosTostMessage
} from "../../../components/index";
import { PosErrorHook } from "../../../hooks";
import * as Constants from "../../../constants/Constants";
import { WithRouter } from "../../../navigators/WithRouter";

const UnitOfMeasure = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [sysAllGlobalSettings, setSysAllGlobalSettings] = useState([]);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  
  useEffect(() => {
    if (effectRun.current === true) {
      myContext.handleLoading(false);
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setSysAllGlobalSettings(loaderResponse.data.data);
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.TAX_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);

  return (
    <Box padding={4}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        width={{ base: "100%", md: "95%" }}
        gap={2}
      >
        <PosListHeader
          header={"Global Settings"}
          discription={
            "Manage all settings that affect all locations in OctoPos."
          }
        />
        <Spacer />
        {/* <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.SYS_ALL_GLOBAL_SETTINGS);
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
          </Stack> */}
      </Flex>
      <Divider
        borderWidth={"0.05rem"}
        borderColor={Colors.dividerColor}
        mt={{ base: 0, md: 8 }}
        width={{ base: "100%", md: "95%" }}
      />
      <Stack
        ml={{ base: 0, md: 21 }}
        divider={
          <StackDivider
            borderWidth={"0.05rem"}
            width={{ base: "100%", md: "85%" }}
          />
        }
        spacing="2"
      >
        {undefined != sysAllGlobalSettings && sysAllGlobalSettings.length>0 && sysAllGlobalSettings.map((cat, i) => (
        
          cat.name == 'Basic Details' && (
            <Box key={i} style={{ cursor: "pointer" }} onClick={() => {
              props.navigate(Constants.SYS_ALL_LOC_GLOBAL_SETTING+cat.id);
            }}>
          <Flex
              mt={6}
              mb={3}
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={"row"}
            >
              <>
                <Box ml={3} mt={3}>
                  <DisplaySettingsIcon style={{ fill: "#5881FE" }} />
                </Box>
                <Box w={{ base: "85%", md: "95%" }} ml={6}>
                <Heading
                  as="h3"
                  size="xs"
                  fontWeight="500"
                  fontSize="1.125rem"
                  lineHeight="1.40625rem"
                  letterSpacing="-0.016875rem"
                  color="#010048"
                >
                  {cat.name}
                </Heading>
                <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                Manage and create {cat.name} in the OctoPos system.
                </Text>
              </Box>
              <Box
                alignItems={{ base: "flex-end" }}
                w={{ base: "15%", md: "25%" }}
              >
                <NavigateNextRoundedIcon fontSize="large" />
              </Box>
              </>
          </Flex>
        </Box>
        )
        ))}
        <Box style={{ cursor: "pointer" }} onClick={() => {
          props.navigate(Constants.UNIT_OF_MEASURE_CREATE);
        }}>
          <Flex
            mt={6}
            mb={3}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={"row"}
          >
            <Box ml={3} mt={3}>
              <DriveFileRenameOutlineRoundedIcon style={{ fill: "#5881FE" }} />
            </Box>
            <Box w={{ base: "85%", md: "95%" }} ml={6}>
              <Heading
                as="h3"
                size="xs"
                fontWeight="500"
                fontSize="1.125rem"
                lineHeight="1.40625rem"
                letterSpacing="-0.016875rem"
                color="#010048"
              >
                Unit Of Measure Settings
              </Heading>
              <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
              Manage and create units of measure for inventory in the OctoPos system.
              </Text>
            </Box>
            <Box
              alignItems={{ base: "flex-end" }}
              w={{ base: "15%", md: "25%" }}
            >
              <NavigateNextRoundedIcon fontSize="large" />
            </Box>
          </Flex>
        </Box>
        {undefined != sysAllGlobalSettings && sysAllGlobalSettings.length>0 && sysAllGlobalSettings.map((cat, i) => (
          cat.name == 'Credit / House Account Settings' && (
        <Box key={i} style={{ cursor: "pointer" }} onClick={() => {
          props.navigate(Constants.SYS_ALL_LOC_GLOBAL_SETTING+cat.id);
        }}>
          <Flex
            mt={6}
            mb={3}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={"row"}
            >
              <>
                <Box ml={3} mt={3}>
                  <CreditCardIcon style={{ fill: "#5881FE" }} />
                </Box>
                <Box w={{ base: "85%", md: "95%" }} ml={6}>
                <Heading
                  as="h3"
                  size="xs"
                  fontWeight="500"
                  fontSize="1.125rem"
                  lineHeight="1.40625rem"
                  letterSpacing="-0.016875rem"
                  color="#010048"
                >
                  {cat.name}
                </Heading>
                <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                Manage and create {cat.name} in the OctoPos system.
                </Text>
              </Box>
              <Box
                alignItems={{ base: "flex-end" }}
                w={{ base: "15%", md: "25%" }}
              >
                <NavigateNextRoundedIcon fontSize="large" />
              </Box>
            </>
          </Flex>
        </Box>
        )))}
      </Stack>
      <Flex
        minWidth="max-content"
        gap="2"
        mt={{ base: "4.72rem", md: "4.72rem" }}
      >
        <Spacer />
      </Flex>
    </Box>
  );
};

export default WithRouter(UnitOfMeasure);
