import {
  Box,
  ButtonGroup,
  Flex,
  SimpleGrid,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import EditIcon from "@mui/icons-material/Edit";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosDatePicker,
  PosFormButton,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import {
  commonDateFormate,
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllDeactiveTax, getAllPromotion } from "./PromotionService";

const PromotionList = (props) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const { addToast } = PosTostMessage();
  const stateData = useLocation(); // get backraking data as param
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [promotionData, setPromotionData] = useState([]);
  const [promotionDeactiveData, setPromotionDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type
      : "desc"
  );
  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order
      : "id"
  );
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );
  const [buttonDisable, setButtonDisable] = useState(false);
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [showFilter, setShowFilter] = useState(
    undefined !== stateData?.state &&
      null !== stateData?.state &&
      "" !== stateData?.state &&
      null != stateData?.state?.showFilter &&
      "" != stateData?.state?.showFilter
      ? stateData?.state?.showFilter
      : false
  );
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const effectRun = useRef(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "valid_from",
      headerName: "Valid From",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "valid_until",
      headerName: "Valid Until",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "usage",
      headerName: "Usage",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "actions",
      headerName: "Actions",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          // backtracking
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
            showFilter: showFilter,
            fromDate: formik.values.fromDate,
            toDate: formik.values.toDate,
          };
          props.navigate(`${Constants.PROMOTION_EDIT + item.id}`, {
            state: data,
          });
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.VENDOR_LIST,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "Deactive",
              disabledButton: buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];
  const formik = useFormik({
    initialValues: {
      fromDate:
        undefined !== stateData?.state &&
        null !== stateData?.state &&
        "" !== stateData?.state &&
        null != stateData?.state?.fromDate &&
        "" != stateData?.state?.fromDate
          ? stateData?.state?.fromDate
          : "",
      toDate:
        undefined !== stateData?.state &&
        null !== stateData?.state &&
        "" !== stateData?.state &&
        null != stateData?.state?.toDate &&
        "" != stateData?.state?.toDate
          ? stateData?.state?.toDate
          : "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {},
  });

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        effectRun.current = true;
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        effectRun.current = true;
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      effectRun.current = true;
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };

  const showDropDown = () => {
    setShowFilter((prevState) => {
      // Toggle the state
      const nextState = !prevState;
      // Conditionally set another state based on the previous state value
      if (
        prevState &&
        (formik.values.fromDate != "" || formik.values.toDate != "")
      ) {
        paginationModel.page = 0;
        // Set other state based on prevState being false
        // setSelectedLocations([]);
        // setSelectedCategories([]);
        formik.setFieldValue("fromDate", "");
        formik.setFieldValue("toDate", "");
        effectRun.current = true;
        // setSelectedVendors([]);
      }
      return nextState; // Return the toggled state
    });
    // setShowFilter(prevState => !prevState); // Toggle the state
  };
  const clearFilterData = () => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (searchQuery.length > 0) {
      setSearchQuery("");
      fetchData();
    }
    formik.setFieldValue("fromDate", "");
    formik.setFieldValue("toDate", "");
    props.navigate(Constants.PRODUCT_PROMOTION_PATH);
  };
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    paginationModel.page = 0;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      apply();
    }, 250);

    setTimeoutId(newTimeoutId);
  };
  const apply = () => {
    effectRun.current = true;
    setLoading(true);
    // fetchData();
  };
  // const apply = () => {
  //   effectRun.current = true;
  //   setLoading(true);
  //   // fetchData();
  // };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.PRODUCT_PROMOTION_PATH);
  };
  const deactivatedColumnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "valid_from",
      headerName: "Valid From",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "valid_until",
      headerName: "Valid Until",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "usage",
      headerName: "Usage",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          // backtracking
          setButtonDisable(true);
          if (
            deactiveTaxData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.PRODUCT_PROMOTION_PATH,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? "gray" : Colors.posNavbarLink}
            // cursor="pointer"
            // color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            // textDecoration="underline"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];
  // const columnNames = [
  //   {
  //     columnNames: "ID",
  //     sortName: "id",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Name",
  //     sortName: "name",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Type",
  //     sortName: "type",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Valid From",
  //     sortName: "valid_from",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Valid Until",
  //     sortName: "valid_until",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Usage",
  //     sortName: "",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Actions",
  //     sortName: "",
  //     width: "",
  //   },
  // ];
  // backtracking
  const [paginationModel, setPaginationModel] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  // backtracking
  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);

  const options = {
    buttonName: "Edit",
    buttonIcon: <EditIcon />,
    handleClick: (item) => handleEditPage(item),
    subButtons: [
      {
        name: "Deactive",
        icon: <CloseIcon style={{ color: "red" }} />,
        onClick: (item) => handleDeactive(item),
      },
    ],
  };
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.page
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.limit
      : 25
  );

  const handleDeactive = (item) => {
    setButtonDisable(true);
    myContext.handleLoading(true);
    let data = {
      id: item.id,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.PRODUCT_PROMOTION_PATH,
    });
    // onClose();
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    effectRun.current = true;
    setLoading(true);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    effectRun.current = true;
    setLoading(true);
  };
  const handleSortColumn = (column, sort) => {
    setLoading(true);
    setSortColumn(column.sortName);
    effectRun.current = true;
    setSortType(sort);
  };
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    if (null !== formik.values.fromDate && "" !== formik.values.fromDate) {
      data.valid_from = reportDateFormate(formik.values.fromDate);
    }
    if (null !== formik.values.toDate && "" !== formik.values.toDate) {
      data.valid_until = reportDateFormate(formik.values.toDate);
    }
    if (
      showFilter &&
      ((null !== formik.values.fromDate && "" !== formik.values.fromDate) ||
        (null !== formik.values.toDate && "" !== formik.values.toDate))
    ) {
      data.local_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    try {
      getAllPromotion(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setPromotionData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setPromotionData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !searchInputHideRef.current &&
            err?.response?.data?.data === 0 &&
            (null == formik.values.fromDate || "" == formik.values.fromDate) &&
            (null == formik.values.toDate || "" == formik.values.toDate)
          ) {
            searchInputHideRef.current = true;
          }
          setPromotionData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setPromotionData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchDeActiveTaxData = async () => {
    setLoading(true);
    // backtracking
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllDeactiveTax(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setPromotionDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setPromotionDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setPromotionDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setPromotionDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveTaxData();
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [
    paginationModel,
    showFilter,
    sortModel,
    loading,
    searchQuery,
    paginationModelDeactive,
    sortModelDeactive,
  ]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PROMOTION_MODULE,
            alertDescription: Constants.PROMOTION_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          fetchDeActiveTaxData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PROMOTION_MODULE,
            alertDescription: Constants.PROMOTION_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
      // else {
      //   let actionData = actionResponse;
      //   error({ actionData });
      // }
    }
  }, [actionResponse]);

  const handleEditPage = (item) => {
    // backtracking
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      showFilter: showFilter,
      fromDate: formik.values.fromDate,
      toDate: formik.values.toDate,
    };
    myContext.handleLoading(true);
    // pass backtraking relate all data
    props.navigate(`${Constants.PROMOTION_EDIT + item.id}`, {
      state: data,
    });
  };
  const handleReactive = (id) => {
    myContext.handleLoading(true);
    let data = {
      id: id,
      type: Constants.REACTIVE,
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.PRODUCT_PROMOTION_PATH,
    });
  };
  const handleTabChange = (index) => {
    // tab change time set currunt tab and pagination related clear all data
    setCurruntTab(index);
    setLoading(true);
    setSearchQuery("");
    setShowFilter(false);
    formik.setFieldValue("fromDate", "");
    formik.setFieldValue("toDate", "");
    setPaginationModelDeactive({
      page: 0,
      pageSize: 25,
    });
    setSortModelDeactive([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    effectRun.current = true;
    if (index == 0) {
      deactiveSearchHideRef.current = false;
    } else {
      searchInputHideRef.current = false;
    }
  };
  const handleSearchList = () => {
    props.navigate(Constants.PRODUCT_PROMOTION_PATH);
    paginationModel.page = 0;
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveTaxData();
    }
  };
  const modifiedData = promotionData.map((tax) => ({
    ...tax,
    type:
      tax.type == Constants.BX1
        ? "Buy X Get 1 Free"
        : tax.type == Constants.BXPY
        ? "Buy X At Y Price"
        : "Sale Price X on Product Y",
    valid_from:
      tax.valid_from == null ||
      undefined == tax.valid_from ||
      "" == tax.valid_from
        ? "N.A."
        : commonDateFormate(tax.valid_from + "Z"),
    valid_until:
      tax.valid_until == null ||
      undefined == tax.valid_until ||
      "" == tax.valid_until
        ? "N.A."
        : // : moment(tax.valid_until + "Z").format("MM/DD/yyyy HH:mm:ss"),
          commonDateFormate(tax.valid_until + "Z"),
  }));
  const deactiveTaxData = promotionDeactiveData.map((tax) => ({
    ...tax,
    type:
      tax.type == Constants.BX1
        ? "Buy X Get 1 Free"
        : tax.type == Constants.BXPY
        ? "Buy X At Y Price"
        : "Sale Price X on Product Y",
    valid_from:
      tax.valid_from == null ||
      undefined == tax.valid_from ||
      "" == tax.valid_from
        ? "N.A."
        : commonDateFormate(tax.valid_from + "Z"),
    valid_until:
      tax.valid_until == null ||
      undefined == tax.valid_until ||
      "" == tax.valid_until
        ? "N.A."
        : commonDateFormate(tax.valid_until + "Z"),
  }));
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Promotions"}
          discription={"Manage promotions for your products"}
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              // backtracking
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                filter: curruntTab == 1 ? "" : searchQuery,
                showFilter: showFilter,
                fromDate: formik.values.fromDate,
                toDate: formik.values.toDate,
              };
              myContext.handleLoading(true);
              // pass all backtraking ralated all in create tax route
              props.navigate(Constants.PROMOTION_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={(e) => {
                let value =searchQuery.length > 0 ? 
                e.target.value: e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear} 
            />
            <Spacer />
          </Flex> */}
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <>
              <Flex
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "stretch", md: "center" }}
                gap={{ base: 2, md: 0 }}
                mt={6}
                mb={3}
                ml={4}
                mr={4}
              >
                <PosSearch
                  value={searchQuery}
                  // onChange={(e) => {
                  //   let value =
                  //     searchQuery.length > 0
                  //       ? e.target.value
                  //       : e.target.value.trim();
                  //   setSearchQuery(value);
                  // }}
                  onChange={onChange}
                  onSearch={handleSearchList}
                  handleClear={handleClear} // clear input value and get all data
                />
                {/* <Spacer /> */}
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  w={"3rem"}
                  ml={{ base: 0, md: "1rem" }}
                  mt={{ base: "1rem", md: 0 }}
                >
                  <IconButton
                    onClick={() => {
                      showDropDown();
                    }}
                    // isRound={false}
                    style={{
                      border: "1px solid #ccc",
                      boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                      variant: "posoutline",
                      color: Colors.posTextInfo,
                      borderColor: Colors.dividerColor,
                      borderRadius: "5px",
                      padding: 5,
                    }}
                  >
                    <FilterListIcon style={{ color: Colors.posTextInfo }} />
                  </IconButton>
                </Box>
              </Flex>
              {showFilter && (
                <SimpleGrid
                  columns={{ base: 1, sm: 1, md: 1, lg: 3, xl: 4 }}
                  // minChildWidth='150px'
                  // spacing='20px'
                  ml={4}
                  mr={4}
                  spacing={{
                    base: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "1rem",
                    xl: "1rem",
                  }}
                >
                  <Box>
                    <PosDatePicker
                      id={"fromDate"}
                      ref={calendarRef}
                      readOnlyInput={true}
                      value={formik.values.fromDate}
                      label={"Valid From"}
                      width={"100%"}
                      onFocus={() => {
                        if (
                          null !== toDateCalendarRef.current &&
                          toDateCalendarRef.current
                        ) {
                          toDateCalendarRef.current.hide();
                        }
                        setToDateFocus(false);
                        setTimeout(() => {
                          setFromDateFocus(true);
                        }, 1000);
                        formik.setFieldTouched("fromDate", true);
                      }}
                      handleSelectData={hadleFromDateSelect}
                      yearRange={"2000:2030"}
                      // maxDate={dateTimeValue}
                      viewDate={fromDateView}
                      showButtonBar={true}
                      showTime={true}
                    />
                    <Text color={Colors.errorColor}>
                      {formik.touched.fromDate && formik.errors.fromDate ? (
                        <span>{formik.errors.fromDate}</span>
                      ) : null}
                    </Text>
                  </Box>
                  <Box>
                    <PosDatePicker
                      id={"toDate"}
                      ref={toDateCalendarRef}
                      readOnlyInput={true}
                      value={formik.values.toDate}
                      label={"Valid Until"}
                      width={"100%"}
                      onFocus={() => {
                        if (
                          null !== calendarRef.current &&
                          calendarRef.current
                        ) {
                          calendarRef.current.hide();
                        }
                        setFromDateFocus(false);
                        setTimeout(() => {
                          setToDateFocus(true);
                        }, 1000);
                        formik.setFieldTouched("toDate", true);
                      }}
                      handleSelectData={(e) => {
                        effectRun.current = setPaginationModel({
                          page: 0,
                          pageSize: 25,
                        });
                        formik.setFieldValue("toDate", e.value);
                        setToDateView(e.value);
                        effectRun.current = true;
                      }}
                      yearRange={"2000:2030"}
                      minDate={
                        null !== formik.values.fromDate &&
                        "" !== formik.values.fromDate
                          ? formik.values.fromDate
                          : undefined
                      }
                      // maxDate={dateTimeValue}
                      viewDate={toDateView}
                      showButtonBar={true}
                      showTime={true}
                    />
                    <Text color={Colors.errorColor}>
                      {formik.touched.toDate && formik.errors.toDate ? (
                        <span>{formik.errors.toDate}</span>
                      ) : null}
                    </Text>
                  </Box>
                  <Box>
                    <PosFormButton
                      isDisabled={
                        searchQuery.length > 0 ||
                        formik.values.fromDate !== "" ||
                        formik.values.toDate !== ""
                          ? false
                          : true
                      }
                      onClick={() => {
                        clearFilterData();
                      }}
                      buttonText={"Clear"}
                      CancelButton={true}
                    />
                  </Box>
                  {/* </Box> */}
                </SimpleGrid>
              )}
            </>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels p={0}>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                // <PosTable
                //   columnNames={columnNames}
                //   rawdata={modifiedData}
                //   totalcount={totalcount}
                //   spliteButton={true}
                //   spliteOptions={options}
                //   DectivatedId={handleDeactive}
                //   onPageChange={handlePageChange}
                //   onRowsPerPageChange={handleRowsPerPageChange}
                //   handleSortColumn={handleSortColumn}
                //   sortColumnName={sortColumn}
                //   page={page}
                //   rowsPerPage={rowsPerPage}
                //   order={sortType}
                //   goEdit={(id) => handleEditPage(id)}
                // />
                <PosDataGridTable
                  columns={columnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  columnVisibilityModel={olColumnVisibilityModel}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    effectRun.current = true;
                    setSortModel(newSortModel);
                  }}
                  fileName={Constants.ACTIVE_PROMO_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound title={Constants.PROMOTION_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                // <PosTable
                //   columnNames={columnNames}
                //   rawdata={deactiveTaxData}
                //   totalcount={deactiveTotalCount}
                //   DectivatedId={handleDeactive}
                //   onPageChange={handlePageChange}
                //   onRowsPerPageChange={handleRowsPerPageChange}
                //   goEdit={(id) => handleEditPage(id)}
                //   goReactive={(id) => handleReactive(id)}
                //   handleSortColumn={handleSortColumn}
                //   sortColumnName={sortColumn}
                //   page={page}
                //   order={sortType}
                //   rowsPerPage={rowsPerPage}
                // />
                <PosDataGridTable
                  columns={deactivatedColumnNames}
                  rows={deactiveTaxData}
                  totalcount={deactiveTotalCount}
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModelDeactive) => {
                    effectRun.current = true;
                    setPaginationModelDeactive(newPaginationModelDeactive);
                  }}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  columnVisibilityModel={olColumnVisibilityModel}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  fileName={Constants.DEACTIVE_PROMO_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound
                  title={Constants.DEACTIVE_PROMOTION_NOT_FOUND}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(PromotionList);
