import { Box, useMediaQuery } from "@chakra-ui/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { tablePaginationClasses } from "@mui/material/TablePagination";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Colors from "../../assets/colors/Colors";
import { Mtheme } from "../../theme";

const areEqual = (prevProps, nextProps) => prevProps == nextProps;

function TablePaginationActions(props) {
  const theme = createTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        style={{ color: Colors.posTextInfo }}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        style={{ color: Colors.posTextInfo }}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight style={{ color: Colors.posTextInfo }} />
        ) : (
          <KeyboardArrowLeft style={{ color: Colors.posTextInfo }} />
        )}
      </IconButton>
      <IconButton
        style={{ color: Colors.posTextInfo }}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft style={{ color: Colors.posTextInfo }} />
        ) : (
          <KeyboardArrowRight style={{ color: Colors.posTextInfo }} />
        )}
      </IconButton>
      <IconButton
        style={{ color: Colors.posTextInfo }}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon style={{ color: Colors.posTextInfo }} />
        ) : (
          <LastPageIcon style={{ color: Colors.posTextInfo }} />
        )}
      </IconButton>
    </Box>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const PosDataGridTable = React.memo((props) => {
  // const [isMobile] = useMediaQuery("(max-width: 500px)");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [hideFooter, setHideFooter] = useState(false);
  const [hideExport, setHideExport] = useState(false);
  const onFilterChange = React.useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    let flag =
      filterModel.items.length > 0 &&
      undefined !== filterModel.items[0].value &&
      "" !== filterModel.items[0].value;
    setHideFooter(flag);
  }, []);
  return (
    <ThemeProvider theme={Mtheme}>
      <DataGrid
        autoHeight={props.autoHeight === false ? false : true}
        {...props.rows}
        rows={props.rows}
        columns={props.columns}
        initialState={{
          density: "standard",
        }}
        sortingOrder={["asc", "desc"]}
        paginationModel={props.paginationModel}
        paginationMode={props.paginationMode}
        sortingMode={props.sortingMode}
        onPaginationModelChange={props.onPaginationModelChange}
        pageSizeOptions={[10, 25, 50, 100]}
        onFilterModelChange={onFilterChange}
        rowCount={props.totalcount}
        hideFooter={props.hideFooter || hideFooter}
        columnHeaderHeight={
          undefined != props.columnHeaderHeight ? props.columnHeaderHeight : 55
        }
        slots={props.slots}
        columnVisibilityModel={props.columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          props.onColumnVisibilityModelChange(newModel)
        }
        disableRowSelectionOnClick
        sx={{
          // "& .MuiDataGrid-columnHeaderTitle": {
          //   whiteSpace: isMobile ? "normal": props.whiteSpace,
          //   lineHeight: "normal",
          // },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-columnHeader": {
            fontWeight: 300,
            fontSize: "1rem",
            lineHeight: "1.18rem",
            letterSpacing: "-1.5%",
            color: Colors.posTextInfo,
            backgroundColor: Colors.modifierTable,
            height: undefined != props.columnHeaderHeight ? props.columnHeaderHeight : "unset !important",
            minHeight: undefined != props.columnHeaderHeight ? "0px !important" : "55px !important",
          },
          "& .MuiDataGrid-cell": {
            fontWeight: 300,
            fontSize: "1rem",
            color: Colors.posTextInfo,
            alignContent: "center !important",
          },
          "& .MuiDataGrid-footerContainer": {
            justifyContent: "center",
          },
          "& .MuiTablePagination-root .MuiTablePagination-selectLabel ": {
            // display: isMobile ? "none !important" : "inline-flex"
            display: "inline-flex !important",
          },
          "& .MuiTablePagination-root .MuiTablePagination-input": {
            display: "inline-flex !important",
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            minHeight: "36px",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            minHeight: "52px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            minHeight: "67px",
          },
          [`& .${tablePaginationClasses.selectLabel}`]: {
            color: Colors.posNavbarLink,
            fontWeight: 300,
            fontSize: "0.75rem",
            lineHeight: "1rem",
            letterSpacing: "-1.5%",
          },
        }}
        sortModel={props.sortModel}
        onSortModelChange={(newSortModel) =>
          props.onSortModelChange(newSortModel)
        }
        slotProps={{
          pagination: {
            labelRowsPerPage: "Items per page",
            ActionsComponent: TablePaginationActions,
          },
          toolbar: {
            printOptions: { disableToolbarButton: true },
            csvOptions: {
              fileName:
                undefined != props.fileName ? props.fileName : document.title,
              // disableToolbarButton: hideExport,
              disableToolbarButton: true,
            },
          },
          ...props.slotProps,
        }}
        filterMode={props.filterMode}
        onFilterModelChange={props.onFilterModelChange}
        // onStateChange={(e) => {
        //   let data = Object.values(e.filter.filteredRowsLookup).every(
        //     (item) => !item
        //   );
        //   if (hideExport !== data) {
        //     setHideExport(data);
        //   }
        // }}
        // filterModel={props.filterModel}
        disableColumnFilter={
          undefined !== props.disableColumnFilter
            ? props.disableColumnFilter
            : true
        }
        // loading={props.loading}
        rowHeight={55}
        getRowHeight={() => "auto"}
        disableColumnMenu
      />
    </ThemeProvider>
  );
}, areEqual);

export default PosDataGridTable;
