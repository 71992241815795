import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import Colors from "../../../../../assets/colors/Colors";
import * as Constants from "../../../../../constants/Constants";

import {
  AppContext,
  PosTab,
  PosSearch,
  PosLable,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  PosSpliteButton,
  PosInput,
  PosTextArea,
} from "../../../../../components/index";
import {
  endOfTodaysDay,
  startOfTodaysDay,
  twofix,
} from "../../../../../helpers/utils/Utils";
import { useLocation } from "react-router-dom";
import { WithRouter } from "../../../../../navigators/WithRouter";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import {
  getPayOutList,
  getDectivePayOutList,
  getdatapos,
} from "./PayoutService";
import { PosErrorHook } from "../../../../../hooks";
import { GridToolbar } from "@mui/x-data-grid";

const PayoutCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [posStation, setposStationData] = useState([]);
  const [selectedPosStation, setSelectedPosStation] = useState([]);
  const [users, setUserData] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state); // store in state

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setposStationData(loaderResponse?.data?.data?.stations);
          setUserData(loaderResponse?.data?.data?.users);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.PAY_OUT_REPORT_ROUTE, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.PAYOUT_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedPosStation(e.value);
      formik.setFieldValue("selectedPosStation", e.value.name);
    }
    //   formik.setFieldValue("selectedLoactions", e.value);
  };

  const formik = useFormik({
    initialValues: {
      selectedPosStation: "",
      selectedUsers: "",
      amount: "",
      reason: "",
    },
    validationSchema: Yup.object({
      selectedPosStation: Yup.string().required(Constants.POS_STATION_REQUIRED),
      selectedUsers: Yup.string().required(Constants.EMPLOYEE_REQUIRED),
      // amount: Yup.string().required(
      //   Constants.AMOUNT_REQUIRED
      // ),
      amount: Yup.number()
        .typeError(Constants.PAYOUT_PLEASE_ENTER_A_VALID_AMOUNT)
        .max(999999.99, Constants.PAYOUT_AMOUNT_MAX_ERROR)
        .required(Constants.AMOUNT_REQUIRED)
        .min(0.01, Constants.PAYOUT_AMOUNT_MIN_ERROR),

      reason: Yup.string()
        .required(Constants.REASON_REQUIRED)
        .max(5000, Constants.PAY_IN_REASON_GREATER_VALUE),
    }),
    onSubmit: async () => {
      // myContext.handleLoading(true);
      // setButtonDisable(true);
      // actionRun.current = true;
      // submit(payload, {
      //   method: Constants.POST,
      //   path: Constants.TILL_REPORT_ROUTE,
      // });
    },
  });

  const handleSubmitButton = () => {
    myContext.handleLoading(true);
    const payload = {
      user_id: parseInt(selectedUsers.id),
      user_id_dropdown: JSON.stringify({
        id: selectedUsers.id,
        name: selectedUsers.name,
      }),
      pos_station_id: parseInt(selectedPosStation.id),
      pos_station_id_posStation: JSON.stringify({
        id: selectedPosStation.id,
        name: selectedPosStation.name,
      }),
      amount: parseFloat(formik.values.amount).toFixed(2),
      reason: formik.values.reason,
    };
    actionRun.current = true;
    setButtonDisable(true);
    submit(payload, {
      method: Constants.POST,
      path: Constants.PAY_OUT_REPORT_ROUTE,
    });
  };

  const handleUserSelect = (e) => {
    if (undefined !== e.value) {
      setselectedUsers(e.value);
      formik.setFieldValue("selectedUsers", e.value.name);
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        // formik.values.selectedPosStation.length <= 0 ||
        (formik.errors.selectedPosStation ||
        formik.errors.selectedUsers ||
        formik.errors.amount ||
        formik.errors.reason ||
        buttonDisable
          ? true
          : false)
      )
    ) {
      formik.handleSubmit();
    }
  };

  return (
    <Box minH="100vh" flex="1" pb="3.31rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              if (i === 0) {
                props.navigate(Constants.REPORT_PATH, {
                  state: stateData.state,
                });
              } else if (i === 1) {
                props.navigate(Constants.PAY_OUT_REPORT_ROUTE, {
                  state: stateData.state,
                });
              }
            }}
            breadCrumNames={["Report", "Pay Out", "Create a Pay Out"]}
            breadCrumTitle={"Create Pay Out"}
          />
        </Box>
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              props.navigate(Constants.PAY_OUT_REPORT_ROUTE, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            isDisabled={
              formik.values.selectedPosStation.length <= 0 ||
              formik.errors.selectedPosStation ||
              formik.errors.selectedUsers ||
              formik.errors.amount ||
              formik.errors.reason ||
              buttonDisable
                ? true
                : false
            }
            // isDisabled={
            //   !formik.isValid || !formik.dirty || buttonDisable
            //     ? // selectedRoles.length === 0
            //       true
            //     : false
            // }
            buttonsubmit={"Submit"}
            SubmitButton={true}
            onClick={handleSubmitButton}
          />
        </Stack>
      </Flex>
      <Box
        mt={{ base: 2, md: "2rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        p="8"
        // onKeyDown={handleKeyDown}
      >
        <Text
          fontSize="1.23rem"
          pt={"1.56rem"}
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontSize: "1.13rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          Create Pay Out
        </Text>
        <Stack spacing={2}>
          <PosLable
            name={"POS Station"}
            requiredLabel={true}
            fontSize="0.94rem"
            fontStyle="normal"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />

          <PosDropDown
            id="selectedPosStation"
            options={posStation}
            value={selectedPosStation}
            onChange={handleOptionSelect}
            className="w-full md:w-20rem"
            width={"100%"}
            onBlur={(e) => {
              formik.setFieldTouched("selectedPosStation");
              formik.handleBlur(e);
            }}
            multiSelect={false}
            optionLabel="name"
            placeholder="Select A Pos Station"
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedPosStation &&
            formik.errors.selectedPosStation ? (
              <span>{formik.errors.selectedPosStation}</span>
            ) : null}
          </Text>
          {/* </WrapItem>
        </Wrap> */}
        </Stack>
        <Stack spacing={2}>
          <PosLable
            name={"Employee"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosDropDown
            id="selectedUsers"
            options={users}
            value={selectedUsers}
            onChange={handleUserSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedUsers");
              formik.handleBlur(e);
            }}
            className="w-full md:w-20rem"
            width={"100%"}
            multiSelect={false}
            optionLabel="name"
            placeholder="Select A User"
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedUsers && formik.errors.selectedUsers ? (
              <span>{formik.errors.selectedUsers}</span>
            ) : null}
          </Text>
          {/* <Text color={Colors.errorColor}>
              {formik.touched.selectedUsers && errors.length > 0 ? (
                <span>{errors}</span>
              ) : null}
            </Text> */}
        </Stack>
        <Stack spacing={2}>
          <PosLable
            name={"Amount"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="amount"
            mt={"1rem"}
            placeholder={"Enter a Amount"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.amount}
            // handleBlur={formik.handleBlur}
            // onKeyDown={handleKeyDown}
            posNumberInput={true}
            inputType={"number"}
            onKeyDown={(e) => {
              if (e.key === "-" || e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
            }}
            handleBlur={(e) => {
              formik.handleBlur(e);
              formik.setFieldValue("amount", twofix(formik.values.amount) || "");
            }}
            inputError={formik.touched.amount && formik.errors.amount}
          />
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.amount && formik.errors.amount ? (
              <span>{formik.errors.amount}</span>
            ) : null}
          </Text>
        </Stack>
        <Stack>
          <PosLable
            requiredLabel={true}
            name={"Reason"}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosTextArea
            id="reason"
            placeholder="Enter a Reason"
            onChange={formik.handleChange}
            value={formik.values.reason}
            onBlur={formik.handleBlur}
            onKeyDown={(e) => {
              if (e.key === " " && !formik.values.reason) {
                e.preventDefault();
              }
            }}
            inputerror={formik.touched.reason && formik.errors.reason}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.reason && formik.errors.reason ? (
              <span>{formik.errors.reason}</span>
            ) : null}
          </Text>
        </Stack>
        {null != formik.values.reason &&
        "" != formik.values.reason &&
        5000 - formik.values.reason.length > 0 ? (
          <Text
            pt={"0.13rem"}
            color={Colors.textareaColor}
            fontSize={"0.94rem"}
            fontStyle={"normal"}
            fontWeight={300}
            lineHeight={"normal"}
            letterSpacing={"-0.01rem"}
          >
            {5000 - formik.values.reason.length} characters left for reason
          </Text>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};
export default WithRouter(PayoutCreate);
