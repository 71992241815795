import { Box, Flex } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useActionData, useLoaderData } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import { AppContext, PosBreadCrumb } from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const BillingView = (props) => {
  const { error } = PosErrorHook();
  const [userData, setUserData] = useState([]);

  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const myContext = useContext(AppContext);
  const [billingdetails, setbillingdetails] = useState([]);

  useEffect(() => {
    if (
      undefined != loaderResponse &&
      null != loaderResponse &&
      {} != loaderResponse
    ) {
      if (
        undefined != loaderResponse?.error &&
        null != loaderResponse?.error &&
        loaderResponse?.error
      ) {
        let actionData = loaderResponse;
        error({ actionData });
      } else if (
        null !== loaderResponse.data[Constants.FLAGE] &&
        true === loaderResponse.data[Constants.FLAGE]
      ) {
        setbillingdetails(loaderResponse.data?.data?.billingdetails);

        setUserData(loaderResponse.data);
        myContext.handleLoading(false);
      } else {
        let actionData = loaderResponse;
        error({ actionData });
      }
    }
  }, [loaderResponse]);

  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1">
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            breadCrumNames={["Billing Details ", "View Billing Details"]}
            breadCrumTitle={"View Billing Details"}
          />
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          mb={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        ></Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(BillingView);
