import { defineStyleConfig } from "@chakra-ui/react";
import Colors from "../../assets/colors/Colors";

const ButtonTheme = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "500",
  },
  variants: {
    outline: {
      border: "1px solid",
      borderColor: Colors.posCancleButtonRed,
      color: Colors.posCancleButtonRed,
      _hover: {
        background: Colors.buttonOutlineHover,
        borderColor: Colors.buttonOutlineHover,
      },
      _focus: {
        background: Colors.buttonOutlineFocus,
        borderColor: Colors.inputBorderColor,
        boxShadow: "0px 0px 0px 3px" + Colors.taxInfo,
      },
      _press: {
        background: Colors.inputBorderColor,
      },
      _disabled: {
        backgroundColor: Colors.buttonOutlineDisable,
      },
      _focusVisible: {
        background: Colors.navbarLink,
        boxShadow: "0px 0px 0px 3px " + Colors.taxInfo,
      },
    },
    solid: {
      bg: Colors.taxInfo,
      color: Colors.loginAuthBackground,
      _hover: {
        background: Colors.buttonHover,
      },
      _active: {
        background: Colors.buttonHover,
      },
      _disabled: {
        _hover: {
          background: Colors.buttonHover,
        },
      },
      _focusVisible: {
        background: Colors.navbarLink,
        boxShadow: "0px 0px 0px 3px " + Colors.taxInfo,
      },
    },
    ghost: {
      fontWeight: "500",
      fontSize: "0.938rem",
      border: "1px solid",
      borderColor: Colors.modifierTable,
      borderRadius: "0.625rem",
      bg: Colors.ghostBackground,
      color: Colors.posTextInfo,
    },
    posghost: {
      fontWeight: "300",
      fontSize: "0.94rem",
      border: "1px solid",
      borderColor: Colors.resetButton,
      borderRadius: "0.625rem",
      bg: Colors.campCardBgColor,
      color: Colors.posCancleButtonRed,
    },
    avatar: {
      color: Colors.mainHeader,
      borderColor: Colors.inputBorderColor,
      fontSize: "sm",
      height: 12,
      _hover: {
        background: Colors.buttonOutlineHover,
      },
    },
    invoerror: {
      color: Colors.white,
      backgroundColor: Colors.posCancleButtonRed,
      fontSize: "sm",
      lineHeight: 5,
      height: 12,
      _hover: {
        background: Colors.buttonOutlineHover,
      },
    },
    posoutline: {
      border: "0.5px solid",
      borderColor: Colors.dividerColor,
      color: Colors.dividerColor,
      alignSelf:"center",
      backgroundColor: Colors.buttonOutlineFocus,
      _hover: {
        background: Colors.buttonOutlineHover,
        borderColor: Colors.buttonOutlineHover,
      },
      _focus: {
        background: Colors.buttonOutlineFocus,
        borderColor: Colors.inputBorderColor,
        boxShadow: "0px 0px 0px 3px" + Colors.taxInfo,
      },
      _press: {
        background: Colors.inputBorderColor,
      },
      _disabled: {
        backgroundColor: Colors.buttonOutlineDisable,
      },
      _focusVisible: {
        background: Colors.navbarLink,
        boxShadow: "0px 0px 0px 3px " + Colors.taxInfo,
      },
    },
  },
});

export default ButtonTheme;
