import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useMediaQuery
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "./../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage
} from "./../../../../components/index";
import * as Constants from "./../../../../constants/Constants";
import { PosErrorHook } from "./../../../../hooks";
import { WithRouter } from "./../../../../navigators/WithRouter";
import { SimpleReportLayout } from "../../../.././layouts/index";
const CashbackReport = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(1);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [applyClicked, setApplyClicked] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const [isLarge] = useMediaQuery("(min-width: 906px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isSmall] = useMediaQuery("(min-width: 571px)");
  const [isMeduim] = useMediaQuery("(max-width: 768px)");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "payment_id",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const columnNames = [
    { field: "order_id", headerName: "Order Id", sortable: false,...actionColumn, },
    { field: "payment_id", headerName: "Payment Id", sortable: true, ...actionColumn,},
    {
      field: "amount",
      headerName: "Amount($)",
      sortable: true,
      ...actionColumn,
    },
  ];

  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if(undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1){
              setSelectedLocation(loaderResponse?.data?.data?.location[0])
              formik.setFieldValue("selectedLoactions", loaderResponse?.data?.data?.location[0].name);
            }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    getCashbackReportData();
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Cashback Report successfully exported."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, Constants.CASHBACK_EXCEL_FILENAME);
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  const handleExportReport = () => {
    myContext.handleLoading(true);
    if (
      // formik.values.selectedLoactions.length > 0 &&
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      const formattedLocation = {
        label: selectedLocation.name,
        value: selectedLocation,
      };
      let data = {
        getreport: false,
        //   location: JSON.stringify(formik.values.selectedLoactions),
        location: JSON.stringify(formattedLocation),
        type: "xls",
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.CASHBACK_REPORT_ROUTE,
      });
    }
  };
  const getCashbackReportData = () => {
    if (
      // formik.values.selectedLoactions.length > 0 &&
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      setButtonDisable(true);
      setLoading(true);
      setShowTable(false);
      setApplyClicked(true);
      const formattedLocation = {
        location: {
          label: selectedLocation.name,
          value: selectedLocation,  
        },
      };
      let data = {
        getreport: true,
        location: JSON.stringify(formattedLocation),
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.CASHBACK_REPORT_ROUTE,
      });
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
    setSelectedLocation(e.value);
    formik.setFieldValue("selectedLoactions", e.value.name);
    //   formik.setFieldValue("selectedLoactions", e.value);
    }
  };
  const handleResetAllData = () => {
    setSortModel([
      {
        field: "payment_id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSelectedLocation([])
    setTableData([]);
    setShowTable(true);
    setApplyClicked(false);
    setTotalCount(0);
    formik.resetForm();
  };
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card flex justify-content-center">
        <PosDropDown
                id="selectedLoactions"
                options={locations}
                value={selectedLocation}
                resetFilterOnHide={true}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                // multiSelect={true}
                optionLabel="name"
                placeholder="Select Locations*"
                width={
                  isExtraSmall
                    ? "100%"
                    : isLarge || (isSmall && isMeduim)
                    ? "20rem"
                    : "13.25rem"
                }
                height={"2.5rem"}
                className="w-full md:w-20rem"
                // maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
        </Box>
        
      </>
    );
  };
  const modifiedData = tableData.map((data) => ({
    id: data.id,
    amount: data.amount,
    payment_id: data.payment_id,
    order_id: data.payment.order_id,
  }));
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Cashback"]}
          breadCrumTitle={"Cashback"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          { tableData.length > 0 && ( <PosIconButton
            name={Constants.EXPORT_BUTTON}
            onClick={handleExportReport}
            exportIcon={true}
            width={"7.5rem"}
          />)}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
         direction={isExtraSmall ? "column" : "row"}
         justifyContent={"space-between"}
        >
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
          {/* <Wrap
            alignItems={{ base: "stretch", md: "center" }}
            spacing={4}
            mt={6}
            mb={4}
            ml={4}
            mr={4}
          >
            <WrapItem flexDirection={"column"}>
              <PosDropDown
                id="selectedLoactions"
                options={locations}
                value={selectedLocation}
                resetFilterOnHide={true}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                // multiSelect={true}
                optionLabel="name"
                placeholder="Select Locations*"
                width={"20rem"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                // maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </WrapItem>
          </Wrap> */}
          {/* <Spacer /> */}
          <Stack
             direction={"row"}
             justifyContent={isExtraSmall ? "flex-start" : "flex-end"}
             alignItems={"flex-start"}
             pl={isExtraSmall ? 4 : 0}
             pt={isExtraSmall ? 0 : 4}
             pr={4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              buttonText={"Reset"}
              isDisabled={applyClicked ? false : true}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.errors.selectedLoactions ||
                buttonDisable
              }
              onClick={getCashbackReportData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : tableData.length > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                // noAction={true}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={setPaginationModel}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.CASHBACK_CSV}
              />
            </Flex>
          ) : (
            <PosNoDataFound title={"There are no Cashback."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(CashbackReport);
